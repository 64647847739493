import React from 'react';
import { Grid } from "@material-ui/core";
import "./template.scss"; 
const TempFooter = () => {
    return(
        <>
        <Grid container item spacing={2} lg={12} md={12} className="m-0 preview-footer">
        <Grid item lg={12} md={12} sm={12} xs={12} className="p-0 text">
          &nbsp;&nbsp;Powered by <img src="/static/img/logo.png" height="37" alt="OTS Logo" /> {new Date().getFullYear()} All Rights Reserved
        </Grid>
        </Grid>
        </>
    );
}
export default TempFooter;

