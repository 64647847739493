import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@material-ui/core";

import { getEventByAccountId, getEventById } from '../../services/eventService';
import Notification from "../../components/Notification";
import { generateReport, listReports } from "../../services/reportService";
import Controls from "../../components/controls/Controls";


const ReportBuilder = ({ AccountId }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const cookies = new Cookies();
  const [reports, setReports] = useState([]);
  useEffect(() => {
    console.log('AccountId', AccountId);    
    setReports([]);
    setFilters({});
    listReports(AccountId).then(reports => {
      setReports(reports);
    }).catch(err => {
      notification(err.message, 'error');
    })
    getEventByAccountId({"accountid" : Number(AccountId)}).then((res) => {
      setEvents(res.data);
    })
  }, [AccountId]);  
  const history = useHistory();
  const [report, setReport] = useState('');
  const [filters, setFilters] = useState({});
  const [events, setEvents] = useState([]);

  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }

  const handleReportChange = (e) => {
    console.log('value', e.target.value);
    const report = reports.find(r => r.reportId == e.target.value)
    setReport(report);
  }

  const handleGenerateReport = (e) => {
    if (report) {
      generateReport(report.reportId, filters).then((result) => {
          
      }).catch((error) => {
        notification(error?.response?.data?.error_msg || error.statusText, 'error')
      });      
    } else {
      notification('Please choose a report first', 'error');
    }
  }




  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const handleEventChange=(e)=>{  
    setFilters({
      ...filters,
      eventId: e.target.value,
    });
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }

  return (
    <div>
      <div>
        <Card mb={3} className="tile-card">
          <CardHeader
            title="Report Builder"
            className="card-title mb-0" />

          <CardContent className="tile-title pt-0">
            <Grid container direction="column">
              <Grid item>
                <select className="select-box mb-2 mr-2" value={report?.id} onChange={handleReportChange} style={{ width: '200px' }}>
                <option value="">Select Report</option>
                {reports.map(r=> {
                  return <option key={r.reportId} value={r.reportId}>{r.name}</option>
                })}
              </select >
              </Grid>
              {
                report?.has_event_filter &&
                  <select className="select-box mb-2 mr-2" value={filters?.eventId} onChange={handleEventChange} style={{width: '200px'}}>
            	    <option key='eventid' value="">Select Event</option>
                  {
                    events.map(e => {
                      return <option key={e.id} value={e.id}>{e.eventname}</option>
                    })
                  }
                  
                  </select >                          
              }
              <Grid item>
              </Grid>
              <Grid item>
                <Controls.Button
                type="button"
                onClick={handleGenerateReport}
                text="Generate Report" className="link-red" />
              </Grid>

            </Grid>

          </CardContent>
        </Card>
      </div>
      <Notification notify={notify}
        setNotify={setNotify} />
    </div>
  )
}

export default ReportBuilder