import React ,{useState,useEffect} from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import BackToTop from "../../components/controls/BackToTop";
import "./dashboard.scss";
import {getEventDashboardByAccountId,getTopsellingEvent,getEventBarChartByAccountId} from '../../services/eventService';
import userService from '../../services/userService';	
import UpcomingEvents from "./UpcomingEvents";
import Overview from "./Overview";
import TopSellingEvents from "./TopSellingEvents";
import LinearWithValueLabel from "../dashboard/CustomizedProgressBars";

import {
  makeStyles,
  Grid,
  Divider, 
  Typography,
  Toolbar,
  Box,
  Button
} from "@material-ui/core";
import { universaldate } from "../../utils/dateFormat";
import { initWidget } from '../../helper/Widget';
import { setFilteredEventDetails } from "../../redux/actions/eventActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  pageContent:{
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },   
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
    }
  },
}));
const drawerWidth = 250;
const DefaultDashboard = () => {
  const cookies = new Cookies();
  const classes = useStyles(); 
  const dispatch = useDispatch();
  const filteredEventDetails = useSelector(state => state.eventReducer.filteredEventDetails);
  const [eventTopDetails,setEventTopDetails]=useState([]);
  const [eventUpCommingDetails,setEventUpCommingDetails]=useState([]);
  const [records, setRecords] = useState([]);
  const [chartDate,setChartDate] = useState([]);
  const [chartRevenue,setChartRevenue] = useState([]);
  const [eventbarChartDetails,setEventbarChartDetails] = useState([]);
  const [visible,Setvisible] = useState(false);
  const [remainingTicket, setRemainingTicket] = useState(1);;
  const [chartValue,setChartValue]=useState([]);

  const currentUser = cookies.get('currentUser');
  const [selectedAccountId,setSelectedAccountId]=useState(currentUser.accountid);
  const [showDashboard,setShowDashboard]=useState(true);
  const [showLoading,setShowLoading]=useState(true);
  const firstName = currentUser.firstName? currentUser.firstName:''
  const lastName = currentUser.lastName? currentUser.lastName:''
  const defaultPageLength = 10;

  useEffect(() => {  
    let cancel = true;  
    if(currentUser.roleid===1){
    getEventDetailValues(currentUser.accountid);
    userService.getUserAccounts()
    .then(result=>{ 
      if (cancel){
      setRecords([...result]);            
    }
    }).catch((error)=>{
      setShowLoading(false);
    })
    }else{
      getEventDetailValues(currentUser.accountid);
    }    
    if(currentUser.roleid===3){
      setShowDashboard(false);
    }
    return () => {
      cancel = false;
    }
},[]); 

useEffect(() => {  
  let cancel = true;  
  if (currentUser.roleid !== 3 && filteredEventDetails.length > 0){
    getEventBarChartByAccountId({"accountid" : selectedAccountId})
    .then((res) => { 
      if(cancel){       
      if(res.data!==undefined){
        
        for(let i=0;i<res.data.BarChart.length && i<14 ;i++){
          res.data.BarChart[i].eventDate=universaldate(res.data.BarChart[i].eventDate);
        }
        setEventbarChartDetails(res.data.BarChart.slice(0,14));        
        let value=res.data.BarChart.slice(0,7);
        let date=[];
        let revenue=[];
        value.forEach((chart)=>{            
            date.push(chart.eventDate);
            revenue.push(chart.totalEventCost)         
        })
        setChartDate(date);
        setChartRevenue(revenue);

        setChartValue(res.data.LineChart);
        let values=res.data.LineChart;
        let TotalsoldPerc=(values.totalsoldcount/(values.totalsoldcount+values.remainingTicketcount))*100;
        setRemainingTicket(TotalsoldPerc)

        Setvisible(true);

      }else{
        setChartDate([]);
        setEventbarChartDetails([]);
        Setvisible(false);
      }
    }
    }).catch((error)=>{
      setEventbarChartDetails([])
      Setvisible(false);
    })
  }
  return () => {
    cancel = false;
  }
},[filteredEventDetails]);
     
const getEventDetailValues=(id)=>{
  let cancel = false;  
  getEventDashboardByAccountId({"accountid" : Number(id) }, defaultPageLength, 1)
  .then((res) => {  
    if (cancel) return;  
    if(res.data!==undefined){
      setShowLoading(false);
      for(let a=0 ;a<res.data.length;a++){
        res.data[a].customerdateformat=universaldate(res.data[a].eventdatetime);
      }
    dispatch(setFilteredEventDetails(res.data));
    setEventUpCommingDetails(res.data);
    }
    setShowLoading(false);
   }).catch((error)=>{
     setShowLoading(false);
   })
   if(currentUser.roleid!==3){
   getTopsellingEvent({"account_id" : Number(id), off_set : 0 })
   .then((res) => {  
    for(let a=0 ;a<res.data.length;a++){
      res.data[a].customerdateformat=universaldate(res.data[a].eventdatetime);
    }
    setEventTopDetails(res.data)
   }).catch((err)=>{

   })
  }
   return () => {
    cancel = true;
  }
}

const getAccountidDetails = (e) => {  
  setShowLoading(true);
  dispatch(setFilteredEventDetails([]));
  setEventTopDetails([]);
  setEventUpCommingDetails([]);
  setChartDate([]);
  Setvisible(false);
  getEventDetailValues(e.target.value)
  setSelectedAccountId(e.target.value)
}

const openWidget = () => {
  initWidget(selectedAccountId);
}

  return ( 
   <div>  
      <Helmet title="Dashboard" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
        <Grid container spacing={0} alignItems="center">                      
          <Grid item lg={6} md={4} sm={7} xs={12}>
            <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
              User Dashboard
            </Typography>
            <Box variant="subtitle1" className="welcome-msg">
              <span style={{ flexGrow: '1', paddingBottom: '12px' }}>Welcome, <strong>{firstName} {lastName}</strong></span>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={5} xs={6}>
            <Toolbar style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button onClick={openWidget} className="link-red">
                Events Calendar
              </Button>
            </Toolbar>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={6}>
          <Toolbar className="selectbar">                     
          <Box>
          {records.length>0?(
          <select                     
                    value={selectedAccountId}
                    onChange={getAccountidDetails}
                    className="select-box mt-0"
                  >
                    <option value="0">Select Customer</option>
                    {(records).map((v, k) => {
                      if(v.roleid!==1)
                        return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                      else
                        return null
                    })}
                  </select>
        ):(<div></div>)} 
          </Box>
          </Toolbar>
          </Grid>
        </Grid>
       </Grid>       
       <Divider />
      <div className={classes.pageContent}>   
  
      {showDashboard?(
        <div>
          <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl>
      <Overview chartRevenue1={chartRevenue} chartDate1={chartDate} eventFullDetails1={eventbarChartDetails} visible={visible}/>
      </Grid>     
         <Grid item xs={12} sm={12} md={8} lg={8} xl className="pb-0">
         <TopSellingEvents eventFullDetails={eventTopDetails} showLoadingTopsell={showLoading} id={selectedAccountId}/>
       </Grid>
         <Grid item xs={12} sm={12} md={6} lg={12} xl>
         <UpcomingEvents eventFullDetails={eventUpCommingDetails} showLoadingUpComing={showLoading} id={selectedAccountId}/>
         </Grid>
         <Grid item xs={12} sm={12} md={6} lg={12} xl className="pb-0">
          <LinearWithValueLabel chartValue1={chartValue} remainingTicket1={remainingTicket} visible={visible}/>
         </Grid>
         </Grid>  
         </div>
      ): null}
     
      </div>
      <BackToTop/>
      </main>
     <Footer /> 
      </div>    
  
  );
} 

export default DefaultDashboard;
