import * as types from "../../constants";

const initialstate = {
  filterData: {
    selectedEventName: '',
    selectedEventTime: '',
    selectedDate: null,
    dateInputValue: ''
  },
  filteredEventDetails: [],
}

export default function reducer(state = initialstate, actions) {
  switch (actions.type) {
    case types.SET_EVENT_FILTER_DATA:
      return {
        ...state,
        filterData: Object.assign({}, state.filterData, actions.data)
      };
    case types.SET_FILTERED_EVENT_DETAILS:
      return {
        ...state,
        filteredEventDetails: actions.events,
      };
    default:
      return state;
  }
}