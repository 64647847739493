import React, { useEffect, useRef, useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import CONFIG from './config';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import './map.scss';



const Map = ({ mapData, range, setCountryId, setStateId, ...props }) => {
  const [currentMap, setCurrentMap] = useState(CONFIG.DEFAULT.WORLD);
  const [loading, setLoading] = useState(false);
  const [trackMap, setTrackMap] = useState([]);
  const mapRef = useRef(null);
  const map = mapRef.current && mapRef.current.getMapObject();
  
  useEffect(() => {
    return () => {
      if(mapRef.current) {
        mapRef.current.tip.remove();
      }
    }
  }, [mapRef])


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300)
    setTimeout(()=> { Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el) => { el.style.display = 'none' }); },100);
  }, [mapData])


  const interpolateColor = (color1, color2, factor) => {

    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  const interpolateColors = (color1, color2, steps) => {
    var stepFactor = 1 / (steps - 1),
      interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for (var i = 0; i < steps; i++) {
      interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
  }

  var colorArray = interpolateColors('rgb(255, 243, 58)', ' rgb(233, 62, 59)', 5);

  const handleClick = (e, countryCode) => {

    if ((CONFIG.COUNTRIES[countryCode] && CONFIG.COUNTRIES[(countryCode).toUpperCase()]) || countryCode === '') {
     
      // console.log('refMap',mapRef.current.tip.removeAllMarkers())
      setLoading(true);
      if (countryCode) {
        setCurrentMap(CONFIG.COUNTRIES[(countryCode).toUpperCase()]);
        const track = trackMap;
        track.push((countryCode).toUpperCase());
        setTrackMap(track)
        setCountryId(countryCode)
      } else {
        setCurrentMap(CONFIG.DEFAULT.WORLD);
        setTrackMap([])
      }
      setTimeout(() => {
        setLoading(false);
      }, 300)
    } else if (countryCode !== '') {
      setStateId(countryCode.split('-').pop())
      const track = trackMap;
      track.push((countryCode).toUpperCase());
      setTrackMap(track)
    }
  };

  const handleBack = () => {
    const track = trackMap;
    const countryCode = track.pop();
    setTrackMap(track)

    if (track.length > 0) {
      setLoading(true);
      setLoading(false);
      const cont = countryCode.split('-');
      setCountryId(cont[0])
      setStateId('')
    } else {
      handleClick('', '');
      setCountryId('')
      setStateId('')
    }

  }

  const nominalValue = () => {
    let step = Math.round(range[1] / 5);
    return (
      <div className="heatmap">
        {colorArray.map((v, k) => <div className="heatmapWrapper">
          <div className="value" style={{ 'background': `rgba(${v})` }}></div>
          <span className="f-12">{step * k}</span>
        </div>)}
      </div>
    )
  }



  return !loading?<>
<div>
    {trackMap.length > 0 ? <div className="backbutton" data-html2canvas-ignore="true"  onClick={handleBack}><KeyboardArrowLeftIcon />Back</div> : null}
  
    </div>
      {nominalValue()}
    <VectorMap
      map={currentMap}
      mapRef={mapRef}
    
      backgroundColor="transparent" //change it to ocean blue: #0077be
      zoomOnScroll={false}
      containerStyle={{
        width: "100%",
        height: "100%"
      }}
      onRegionClick={handleClick} //gets the country code
      containerClassName="map"
      regionStyle={{
        initial: {
          fill: "#e4e4e4",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: "pointer"
        },
        selected: {
          fill: "#2938bc" //color for the clicked country
        },
        selectedHover: {}
      }}
      regionsSelectable={false}
      series={{
        regions: [
          {
            values: mapData, //this is your data
            scale: ["#FFF33A", "#E93E3B"], //your color game's here
            normalizeFunction: "linear"
          }
        ]
      }}
    /></> :null
};

export default Map;
