import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, TableCell, Toolbar, makeStyles, InputAdornment, Typography, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Popup from "../../components/Popup";
import UserForm from './UserForm';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import userService from '../../services/userService';
import { useSelector , useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import Loading from '../../components/controls/Loading';
import { getUserData } from '../../redux/actions/authActions';
//import "./UserStyle.css"; 
import { Alert } from "@material-ui/lab";
import BackToTop from "../../components/controls/BackToTop";
 
const useStyles = makeStyles(theme=>({
  root: {
    width: "100%"
  },
  pageContent: {
    padding: '0px 15px',
    margin: '0px auto', 
  },
content: {
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}, 
drawerPaper: {
  width: drawerWidth,
},
toolbar: theme.mixins.toolbar,

addBar: {
  float: 'right',
  padding: '0px',
},

}));


const drawerWidth = 250;
const headCells = [
// { id : "userid", label : "ID"},
{ id : "firstname", label : "First Name"},
{ id : "lastname", label : "Last Name"},
{ id : "companyName", label : "Customer Account"},
{ id : "role", label : "Role"},
{ id : "emailid", label : "Email"},
{ id : "mobilenumber", label : "Mobile"},
{ id: 'actions', label: 'Actions', disableSorting: true }

];


const User = () => {

  const classes = useStyles();
 
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [disable, setDisable] = useState(false);
  const [titlename, setTitlename] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [condition , setCondition] = useState(false);
  const check = useSelector(state=>state.authReducer.user);
  const cookies = new Cookies();
  const dispatch = useDispatch();  
  const [showLoading, setShowLoading] = useState(true);
  useEffect(()=>{
    
    let cancel = false;
    if(Object.keys(check).length===0){
    const currentUser = cookies.get('currentUser');
    const id = currentUser?currentUser.userid:'';
   userService.getUserById(id)
    .then((result) => {
      if (cancel) return;
        dispatch( getUserData(result));
        }) 
        .catch((error) => {
          const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        if(messages === 'Invalid Token'){
        notification(messages,'error');
        timeOutSet();
        }else{
          notification(messages, 'error');
        }
        }
        );
      }
      else{
       const id = check ? check.userid : '';
        userService.getAllUsers({userid:id})
    .then(result=>{
      if (cancel) return;
      setRecords([...result])})
      
      .catch((error) => {
        setShowLoading(false)
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
     
      if(messages === 'Invalid Token'){
        notification(messages,'error');
        timeOutSet();
        }else{
          notification(messages, 'error');
        }
      }
      );
      }
      return () => { 
        cancel = true;
      }              
  },[])

  useEffect(()=>{
    
    let cancel = false;
    if(Object.keys(check).length===0){
    const currentUser = cookies.get('currentUser');
    const id = currentUser?currentUser.userid:'';
    userService.getAllUsers({userid:id})
    .then(result=>{
      if (cancel) return;
      setRecords([...result])})
      
      .catch((error) => {
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      

      if(messages === 'Invalid Token'){
        notification(messages,'error');
        timeOutSet();
        }else{
          notification(messages, 'error');
        }
      }
      );
    }
    return () => { 
      cancel = true;
    }
  },[])

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records,headCells,filterFn,condition);

    const handleSearch = e => {
  
     
      let target = e.target;
      let searchString = target.value;
      setFilterFn({
          fn: items => {
              if (searchString === ""){
               
                  return items;
              }
              else{
                  return items.filter(x =>( x.firstname.toLowerCase().includes(searchString.toLowerCase())
                                        ||  x.emailid.toLowerCase().includes(searchString.toLowerCase())
                  ))
                 
              }
          }
      })
      if (searchString === ""){
        setCondition(false);
      }else{
        setCondition(true);
      }

    };
 
    
    const cancelbutton=()=>{
      setOpenPopup(false);
    }

    const timeOutSet =()=> {
      setTimeout(() => {
        cookies.remove('currentUser');history.push('/');
      }, 1000);
    }

    const notification = (message,type)=>{
      setNotify({
        isOpen: true,
        message: message,
        type: type
      })
    }

  const addOrEdit = (user, resetForm) => {
    
    if (user.userid === 0) {
      if (check.roleid !== 1) {
        const newuser = {
          accountid: check.accountid,
          active: user.active,
          emailid: user.emailid,
          firstname: user.firstname,
          lastname: user.lastname,
          mobilenumber: user.mobilenumber,
          roleid: user.roleid,
          userid: user.userid
        }
        userService.manageUsersput(newuser).then(res => {
              
               const record = userService.getAllUsers({userid:check.userid});
              record.then(result => setRecords(result));
              notification("User added successfully","success")

        }).catch(error=>{
          const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
         if(messages === 'Invalid Token'){
          notification(messages,'error');
          timeOutSet();
          }else{
            notification(messages, 'error');
          }
        });

      }
      else {
        userService.manageUsersput(user).then(res => {
          
            const record = userService.getAllUsers({userid:check.userid});
            record.then(result => setRecords(result));
            notification( 'User added successfully', 'success');

        }).catch(error=>{
          
          const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
          if(messages === 'Invalid Token'){
            notification(messages,'error');
            timeOutSet();
            }else{
              notification(messages, 'error');
            }
        })
      }
    }
    else {
      userService.editUsersput(user).then(res => {
          const record = userService.getAllUsers({userid:check.userid});
          record.then(result => setRecords(result));
         notification( 'User updated successfully', 'success');
      }).catch(error=>{
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        if(messages === 'Invalid Token'){
          notification(messages,'error');
          timeOutSet();
          }else{
            notification(messages, 'error');
          }
      })
    }

resetForm()
setRecordForEdit(null)
setOpenPopup(false)
     
    };

  const openInPopup = item => {
    
      setRecordForEdit(item);
      setOpenPopup(true);
      setDisable(true);
      setTitlename(false);
  };

  const onDelete = id => {
    

      setConfirmDialog({
          ...confirmDialog,
          isOpen: false
      });
       userService.deleteUser(id).then(res=> 
          {

          const record = userService.getAllUsers({userid:check.userid});
          record.then(result=>setRecords(result));
          notification(res.data,"success");
            
          }).catch((error)=>{
            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            if(messages === 'Invalid Token'){
              notification(messages,'error');
              timeOutSet();
              }else{
                notification(messages, 'error');
              }
          });
  };

  return (
    <div >
    <Helmet title="Users" />
    <main className={classes.content}>   
    <Typography variant="h5" gutterBottom className="breadcrumbs">
       Users
      </Typography>
      <Divider />
      <div className={classes.pageContent}>      
        <Toolbar className={classes.addBar}>
            <Input
                label="Search"
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                        <Search />
                    </InputAdornment>)
                }}
                onChange={handleSearch}
                size="small"/>                
                <Controls.ActionButton
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null);
                          setTitlename(true); setDisable(false) }}>
                        <AddCircleOutlineIcon />
                      </Controls.ActionButton>

                      
              
        </Toolbar>
        <TblContainer className={classes.container}>        
          <TblHead/>
          <TableBody>
            {
              recordsAfterPagingAndSorting().map((record,index) => (
                <TableRow key={index}>
                  {/* <TableCell className="p-1">{record.userid}</TableCell> */}
                  <TableCell>{record.firstname}</TableCell>
                  <TableCell>{record.lastname}</TableCell>
                  <TableCell>{record.accountname}</TableCell>
                  <TableCell>{record.role}</TableCell>
                  <TableCell>{record.emailid}</TableCell>
                  <TableCell>{record.mobilenumber}</TableCell>
                  {/* <TableCell>{record.accountid}</TableCell> */}
                  {/* <TableCell className="p-1"> 
                     {
                      accountTypes.map(
                          (item,index) => 
                          {return record.accountid===item.accountid ?
                            <div key={index} >{item.accountname}</div>: null;
                          }
                      )
                  }
                </TableCell> */}
                  <TableCell>
                 
                      <Controls.ActionButton
                          onClick={() => { openInPopup(record) }} className="outlined-primary">
                          <EditOutlinedIcon fontSize="small" variant="outlined" color="primary"/>
                      </Controls.ActionButton>
                      <Controls.ActionButton
                          onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: `Are you sure to delete the record of the user [ ${record.firstname} ]?` ,
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onDelete(record.userid) }
                            })
                        }}>
                          <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary"/>
                      </Controls.ActionButton>
                  </TableCell>
                </TableRow>  
              ))
            }

          </TableBody> 
          <TableBody>
            {!recordsAfterPagingAndSorting().length && <TableRow style={{textAlign:'center'}} key={0}>
          {showLoading?(<TableCell className="p-1 text-left w-100" colSpan="7"><Loading /></TableCell>):<TableCell className="p-1 text-left" colSpan="7">{  <Alert severity="warning">No records found</Alert> }</TableCell> }
            </TableRow> }
          </TableBody>
          
        </TblContainer>
        <TblPagination gutterLeft/>
      </div>
      <Popup
          title={titlename?"Create User":"Edit User"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
      >
          <UserForm
              recordForEdit={recordForEdit}
              addOrEdit={addOrEdit} 
              disable={disable}
              cancelbutton={cancelbutton}
          /> 
      </Popup>
      <Notification
          notify={notify}
          setNotify={setNotify}
      />
      <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      /> 
     <BackToTop />
     </main>
     <Footer /> 
    </div>
  );
};

export default User;