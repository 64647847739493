import React ,{useState,useEffect} from "react";
import {getEventBarChartByAccountId} from '../../services/eventService';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';

import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  Zoom,
} from "@material-ui/core";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} className="linearBar">
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2"></Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({remainingTicket1,chartValue1,visible}) {
  const [remainingTicket, setRemainingTicket] = useState(1);;
  const [chartValue,setChartValue]=useState([]);
  useEffect(() => {
if(visible){
  setRemainingTicket(remainingTicket1);
  setChartValue(chartValue1);
}else{
  setRemainingTicket(1);
  setChartValue([])
}
  }, [remainingTicket1,chartValue1,visible]);



  return (
      <div>
        <Card mb={3} className="upcoming-events tile-card">
          <CardHeader
              title="Tickets Sold / Available Meter" className="title pb-0"/>
             
            <CardContent p={1} className="tile-title">
      {visible?(
        <Box sx={{ width: '100%' }} className="linearBar">
      <Box className="float-left"><h6>{chartValue.currencytype==='USD'?'$':'£'}{Number(chartValue.totalEventCost).toFixed(2)}</h6>
      Total Revenue</Box>
      <Box className="tickets-lbl"><Tooltip TransitionComponent={Zoom} title="Tickets Sold" arrow><span className="ticketsSold">&nbsp;</span></Tooltip>Tickets Sold :<span className="value">{chartValue.totalsoldcount}</span></Box>
      <Box className="tickets-lbl"><Tooltip TransitionComponent={Zoom} title="Tickets Remaining" arrow><span className="remainingSold">&nbsp;</span></Tooltip>Tickets Remaining :<span className="value">{chartValue.remainingTicketcount}</span></Box>
      <div>
      <LinearProgressWithLabel value={remainingTicket} /></div>
      
      
    </Box>
     
      ):(
        <div></div>
      )}
      </CardContent>
     </Card>
    </div>
  );
}
