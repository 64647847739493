import React, { useEffect } from "react";
import { Alert } from "@material-ui/lab";
import {
      Table,
      TableHead,
      TableBody,
      TableRow, 
      TableCell,
      CardHeader,
      IconButton,
      Menu, 
    MenuItem,
    Grid,
    Divider,
    Typography,
    TableContainer
     } from "@material-ui/core";
     import MoreVertIcon from '@material-ui/icons/MoreVert';
     import XLSX from 'xlsx'
     import jsPDF from "jspdf";
import "jspdf-autotable";
import eventService from "../../services/eventService";
import Loading from "../../components/controls/Loading";

const TicketDetail =({eventID,eventDetailID,eventTitle,dateTime})=>{
    const [eventFullData,setEventFullData]=React.useState([]);
    const [upsellDetails,setUpsellDetails]=React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading,setLoading] = React.useState(true);
    const [teamData,setTeamData] = React.useState(null);

    useEffect(()=>{
      let cancel = true;
        const users = {
            eventid:eventID,
            eventdetailid:eventDetailID
          } 
          eventService.getEventReport(users).then((result) => { 
            if(cancel){
            setEventFullData(result.data);
            setUpsellDetails(result.upselldetails);
            setTeamData(result.teamData);
            setLoading(false);
            }
          }).catch((error) => {
            setEventFullData([]);      
            //const messages = (error.response) && !error.response.data.message ? error.response.data : error.message;
            setLoading(false)
          });
          return ()=>{
            cancel=false;
          }
    },[eventID,eventDetailID])
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

      const exportFile = () => {
            const downloadfile = eventFullData.map((e)=>{
              return{
                Email:e.email,
                EnduserName:e.endusername,        
                TicketsPurchased:e.nooftickets,
                TicketValidated:e.redeemedtickets,
                UpsellPurchased:e.upsellcount,
                UpsellReedemed:e.redeemedupsells,
                PaymentId:e.paymentid,        
                PaymentStatus: e.paymentstatus,
                Reservation: e.reservationId,
              }
                                        
            })
            
                const ws = XLSX.utils.json_to_sheet(downloadfile);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Event Data");

                if (teamData) {
                  const excelTeamData = [];
                  Object.keys(teamData).forEach(key => {
                    teamData[key].forEach(player => {
                      const exData = {};
                      exData['Team'] = key;
                      exData['Username'] = player.endusername;
                      exData['PlayerID'] = player.playerid;
                      excelTeamData.push(exData);
                    });
                  });
                  const team_ws = XLSX.utils.json_to_sheet(excelTeamData);
                  XLSX.utils.book_append_sheet(wb, team_ws, "Team Data");
                }

                XLSX.writeFile(wb, `${eventTitle}${dateTime}.xlsx`);
            };

            const exportPDF = () => {
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "landscape"; // portrait or landscape
            
                const marginLeft = 20;
                const doc = new jsPDF(orientation, unit, size);
            
                doc.setFontSize(15);
            
                const title = `${eventTitle}, ${dateTime} - Buyer List Report`;
                const headers = [["Email",
                  "Name",
                  "Tickets Purchased",
                  "Ticket Validated",
                  "Upsell Purchased",
                  "Upsell Redeemed",
                  "Order Comments",
                  "PaymentId"]];
            
                const data = eventFullData.map(e=>[
                    e.email,
                    e.endusername,
                    `${e.nooftickets} ${e.paymentstatus !== 'Success' ?  e.reservationId ? `(Reserved ${e.reservationId})` : e.paymentstatus : null}`,
                    e.redeemedtickets,
                    e.upsellcount,
                    e.redeemedupsells,
                    e.order_comments,
                    e.paymentid
                  ]);
            
                let content = {
                  headStyles: { fillColor: [241, 25, 20],margin: { top: 10 } },
                  theme:'grid',
                  styles:{lineColor:[0,0,0],minCellWidth: 10},
                 // startY: 50,
                  head: headers,
                  body: data
                };
            
            
                doc.text(title, marginLeft, 20);
                doc.autoTable(content);

                if (teamData) {
                  let finalY = doc.lastAutoTable.finalY + 40; // The y position on the page
                  doc.text('\n', marginLeft, finalY);
                  let finalX = marginLeft;
                  let maxTextLen = marginLeft;
                  Object.keys(teamData).forEach((k) => {
                    doc.setFontSize(15);
                    finalY = doc.lastAutoTable.finalY + 40; //reset for new column
                    doc.text(k, finalX, finalY); // render team name
                    teamData[k].forEach(player => {
                      doc.setFontSize(12);
                      finalY += 20;
                      const text = `${player.endusername} - ${player.playerid}`;
                      doc.text(text, finalX, finalY)
                      const txt_width = doc.getTextWidth(text);
                      if (txt_width > maxTextLen) {
                        maxTextLen = txt_width;
                      }
                    });

                    if (maxTextLen !== finalX) {
                      finalX += maxTextLen;
                      finalX += 40;
                    } else {
                      finalX += 120;
                    }
                  });
                }

                doc.save(`${eventTitle}${dateTime}.pdf`)
              }
    return(
        <div className="buyer-details">
            <CardHeader
              title={`${dateTime} (${eventDetailID})`}
              action={
                eventFullData.length > 0 ? <IconButton aria-label="settings" className="v-button" onClick={handleClick}>
                  <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    />
                </IconButton>:<div></div>
              }
             className="p-0 pb-2 mb-0"/>
             <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={exportPDF}>Download PDF</MenuItem>
                <MenuItem onClick={exportFile}>Download Excel</MenuItem>
            </Menu>
            <TableContainer style={ {
            maxHeight: 200,
            width: '100%',
            overflowX: 'auto',
      }}>   
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>              
              <TableCell>Email</TableCell>
              <TableCell>Buyer Name</TableCell>
              <TableCell className="text-center">Ticket Purchased</TableCell>
              <TableCell className="text-center">Ticket Validated</TableCell>
              <TableCell className="text-center">Upsell Purchased</TableCell>
              <TableCell className="text-center">Upsell Redeemed</TableCell>
              <TableCell className="text-center">Order Comments</TableCell>
              <TableCell className="text-left">Payment Id</TableCell>	              
            </TableRow>
          </TableHead>
          <TableBody>     
          {eventFullData.length>0?(eventFullData.map((events,index)=>(
              <TableRow key={index}>
               <TableCell>{events.email}</TableCell>
               <TableCell>{events.endusername}</TableCell>             
          <TableCell className="text-center">{events.nooftickets} {
            events.paymentstatus !== 'Success' ?  events.reservationId ? `(Reservation ${events.reservationId})` : `(${events.paymentstatus})` : null
          }</TableCell>
          <TableCell className="text-center">{events.redeemedtickets}</TableCell>
            <TableCell className="text-center">{events.upsellcount}</TableCell>
            <TableCell className="text-center">{events.redeemedupsells}</TableCell>
            <TableCell className="text-left order-comments-cell">{events.order_comments}</TableCell>
            <TableCell className="text-left">{events.paymentid}</TableCell>         
           </TableRow>
         ))):(loading?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="8"><Loading /></TableCell></TableRow>):(<TableRow><TableCell colSpan={8} className="center"><Alert severity="warning">No records found</Alert></TableCell></TableRow>))
          }
          </TableBody>
        </Table> 
        </TableContainer>
      <br/>
      <Typography variant="h6">
                    Upsell Purchase Details
      </Typography>
      <TableContainer style={ {
            maxHeight: 180,
            width: '100%',
            overflowX: 'auto',
      }}>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow>              
              <TableCell className="p-1">Email</TableCell>
              <TableCell className="p-1">Buyer Name</TableCell>
              <TableCell className="p-1">Upsell Purchased</TableCell>
              <TableCell className="p-1">Upsell Details</TableCell>    
            </TableRow>
          </TableHead>
          <TableBody>     
          {upsellDetails?.length>0?(upsellDetails.map((item,index)=>(

            item?.eventticketUpsellmappings.map((upsell, index2) => {
              return (
              <TableRow key={index}>        
              <TableCell className="p-1">{item.email}</TableCell>
              <TableCell className="p-1">{item.endusername}</TableCell>
              <TableCell className="p-1">{upsell.upsellcount}</TableCell>
              <TableCell className="p-1">{upsell?.upsell?.upsellname} {upsell?.individualupselldetails?.map(d => d.variantname)?.join(',')}</TableCell>         
            </TableRow>
              )
            }
            )
         ))):(loading?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="8"><Loading /></TableCell></TableRow>):(<TableRow><TableCell colSpan={8} className="center"><Alert severity="warning">No upsells found</Alert></TableCell></TableRow>))
          }
          </TableBody>
        </Table> 
        </TableContainer>
          {
            teamData ? 
            <>
            <Divider style={{marginBottom: '10px'}} />
            <Grid container>
            {Object.keys(teamData).map((key, index) => {
              return (
                <Grid item sm={3} key={key}>
                <h5>{key}</h5>
                <ul>
                  { teamData[key].map(player => (
                    <li>{player.endusername} - {player.playerid}</li>
                  ))
                  }
                  </ul>
                </Grid>
              )
            })}
            </Grid> </>: null
          }
        </div>

    )
}
export default TicketDetail