import React from 'react';

import {
  makeStyles, 
  Card,
  Box,

  } from "@material-ui/core";


  const useStyles = makeStyles((theme) => ({

  
  footer: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      bottom:'0px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  
}));

const drawerWidth = 250;


function Footer() {
const classes = useStyles();

return (
<footer className="footer mt-auto">
      <Box className={classes.footer}>
    <Card className={classes.root} variant="outlined">      
        <Box textAlign="center" className="bottom-link">
        © {new Date().getFullYear()} ON THE STAGE. All Rights Reserved
        </Box>  
    </Card>
	  </Box>
	  
</footer>

);

}
export default Footer;