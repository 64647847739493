import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


export async function generateReport(reportId, filters) {

  const currentUser = cookies.get('currentUser');
  let url = `${process.env.REACT_APP_DEV_API_URL}/report/generateReport?authorization=Bearer ${currentUser.token}&reportId=${reportId}`;
  try {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      url += `&${filterKey}=${filterValue}`
    }
    console.log('url', url);
    window.open(url);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function listReports(accountId) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/report/listReports`, {
        accountId,
      }, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getBoldReports(accountid) {
  const currentUser = cookies.get('currentUser');
  try {
    const r = await http.get(`/report/all/${accountid}`, {
      headers: {
        'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",
      }
    });

    return r.data;
  } catch (error) {
    throw error;
  }
}

export async function getMetabaseDashboardToken(accountid) {
  const currentUser = cookies.get('currentUser');
  try {
    const response = await http.get(`/report/getMetabaseDashboardToken/${accountid}`, {
      headers: {
        'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
      }
    });
    return response.data.token;
  } catch (error) {
    throw error;
  }
}

export default {
  generateReport
}