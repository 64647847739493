import React, { useEffect } from 'react';
import useForm from '../../components/useForm';
import Controls from '../../components/controls/Controls';
import { Grid, DialogActions, Divider } from '@material-ui/core';
import Form from '../../components/Form';
const initialFValues = {
    Sendgridkey: ""
};
const SendGrid = (props) => {

    const { addOrEdit, recordForEdit, cancelbutton } = props;

    const validate = (fieldValues = values) => {
        const temp = { ...errors };
        if ("Sendgridkey" in fieldValues) {
            temp.Sendgridkey = fieldValues.Sendgridkey ? "" : "Sendgrid Key is required";
        }
        setErrors({ ...temp });
        return Object.values(temp).every((x) => x === "");

    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };
    const { values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm } = useForm(initialFValues, true, validate);
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
        return () => {
            setValues({});
        }
    }, [recordForEdit]);
    return (
        <Form onSubmit={handleSubmit} className="create-user">
            <Grid container item spacing={2} md={12} className="m-0">

                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controls.Input
                        multiline
                        rows={4}
                        name="Sendgridkey"
                        label="Add API key"
                        value={values.Sendgridkey || ''}
                        onChange={handleInputChange}
                        error={errors.Sendgridkey} className="smaller-input" />
                </Grid>

                <Divider />
                <DialogActions className="w-100">
                    <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Controls.Button
                            text="Cancel"
                            onClick={()=>{resetForm(); cancelbutton()}} className="link-gray" />
                        <Controls.Button
                            type="submit"
                            text="Connect" className="link-red" />
                    </Grid>
                </DialogActions>
            </Grid>
        </Form>
    )

}

export default SendGrid;