import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { getEventByAccountId, getEventDashboardByAccountId } from '../../services/eventService';
import { universaldate } from "../../utils/dateFormat";
import moment from "moment";
import Controls from '../../components/controls/Controls';
import Notification from '../../components/Notification';
import { setEventFilterData, setFilteredEventDetails } from '../../redux/actions/eventActions';
import { useSelector, useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import locale from 'date-fns/locale/en-GB'

export default function EventsFilter({ id, defaultPageLength }) {
  const dispatch = useDispatch();
  const filterData = useSelector(state => state.eventReducer.filterData);
  const [eventNameList, setEventNameList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
  const timeDiffernece = ["00", "15", "20", "30", "40", "45"];
  const timeLength = timeDiffernece.length;

  const [value, setValue] = React.useState(filterData.selectedDate ? moment(filterData.selectedDate).utc().toISOString() : null);

  const handledateChange = (date) => {
    const values = date ? date : null;
    setValue(values);
    sortEventsByDate(values);
  }

  useEffect(() => {
    async function fetchData() {
      const eventNameList = await getEventByAccountId({ "accountid" : Number(id) });
      setEventNameList(eventNameList.data);
    }
    id && fetchData();
  }, [id]);
  
  const sortEventsByDate = async (date, value) => { 
    
    const formattedDate = date ? moment(date).format('MM-DD-YYYY') : null;
     dispatch(setEventFilterData({ selectedDate: formattedDate}));
    // dispatch(setEventFilterData({ dateInputValue: value }))
    const bodyData = { "accountid" : Number(id), date: formattedDate }
    if (filterData.selectedEventName) bodyData['eventname'] = filterData.selectedEventName;
    if (filterData.selectedEventTime) bodyData['time'] = filterData.selectedEventTime;
    bodyData['isFirstEventsRender'] = true
    try {
      setIsSubmitting(true);
      const eventsList = await getEventDashboardByAccountId(bodyData, defaultPageLength, 1);
      if (eventsList.data?.length > 0) { 
        setIsSubmitting(false);
        for (let pageLength = 0; pageLength < eventsList.data.length; pageLength++) {
          eventsList.data[pageLength].customerdateformat = universaldate(eventsList.data[pageLength].eventdatetime);
        }
        dispatch(setFilteredEventDetails(eventsList.data));
      } else {
        dispatch(setFilteredEventDetails([]));
        setIsSubmitting(false);
      }
    } catch (err) {
      dispatch(setFilteredEventDetails([]));
      setIsSubmitting(false);
    }
  }

  const sortEventsByNameOrTime = (e) => { 
    fetchEventData(defaultPageLength, 1, e.target.value, e.target.name);
    if (e.target.name === 'time') dispatch(setEventFilterData({ selectedEventTime: e.target.value }))
    if (e.target.name === 'eventname') dispatch(setEventFilterData({ selectedEventName: e.target.value }))
  }
  
  const fetchEventData = async (pageLength, pageNumber, selectedValue, nameProp) => {
    const bodyData = { "accountid" : Number(id) };
    if (nameProp === 'eventname') {
      bodyData[nameProp] = selectedValue
    } else if (filterData.selectedEventName) {
      bodyData['eventname'] = filterData.selectedEventName;
    }
    if (nameProp === 'time') {
      bodyData[nameProp] = selectedValue
    } else if (filterData.selectedEventTime) {
      bodyData['time'] = filterData.selectedEventTime;
    }
    if (filterData.selectedDate) bodyData['date'] = filterData.selectedDate;

    try {
      setIsSubmitting(true);
      const eventsList = await getEventDashboardByAccountId(bodyData, pageLength, pageNumber);
      if (eventsList.data.length > 0) { 
        setIsSubmitting(false);
        for (let pageLength = 0; pageLength < eventsList.data.length; pageLength++) {
          eventsList.data[pageLength].customerdateformat = universaldate(eventsList.data[pageLength].eventdatetime);
        }
        dispatch(setFilteredEventDetails(eventsList.data));
      } else {
        dispatch(setFilteredEventDetails([]));
        setIsSubmitting(false);
      }
    } catch (err) {
      dispatch(setFilteredEventDetails([]));
      setIsSubmitting(false);
    }
  };

  const clearFilters = async () => {
    try {
      setIsSubmitting(true);
      const eventsList = await getEventDashboardByAccountId({ "accountid" : Number(id), 'isFirstEventsRender': true, }, defaultPageLength, 1);
      if (eventsList.data.length > 0) {
        for (let pageLength = 0; pageLength < eventsList.data.length; pageLength++) {
          eventsList.data[pageLength].customerdateformat = universaldate(eventsList.data[pageLength].eventdatetime);
        }
        dispatch(setFilteredEventDetails(eventsList.data));
        dispatch(setEventFilterData({ selectedEventName: '', selectedEventTime: '',
        selectedDate: null, dateInputValue: '' }));
        setValue(null);
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      const messages = (error.response) && !error.response.data.message ? error.response.data:error.message;
      notification(messages, 'error');     
    }
  }

  const notification = (message, type)=>{
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  return (
      <Grid container spacing={2} alignItems="center" justifyContent="flex-start" mt={2} mb={2} className="event-filter">
        <Grid item xs={3} md={1} lg={1} xl={1} style={{ paddingLeft: 10 }}>
          Filter By 
        </Grid>
        <Grid item xs={9} md={3} lg={3} xl={3}>
          <FormControl fullWidth variant="outlined" size="medium">
          <InputLabel id="demo-simple-select-label">Select Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Event"
              name="eventname"
              value={filterData.selectedEventName}
              onChange={sortEventsByNameOrTime}
            >
              <MenuItem value="">
                None
              </MenuItem>
              { eventNameList.length > 0 && eventNameList.map(event => (
                <MenuItem key={event.eventdetailid} value={event.eventname}>{event.eventname}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} md={2} lg={2} xl={2}>
          <FormControl fullWidth variant="outlined" size="medium">
          <InputLabel id="demo-simple-select-label">Time</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Time"
            name="time"
            value={filterData.selectedEventTime}
            onChange={sortEventsByNameOrTime}
          >
    
     
         <MenuItem value="">None</MenuItem>
            {Array.from({ length: 144 }, (_, index) => {
              const hours = Math.floor(index / timeLength);
              const minutes = timeDiffernece[index % timeLength];
              const period = hours >= 12 ? "PM" : "AM";
              const displayTime = `${hours % 12 || 12}:${minutes}${period}`;

              return (
                <MenuItem key={index} value={displayTime}>
                  {displayTime}
                </MenuItem>
              );
            })}
          </Select>
          </FormControl>
        </Grid>
        <Grid item xs={7} md={3} lg={3} xl={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <DatePicker
              name="date"
              label="Date"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              clearable
              value={value}
              onChange={handledateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Controls.Button
            text="Clear"
            color="default" 
            size="medium"
            onClick={clearFilters} className="link-gray" />
        </Grid>
        <Grid item>
          {isSubmitting && <CircularProgress size={24} color="secondary" style={{ marginLeft: '5px'}} /> } 
        </Grid>
        <Notification
          notify={notify}
          setNotify={setNotify}
        /> 
      </Grid>
  )
}
