import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Grid, Divider, Link, Breadcrumbs, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
  Slide, 
  Badge,} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Notification from "../../components/Notification";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import { getEventById } from '../../services/eventService';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import Controls from '../../components/controls/Controls';
import { useSelector, useDispatch } from 'react-redux';
import orderService from "../../services/orderService";
import TabTicket from "./TabTicket";
import NoEventsFound from '../../components/controls/NoEventsFound';
import {dateformat} from '../../utils/dateFormat'
import { exchangeticket } from "../../redux/actions/authActions";
import Loading from "../../components/controls/Loading";
import {getPlayereventDetails} from '../../services/mapInventoryService' ;
import { eventExchangeDetails } from "../../services/payment";
import locale from 'date-fns/locale/en-GB'
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import accounting from "accounting";

if (locale && locale.options) {
  locale.options.weekStartsOn = 1
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  breadcrumbs: {
    margin: '0px !important',
    padding: '12px 16px 0px 16px !important',
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  select: {
    width: '100% !important',
  },

}));

const drawerWidth = 250;

function fakeFetchTemp(date, { signal }, tickets) {
  


  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      getDaysInMonth(date);
      const daysToHighlight = tickets;
      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException('aborted', 'AbortError'));
    };
  });
}

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ExchangeTicket = () => {

  
  const classes = useStyles();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

 
  const history = useHistory();
  const [event, setEvent] = useState(null);
  const [eventTemp, setEventTemp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [eventDateSelected, setEventDateSelected] = useState(true);
  const [showTiming, setShowTiming] = useState([]);
  const [open, setOpen] = useState(false);
  const initialValue = new Date();
  const dispatch = useDispatch();
  const requestAbortController = React.useRef(null);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [value, setValue] = React.useState(initialValue);
  const [timeValue, setTimeValue] = useState('0');
  // const [team, setTeam] = useState([]);
  // const [arrayTeam, setArrayTeam] = useState([]);
  const [arrayWithoutTeam, setArrayWithoutTeam] = useState([]);
  // const datas = useSelector(state => state.authReducer.order);
  const [showLoading, setShowLoading] = useState(false);
  const [exchangePaymentDetail, setExchangePaymentDetail] = useState({});
  const[order, setOrder]= useState(null);
  const { eventTicketDetailId, eventid, eventdetailid } = useParams();

  const change = useSelector(state => state.authReducer.exchangeticket);
 
  const splitDateandTime = change.length > 0 ? change[0].eventdatetime.toString().split(',') : [];
  const eventdate = splitDateandTime[0]
  const eventDatetimeto = splitDateandTime[1]
  const eventdetailidto = change.length>0?change[0].occurenceid:"";

  const handleMonthChangeFilter = (date, eve) => {
    if (eve === undefined || eve === 'undefined') {
      eve = event;
    }

    // const val= new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    let newEvents = eve.eventdetailfile.filter(x => (dateformat(x.eventdatetime.split(',')[0]).getMonth() - new Date(date).getMonth() === 0));



    if (newEvents.length > 0) {
      setEventDateSelected(true);


    } else {
      setEventDateSelected(false);

    }

    if (newEvents.length > 0) {
      let ticketDates = newEvents.map(x => (dateformat(x.eventdatetime.split(',')[0]).getMonth()+'-'+
        dateformat(x.eventdatetime.split(',')[0]).getDate()+'-'+dateformat(x.eventdatetime.split(',')[0]).getFullYear()));

      if (ticketDates.length > 0) {
        ticketDates.unshift(0);
        fetchHighlightedDaysTemp(date, ticketDates)
        return () => requestAbortController.current?.abort();
      }
    } else {
      fetchHighlightedDaysTemp(date, [])
      return () => requestAbortController.current?.abort();
    }
  }

  const fetchHighlightedDaysTemp = (date, tickets) => {
    const controller = new AbortController();

    fakeFetchTemp(date, {
      signal: controller.signal
    }, tickets)
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);

      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  const timeChange = (e) => {
    setTimeValue(e.target.value)
    let timeBasedEvents = [];
    let newEvents = event.eventdetailfile.filter((x, index) => (dateformat(x.eventdatetime.split(',')[0]) - new Date(value.getFullYear(), value.getMonth(), value.getDate()) === 0));

    newEvents.forEach((x, index) => {
      if (x.eventdatetime.split(',')[1] === e.target.value) {
        if (index === 0 && newEvents.length === 1) {
          timeBasedEvents.push(newEvents[index]);
        } else if (index === 0 && newEvents.length > 1) {
          timeBasedEvents.push(newEvents[index], newEvents[index + 1]);
        } else if (index === 1 && newEvents.length > 2) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index], newEvents[index + 1]);
        } else if (index === 1 && newEvents.length === 2) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index]);
        } else if (index === newEvents.length - 1) {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index]);
        }
        else {
          timeBasedEvents.push(newEvents[index - 1], newEvents[index], newEvents[index + 1]);
        }
      }

    })
    if (e.target.value === '0') {
      eventTemp.eventdetailfile = event.eventdetailfile.filter(x => (dateformat(x.eventdatetime.split(',')[0]) - new Date(value.getFullYear(), value.getMonth(), value.getDate()) === 0));
    } else {
      eventTemp.eventdetailfile = timeBasedEvents;
    }
    setEventTemp(eventTemp);
    if (newEvents.length > 0) {
      setEventDateSelected(true);
    }
  }
  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

function dateformat(dateString) {
    let dateConvert = new Date(dateString.replace(/-/g, '/').replace('AM', ' AM').replace('PM', ' PM'));
    return dateConvert;
}

function isSameDay(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
}

const displayEventsByDateTime = (event, eventDate) => {
  let eventList = [];
  let currentDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

  if (event.mapinventory !== 1) {
    eventList = event.eventdetailfile.filter(x => {
      let eventDateTime = dateformat(x.eventdatetime.split(',')[0]);
      return isSameDay(eventDateTime, currentDate) && x._id !== eventdetailid; 
    });
  } else {
    eventList = event.eventdetailfile.filter(x => {
      let eventDateTime = dateformat(x.eventdatetime.split(',')[0]);
      return isSameDay(eventDateTime, currentDate);
    });
  }
  
  return eventList;
};

  useEffect(() => {
    
    setLoading(true);
    if (eventid && eventTicketDetailId) {
      try {
        const eventData = async() => {
          const result = await getEventById({eventid: eventid,channel: "boxoffice"});
          if (result && result.hasOwnProperty('data') && result.data[0].eventdetailfile.length > 0) {
     
            let startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0); 
            let endOfDay = new Date();
            endOfDay.setHours(23, 59, 59, 999); 

            let filterEvents = result.data[0].eventdetailfile.filter(x => {
              let now = new Date();
              let startOfDay = new Date();
              startOfDay.setHours(0, 0, 0, 0);
          
              let onSaleDate = new Date(x.onsaledateISO);
              let eventDateTime = new Date(x.eventdatetimeISO);
          
              return (onSaleDate <= now && eventDateTime >= startOfDay);
            });
          
            result.data[0].eventdetailfile = filterEvents
            setEvent({ ...result.data[0] });
            if(result.data[0].eventdetailfile.length > 0){
            setValue(dateformat(result.data[0].eventdetailfile[0].eventdatetime.split(',')[0])); //initial calender value                  
            handleMonthChangeFilter(dateformat(result.data[0].eventdetailfile[0].eventdatetime.split(',')[0]), result.data[0]);
   
            let firstEventDate = dateformat(result.data[0].eventdetailfile[0].eventdatetime.split(',')[0]);
            let eventValues = result.data[0];
            eventValues.eventdetailfile = displayEventsByDateTime(result.data[0],firstEventDate);

            setEventTemp({ ...eventValues});
            setShowTiming(eventValues.eventdetailfile);  
          }
        }
        }
        const orderDetails = async() => {
          const order = { account_id: '', email_id: '', eventticketdetail_id: eventTicketDetailId };
          const res = await orderService.getOrderDetails(order);
           setOrder(res.data.eventinfo[0]);
        }
        orderDetails();
        eventData();
      } catch (error) {
        setLoading(false);
        const messages = (error.response) ? error.response.data.message : error.message;
        notification(messages, 'error')
        // history.push('/Orders');
      }
    }else{
      setLoading(false);
    }     
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, [eventid, eventTicketDetailId]);


  const exchangeTicketFun = async () => {


    const eventdetailid_destination = eventdetailidto;
    const eventticketDetailid_source = eventTicketDetailId;

    if (eventdate !== undefined) {
      if( order?.nooftickets === change[0]?.numberoftickets ){
      const exchange = await eventExchangeDetails({
        eventid,
        eventdetailid_destination,
        eventticketDetailid_source,
      });
      setExchangePaymentDetail(exchange);
      }
      if(event.mapinventory === 1){
        if(change.length > 1){
         setOpen(true); 
        }else{
          notification('Please select the Team Players', 'error'); 
        }
      }else{
        const user = {
          "action_type": "player list",
          "eventid": eventid,
          "eventdetailid": eventdetailidto
        }
        getPlayereventDetails(user).then((res) => {
          
          // commenting out allocation logic
          // if (res.allocations.length > 0) {
          //   let withoutTeam = [];
          //   let purchasedTicket = PurcTicket;
          //   const OccuranceSalesInfo = res.allocations.filter(y => y.isapi !== 1);
          //   debugger;
          //   if (OccuranceSalesInfo.length > 0) {
          //     let totalNumberTicket = 0;
              
          //     for (const xOcc of OccuranceSalesInfo) {
          //       var availableTickets = (Number(xOcc.allocationcount) - Number(xOcc.soldcount));
          //       totalNumberTicket = totalNumberTicket + availableTickets;
  
          //       if (availableTickets > 0) {
          //         let countStatus = availableTickets - purchasedTicket ;
          //         if(countStatus >= 0){
          //         withoutTeam.push({allocationid:xOcc.allocationid , eventid :eid , eventdetailid : eventdetailidto , nooftickets : purchasedTicket  });               
          //         purchasedTicket = 0;
          //         setArrayWithoutTeam(withoutTeam);
          //         setOpen(true);
          //         return;
          //       }else{
          //         purchasedTicket = purchasedTicket - availableTickets;
          //         withoutTeam.push({allocationid:xOcc.allocationid , eventid :eid , eventdetailid : eventdetailidto , nooftickets : availableTickets  });
          //         }
          //       }
          //     }
          //     if( purchasedTicket >= 0   && ((Number(change[0].ticketcountstatus) - totalNumberTicket ) >= purchasedTicket) ){
          //       setArrayWithoutTeam(withoutTeam);
          //       setOpen(true);
  
          //     }else{
          //       notification("Ticket is sold out","error")
          //     }
          //   }else{
          //     setOpen(true);
          //   }
          //   }
            
            // else{
              setOpen(true);
            // }
        }).catch((err)=>{
          notification("Please Wait Something error","error");
        })
      }
    } else {
      notification('Please choose any one date', 'error');
    }
  }
 

const handleContinue = (isTriggerCharge) => {
  debugger;
  const PurcTicket = change[0].numberoftickets;
  const added_data = change[0].ticketinfo;
  const old_Data = order.paymentteamMapping;
  let result = [];

  if (event.mapinventory === 1) {
    const groupedData = added_data.reduce((acc, obj) => {
      const paymentteammapid = obj.paymentteammapid;
      if (!acc[paymentteammapid]) {
        acc[paymentteammapid] = { ...obj, playerid: obj.playerid.toString() };
      } else {
        acc[paymentteammapid].playerid += ',' + obj.playerid.toString();
      }
      return acc;
    }, {});

    const finalData = Object.values(groupedData);

    for (const prev of finalData) {

      for (const get of old_Data) {
        if (prev.paymentteammapid === get.paymentteammapid) {
          const prevPlayer = prev.playerid.split(",");
          const wholeplayer = get.playerid.split(",");

          const unmatchedPlayerIds = wholeplayer.filter(id => !(prevPlayer.includes(id)));
          if (unmatchedPlayerIds.length > 0) {
            const unmatchedPlayerIdsString = unmatchedPlayerIds.join(",");
            result.push({
              paymentteammapid: get.paymentteammapid,
              playerid: unmatchedPlayerIdsString,
              eventid: eventid, paymentid: order.paymentid,
              eventdetailid: eventdetailid,
              teamid: get.teamid
            });
          }
        }
      }
    }
  }
  if (order.eventdetailid !== eventdetailidto && PurcTicket > Number(change[0].ticketcountstatus)) {
    notification('Please choose any other date', 'error')
  }
  if (Number(change[0].ticketcountstatus) > 0) {
      setShowLoading(true)
      const ticketFrmCookies = {
        eventid: eventid,
        eventticketdetail_id: eventTicketDetailId,
        eventdetailid_from: order.eventdetailid,
        eventdetailid_to: eventdetailidto,
        ticketcount: PurcTicket,
        payment_intent : order.paymentid,
        allocationdetails :  event.mapinventory === 1 ? [...change[1], ...result] : [],
        ticketallocationdetails : arrayWithoutTeam,
        isTriggerCharge,
        ticketinfo: change[0].ticketinfo,
        upsellInfo: change[0].upsellInfo,
      }
      orderService.exchangeEventTicket(ticketFrmCookies).then((res) => {
        const response = (res.message) ? res.message : res.res
         notification(response, 'success')
        dispatch(exchangeticket([]));
         setOpen(false);
        setTimeout(() => {                 
          history.push(`/OrderDetails/${eventTicketDetailId}`)
        }, 2000);

      }).catch((error) => {
        setShowLoading(false);
        const messages = (error.response) ? error.response.data.message : error.message;
        notification(messages, 'error')
        setOpen(false);
      })

    }
    else {
      notification('Please choose any another date', 'error')
     // history.push("/Orders")
    }
    
  }


  const handleClose = () => {
    setOpen(false);
  };

  const filterDate = (firstEventDate) => {

    eventTemp.eventdetailfile = displayEventsByDateTime(event,firstEventDate);;
    setEventTemp(eventTemp);
    setShowTiming(eventTemp.eventdetailfile);
    setTimeValue('0');
    if (eventTemp.eventdetailfile.length > 0) {
      setEventDateSelected(true);
    }
  }

  const handledateChange = (date) => {
    
    setValue(date);
    filterDate(date);
    dispatch(exchangeticket([]));
  }

  return (
    <div>

      <Helmet title="Exchange Ticket" />
      <main className={classes.content}>
        <Typography variant="h5" gutterBottom className={classes.breadcrumbs}>
          Exchange Ticket
      </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
          <Link component={NavLink} exact to="/Orders" underline="hover" color="inherit">
            Orders
      </Link>
          <Typography color="textPrimary">Exchange Ticket</Typography>
        </Breadcrumbs>
        <Divider className="mb-3" />
        <div className="pageContent">
          
            <Grid container spacing={2} className="exchange-ticket">
              <Grid item xl={3} lg={4} md={6} sm={12} xs={12} className="date-container">
                {showTiming.length > 0 ? (

                  <select
                    value={timeValue}
                    onChange={timeChange}
                    className="select-box w-100 mb-2"
                  >
                    <option value="0">All Shows</option>
                    {(showTiming).map((v, k) => {
                      return <option key={k} id={k} value={v.eventdatetime.split(',')[1]}>{v.eventdatetime.split(',')[1]}</option>
                    })}
                  </select>
                ) : (<div></div>)}

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <DatePicker
                    orientation="landscape"
                    openTo="date"
                    variant="static"
                    disableToolbar="true"
                    disablePast
                    displayStaticWrapperAs="desktop"
                    value={value}
                    loading={loading}
                    onChange={handledateChange}
                    onMonthChange={(date) => {
                      handleMonthChangeFilter(date);
                    }}
                    renderDay={(day, _value, dayInCurrentMonth, dayComponent) => {
                      const dayString = `${day.getMonth()}-${day.getDate()}-${day.getFullYear()}`;
                      const isSelected =
                        highlightedDays.indexOf(dayString) > 0 && dayInCurrentMonth;
                      return isSelected ? (
                        <Badge
                          key={day.toString()}
                          overlap="circular"
                          badgeContent={isSelected ? '' : undefined}
                        >
                          {dayComponent}
                        </Badge>
                      ): dayComponent;
                    }}
                  />
                </MuiPickersUtilsProvider>
    
              </Grid>
              {eventTemp && order ? ( <Grid item xl={9} lg={8} md={6} sm={12} xs={12}>        
                {eventDateSelected ? (<Grid item lg={12} xs={12}>
                  <TabTicket detail={eventTemp} PurcTicket={order.nooftickets} order={order} />
                </Grid>) : (<Grid item lg={12} xs={12}>
                  <NoEventsFound />
                </Grid>)
                }
                <Grid container spacing={2} className="text-right">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Controls.Button text="Exchange" onClick={exchangeTicketFun} className="link-red">Exchange</Controls.Button>
                  </Grid>
                </Grid>
              </Grid>
              ) : (
              !loading ? <Grid item lg={12} xs={12}>
                 <NoEventsFound />
              </Grid> : <Loading/>
            )}


            </Grid>
          <Notification notify={notify} setNotify={setNotify} />

        </div>
      </main>
      <Footer />

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Exchange Confirmation</DialogTitle>
        <DialogContent>
          {!showLoading ? <DialogContentText>{`You are about to transfer the ticket(s) for  ${order?.eventname?.toUpperCase()}  from ${order?.eventdatetime} occurence to ${eventdate + ',' + eventDatetimeto} occurence`}</DialogContentText> : <DialogContentText>Loading Please wait until response....</DialogContentText>}
          
          {order?.nooftickets === change[0]?.numberoftickets ? <>
          { exchangePaymentDetail.paymentOrRefund === 'Payment' &&
          exchangePaymentDetail.priceChange >= 0.5 &&
            <DialogContentText>This exchange results in an extra charge of {accounting.formatMoney(exchangePaymentDetail.priceChange, exchangePaymentDetail.currencysymbol)} because the ticket total has changed from {accounting.formatMoney(exchangePaymentDetail.sourceTotal, exchangePaymentDetail.currencysymbol)} to {accounting.formatMoney(exchangePaymentDetail.destinationtotal, exchangePaymentDetail.currencysymbol)}. Do you wish to charge the customer?</DialogContentText>
          } 
          { exchangePaymentDetail.paymentOrRefund === 'Refund' &&
          exchangePaymentDetail.priceChange < 0.5 &&
            <DialogContentText>This exchange results in a refund of {accounting.formatMoney(exchangePaymentDetail.priceChange, exchangePaymentDetail.currencysymbol)} because the ticket total has changed from {accounting.formatMoney(exchangePaymentDetail.sourceTotal, exchangePaymentDetail.currencysymbol)} to {accounting.formatMoney(exchangePaymentDetail.destinationtotal, exchangePaymentDetail.currencysymbol)}. Do you wish to refund the customer?</DialogContentText>
          } 
        </>: null}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="link-gray">
            Cancel
          </Button>

          <ExchangeButtons exchangePaymentDetail={exchangePaymentDetail} showLoading={showLoading} handleContinue={handleContinue} ticketcount={order?.nooftickets || 0} selectedTicketCount={change[0]?.numberoftickets || 0}></ExchangeButtons>
         
        </DialogActions>
      </Dialog>


    </div>
  );

};

const ExchangeButtons = ({exchangePaymentDetail, showLoading, handleContinue, selectedTicketCount, ticketcount}) => {
  if (ticketcount > selectedTicketCount ) {
    return (
    <Button autoFocus onClick={() => { handleContinue(false) }}  color="primary"  className="link-red">
    Continue 
    </Button>);
  }
  else if (exchangePaymentDetail.paymentOrRefund === 'Payment' && exchangePaymentDetail.priceChange >= 0.5) {
    return (
      <>
      {!showLoading ? <Button disabled={exchangePaymentDetail.giftCardUsed} onClick={() => { handleContinue(true) }} color="primary" autoFocus className="link-red">
        Continue With Charge
      </Button> :  <Button disabled autoFocus  className="link-red py-0">
        Continue With Charge
      </Button>}            
    
      {!showLoading ? <Button onClick={() => { handleContinue(false) }} color="primary" autoFocus className="link-red">
    Continue Without Charge
      </Button> :  <Button disabled autoFocus  className="link-red py-0">
        Continue Without Charge
      </Button>}
                            
      </>      
    )
  }
  else if (exchangePaymentDetail.paymentOrRefund === 'Refund' && exchangePaymentDetail.priceChange < 0.5) {
    return (
      <>
      {!showLoading ? <Button disabled={exchangePaymentDetail.giftCardUsed} onClick={() => { handleContinue(true) }} color="primary" autoFocus className="link-red">
        Continue With Refund
      </Button> :  <Button disabled autoFocus  className="link-red py-0">
        Continue With Refund
      </Button>}            
    
      {!showLoading ? <Button onClick={() => { handleContinue(false) }} color="primary" autoFocus className="link-red">
    Continue Without Refund
      </Button> :  <Button disabled autoFocus  className="link-red py-0">
        Continue Without Refund
      </Button>}
             
      </>        
    )
  }
  return (
    <>
      {!showLoading ? <Button onClick={() => { handleContinue(false) }} color="primary" autoFocus className="link-red">
      Continue
        </Button> :  <Button disabled autoFocus  className="link-red py-0">
          Continue
        </Button>}            
    </>
  )
};

export default ExchangeTicket;