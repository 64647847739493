import Cookies from 'universal-cookie'; 
const cookies = new Cookies(); 

export default function initWidget(id) {
    const d = document.getElementById('widget-onthe-stage');
    
    const currentUser = cookies.get('currentUser');
    if (d) {
        const width = d.getAttribute('data-width')
        const height = d.getAttribute('data-height')
        const iframe = document.createElement('iframe');
        const iframeId = id + '-iframe';
        iframe.id = iframeId;
        iframe.src = `${process.env.REACT_APP_WIDGET_URL}`;
        iframe.width = width + '%';
        iframe.height = height + '%';
        iframe.setAttribute('frameborder', 'no');
        iframe.setAttribute('allowfullscreen', 'allowfullscreen');
        iframe.setAttribute('allow', 'payment');
        d.appendChild(iframe); 

  

        //listners
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent, function (event) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const customerid = sessionStorage.getItem('widgetCustomerId');
            const eventid = sessionStorage.getItem('widgetEventId')
            const occurrenceid = sessionStorage.getItem('widgetOccurrenceid')
            const upsellData = sessionStorage.getItem('widgetUpsellData')?JSON.parse(sessionStorage.getItem('widgetUpsellData')):{};
            const venueDetail = sessionStorage.getItem('widgetVenueDetail');
            const reservationId = sessionStorage.getItem('reservationId');
            const channel ="boxoffice";
            const userid = currentUser ? currentUser.userid : null;
            const token = currentUser ? currentUser.token : null;
            const host = window.location.href;
          
            if(event && event.data && event.data.type){
                if(event.data.type === 'INITIALIZE_WIDGET'){
                    document.getElementById(iframeId).contentWindow.postMessage({ type: event.data.type,host,eventid, customerid,occurrenceid,upsellData, host, venueDetail,channel, userid, token, reservationId }, '*');
					return;
                } else if (event.data.type === 'WIDGET_LOADED') {
                    console.log('EMBED.jS WIDGET_LOADED');
                    if(params && params.hasOwnProperty('app') && params.hasOwnProperty('payid') && params.app && params.payid && event?.data?.type !== 'downloadPdf'){

                        const modal = document.getElementById("myModal");
                        modal.style.display = "block";

                        document.getElementById(iframeId).contentWindow.postMessage({ 
                            type: 'WIDGET_PAYMENT_VERIFY',
                            ...params,
                            host,
                            eventid,
                            customerid,
                            occurrenceid,
                            upsellData,
                            venueDetail,
                            channel,
                            userid
                        }, '*');
                        document.getElementById(id).scrollIntoView();
                        window.history.pushState(null, "", window.location.href.split("?")[0]);
                        return;
                    }                    
                }  else if(event.data.type === 'stripeCheckout' && event.data.url){
                    window.location.replace(event.data.url)
					return;
                }else if(event.data.type === 'downloadPdf' && event.data.url){
                    const customerid = sessionStorage.getItem('widgetCustomerId')
                    const eventid = sessionStorage.getItem('widgetEventId')
                    const occurrenceid = sessionStorage.getItem('widgetOccurrenceid')
                    const upsellData = sessionStorage.getItem('widgetUpsellData')?JSON.parse(sessionStorage.getItem('widgetUpsellData')):{};
                    const venueDetail = sessionStorage.getItem('widgetVenueDetail')
                    const channel ="boxoffice";
                    const userid = currentUser ? currentUser.userid : null;
                    const host = window.location.href;
                    document.getElementById(iframeId).contentWindow.postMessage({ type: 'INITIALIZE_WIDGET',host,eventid, customerid, upsellData,occurrenceid,host,venueDetail,channel,userid}, '*');
                    const link = document.createElement('a');
                    link.href = event.data.url
                    link.download = event.data.pdfName;
                    link.target="_blank"
                    link.click();
					return
                }
            }
        }, false);
    }
}
