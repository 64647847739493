import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { authorizeFirebaseUser } from "../../services/authService";
import Cookies from 'universal-cookie';
import Notification from '../../components/Notification';
import { getEventPerformanceViewData } from '../../services/eventService'

export default function CheckInView() {
  const { eventOccurrenceId } = useParams();
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [checkinUrl, setCheckinUrl] = useState('');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [eventOccurrence, setEventOccurrence] = useState({});

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const errorHandle = (error) => {
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
    notification(messages, 'error');
  }

  const getCheckInListUrl = async (e) => {
    try {
      const firebaseHost = process.env.REACT_APP_FIREBASE_CHECKIN_APP;
      const data = {
        uid: e.uid,
        eventId: e.id,
        eventName: e.title,
        OrganizationId: eventOccurrence?.eventdetailfile?.accountid,
        enable_qr: true
      };

      const res = await authorizeFirebaseUser(data)
      const token = res.customToken;
      const orgId = res.orgId;
      const parameters = `token=${token}&eventId=${e.id}&orgId=${orgId}&enable_qr=true&is_paddington=true`;
      return `${firebaseHost}?${encodeURIComponent(parameters)}`;
    } catch (error) {
      errorHandle(error);
    }
  }

  useEffect(() => {
    const load = async () => {
      if (checkinUrl === '') {
        const eventPerformanceViewData = await getEventPerformanceViewData(eventOccurrenceId);
        const eventOccurrence = eventPerformanceViewData.data.eventOccurrence;
        console.log('eventOccurrence',eventOccurrence);
        setEventOccurrence(eventOccurrence || {});
        const eventData = {
          uid: currentUser.userid.toString(),
          email: currentUser.email,
          OrganizationId: currentUser.accountid,
          id: eventOccurrenceId,
          title: eventOccurrence.eventname
        };
        const checkinURL = await getCheckInListUrl(eventData);
        setCheckinUrl(checkinURL);
      }
    };

    setTimeout(load, 500);
  }, [])

  return <div>
    <Helmet title="CheckIn" />

    <Notification
      notify={notify}
      setNotify={setNotify}
    />

    {checkinUrl !== '' && <iframe key={eventOccurrenceId} title='CheckIn View' width="100%" height="100%" allow="camera; microphone; fullscreen" src={checkinUrl} style={{ 'height': '100vh' }}></iframe>}
  </div>
}