import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});
const getAllTaxCategory = async (user) => {
    const currentUser = cookies.get('currentUser');//list

    return await http
        .post(`/customers/getAllTaxCategory`, user, {
            headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

}

const getAddListTaxCategory = async (user) => {//add list
  const currentUser = cookies.get('currentUser');
    return await http
        .post(`/customers/getAddListTaxCategory`, user, {
            headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

async function updateTaxCategory(user) {
    const currentUser = cookies.get('currentUser');
    return await
      http
        .post(`/customers/updateTaxCategory`, user, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

const deleteTaxCategory = async (user) => {//edit percentage variable null
  const currentUser = cookies.get('currentUser');
    return await http
        .post(`/customers/deleteTaxCategory`, user, {
            headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

async function venueByAccountId(id) {
    const currentUser = cookies.get('currentUser');
    return await
      http
        .post(`/customers/venueByAccountId`,id, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data[0];
        })
        .catch((error) => {
          throw error;
        });
  }
export default { getAllTaxCategory, getAddListTaxCategory, updateTaxCategory, deleteTaxCategory,venueByAccountId };