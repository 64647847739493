import React, { useEffect } from "react";
import "./App.css";
import "./main.css";
import Routing from "./routers/Router";
import initWidget from "./embed";

const App = () => {
  useEffect(() => {
    window.initWidget = initWidget;
    initWidget('widget-onthe-stage');
  }, []);
  return (
    <Routing/>
  );
}

export default App;