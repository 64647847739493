import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, TableCell, Toolbar, Grid, makeStyles, Box, InputAdornment, Divider, Typography, Switch } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";
import userService from '../../services/userService';
import Loading from '../../components/controls/Loading';
import { getTicketTypeByAccountId  ,updateTicketType ,deleteTicketType} from '../../services/ticketTypeService';
import { eventedit } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    pageContent: {
        padding: '0px 15px',
        margin: '0px auto',
    },
    content: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));


const drawerWidth = 250;


const headCells = [
    { id: "ticket_types.name", label: "Name" },
    { id: "ticket_types.description", label: "Description" },
    { id: "eventname", label: "Performance/Show" },
    { id: "ticket_types.per_performance", label: "Per Performance" },
    { id: "ticket_types.maximum_ticket_per_event", label: "Per Event" },
    { id: "ticket_types.activestatus", label: "Status" },
    { id: 'actions', label: 'Actions', disableSorting: true }
];

const TicketTypes = () => {

    const classes = useStyles();
    const dispatch = useDispatch(); 
    const history = useHistory();
    const [records, setRecords] = useState([]);
    const [record, setRecord] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [condition, setCondition] = useState(false);
    const cookies = new Cookies();
    const currentUser = cookies.get('currentUser');
    const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
    const [show, setShow] = useState({ showLoading: true, disableSwitch: false });
    const { showLoading, disableSwitch } = show;

    const checkParam = (reqParams, param_key) => {
        return (reqParams.includes(param_key));
    };

    const errorHandle = (error) => {
        if (!error && !checkParam(Object.keys(error))) {
            notification(error, 'error');
        } else {
            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
        }
    }

    useEffect(() => {
        let cancel = false;
   
        userService.getUserAccounts()
            .then(result => {
                if (cancel) return;
                setRecord([...result])
            })
            .catch((error) => {
                errorHandle(error);

            });
            ticketTypeList(selectedAccountId);
        return () => {
            cancel = true;
        }

    }, []);

    const ticketTypeList =async(value)=>{
        try{
         const response = await getTicketTypeByAccountId({accountid:value})
         setRecords(response.ticketTypeList);
         setShow({ ...show, showLoading: false });
        }catch(err){
            errorHandle(err);
            setShow({ ...show, showLoading: false });
        }

    }
 

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn, condition);

    const handleSearch = e => {
        let target = e.target;
        let searchString = target.value;
        setFilterFn({
            fn: items => {
                if (searchString === "") {
                    return items;
                }
                else {
                    return items.filter(x => (x.ticket_types.name.toLowerCase().includes(searchString.toLowerCase())
                    || x.eventname.toLowerCase().includes(searchString.toLowerCase()) ))
                    
                }
            }
        })
        if (searchString === "") {
            setCondition(false);
        } else {
            setCondition(true);
        }
    };


    const timeOutSet = () => {
        notification("Your Session Expired....Try again...", "error");
        setTimeout(() => {
            cookies.remove('currentUser'); history.push('/');
        }, 2000);
    }

    const notification = (message, type) => {
        setNotify({
            isOpen: true,
            message: message,
            type: type
        })
    }

    const openInPopup = item => {
        dispatch(eventedit(item));
        history.push(`/TicketTypeConfiguration/${selectedAccountId}`)
    };


    const getAccountidDetails = (e) => {

        setRecords([]);
        setShow({ ...show, showLoading: true });
        setSelectedAccountId(e.target.value)
        ticketTypeList(e.target.value);
    }
    


    const onDelete = async(records) => {
        try{
            let object = {
                event_id: records._id,
                ticketTypeid: records.ticket_types._id,
              }
              const res = await deleteTicketType(object);
              ticketTypeList(selectedAccountId);
              notification(res.message,"success");
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false
              });
        }catch(err){
            errorHandle(err);
        }
    };

    const handleActiveStatus = async (status, item) => {
        try{

          let object = {
            ...item.ticket_types,
            ticketTypeid: item.ticket_types._id,
          }
          delete object.activestatus;
          object.activestatus = status;
          const res = await updateTicketType(object);
          ticketTypeList(selectedAccountId);
          notification(res.message,"success");

        }catch(err){
            errorHandle(err);
        }
    }
    return (
      <div>
        <Helmet title="Ticket Type" />
        <main className={classes.content}>
          <Grid item lg={12} xs={12}>
            <Grid container spacing={0}>
              <Grid item lg={6} sm={6} xs={6}>
                <Typography variant="h5" gutterBottom className="breadcrumbs">
                  Ticket Types
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={6}>
                <Toolbar className="selectbar singleline">
                  <Box>
                    {currentUser.roleid === 1 && record.length > 0 ? (
                      <select
                        value={selectedAccountId}
                        onChange={getAccountidDetails}
                        className="select-box mt-0 mb-0"
                      >
                        <option value="0">Select Customer</option>
                        {record.map((v, k) => {
                          if (v.roleid !== 1)
                            return (
                              <option key={k} id={k} value={v.accountid}>
                                {v.accountname}
                              </option>
                            );
                        })}
                      </select>
                    ) : (
                      <div></div>
                    )}
                  </Box>
                </Toolbar>
              </Grid>
            </Grid>
          </Grid>
          <Divider />

          <div className={classes.pageContent}>
            <Toolbar className="searchbar">
              <Input
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                size="small"
              />

              <Controls.ActionButton
                onClick={() => {
                  history.push(`/TicketTypeConfiguration/${selectedAccountId}`)
                }}
                className="outlined-primary"
              >
                <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
              </Controls.ActionButton>
            </Toolbar>
            <TblContainer className="upsell-tbl">
              <TblHead className="p-1" />
              <TableBody className="upsell-tbl">
                {recordsAfterPagingAndSorting().map((records, index) => (
                  <TableRow key={index}>
                    {/* <TableCell className="p-1">{record.userid}</TableCell> */}
                    <TableCell>{records.ticket_types.name}</TableCell>
                    <TableCell>
                        <Typography  style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "280px",
                        display: "block",
                        overflow: "hidden",
                        fontSize: "14px"
                      }}>
                      {records.ticket_types.description}
                      </Typography>
                    </TableCell>
                    <TableCell>{records.eventname}</TableCell>
                    <TableCell>
                      {records.ticket_types.per_performance}
                    </TableCell>
                    <TableCell>
                      {records.ticket_types.maximum_ticket_per_event}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={records.ticket_types.activestatus}
                        onChange={(e) =>
                          handleActiveStatus(e.target.checked, records)
                        }
                        value={index}
                        disabled={disableSwitch}
                      />
                    </TableCell>
                    <TableCell className="p-1">
                      <Controls.ActionButton
                        onClick={() => {
                          openInPopup(records);
                        }}
                        className="outlined-primary"
                      >
                        <EditOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="primary"
                        />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to delete the record ?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(records);
                            },
                          });
                        }}
                      >
                        <DeleteOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="secondary"
                        />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableBody>
                {!recordsAfterPagingAndSorting().length && (
                  <TableRow style={{ textAlign: "center" }}>
                    {showLoading ? (
                      <TableCell className="p-1 text-left w-100" colSpan="10">
                        <Loading />
                      </TableCell>
                    ) : (
                      <TableCell className="p-1 text-left" colSpan="10">
                        {<Alert severity="warning">No records found</Alert>}
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </div>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </main>
        <Footer />
      </div>
    );
};

export default TicketTypes;