import React, { useEffect, useState } from 'react';
import {Grid, Button, DialogActions} from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import "./mapping.scss";
import MappingPreview from './MappingPreview';
import Popup from '../../components/Popup';
import Notification from '../../components/Notification';
import {getPlayereventDetails} from '../../services/mapInventoryService' ;



const AddFields = ({setOpenPopupMapping,eventid,dbsend,teamdetail,setTeamDetail,addorEdit,setaddorEdit,setMapinventoryCheck}) => {
    const [inputList, setInputList] = useState([{ teamid:"0",teamname: "", noofplayers: "",isdelete:"0" }]);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
    const [titlename, setTitlename] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [validation,setValidation]= useState("-1");
    const [validation1,setValidation1]= useState("-1");
    const [validation2,setValidation2]= useState(null);
    const [remove,setRemove]=useState([])
    const [finalvalue,setFinalValue] = useState([])


    useEffect(()=>{
     
      if (teamdetail.length > 0) {
        setInputList(teamdetail)
        setFinalValue(teamdetail);
      } else {

        const user = {
          "action_type": "teammaster list",
          eventid: eventid,
        }

        if (eventid)
          getPlayereventDetails(user).then((res) => {
            if (res.data.length > 0) {
              setInputList(res.data);
              setFinalValue(res.data);
              setaddorEdit("Edit");
            }
          }).catch((error) => {
            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            notification(messages, "error");
          });

      }

    },[teamdetail])





    const handleInput = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
      setFinalValue(list);
      setValidation2(null)
    };
   
 
    const handleRemoveClick = (index,x) => {
   
    let datas=[]
   if(x.teamid!=="0" && dbsend==="Edit"){
    setRemove([...remove,x.teamid])
    datas.push(x)
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    

   }else{
    
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFinalValue(list);

    };
  }
   
  
    const handleAddClick = () => {

      setInputList([...inputList, {teamid:"0", teamname: "", noofplayers: "",isdelete:0 }]);
     
    };

  

    
    const handleContinue=()=>{
      
      let count=0
      
     
   
      inputList.forEach((e,i)=>{
        let check=0
        if(e.teamname===""){
          setValidation(i);
          count++;
        }
        if(e.noofplayers===""||Number(e.noofplayers)===0){
          setValidation1(i);
          count++;
        }
        
        for(let a=0; a<inputList.length;a++){
       
          if(e.teamname===inputList[a].teamname){
            check=check+1
            if(check>1){
              setValidation2(i)
              count++;
           
            }
          }
        }
      
      })
     if(count===0){
      setOpenPopup(true);
      setTitlename(true);
     }else{
       notification("Please check the  field","error")
     }

 
    
    }
    const onKeyPressHandler = e=> {
      if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
      }
      else{
        e.preventDefault(); 
      }
  }

  const notification = (message,type)=>{
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }
  
    return (
      <div>  <ol className="decimal">   
        {inputList.map((x, i) => {
          if(x.isdelete!=='1'){
          return (
            <li>           
              <Grid container spacing={2}> 
                     
            <Grid item lg={5} md={5} sm={5} xs={12}>
            <Controls.Input
            type="text"
            name="teamname"
            label="Team Name"
            value={x.teamname}
             className="smaller-input"
             onChange={e => handleInput(e, i)} 
            error={Number(validation)===i?inputList[i].teamname?"":"Team Name is required":(validation2===i?"This Team Name is repeated":"")}
            // error={()=>handlerror(i)}
            />
            
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={12}>
            <Controls.Input
            
            name="noofplayers"
            label="Number of ticket"
            onKeyPress={onKeyPressHandler}
            value={x.noofplayers}
            inputProps={{
              maxLength: 2,
            }}
             className="smaller-input"
             onChange={e => handleInput(e, i)} 
             error={Number(validation1)===i?inputList[i].noofplayers?Number(inputList[i].noofplayers)!==0?"":"Zero is not accepted":"Ticket is required":""}/>        
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={12} alignItems="center">
            {inputList.length !== 1 && <div
                className="mr10"
                ><DeleteOutlinedIcon fontSize="medium" variant="outlined" className="text-red pointer" onClick={() => handleRemoveClick(i,x)} /></div>}
                
            </Grid>  
            <Grid item lg={12} md={12} sm={12} xs={12}>
            {inputList.length - 1 === i && <Button size="medium" variant="outlined" className="btn-redoutline" title="Add Another"onClick={handleAddClick}>
                <AddCircleOutlineIcon color="primary"  className="text-red" />&nbsp;Add Another</Button>}
            </Grid>
           
            </Grid>   
            </li>
               
          );
            }
        })}
         </ol> 
         <DialogActions dividers>          
         <Controls.Button text="Cancel" className="link-gray" onClick={() => {setOpenPopupMapping(false)}} />
         <Controls.Button type="submit" onClick={handleContinue} text="Continue" className="link-red"> </Controls.Button>
      </DialogActions>  
      <Popup
          title={titlename ? "Mapping Preview" : "Mapping Preview"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
      >
          <MappingPreview  inputList={inputList} setOpenPopup={setOpenPopup} 
            setOpenPopupMapping={setOpenPopupMapping} eventid={eventid} addorEdit={addorEdit} remove={remove}
            finalvalue={finalvalue} setTeamDetail={setTeamDetail} setMapinventoryCheck={setMapinventoryCheck}
          /> 
      </Popup>
      <Notification
        notify={notify}
        setNotify={setNotify}
    />
      </div>
      
    );
  }
   

export default AddFields;