import { Grid, makeStyles, DialogActions, InputAdornment,Divider,FormControl ,Box,Switch,Button ,Select,MenuItem,InputLabel} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import { updateEventDetails } from '../../services/eventService';
import { dateformat } from '../../utils/dateFormat';
import Cookies from 'universal-cookie';
import EventTicketallocation from '../events/EventTicketallocation';
import {getAllotEventManagement} from '../../services/allotmentService'
import moment from 'moment';

const useStyles = makeStyles(theme=>({
    root: {
        '& >  + ': {
          margin: theme.spacing(2),
        },    
      },
    select:{

        width: '100% !important',
    },
  }));
const initialFValues = {
    EventDate:'',
    EventTime:'',
    TicketCost:0,
    NumberofTickets:0,
    Onsaledate:'',
    OnsaleTime:'',
    Enddate:'',
    EndsaleTime:'',
    CustomMessage: '',
 
   
};

const EventModal = (props) =>{
    const cookies = new Cookies();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
    const {cancelbutton,recordForEdit,setOverWrite,overWriteData,setOverWriteData,disabled,dbsend } = props;
    const [alignment, setAlignment] = useState(true);
    const [Currency,setCurrency]=useState('');
    const [records,setRecords]=useState(null);
    const currentUser = cookies.get('eventaddedit');
    const currentUser1 = cookies.get('currentUser');
    const [maxValidation, setMaxValidation] = useState(null);
    const [inputList, setInputList] = useState([{ teamid: null,allocationid:0, allocationname: "0", allocationcount: "",allocationeventoccurrencemapid:"0", isaction: "0" }]);
    const [ticketError,setTicketerror]=useState(true);
    const [eventticketDetail,setEventticketdetail]=useState([]);
    const [removed, setRemoved] = useState([]);
    const[check,setcheck]= useState("");
    const[removedteam,setRemovedteam]= useState([]);
     const [finalvalue, setFinalValue] = useState([])
     const [checkingCount, setCheckingCount] = useState([])
     const [oneClick, setOneClick] = useState(false);
    const customDateFormat = currentUser1.customdateformat !== undefined ? currentUser1.customdateformat : 'MM-DD-YYYY';

    useEffect(()=>{
        const user={
            "action_type":"list",
            "eventdetail_id":recordForEdit.datas._id,
            "eventid":'',
            "allocations": []
          
        }
        getAllotEventManagement(user).then((res)=>{
            if(res.data!==undefined&&res.data.length>0){  
                let itemarray=[]
               res.data.forEach((x)=>{
                let itemdata={}
                itemdata.teamid=x.teamid    
                itemdata.allocationcount=Number(x.availablecount)
                itemdata.allocationid=x.allocationid
                itemdata.allocationname=x.allocationname
                itemdata.eventdetailid=x.eventdetailid
                itemdata.eventid=x.eventid
                itemdata.allocationeventoccurrencemapid=x.allocationeventoccurrencemapid
                itemarray.push(itemdata)
               })
               setInputList(itemarray)
               setCheckingCount(res.data);
            }
      
        })
    },[])

    const validate = (fieldValues = values)=>{
       
        const temp = {...errors};           
     
        if("EventDate" in fieldValues){
            if(!isValidDate(fieldValues.EventDate)){
                temp.EventDate = "Date Format is wrong, Eg.MM-DD-YYYY"
            }else if(!isValidPastDate(fieldValues.EventDate)&&!disabled){
                temp.EventDate = "Event Date is should not be past date"
            }
            else{
            temp.EventDate = fieldValues.EventDate?"":"EventDate is required";
            }
        }
        if("EventTime" in fieldValues){
            if(!isValidTime(fieldValues.EventTime)){
                temp.EventTime = "Time Format is wrong, Eg.12:00PM"
            }else{
            temp.EventTime = fieldValues.EventTime?"":"EventTime is required";
            }
        }
        if("TicketCost" in fieldValues){
            temp.TicketCost = fieldValues.TicketCost?"":"Ticket Cost is required";
        }
        if("NumberofTickets" in fieldValues){
            if(maxValidation!==0){
            if(Number(fieldValues.NumberofTickets) < maxValidation){
                temp.NumberofTickets = `Total tickets cannot be less than ${maxValidation}.`
              }else{
                temp.NumberofTickets = ''
              }
            }else{
            temp.NumberofTickets = fieldValues.NumberofTickets&&Number(fieldValues.NumberofTickets)!==0?"":"NumberofTickets is required";
            }
        }
        if("Onsaledate" in fieldValues){
            if(!isValidDate(fieldValues.Onsaledate)){
                temp.Onsaledate = "Date Format is wrong, Eg.MM-DD-YYYY"
            }else if(!isValidPastDate(fieldValues.Onsaledate)&&!disabled){
                temp.Onsaledate = "Onsaledate is should not be past date"
            }
            else{
            temp.Onsaledate = fieldValues.Onsaledate?"":"Onsaledate is required";
            }
           
        }
        if("OnsaleTime" in fieldValues){
            if(!isValidTime(fieldValues.OnsaleTime)){
                temp.OnsaleTime = "Time Format is wrong, Eg.12:00AM"
            }else{
            temp.OnsaleTime = fieldValues.OnsaleTime?"":"OnsaleTime is required";
            }
        }
        if("Enddate" in fieldValues){
            if(!isValidDate(fieldValues.Enddate)){
                temp.Enddate = "Date Format is wrong, Eg.MM-DD-YYYY"
            }else if(!isValidPastDate(fieldValues.Enddate)&&!disabled){
                temp.Enddate = "Enddate is should not be past date"
            }
            else{
            temp.Enddate = fieldValues.Enddate?"":"Enddate is required";
            }
        }
        if("EndsaleTime" in fieldValues){
            if(!isValidTime(fieldValues.EndsaleTime)){
                temp.EndsaleTime = "Time Format is wrong, Eg.12:00AM"
            }else{
            temp.EndsaleTime = fieldValues.EndsaleTime?"":"EndsaleTime is required";
            }
        }
     

        setErrors({...temp});
        return Object.values(temp).every((x) => x === "");


    };
    const formIsValid = (fieldValues = values) => {
      if( validate()) {
        const isValid =
     
          fieldValues.Enddate &&
          fieldValues.EventDate &&
          fieldValues.Onsaledate &&
          fieldValues.EventTime &&
          fieldValues.OnsaleTime &&
          fieldValues.EndsaleTime &&
          fieldValues.TicketCost&&
         
          Object.values(errors).every((x) => x === "");
    
         return isValid;
        }
      };
         
  const isValidPastDate = (dateString) => {
    let d = new Date();
    let today = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
    let todayDt = moment(today, 'MM-DD-YYYY');
    const dt = moment(dateString, customDateFormat.toUpperCase());
    if (dt < todayDt) {
      return false;
    }
  }

  const isValidDate = (dateString) => {
    const d = moment(dateString, customDateFormat.toUpperCase());
    return d !== 'Invalid Date' || !d.isValid();
  };

  const getDBDate = (d) => {
    const dt = moment(d, 'MM-DD-YYYY').format(customDateFormat.toUpperCase());
    return dt; // convert to default format that DB understands
  };

  const isValidTime = (timeString) => {

    if (!(/^\d{1,2}:\d{2}([AP]M)?$/.test(timeString))) {
      return false;
    }
    let parts = timeString.split(":");
    let minutes = parseInt(parts[1], 10);
    let hours = parseInt(parts[0], 10);

    if (hours <= 0 || hours > 12 || minutes < 0 || minutes >= 60) {
      return false;
    }

    return true
  };

const timeCheck=(startTime,endTime,Dates)=>{

    const start = startTime.replace('AM', ' AM').replace('PM', ' PM')
    const end = endTime.replace('AM', ' AM').replace('PM', ' PM')
let dtStart = new Date(`${Dates.replace(/-/g,'/')} ` + start);
let dtEnd = new Date(`${Dates.replace(/-/g,'/')} `  + end);
let difference_in_milliseconds = dtEnd - dtStart;
if (difference_in_milliseconds === 0)
{
    notification("Dupliate EventDate And Time exists","error");
    return true;
}
}

const duplicateDate = ()=>{
    if(recordForEdit.records!==0){
    const rows = recordForEdit.records.eventdetailfile

        if(recordForEdit.datas.eventdatetime.split(',')[0]!==values.EventDate||recordForEdit.datas.eventdatetime.split(',')[1]!==values.EventTime){
            const eventDated = rows.filter((e)=>e.eventdatetime.split(',')[0]===values.EventDate);
            if(eventDated.length!==0){
                let count = 0;
                const eventDatedList = eventDated.filter((e)=>e._id!==(recordForEdit.datas)._id);
                for(let a=0;a<eventDatedList.length;a++){
                const Time = timeCheck(eventDatedList[a].eventdatetime.split(',')[1],values.EventTime,values.EventDate);
              if(Time){
                  count = count+1;
              }
            }
            return count>0?false:true;
            }else{
                return true;
            }
        }else{
            return true;
        }
     }
    else{
        return true;
    }
}

const beforeDate= (start,end,startTime,endTime,name)=>{
    let startDateTime = moment(`${start} ${startTime}`, customDateFormat.toUpperCase() + ' hh:mmA');
    let endDateTime = moment(`${end} ${endTime}`, customDateFormat.toUpperCase() + ' hh:mmA');
    if (endDateTime.isBefore(startDateTime)) {
        console.log('enddate time is after start date time', endDateTime, startDateTime);
        Notifications(name, 1);
        return true;
    }
    return false;
}

const checkDuplicate=()=>{
if(beforeDate(values.Enddate,values.EventDate,values.EndsaleTime,values.EventTime,"EndDate")){
    return false;
}
else if(beforeDate(values.Onsaledate,values.Enddate,values.OnsaleTime,values.EndsaleTime,"Onsaledate")){  
    return false;
}
else{
    return true;
}
}
const Notifications=(a,b)=>{
if(a==="EndDate"){
if(b===1){
    notification("EndDate is higher then EventDate","error");
}else{
    notification("Ticket Sales end time is greater than or equal to the Event time ","error");
}
}else{
if(b===1){
    notification("OnSale date is higher than Enddate ","error");
}
else{
    notification("OnSaleTime is greater than EndsaleTime","error")
}
}
}


 const {values,
           setValues,
           errors,
           setErrors,
           handleInputChange,
           resetForm } = useForm(initialFValues,true,validate);

    const ticketValidation=()=>{
    let condition = []
    let count = []
   
    for(let i=0;i<inputList.length;i++){
      count.push(Object.values(inputList[i]).every((e)=> e !==""))
      if(inputList[i].allotmentid===""){
          condition.push(false)
      }else{
        condition.push(true)
      }
    }
    if(Object.values(condition).every((e)=>e===false)){
        return true
    }
    else if(Object.values(count).every((e)=>e===true)){
        return true
    }else{
        notification("Please Give the value (or) delete the field in Ticket Allocation","error")
        return false
    }
    
     } 


    const handleSubmited = (event)=> {
     
       if (formIsValid()&&checkDuplicate()&&ticketError&&ticketValidation()) {
        setOneClick(true);
           const jsonvalue = inputList.map((x) => {
               const DataAllocation = checkingCount.filter(y=>y.allocationid===x.allocationid && y.teamid === x.teamid);
              const countStatus = DataAllocation.length > 0 ? Number(x.allocationcount) - DataAllocation[0].availablecount + DataAllocation[0].allocationcount : Number(x.allocationcount);
                return {
                    teamid: Number(x.teamid)?Number(x.teamid):null,
                    allocationcount: Number(countStatus),
                    allocationid: Number(x.allocationid),
                    allocationeventoccurrencemapid: Number(x.allocationeventoccurrencemapid),
                    isaction: Number(x.allocationeventoccurrencemapid)?"edit":"add"
               }    
           })
       const json=removed.length>0?jsonvalue.concat(removed):jsonvalue
           const userdetaildata = {
               "action_type": "all",
               "eventdetail_id": eventticketDetail._id,
               "event_id": (recordForEdit.records).id,
               "allocations": json
           }
           
           getAllotEventManagement(userdetaildata).then((res) => {
            
                  if ((res.message).toLowerCase() === "created") {
                      notification("Event Occurrence Updated Successfully", "success")
                  }
           }).catch((err)=>{
            setOneClick(false);
            notification("Not Updated Successfully","error");
           })




           let  availableTicket = 0 ;
           if(maxValidation===0){
            availableTicket = Number(values.NumberofTickets);
           }else if(maxValidation<Number(values.NumberofTickets)){
            availableTicket = Number(values.NumberofTickets)-maxValidation;
           }else{
            availableTicket=0
           }
           let data = {
               eventid: (recordForEdit.records).id,
               eventdetailid: (recordForEdit.datas)._id,
               accountid: currentUser ? currentUser.eventAccountId : '1',
               eventdatetime: recordForEdit.datas.eventdatetime,
               numberoftickets: Number(values.NumberofTickets),
               ticketcountstatus: Number(availableTicket),
               onsaledate: recordForEdit.datas.onsaledate,
               enddate: recordForEdit.datas.enddate,
               ticketcost: Number(values.TicketCost),
               onsaleflag: alignment ? 1 : 0,
               activestatus: (recordForEdit.datas).activestatus,
               custommessage: values.CustomMessage,
               venueid: (recordForEdit.records).venueid,
           }
          
        updateEventDetails(data).then((result)=>{       
            notification("Event Occurrence Updated Successfully","success");    
try{
   
              let remove = overWriteData.eventdetailfile.filter(x=>x._id!==(recordForEdit.datas)._id);
              data._id=data.eventdetailid
              delete data.eventid
              delete data.eventdetailid
              remove.push(data);
              const upcomming = [].concat(remove)
              .sort((a, b) => dateformat(a.eventdatetime.replace('AM',' AM').replace('PM',' PM')) > dateformat(b.eventdatetime.replace('AM',' AM').replace('PM',' PM')) ? 1 : -1)
              .map((item, i) => {return item} );
              records.eventdetailfile=upcomming;
              setOverWriteData(records);
              setOverWrite("Edit");
              setTimeout(() => {
             setOneClick(false);
             cancelbutton();
              }, 1000);
              
}catch{
    setOneClick(false);
    notification("Something went wrong","error");
}
              
          }).catch((error)=>{
            setOneClick(false);
            notification("Not Updated Successfully","error");
          })
         }
        
        
            
    };

    const notification = (message,type)=>{
        setNotify({
          isOpen: true,
          message: message,
          type: type
        })
      }
      
    useEffect(() => {
        if (recordForEdit !== null){
          
            const data={
                accountid:recordForEdit.datas.accountid,
                Enddate:getDBDate(recordForEdit.datas.enddate.split(',')[0]),
                EndsaleTime:recordForEdit.datas.enddate.split(',')[1],
                EventDate:getDBDate(recordForEdit.datas.eventdatetime.split(',')[0]),
                EventTime:recordForEdit.datas.eventdatetime.split(',')[1],
                NumberofTickets:recordForEdit.datas.numberoftickets,
                Onsaledate:getDBDate(recordForEdit.datas.onsaledate.split(',')[0]),
                OnsaleTime:recordForEdit.datas.onsaledate.split(',')[1],
                id:recordForEdit.datas._id,
                TicketCost:recordForEdit.datas.ticketcost,
                CustomMessage: recordForEdit.datas.custommessage,
                index:recordForEdit.index
            }
            setEventticketdetail(recordForEdit.datas)
            setRecords(recordForEdit.records);
            setCurrency(recordForEdit.records.currencytype);
            setAlignment(recordForEdit.datas.onsaleflag!==0?true:false);
            setValues(data)
            setMaxValidation(Number(recordForEdit.datas.numberoftickets) - Number(recordForEdit.datas.ticketcountstatus));
        }
            
    }, [recordForEdit]);

    

    const onKeyPressHandler = e=> {
        if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
        }
        else{
          e.preventDefault(); 
        }
    }
    const handleAlignment = (e) => {
        setAlignment(e.target.checked);
    };

    return(
        
        <Form className="create-user">
     <Notification
          notify={notify}
          setNotify={setNotify}
      />
            <Grid container item spacing={2} md={12} className="m-0">

            
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="EventDate"
                        label="Event Date"
                         value={values.EventDate||''}
                         disabled={disabled}
                         onChange={handleInputChange}
                        error={errors.EventDate} className="smaller-input" />

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="EventTime"
                        label="Event Time"
                         value={values.EventTime||''}
                         disabled={disabled}
                         onChange={handleInputChange}
                        error={errors.EventTime} className="smaller-input" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="CustomMessage"
                        label="Performance Message"
                         value={values.CustomMessage||''}
                         onChange={handleInputChange}
                        error={errors.CustomMessage} className="smaller-input" />

                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="NumberofTickets"
                        label="Number of Tickets"
                        onKeyPress={onKeyPressHandler}
                         value={values.NumberofTickets||''}
                         onChange={handleInputChange}
                        error={errors.NumberofTickets} className="smaller-input" />

                </Grid>
                                
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="TicketCost"
                        label="Ticket Cost"
                        type="number"
                        value={values.TicketCost || ''}
                        onChange={handleInputChange}
                        inputProps={{min:0}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{Currency}</InputAdornment>
                        }}
                        error={errors.TicketCost} className="smaller-input"
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="Onsaledate"
                        label="On Sale Date"
                        value={values.Onsaledate||''}
                        disabled={disabled}
                        onChange={handleInputChange}
                        error={errors.Onsaledate} className="smaller-input" />

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="OnsaleTime"
                        label="On Sale Time"
                        value={values.OnsaleTime || ''}
                        disabled={disabled}
                        onChange={handleInputChange}
                        error={errors.OnsaleTime} className="smaller-input" />

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Controls.Input
                        name="Enddate"
                        label="End Date"
                        value={values.Enddate||''}
                        disabled={disabled}
                         onChange={handleInputChange}
                        error={errors.Enddate} className="smaller-input" />

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                        name="EndsaleTime"
                        label="End Sale Time"
                        value={values.EndsaleTime || ''}
                        disabled={disabled}
                        onChange={handleInputChange}
                        error={errors.EndsaleTime} className="smaller-input" />

                </Grid>

     
     
              <Divider />
             
              < EventTicketallocation finalvalue={finalvalue} setFinalValue={setFinalValue} 
              eventticketDetail={eventticketDetail} records={records} values={values} 
              inputList={inputList} setInputList={setInputList} 
              setTicketerror={setTicketerror} removed={removed}
               setRemoved={setRemoved} setRemovedteam={setRemovedteam}
                removedteam={removedteam} setcheck={setcheck}  />
             
                <DialogActions className="w-100">
                <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box className="p-0"><b>Ticket Onsale:</b><Switch checked={alignment} onChange={handleAlignment} />	</Box>
                        <Controls.Button
                            text="Submit" size="medium" disabled={oneClick} onClick={handleSubmited} className="link-red" />                            
                   </Grid>
                </DialogActions>                
            </Grid> 
           
        </Form>
    )
};

export default EventModal;