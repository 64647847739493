import React,{useState} from "react";
import {
      Table,
      TableHead,
      TableBody,
      TableRow, 
      TableCell,
      Box,
      Chip,
     } from "@material-ui/core";
     import InfiniteScroll from "react-infinite-scroll-component";
     import Loading from "../../components/controls/Loading";
     import {getEventDashboardByAccountId} from '../../services/eventService';
     import { universaldate } from "../../utils/dateFormat";
const EventPopup =({eventFullData,id})=>{
  const [eventDetails,setEventDetails]=useState(eventFullData);
  const [count,setCount]=useState(2);
  const [hasMore,setHasMore]=useState(true);
  const fetchMoreData = () => {
    getEventDashboardByAccountId({"accountid" : Number(id) }, 10 , count)
    .then((res) => { 
        for(let a=0 ;a<res.data.length;a++){
            res.data[a].customerdateformat=universaldate(res.data[a].eventdatetime);
          }
    if(res.data.length>0){ 
    setEventDetails(eventDetails.concat(res.data));
    setCount(count+1);
    setHasMore(true);
    }else{
    setHasMore(false);   
    }
    }).catch((error)=>{
      setHasMore(false);
     })
};
    return(
        <div>
          <div id="scrollableDiv" style={{ height: 300, overflow: "auto"}}> 
<InfiniteScroll
dataLength={eventDetails.length}
next={fetchMoreData}
hasMore={hasMore}
loader={<Loading/>}
scrollableTarget="scrollableDiv"
endMessage={
<p style={{ textAlign: "center" }}>
<br/>
<b>Yay! You have seen it all</b>
</p>
}

>
            <Table>
          <TableHead>
            <TableRow> 
              <TableCell>Event Name</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell className="center">Ticket Cost</TableCell>  
              <TableCell className="center">Booking Fee</TableCell>  
              <TableCell className="center">Ticket Sold</TableCell> 
              <TableCell className="center">Revenue</TableCell>           
            </TableRow>
          </TableHead>
          <TableBody>  
          {eventDetails.length>0?(eventDetails.map((events,index)=>(
             <TableRow key={index}>             
             <TableCell>{events.eventname}</TableCell>
             <TableCell>{events.customerdateformat.split(',')[0]} <small>{events.eventdatetime.split(',')[1]}</small></TableCell>         
             <TableCell>{events.venuename}</TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.ticketcost}</TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.applicablefees}</TableCell>
             <TableCell className="center"><Box className="chip"><Chip label={events.soldcount +'/'+events.numberoftickets} variant="outlined" size="small"></Chip></Box></TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.totalEventCost}</TableCell>
           </TableRow>
          ))):(<TableRow><TableCell></TableCell></TableRow>)
          }
          
          </TableBody>
        </Table>
        </InfiniteScroll>
          </div> 
        </div>

    )
}
export default EventPopup