import React, { useState,useEffect} from "react";
import { Alert } from "@material-ui/lab";
import { getUpsellByEventId } from "../../services/eventService";
import { useHistory } from "react-router-dom";
import {getBookingDetails,checkBookingDetails,checkBookingDetailsindividual,getBookingDetailsindividual} from "../../services/eventService"
import {
    Typography,
    Button,
    Card, Grid
  } from "@material-ui/core";
  import QrUpsell from "./QrUpsell";
  import {initWidget} from '../../helper/Widget';
  import Cookies from 'universal-cookie';  
import Notification from "../Notification";
import { universaldate } from "../../utils/dateFormat";

const  QrScannedBookingDetails = ({QrData})=> {    
    const history = useHistory();
    const [eventDetails,setEventDetails]=useState([]);
    const [individualUpsellList,setIndividualUpsellList] = useState([]);
    const [validation,setvalidation]=useState(false);
    const [validationText,setvalidationText]=useState(false);
    const [validatedText,setValidatedText]=useState();
    const [validationTextSucess,setValidationTextSucess]=useState(false);    
    const [upsellAvailable,setUpsellAvailable]=useState(false);
    const [eventID,setEventID]=useState("");
    const [playerid,setPlayerid]=useState("");
    const [teamname,setTeamName]=useState("");
    const cookies = new Cookies(); 
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

    const errorHandle=(error)=>{
      const messages = error.response && !error.response.data.message ? error.response.data : error.message;          
      messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
      }

    useEffect(() => {      
        initiate();     
    },[]); 
 
    useEffect(() => {
      let cancel = false;
     if(eventID!==""){
        getUpsellByEventId({eventid:eventID, channel: "boxoffice"})
        .then((res) => {
          if (cancel) return;
              if(res.length>0){                
                setUpsellAvailable(true);
              }else{
                setUpsellAvailable(false);
              }
        })
        .catch((error) => {  
          errorHandle(error);         
        });
      } 
      return () => {
        cancel = true;
      }  
    },[eventID]);
    

    try{
      QrData=JSON.parse(QrData);        
    }
    catch(error){
        QrData={intentId:'',eventTicketDetailId:0}
      //QrData={"intentId":"pi_3LxZJLJQIXbrDAWa1ZblCG7E","eventTicketDetailId":3213,"ticketid":8693}
    }
  

const initiate=()=>{
  let cancel = false;
  if(QrData.ticketid===undefined){          
    getBookingDetails({paymentIntentId:QrData.intentId,List:'List',eventticketdetail_id:0})
    .then((res) => { 
      if (cancel) return;         
      let filteredEvents=res[0].filter((eventFilter)=>eventFilter.eventticketdetailid.toString().includes(QrData.eventTicketDetailId.toString()));      
      if(filteredEvents[0].paymentstatus==="Success"){
      setEventDetails(filteredEvents);
      setEventID(filteredEvents[0].eventid);
      if(filteredEvents[0].nooftickets===1){
        setTeamName(res[1][0].teamname);
        setPlayerid(res[1][0].playerid)
        setvalidation(true);
        setvalidationText(false)
       }            
       if(filteredEvents[0].recordstatus===1){
        setvalidationText(false)
       }   else{
        setvalidation(false);
        setvalidationText(true);        
        let date=(new Date(filteredEvents[0].updatedtime).getMonth()+1)+'/'+new Date(filteredEvents[0].updatedtime).getDate()+'/'+new Date(filteredEvents[0].updatedtime).getFullYear();        
        setValidatedText('Ticket already scanned at ' + date +' '+formatAMPM(new Date(filteredEvents[0].updatedtime)));        
       }
      }else{
        setEventDetails([]);
        notification("The ticket is already Refunded...","error");
      }
        sessionStorage.removeItem('qrData');
    })
    .catch((error) => {           
      errorHandle(error);
    });
  }else{
    const data = { action_type : "individual event list",
payment_id:QrData.intentId,
eventticketdetail_id: QrData.eventTicketDetailId,
ticket_id:QrData.ticketid,
ticket_status:""
}
    getBookingDetailsindividual(data)
    .then((res) => {          
      if (cancel) return;
    let filteredEvents=res.filter((eventFilter)=>eventFilter.eventticketdetailid.toString().includes(QrData.eventTicketDetailId.toString()));      
    if(filteredEvents[0].paymentstatus==="Success"){
      if(filteredEvents[0].tickettypeid){
      getBookingDetails({ paymentIntentId: QrData.intentId, List: 'upsell list', eventticketdetail_id: QrData.eventTicketDetailId })
      .then((res) => {
        const UpsellList = res[1].filter(x=>x.ticketid === QrData.ticketid);
        setIndividualUpsellList(UpsellList); 
      })
    }
    setEventDetails(filteredEvents);  
    setTeamName(res[0].teamname);
    setPlayerid(res[0].playerid);          
       if(filteredEvents[0].ticketstatus === 0){
        setvalidation(true);
        setvalidationText(false);
       }else if(filteredEvents[0].ticketstatus === 2){
        setvalidation(false);
        setvalidationText(true);        
        setValidatedText("This order has been updated. Please scan the updated / latest ticket");   
       } else{
        setvalidation(false);
        setvalidationText(true);        
        let date=(new Date(filteredEvents[0].updatedtime).getMonth()+1)+'/'+new Date(filteredEvents[0].updatedtime).getDate()+'/'+new Date(filteredEvents[0].updatedtime).getFullYear();        
        setValidatedText('Ticket already scanned at ' + date +' '+formatAMPM(new Date(filteredEvents[0].updatedtime)));        
       }
      }else{
        setEventDetails([]);
        notification("The ticket is already refunded...","error");
      }
      sessionStorage.removeItem('qrData');
    })
    .catch((error) => {           
      errorHandle(error);
    });
  }
  return () => {
    cancel = true;
  }
}

const timeOutSet = () => {
  notification("Your Session Expired....Try again...","error");
  setTimeout(() => {
     cookies.remove('currentUser');history.push('/');
  }, 2000);
}

const formatAMPM=(date) =>{  
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;  
  return strTime;
}

const validateTicket =() =>{
  if(QrData.ticketid!==undefined){
 const data =  { "action_type" : "individual ticket validation",
 "payment_id":QrData.intentId,
 "eventticketdetail_id": QrData.eventTicketDetailId,
 "ticket_id":QrData.ticketid,
"ticket_status":"1"
}
  checkBookingDetailsindividual(data)
  .then((res) => {        
      setvalidation(false);
      setvalidationText(false);  
      setValidationTextSucess(true);
  })
  .catch((error) => {           
    errorHandle(error);
  });
}else{
  checkBookingDetails({paymentIntentId:QrData.intentId,eventticketdetail_id:eventDetails[0].eventticketdetailid})
  .then((res) => {        
      setvalidation(false);
      setvalidationText(false);  
      setValidationTextSucess(true);
  })
  .catch((error) => {           
    errorHandle(error);
  });
}

}

const notification = (message, type) => {
  setNotify({
    isOpen: true,
    message: message,
    type: type
  })
}
    
    const addUpsell = ()=>{
      const currentUser = cookies.get('currentUser');
  initWidget(currentUser.accountid,eventDetails[0].eventticketdetailid,'',{eventParentId:eventDetails[0].eventid,eventdetailid:eventDetails[0].eventdetailid,eventticketdetailid:eventDetails[0].eventticketdetailid});
  sessionStorage.setItem('qrData',JSON.stringify(QrData))
}
   
    return(   
        <div>

        {validationText?(
           <Alert severity="warning" className="mx-3">{validatedText}</Alert>
        ):(
          <div></div>
        )} 
        {validationTextSucess?(
           <Alert color="success" severity="success" className="mx-3">Ticket scanned Successfully</Alert>
        ):(
          <div></div>
        )} 

        { eventDetails.length>0?(
            <div>
                 <Card className="p-3 m-3">
                    <Grid item spacing={5} className="qr-content">                  
                      <Grid container lg={12} md={12} sm={12} xs={12} className="ticket-details"> 
                                        
                      <Grid item lg={6} md={6} sm={12} xs={12} className="mb-2"> 
                      <Typography variant="h5">
                        <p><a target="_blank" href={`/OrderDetails/${eventDetails[0]?.eventticketdetailid}`}>Order Details</a></p>
                        <p>Payment Id: {eventDetails[0]?.paymentid}</p>
                        <p>Payment Status: {eventDetails[0]?.paymentstatus}</p>
                        Booked by: {eventDetails[0].endusername}
                        </Typography>
                        <p>{eventDetails[0].email}</p>
                        <p className="mb-2">{eventDetails[0].phonenumber}</p>
                        <Typography variant="h5">
                          Venue :
                        </Typography>
                        <p>{eventDetails[0].venue},</p>
                        <p>{eventDetails[0].address}</p>
                        <Typography variant="h5">
                          Date & Time :
                        </Typography>
                        <p>{universaldate(eventDetails[0].eventdatetime)}</p>                        
                      </Grid>
                      
                                           
                      {eventDetails.length>0?((eventDetails).map((event)=>(
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography variant="h5">
                      {event.eventname}
                        </Typography>
                                               
                        <Typography variant="h6">
                          Number of tickets <strong>{event.rownumber?event.rownumber+"/":null}{event.nooftickets}</strong>
                        </Typography>
                        {eventDetails[0]?.tickettypename ?
                        <>
                        <Typography variant="h6">
                          Tickettype Name<strong>{event.tickettypename}</strong>
                        </Typography>
                        {individualUpsellList?.length > 0 ?
                        <Typography variant="h6">
                          UpsellList
                          {individualUpsellList.map(i=>{
                          return(
                            <>
                          <strong>{i.upsellname} - {i.variantname||"No variant"}</strong>
                          <br/>
                          </>
                          )}
                          )}
                        </Typography> : null}
                         </> : null}
                        <Typography variant="h6">
                          {teamname!==''&&teamname!==undefined?<>Ticket Details <strong>{teamname+" - Player "+playerid}</strong></>:null}
                        </Typography>
                        <Typography variant="h6">
                          Cost  <strong>{eventDetails[0].currencysymbol}{event.eventprice}</strong>
                        </Typography>
                        </Grid>
                        ))                 
                        ):(
                           <Grid>No Records Found</Grid>
                       )}
                       <Grid container>                       
                      {/* {QrData.ticketid===undefined && upsellAvailable?  <Grid item lg>   
                              <Button size="small" onClick={addUpsell} className="mr-3 btn-redoutline">Add Upsell</Button>
                            </Grid> :null}  */}
                      
                      {validation ?( <Grid item lg>
                      <Button size="small" onClick={validateTicket} className="float-right link-red">Validate Ticket</Button> 
                      </Grid>
                      ):(<div></div>       
                      )}</Grid>
                       {eventDetails.length>0?((eventDetails).map((event)=>(<Grid item lg={12} md={12} sm={12} xs={12} className="mt-2">
                         {event.upsellflag!==0&&event.upsellflag&&
                          <div>
                          <Typography variant="h5">
                          Upsell 
                          </Typography>                       
                          <Typography variant="h6">
                          <QrUpsell event={event}></QrUpsell>
                          </Typography>
                          </div>}</Grid>
                       ))
                       ):( <Grid>No Records Found</Grid> ) }
                         
                       <Grid container className="border-top mt-2 pt-2">
                       <Grid item  spacing={5} lg={6} md={6} sm={12} xs={12}></Grid>
                       <Grid item spacing={5} lg={6} md={6} sm={12} xs={12} className="total">
                       <Typography variant="h6">                        
                       <p>{eventDetails[0].taxcategory} <strong>{eventDetails[0].currencysymbol}{Number(eventDetails[0].tax).toFixed(2)}</strong></p>
                        <p>Booking Fee<strong>{eventDetails[0].currencysymbol}{eventDetails[0].applicable_fee}</strong></p>
                        <p><b className="cost">Total Cost</b> <strong className="cost">{eventDetails[0].currencysymbol}{Number(eventDetails[0].total).toFixed(2)}</strong></p>
                        </Typography>
                       </Grid>
                       </Grid>
                      </Grid>
                    </Grid> 
                    </Card>
                
        </div>      
        ):(
          <div><Alert severity="warning" className="mx-3">Please show valid QR code</Alert></div>
        )} 
        <Notification notify={notify} setNotify={setNotify} />  
        </div>      
    )
    
  }


  export default QrScannedBookingDetails;