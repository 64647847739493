import React from "react";

const Loading = () => {

    return(
      <div className="loader-container">
      <div className="Loader">
            <svg width="60" height="60" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
    
            <g fill="none" fillRule="evenodd" strokeWidth="2">
              <circle cx="22" cy="22" r="20" id="c1" fill="none"></circle>
              <circle cx="22" cy="22" r="20" id="c2" fill="none"></circle>
            </g>
          </svg>
        </div>
        </div>
        // <div className="divLoader">
        //  <svg className="svgLoader" viewBox="0 0 100 100" width="5em" height="5em">
        //   <path ng-attr-d="{{config.pathCmd}}" ng-attr-fill="{{config.color}}" stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        // </svg></div>        
        
    );
}

export default Loading;