import React, { useEffect,useState} from "react";
import QrReader from 'react-qr-reader';
import QrScannedBookingDetails from "../../components/qr/QrScannedBookingDetails";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import {
    makeStyles,
    Typography,
    Divider,
    Button,
  } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
    },
    pageContent: {
      margin: theme.spacing(3),
      padding: theme.spacing(3),
    },
    
    content: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
  buttonRight:{
    display: 'grid',
    justifyContent: 'flex-end',
    paddingBottom: '50px',
  }
  }));
  const drawerWidth = 250;
const  QrValidation = ()=> {      
    const classes = useStyles();
    const [QrData,setQrData]=useState("");

   const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [state, setState] = useState(false);
     
     useEffect(()=>{
           if(params && params.app && params.payid && sessionStorage.getItem('qrData')){
        const data = sessionStorage.getItem('qrData');
        setQrData(data)
         setState(false);
      }
     },[])


    
   const handleScan = data => {      
     try{
      if(data) {                 
        setState(false);
        setQrData(data);
      }else{
        setState(true);
        setQrData("");
      }
     }             
        catch(err){
          setQrData('{"eventTicketDetailId":0,"intentId":""}');
         }       
    }
 const handleError = err => {
      console.error(err)
    }

  const  handleClick = ()=>{    
    if(state)
      setState(false);
    else 
      setState(true);
    }
    return(      
        <div>
            <Helmet title="Ticket Scanner" />
      <main className={classes.content}> 
      <Typography variant="h5" gutterBottom className="breadcrumbs">
      Ticket Scanner
      </Typography>
      <Divider />

         <div className="qr-detailsbg" align="center">
          <Button onClick={handleClick} size="medium" className="link-red mb-2">{state?'Close Qr':'Scan QR Code'}</Button>          
        {state?<div>                    
          <QrReader
            delay={450}
            onError={handleError}
            onScan={handleScan}
            className="reader"
          />
        </div>:null}
        </div>
            {QrData !== ""?(
                <QrScannedBookingDetails QrData={QrData}></QrScannedBookingDetails>           
            ):(
                <div></div>
            )}
            </main>
            <Footer />
        </div>      
    )
  }


  export default QrValidation;