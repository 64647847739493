import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Link,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { getALLEvent, deleteOcurrence } from "../../services/eventService";
import XLSX from "xlsx";
import {
  TableContainer,
  Table,
  TableHead,
  Grid,
  Box,
  makeStyles,
} from "@material-ui/core";
import Popup from "../../components/Popup";
import EventModal from "./EventModal";
import IconButton from "@material-ui/core/IconButton";
import BulkEditEventModal from "./BulkEditEventModal";
import useTable from "../../components/useTable";

const EXTENSIONS = ["xlsx", "xls", "csv"];
const CURRENT_YEAR = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  responsiveLinkRed: {
    "&.link-red": {
      width: "100%",
      maxWidth: "200px",
      height: "50px",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
  },

  tableRow: {
    height: "35px",
  },

  iconButton: {
    padding: 0,
    paddingRight: 5
  },

  tableContainer: {
    minHeight: "100px",
    maxHeight: "400px",
    overflowY: "auto",
  },
}));

const EventOccurrence = (props) => {
  const history = useHistory();
  const [occurrences, setOccurrences] = useState([]);
  const [importFileValues, setImportFileValues] = useState("");
  const [records, setRecords] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const eventFromReducer = useSelector((state) => state.authReducer.event);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [inputDisable, setInputDisable] = useState(0);
  const cookies = new Cookies();
  const currentUser = cookies.get("currentUser");
  const [cmatch, setCMatch] = useState([]);
  const [matchcount, setMatchCount] = useState([]);
  const {
    setNewEventTicketDetailArray,
    newEventOccurrences,
    venueName,
    reset,
    venueidfrcrt,
    name,
    dbsend,
  } = props;
  const [overWrite, setOverWrite] = useState("");
  const [overWriteData, setOverWriteData] = useState([]);
  const [count, setCount] = useState(0);
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const customDateFormat =
    currentUser.customdateformat !== undefined
      ? currentUser.customdateformat.toUpperCase()
      : "MM-DD-YYYY";
  // existing event (in update scenarios)
  const eventData = props.eventData ? props.eventData : eventFromReducer;
  const classes = useStyles();

  useEffect(() => {
    if ((eventData.length !== 0 && !reset) || overWrite !== "") {
      //if event exists already
      const editArray =
        overWrite !== "Edit"
          ? eventData.eventdetailfile
          : overWriteData.eventdetailfile;
      const record = overWrite !== "Edit" ? eventData : overWriteData;
      setRecords(record);
      const existingOccurrences = editArray.map((e, index) => {
        const eventDateTime = moment(e.eventdatetime, "MM-DD-YYYY,hh:mmA");
        return {
          Id: e._id,
          EventDateTime: eventDateTime.format(`${customDateFormat} hh:mmA`),
          EventDate: eventDateTime.format(customDateFormat),
          EventTime: eventDateTime.format("hh:mmA"),
          TicketCost: e.ticketcost,
          NumberofTickets: e.numberoftickets,
          ActiveStatus: e.activestatus === 1 ? "Active" : "Deactive",
          TicketcountStatus: e.ticketcountstatus,
          Onsaledate: moment(e.onsaledate, "MM-DD-YYYY,hh:mmA").format(
            `${customDateFormat} hh:mmA`
          ),
          Enddate: moment(e.enddate, "MM-DD-YYYY,hh:mmA").format(
            `${customDateFormat} hh:mmA`
          ),
          eventdatetimeISO: e.eventdatetimeISO,
          OnSaleflag: e.onsaleflag,          
        };
      });
      setOccurrences(existingOccurrences);
      setOverWriteData(record);
      setOverWrite("");
    } else if (!reset && overWrite === "" && count === 0) {
      setOverWrite("");
      setCount(0);
    } else {
      setImportFileValues("");
      setOccurrences([]);
      setOverWrite("");
      setOverWriteData([]);
      setInputDisable(0);
      setCount(1);
    }
  }, [reset, overWrite]);
  useEffect(() => {
    let cancel = false;
    getALLEvent()
      .then((res) => {
        if (cancel) return;
        setCMatch(res);
      })
      .catch((error) => {
        const messages = error.response ? error.response.data : error.message;
        if (messages === "Invalid Token") {
          notification("Your Session Expired....Try again...", "error");
          timeOutSet();
        } else {
          notification(JSON.stringify(messages), "error");
        }
      });
    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    checkName();
  }, [venueidfrcrt, name]);

  const showBulkEditModal = () => {
    setBulkEditModal(true);
  };
  const checkName = () => {
    if (venueName.length !== 0 && name && venueidfrcrt) {
      const names = venueName.find((e) => e.venueid === venueidfrcrt);
      if (!names) {
        // still accounts have not loaded, edge case scenario when we are refreshing the page multiple times
        setMatchCount([]);
        return;
      }
      const venuecount = names.venuename;
      const MatchName = cmatch.find(
        (e) =>
          e.eventname.toUpperCase() === name.toUpperCase() &&
          e.venuename.toUpperCase() === venuecount.toUpperCase()
      );
      if (MatchName !== undefined) {
        setMatchCount(MatchName.eventdetailfile);
      } else {
        setMatchCount([]);
      }
    }
  };

  const isvalidateCount = (checkdate) => {
    if (matchcount.length !== 0) {
      let occurencecount = 0;
      matchcount.forEach((e) => {
        if (
          Date.parse(e.eventdatetime.split(",")[0].replace(/-/g, "/")) ===
          Date.parse(checkdate.replace(/-/g, "/"))
        ) {
          occurencecount = occurencecount + 1;
        }
      });
      if (occurencecount > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onDelete = (row) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const ocurrenceId = row.Id;
    const deleted = records.eventdetailfile.filter((e) => e._id !== ocurrenceId);
    let binding = {...records};
    binding.eventdetailfile = deleted;
    const deletes = { eventid: records.id, eventdetailid: ocurrenceId };
    deleteOcurrence(deletes)
      .then((result) => {
        notification("Event Occurrence Deleted Successfully", "success");
        setOverWriteData(binding);
        setOccurrences(occurrences.filter(o => {
          return o.Id !== ocurrenceId
        }));
      })
      .catch((error) => {
     const messages = error.response?.data?.message || (error.response && !error.response.data.message ? error.response.data : error.message);
     notification(messages, "error");
      });
  };

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension); // return boolean
  };

  const convertExcelRowsToJson = (headers, fileData) => {
    let hasEmptyCells = false;
    const newOccurrences = [];
    fileData.forEach((row) => {
      for (let i = 0; i < headers.length; i++) {
        if (row[i] === undefined) {
          hasEmptyCells = true;
        }
      }

      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      newOccurrences.push(rowData);
    });

    const isValidPastDate = (dateString) => {
      let d = new Date();
      let today = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
      let todayDt = moment(today, "MM-DD-YYYY");
      const dt = moment(dateString, customDateFormat.toUpperCase());
      if (dt < todayDt) {
        return false;
      }
    };

    const isValidDate = (dateString) => {
      const d = moment(dateString, customDateFormat.toUpperCase());
      return d !== "Invalid Date" || !d.isValid();
    };

    const isValidTime = (timeString) => {
      if (!/^\d{1,2}:\d{2}([AP]M)?$/.test(timeString)) {
        return false;
      }
      let parts = timeString.split(":");
      let minutes = parseInt(parts[1], 10);
      let hours = parseInt(parts[0], 10);

      if (hours <= 0 || hours > 12 || minutes < 0 || minutes >= 60) {
        return false;
      }

      return true;
    };

    const isValidTicket = (number) => {
      if (/^[0-9]+$/.test(number)) {
        return true;
      } else {
        return false;
      }
    };

    const isValidTicketCost = (number) => {
      if (/^\d{0,9}(\.\d{0,9}){0,1}$/.test(number)) {
        return true;
      } else {
        return false;
      }
    };

    const timeCheck = (startTime, endTime, Dates) => {
      const start = startTime.replace("AM", " AM").replace("PM", " PM");
      const end = endTime.replace("AM", " AM").replace("PM", " PM");
      let dtStart = new Date(`${Dates.replace(/-/g, "/")} ` + start);
      let dtEnd = new Date(`${Dates.replace(/-/g, "/")} ` + end);
      let difference_in_milliseconds = dtEnd - dtStart;
      if (difference_in_milliseconds === 0) {
        notification("Dupliate EventDate And Time exists", "error");
        return true;
      }
    };

    const validateEvent = () => {
      for (let i = 0; i < newOccurrences.length; i++) {
        const date = isValidDate(newOccurrences[i].EventDate);
        if (date === false) {
          notification("EventDate format is wrong", "error");
          return true;
        }
        const time = isValidTime(newOccurrences[i].EventTime);
        if (time === false) {
          notification("EventTime format is wrong", "error");
          return true;
        }
        const past = isValidPastDate(newOccurrences[i].EventDate);
        if (past === false) {
          notification(
            "The Event date cannot be in the past, please enter a valid Event date.",
            "error"
          );
          return true;
        }
        const count = isvalidateCount(newOccurrences[i].EventDate);
        if (count === false) {
          notification("Same event  cannot have same dates", "error");
          return true;
        }
      }
      for (let a = 0; a < newOccurrences.length; a++) {
        for (let b = 1 + a; b < newOccurrences.length; b++) {
          const row = newOccurrences[a];
          const eventDate = [row].find(
            ({ EventDate }) => EventDate === newOccurrences[b].EventDate
          );
          if (eventDate) {
            const Time = timeCheck(
              row.EventTime,
              newOccurrences[b].EventTime,
              eventDate.EventDate
            );
            if (Time) {
              return true;
            }
          }
        }
      }
    };
    const validateSaleDate = () => {
      for (let a = 0; a < newOccurrences.length; a++) {
        const date = isValidDate(newOccurrences[a].Onsaledate);
        if (date === false) {
          notification("Onsaledate format is wrong", "error");
          return true;
        }
        const time = isValidTime(newOccurrences[a].OnsaleTime);
        if (time === false) {
          notification("OnsaleTime format is wrong", "error");
          return true;
        }
        let onsaleDateTime = moment(
          `${newOccurrences[a].Onsaledate} ${newOccurrences[a].OnsaleTime}`,
          customDateFormat.toUpperCase() + " hh:mmA"
        );
        let endsaleDateTime = moment(
          `${newOccurrences[a].Enddate} ${newOccurrences[a].EndsaleTime}`,
          customDateFormat.toUpperCase() + " hh:mmA"
        );
        if (endsaleDateTime.isBefore(onsaleDateTime)) {
          notification(
            `OnSale date is higher than Enddate on row - ${a + 1}`,
            "error"
          );
          return true;
        }
      }
    };
    const validateEndDate = () => {
      for (let a = 0; a < newOccurrences.length; a++) {
        const date = isValidDate(newOccurrences[a].Enddate);
        if (date === false) {
          notification("Enddate format is wrong", "error");
          return true;
        }
        const time = isValidTime(newOccurrences[a].EndsaleTime);
        if (time === false) {
          notification("EndsaleTime format is wrong", "error");
          return true;
        }
        let eventDate = moment(
          `${newOccurrences[a].EventDate} ${newOccurrences[a].EventTime}`,
          customDateFormat.toUpperCase() + " hh:mmA"
        );
        let endsaleDateTime = moment(
          `${newOccurrences[a].Enddate} ${newOccurrences[a].EndsaleTime}`,
          customDateFormat.toUpperCase() + " hh:mmA"
        );
        if (endsaleDateTime.isAfter(eventDate)) {
          console.log("eventDate", eventDate);
          console.log("endsaleDateTime", endsaleDateTime);

          notification(
            `The End Sale date of ${endsaleDateTime} is higher than the Event Date of ${endsaleDateTime} on row - ${
              a + 1
            }`,
            "error"
          );
          return true;
        }
      }
    };

    const validateTicketCost = () => {
      for (let a = 0; a < newOccurrences.length; a++) {
        const Ticket = isValidTicketCost(newOccurrences[a].TicketCost);
        if (Ticket === false) {
          notification(
            "Please give numbers or decimal in Ticket Cost coloum",
            "error"
          );
          return true;
        }
      }
    };

    const ticket = () => {
      for (let a = 0; a < newOccurrences.length; a++) {
        const Ticket = isValidTicket(newOccurrences[a].NumberofTickets);
        if (Ticket === false) {
          notification(
            "Please give numbers in Number Of Ticket coloum",
            "error"
          );
          return true;
        }
      }
    };

    if (hasEmptyCells) {
      notification(
        "There are empty data cells in your excel sheet. Please try again.",
        "error"
      );
      setImportFileValues("");
      return [];
    } else if (
      validateEvent() ||
      validateSaleDate() ||
      validateEndDate() ||
      validateTicketCost() ||
      ticket()
    ) {
      setImportFileValues("");
      return [];
    } else {
      if (newOccurrences.length !== 1 && newOccurrences.length !== 0) {
        newOccurrences.sort(function comparedatetime(a, b) {
          let startTime = a.EventTime;
          let endTime = b.EventTime;
          const startT = startTime.replace("AM", " AM").replace("PM", " PM");
          const endT = endTime.replace("AM", " AM").replace("PM", " PM");
          return (
            new Date(a.EventDate.replace(/-/g, "/") + " " + startT) -
            new Date(b.EventDate.replace(/-/g, "/") + " " + endT)
          );
        });
      }
      return newOccurrences;
    }
  };

  const timeOutSet = () => {
    setTimeout(() => {
      cookies.remove("currentUser");
      history.push("/");
    }, 2000);
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    });
  };

  const getSampleDate = (d) => {
    const dt = moment(d, "MM-DD-YYYY").format(customDateFormat.toUpperCase());
    return dt; // convert to default format that DB understands
  };

  const exportFile = (e) => {
    let array = [];
    array.push({
      EventDate: getSampleDate(`12-25-${CURRENT_YEAR}`),
      EventTime: "6:00AM",
      TicketCost: 1,
      NumberofTickets: 100,
      Onsaledate: getSampleDate(`12-01-${CURRENT_YEAR}`),
      OnsaleTime: "9:00AM",
      Enddate: getSampleDate(`12-24-${CURRENT_YEAR}`),
      EndsaleTime: "10:00AM",
    });
    array.push({
      EventDate: getSampleDate(`12-30-${CURRENT_YEAR}`),
      EventTime: "6:00PM",
      TicketCost: 1.5,
      NumberofTickets: 100,
      Onsaledate: getSampleDate(`12-20-${CURRENT_YEAR}`),
      OnsaleTime: "9:00PM",
      Enddate: getSampleDate(`12-29-${CURRENT_YEAR}`),
      EndsaleTime: "10:00PM",
    });
    const ws = XLSX.utils.json_to_sheet(array);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "sampleoccurrencetemplate.xlsx");
  };

  const importExcel = (e) => {
    try {
      setNewEventTicketDetailArray([]);
      const file = e.target.files[0];
      setImportFileValues(e.target.value);
      const reader = new FileReader();
      reader.onload = (event) => {
        //parse data

        const bstr = event.target.result;
        const workBook = XLSX.read(bstr, {
          type: "binary",
          cellText: false,
          cellDates: true,
        });

        //get first sheet
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        //convert to array
        const fileData = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
          raw: false,
          dateNF: customDateFormat,
          blankrows: false,
        });
        const headers = fileData[0];
        //const heads = headers.map(head => ({ title: head, field: head }))

        let duplicates = [];

        const tempArray = headers;
        const checkHeader = [
          "EventDate",
          "EventTime",
          "TicketCost",
          "NumberofTickets",
          "Onsaledate",
          "OnsaleTime",
          "Enddate",
          "EndsaleTime",
        ];
        if (headers.length === 9) checkHeader.push('Subcategory')
        let count = 0;
        for (let i = 0; i < tempArray.length; i++) {
          if (tempArray[i + 1] === tempArray[i]) {
            duplicates.push(tempArray[i]);
          }
          for (let a = 0; a < tempArray.length; a++) {
            if (checkHeader[i] === tempArray[a]) {
              count = count + 1;
            }
          }
        }

        if (duplicates.length !== 0) {
          notification(
            "Check the Excel sheet Duplicate Name's are there!",
            "error"
          );
          setImportFileValues("");
        } else if (count !== headers.length) {
          notification(
            "Check the Excel sheet Heading name's are different",
            "error"
          );
          setImportFileValues("");
        } else {
          fileData.splice(0, 1);
          const rows = convertExcelRowsToJson(headers, fileData);
          setNewEventTicketDetailArray(rows);
        }
        setImportFileValues("");
      };

      if (file) {
        if (getExention(file)) {
          reader.readAsBinaryString(file);
        } else {
          notification("Invalid file format.", "error");
          setImportFileValues("");
        }
      }
    } catch {
      notification("Something Went Worng", "error");
    }
  };
  // const checkSaleDate = (e) => {
  //   console.log('e', e);
  //   let onsaleDateTime = moment(e, customDateFormat.toUpperCase() + ' hh:mmA');
  //   if (onsaleDateTime.isBefore()) {
  //     setDisabled(true)
  //   } else {
  //     setDisabled(false)
  //   }
  // }

  const checkToggleDate = (eventDateString, saleDateString) => {
    const saleDate = moment(
      saleDateString,
      customDateFormat.toUpperCase() + " hh:mmA"
    );
    if (inputDisable === 0 && saleDate.isBefore()) {
      setInputDisable(inputDisable + 1);
    }

    const eventDate = moment(
      eventDateString,
      customDateFormat.toUpperCase() + " hh:mmA"
    );
    if (eventDate.isSameOrAfter()) {
      return true;
    } else {
      return false;
    }
  };

  const cancelbutton = () => {
    setOpenPopup(false);
  };

  const openInPopup = (e, index) => {
    let datas = {};
    if (records.eventdetailfile !== 0) {
      datas = records.eventdetailfile.find((i) => i._id === e.Id);
    }

    // checkSaleDate(e.Onsaledate);
    setRecordForEdit({ datas, records, index });
    setCount(0);
    setOpenPopup(true);
  };

  const [condition] = useState(false);
  const headCells = [
    { id: "_id", label: "Id" },
    { id: "EventDateTime", label: "EventDateTime" },
    { id: "EventDate", label: "EventDate" },
    { id: "EventTime", label: "EventTime" },
    { id: "TicketCost", label: "TicketCost" },
    { id: "NumberofTickets", label: "NumberofTickets" },
    { id: "ActiveStatus", label: "ActiveStatus" },
    { id: "TicketcountStatus", label: "TicketcountStatus" },
    { id: "Onsaledate", label: "Onsaledate" },
    { id: "Enddate", label: "Enddate" },
    { id: "eventdatetimeISO", label: "eventdatetimeISO" },
  ];
  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblPagination, recordsAfterPagingAndSorting } = useTable(
    occurrences,
    headCells,
    filterFn,
    condition,
    1000
  );

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <Link href="#" className="text-red m-2" onClick={exportFile}>
          Event Details Template - Excel{" "}
        </Link>
      </Box>
      <br />
      <Grid
        container
        item
        spacing={2}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="m-0 p-0"
      >
        <Grid
          item
          lg={occurrences.length > 0 ? 10 : 12}
          md={occurrences.length > 0 ? 10 : 12}
          sm={occurrences.length > 0 ? 10 : 12}
          xs={occurrences.length > 0 ? 10 : 12}
        >
          <Controls.Input
            type="file"
            name="upload"
            inputProps={{ accept: ".xlsx, .xls, .csv" }}
            onChange={importExcel}
            value={importFileValues}
            className="smaller-input choosefile"
          />
          <label className="upload-label">Upload New Event Details</label>
        </Grid>

        {occurrences.length > 0 && (
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Button
              variant="contained"
              onClick={showBulkEditModal}
              className={`link-red ${classes.responsiveLinkRed}`}
            >
              Bulk Edit
            </Button>
          </Grid>
        )}
      </Grid>
      {newEventOccurrences.length > 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow key={"header"}>
                {Object.keys(newEventOccurrences[0]).map(
                  (headersName, index) => (
                    <TableCell align="left" key={index}>
                      {headersName}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {newEventOccurrences.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((val, i) => (
                    <TableCell className="p-1" align="left" key={i}>
                      {val}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {occurrences.length !== 0 && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow key={"header"}>
                <TableCell>{"Action"}</TableCell>
                <TableCell>{"Offsale/Onsale"}</TableCell>
                {Object.keys(occurrences[0])
                  .filter((header) => header !== "Id")
                  .map((headersName, index) => (
                    <TableCell align="left" key={index}>
                      {headersName}
                    </TableCell>
                  ))}
                <TableCell>{"Id"}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {recordsAfterPagingAndSorting().map((row, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell align="left">
                    {
                     checkToggleDate(
                      row.EventDate + "," + row.EventTime,
                      row.Onsaledate
                    ) ? (
                      <>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            openInPopup(row, index);
                          }}
                        >
                          <EditOutlinedIcon
                            fontSize="small"
                            variant="outlined"
                            color="primary"
                          />
                        </IconButton>
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: `Are you sure to want delete the EventSchedule ?`,
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                onDelete(row);
                              },
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            fontSize="small"
                            variant="outlined"
                            color="secondary"
                          />
                        </IconButton>
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {checkToggleDate(
                      row.EventDate + "," + row.EventTime,
                      row.Onsaledate
                    ) &&
                      (row.OnSaleflag === 1 ? (
                        <Chip
                          label="Onsale"
                          variant="outlined"
                          size="small"
                          className="success-outline"
                        ></Chip>
                      ) : (
                        <Chip
                          label="Offsale"
                          variant="outlined"
                          size="small"
                          className="bg-danger"
                        ></Chip>
                      ))}
                  </TableCell>                  
                  {Object.entries(row)
                    .filter(([key]) => key !== "Id")
                    .map(([key, val], i) => (
                      <TableCell align="left" key={i}>
                        {val}
                      </TableCell>
                    ))}
                  <TableCell align="left">{row.Id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {occurrences.length > 0 && (
        <TblPagination rowsPerPageOptions={[5, 10, 25, 100, 500, 1000]} />
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Popup
        title="Edit Event Occurrence "
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EventModal
          dbsend={dbsend}
          recordForEdit={recordForEdit}
          cancelbutton={cancelbutton}
          setOverWrite={setOverWrite}
          overWriteData={overWriteData}
          setOverWriteData={setOverWriteData}
          disabled={true}
        />
      </Popup>
      {bulkEditModal && records && (
        <BulkEditEventModal
          eventId={records?.id}
          accountId={records?.eventdetailfile[0].accountid}
          mapinventory={records?.mapinventory}
          occurrences={occurrences.map((o) => {
            return {
              Id: o.Id,
              EventDateTime: o.EventDateTime,
              EventDate: moment(
                o.EventDateTime,
                `${customDateFormat} hh:mmA`
              ).format("YYYY-MM-DD"),
              EventTime: o.EventTime,
              eventdatetimeISO: o.eventdatetimeISO,
              ticketcountstatus:o.TicketcountStatus,
              numberoftickets: o.NumberofTickets,  
            };
          })}
          openPopup={bulkEditModal}
          setOpenPopup={setBulkEditModal}
        ></BulkEditEventModal>
      )}
    </div>
  );
};
export default EventOccurrence;
