import { Grid, FormControl, Box,Button, Select, MenuItem, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPlayereventDetails } from '../../services/mapInventoryService';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import Notification from '../../components/Notification';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {getAllallotment} from "../../services/allotmentService";
import Cookies from 'universal-cookie';


const EventTicketallocation = (props) => {
 
  const {eventticketDetail, records, values, inputList, 
    setInputList, setTicketerror,removed,setcheck,setRemoved,
    finalvalue,setFinalValue,setRemovedteam,removedteam,setadd,addoredit } = props
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [group, setGroup] = useState([]);
  const cookies = new Cookies();
  const currentUser = cookies.get('eventaddedit');
  const userAccountid = cookies.get('currentUser');

  useEffect(() => {

    if (records !== null && values.id !== undefined) {
      const user = {
        "action_type": "player list",
        "eventid": records.id,
        "eventdetailid": values.id
      }
      const getIds = currentUser?currentUser.eventAccountId:userAccountid.accountid
      getPlayereventDetails(user).then((res) => {
        OccuranceEvent(getIds)

        if (res.data.length > 0) {
        
          setGroup(res.data)
        }
      }).catch((error) => {
        //  const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        notification("Network error please wait for some time ", "error");

      })
    }
  }, [values])
  

 const OccuranceEvent=(e)=>{
  const user={
    "action_type":"list",
    "account_id":e
  }
  getAllallotment(user).then((res)=>{
  
    if(res.data!==undefined){
  
      setFinalValue(res.data)
    }
  }).catch((error) => {
    notification("Network error please wait for some time ", "error");
  });
 }

 
 

  const onKeyPressHandler = e => {
    if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
    }
    else {
      e.preventDefault();
    }
  }

  const handleInput = (e, index) => {

    const { name, value } = e.target;

    const list = [...inputList];
    list[index][name] = value;

    setInputList(list);

  };


  const handleRemoveClick = (index, x) => {

    let datas = []
    if (Number(x.allocationeventoccurrencemapid)!== 0) {
  const deleted=   {
        teamid: Number(x.teamid)?Number(x.teamid):null,
        allocationcount: Number(x.allocationcount),
        allocationid: Number(x.allocationid),
        allocationeventoccurrencemapid: Number(x.allocationeventoccurrencemapid),
        isaction: "delete"
   }

      setRemoved([...removed, deleted])
      
      datas.push(x)
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);


    } else {

      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);


    };
  }


  const handleAddClick = (x) => {
    if (group.length > 0) {
      
      setInputList([...inputList, { teamid: "",allocationid:0, allocationname: "0",allocationeventoccurrencemapid:"0", allocationcount: "", isaction: "0" }]);
      
    }
    else {
      setInputList([...inputList, { allocationname: "0",allocationid:0, allocationcount: "",allocationeventoccurrencemapid:"0", isaction: "0" }])
    }

  };



  const errormethod = (x, y) => {
    const totalTicket = eventticketDetail.ticketcountstatus; //change here
  const count = inputList.reduce((n, {allocationcount}) => n + Number(allocationcount), 0);

	
	if (y === "" && Number(y)===0) {
      setTicketerror(false)
      return "Tickets is required"
    } else if (Number(totalTicket) < count ) {
      setTicketerror(false)
      return `Enter a value between 1 - ${totalTicket}`
    } else {
      setTicketerror(true)
      return ""
    }
  }

  const errormethod1 = (x, y) => {
    const match = (group.find((e) => e.teamid === x));
    const splitTicket = inputList.filter(i=>i.teamid=== x);
  const count = splitTicket.reduce((n, {allocationcount}) => n + Number(allocationcount), 0);
    const split = match.playerid ? match.playerid.split(',').length : 0;
 
    const checked = (Number(match.noofplayers) - split);
    
    if (y === "" || Number(count)===0) {
      setTicketerror(false)
      return "Tickets is required"
    } else if (checked < Number(count)) {
      setTicketerror(false)
      if (checked !== 0) {
        return `Enter a value between 1 - ${checked}`
      }
      else {
        return "This team is already Soldout"
      }

    } else {
      setTicketerror(true)
      return ""
     }



  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  return (
    <Form className="create-user w-100 px-2">
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
     
        <Box fontWeight="bold" className="m-2">Ticket Allocation</Box>

        {group.length === 0 ? <Grid item xs={13} position="relative">

          <Box className="decimal ticket-allotment">
            {inputList.map((x, i) => {
              //  if (x.isaction !== 'delete') {
                return (

                  <Grid container spacing={2} >

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        variant="outlined"
                        size="medium"
                      >
                        <InputLabel>Allocation Type</InputLabel>
                        <Select

                          label="Allocation Type"
                          value={inputList[i].allocationid}
                          name="allocationid"
                          onChange={e => handleInput(e, i)}

                        >{

                            finalvalue.map(
                              (value, index) => (<MenuItem key={index} style={{ display: inputList.find(y => y.allocationid === value.allocationid) ? "none" : "" }} value={value.allocationid}>{value.allocationname}</MenuItem>)
                            )

                          }

                        </Select>
                      </FormControl>

                    </Grid>


                    {(inputList[i].allocationid) ?

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Controls.Input

                          name="allocationcount"
                          label="Tickets"
                          onKeyPress={onKeyPressHandler}
                          value={x.allocationcount}
                          type="number"
                          InputProps={{ inputProps: { min: 1 } }}
                          className="smaller-input"
                          onChange={e => handleInput(e, i, "field")}
                          error={errormethod(x.allocationid, x.allocationcount)} />
                      </Grid>
                      : null}
                    <Box >
                      {inputList.length > 0&& <div
                        className="my-4 ml-1"
                      ><DeleteOutlinedIcon fontSize="medium" variant="outlined" className="text-red pointer" onClick={() => handleRemoveClick(i, x)} /></div>}

                    </Box>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {inputList.length - 1 === i  && <Button size="medium" variant="outlined" className="btn-redoutline" title="Add Another" onClick={() => handleAddClick(x)}>
                        <AddCircleOutlineIcon color="primary" className="text-red" />&nbsp;Add Another</Button>}
                    </Grid>

                  </Grid>


                );
              //  }
            })}
          </Box>
        </Grid> :

          <Grid item xs={12} position="relative">

            <Box className="decimal">
              {inputList.map((x, i) => {
                //  if (x.isaction !== 'delete') {
                  return (

                 <Grid container spacing={2} className="ticket-allotment-threecontrol">
                <Grid item lg={3} md={3} sm={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          size="medium"
                        >
                       <InputLabel>Allocation Type</InputLabel>
                          <Select

                            label="Allocation Type"
                            value={inputList[i].allocationid}
                            name="allocationid"
                            onChange={e => handleInput(e, i)}

                          >{

                              finalvalue.map(
                                (value, index) => (<MenuItem key={index}  value={value.allocationid}>{value.allocationname}</MenuItem>)
                              )

                            }

                          </Select>
                        </FormControl>

                      </Grid>

                      {(inputList[i].allocationid) ? <Grid item lg={3} md={3} sm={3} xs={12}>
                        <FormControl
                          variant="outlined"
                          size="medium"
                        >
                          <InputLabel  >Group</InputLabel>
                          <Select

                            label="Group"
                            value={inputList[i].teamid}
                            name="teamid"
                            onChange={e => handleInput(e, i)}

                          >{

                              group.map(
                                (value, index) => (<MenuItem key={index} style={{ display: inputList.find(y => y.allocationid === x.allocationid&&y.teamid===value.teamid) ? "none" : "" }}  value={value.teamid}>{value.teamname}</MenuItem>)
                              )

                            }

                          </Select>
                        </FormControl>

                      </Grid> : null}
                      {(inputList[i].allocationid && group.length > 0 && inputList[i].teamid) || (inputList[i].allocationname && group.length === 0) ?

                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Controls.Input
                            name="allocationcount"
                            label="Tickets"
                            type="number"
                            InputProps={{ inputProps: { min: 1 } }}
                            onKeyPress={onKeyPressHandler}
                            value={x.allocationcount}
                            className="smaller-input"
                            onChange={e => handleInput(e, i, "field")}
                            error={errormethod1(x.teamid, x.allocationcount)} />
                        </Grid>
                        : null}
                      <Box>
                        {inputList.length > 0 && <div
                          className="my-4 ml-1"
                        ><DeleteOutlinedIcon fontSize="medium" variant="outlined" className="text-red pointer" onClick={() => handleRemoveClick(i, x)} /></div>}

                      </Box>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {inputList.length - 1 === i && <Button size="medium" variant="outlined" className="btn-redoutline" title="Add Another" onClick={() => handleAddClick(x)}>
                          <AddCircleOutlineIcon color="primary" className="text-red" />&nbsp;Add Another</Button>}
                      </Grid>

                    </Grid>


                  );
                //  }
              })}
            </Box>
          </Grid>}
     

    </Form>
  )

}

export default EventTicketallocation;