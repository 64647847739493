import React, {useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Cookies from "universal-cookie";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import TheatersIcon from '@material-ui/icons/Theaters';
import LayersIcon from '@material-ui/icons/Layers';
import { useHistory } from "react-router-dom";
import Header from "./Header"; 
import styled from 'styled-components';

import { 
  Drawer,
  Hidden,
  ListItem,
  ListItemText,
  Divider,
  List,
  Avatar,
  CssBaseline,
  AppBar,
  Toolbar,
  Collapse,

} from "@material-ui/core";
import {
  load as loadIntercom,
  boot as bootIntercom,
  //update as updateIntercom,
  boolMobileAndTabletCheck
} from "../../pages/dashboard/intercom"
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: '#0d0d0d',
  },
  logoAvatar: {
    height: '63px',
    width: '159px',
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar	
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  header: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0px 16px',
      background: '#000000',
    }
  },
  appMenu: {
    width: '100%',
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    width: drawerWidth,
    borderLeft: 'solid 5px #0d0d0d',
    transition: 'all .5s linear',
  },
  menuItemIcon: {
    color: '#97c05c',
  },
  logoHeaderBg: {
    width: '55%',
    height: '30px',
    borderTop: '64px solid #000000',
    borderRight: '40px solid transparent',
    lineHeight: '37px',
    padding: '0 0 0 10px',
    margin: '0',
  }
}));


function SideBar(props) {
  const classes = useStyles();
  const { window } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const handleDrawerToggle = () => {    
    setMobileOpen(!mobileOpen);    
    bootIntercom(mobileOpen); 

  };
  const StyledLogo = styled.img`
  width: 100%;
  height: auto;
`;
  const [dashopen, setDashopen] = React.useState(false)
  const [admintoolsopen, setAdmintoolsopen] = React.useState(false)
  const [eventsopen, setEventsopen] = React.useState(false)

  if (!currentUser) {
    history.push('/Login');
  }

  const handledash = () => {
   
    setDashopen(!dashopen)
  }
  
  const handleevents = () => {
 
    //setEventsopen(!eventsopen);
     history.push('/Events')
 
  }
  
  const handleadmintools = () => {
    setAdmintoolsopen(!admintoolsopen)
  }
  process.env.NODE_ENV === 'production' &&  loadIntercom();
  if(boolMobileAndTabletCheck()){      
    bootIntercom(!mobileOpen);
  }else{
    bootIntercom(false);
  } 

  useEffect(()=>{
    if((history.location.pathname === '/TaxManagement' || history.location.pathname === '/Customers' || history.location.pathname === '/ClientApi')){
      setAdmintoolsopen(true);setDashopen(false);setEventsopen(false);
    }else if((history.location.pathname === '/EventDashboard' || history.location.pathname === '/ReportingDashboard' ||  history.location.pathname === '/EventSummary')){
      setDashopen(true);setAdmintoolsopen(false);setEventsopen(false);
    }else if(history.location.pathname === '/Events' || history.location.pathname === '/EventConfiguration' || history.location.pathname === '/Branding' || history.location.pathname === '/TicketTemplate'|| history.location.pathname === '/EmailTemplate'){
      setEventsopen(true);setDashopen(false);setAdmintoolsopen(false);
    }else{
      setAdmintoolsopen(false)
      setDashopen(false)
      setEventsopen(false)
    }
  },[history.location.pathname])

  const drawer = (
    <div className={classes.toolbar} className="toolbar">
      <div className="logoHeaderBg">
        <StyledLogo alt="OTS Logo" onClick={() => history.push('/')} src={process.env.REACT_APP_DASHBOARD_LOGO || "/static/img/logo.png"} variant="rounded" className="logoHeader" />
      </div>
      <Divider />
      <div className="scrollbar" style={{ minHeight: 'calc(100vh - 66px)', overflow: 'auto' }}>
        <List>    
          <ListItem button onClick={handledash} className={dashopen ? 'active-bdr' : 'inactive'}>
          <HomeOutlinedIcon className="icon-minwidth" />
            <ListItemText primary="Dashboard" className='list-item' />
            {dashopen ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
     
          
          <Collapse in={dashopen} timeout="auto" unmountOnExit className="submenu">
            <Divider />
         
            {<List component="div">
           
            <ListItem button onClick={() => history.push('/EventDashboard')} className={history.location.pathname === '/EventDashboard' ? '' : ''} >
            
            <ListItemText inset primary="Events Dashboard" className={history.location.pathname === '/EventDashboard' ? 'list-item text-red' : 'list-item'} />
          </ListItem>
          <ListItem button onClick={() => history.push('/EventSummary')}>
            
            <ListItemText inset primary="Events Summary" className={history.location.pathname === '/EventSummary' ? 'list-item text-red' : 'list-item'} />
          </ListItem>
          {currentUser.roleid !== 3 && <ListItem button onClick={() => history.push('/ReportingDashboard')} className={history.location.pathname === '/ReportingDashboard' ? '' : ''} >
            <ListItemText inset primary="Reporting Dashboard" className={history.location.pathname === '/ReportingDashboard' ? 'list-item text-red' : 'list-item'} />
            </ListItem>}
            </List>}
          </Collapse>  
          <ListItem button onClick={() => history.push('/Orders')} className={history.location.pathname === '/Orders' || history.location.pathname === '/OrderDetails' || history.location.pathname === '/ExchangeTicket' ? 'active-bdr' : 'inactive'}>
            <ShoppingCartOutlined className="icon-minwidth" />
            <ListItemText inset primary="Orders" className={history.location.pathname === '/Orders' ? 'list-item text-red' : 'list-item'} />
          </ListItem>
        </List>
        
      {currentUser.roleid !== 3 &&
        <List component="nav">                    
          
          <ListItem button onClick={() => history.push('/PromoCodes')} className={history.location.pathname === '/PromoCodes' || history.location.pathname?.includes('/PromocodeConfiguration') ? 'active-bdr' : 'inactive'}>
          <CardGiftcardIcon className="icon-minwidth" />
            <ListItemText inset primary="Promo Codes"  className={history.location.pathname === '/PromoCodes' ? 'list-item text-red' : 'list-item'} />
          </ListItem>
          
          <ListItem button onClick={handleevents} className={eventsopen ? 'active-bdr' : 'inactive'}>
            <EventOutlinedIcon className="icon-minwidth" />
            <ListItemText primary="Events" className='list-item' />
            {eventsopen ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={eventsopen} timeout="auto" unmountOnExit className="submenu">
            <Divider />   
            <List component="div">            
              <ListItem button onClick={() => history.push('/Branding')} className={history.location.pathname === '/Branding' ? '' : ''} >
                  <ListItemText inset primary="Branding" className={history.location.pathname === '/Branding' || history.location.pathname === '/TicketTemplate'|| history.location.pathname === '/EmailTemplate' ? 'list-item text-red' : 'list-item'} />
            </ListItem>

            </List>

          </Collapse>
          

          <ListItem button onClick={() => history.push('/Upsells')} className={history.location.pathname === '/Upsells' || history.location.pathname === '/UpsellConfiguration' ? 'active-bdr' : 'inactive'}>
            <AddCircleOutlineOutlinedIcon className="icon-minwidth" />
            <ListItemText inset primary="Upsells" className={history.location.pathname === '/Upsells' ? 'list-item text-red' : 'list-item'} />
          </ListItem>

          <ListItem button onClick={() => history.push('/Venues')} className={history.location.pathname === '/Venues' ? 'active-bdr' : 'inactive'}>
            <RoomOutlinedIcon className="icon-minwidth" />
            <ListItemText inset primary="Venues" className={history.location.pathname === '/Venues' ? 'list-item text-red' : 'list-item'} />
          </ListItem>
          

          {["Users"].map((text, index) => (
            <ListItem button key={text} onClick={() => history.push('/Users')} className={history.location.pathname === '/Users' ? 'active-bdr' : 'inactive'}>
              <AccountCircleOutlinedIcon className="icon-minwidth" />
              <ListItemText primary={text} className={history.location.pathname === '/Users' ? 'list-item text-red' : 'list-item'} />
            </ListItem>
          ))}
{currentUser.roleid !== 3 &&
          <ListItem button onClick={handleadmintools} className={admintoolsopen ? 'active-bdr' : 'inactive'}>
            <HeadsetMicOutlinedIcon className="icon-minwidth" />
            <ListItemText primary="Admin Tools" className='list-item' />
            {admintoolsopen ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>}
          <Collapse in={admintoolsopen} timeout="auto" unmountOnExit className="submenu">
            <Divider />
            
            <List component="div">
           
            {currentUser.roleid !== 2 &&<ListItem button onClick={() => history.push('/Customers')} className={history.location.pathname === '/Customers' ? '' : ''} >
                <ListItemText inset primary="Customers" className={history.location.pathname === '/Customers' ? 'list-item text-red' : 'list-item'} />
              </ListItem>}
              
              
              {currentUser.roleid !== 2 &&<ListItem button onClick={() => history.push('/TaxManagement')} className={history.location.pathname === '/TaxManagement' ? '' : ''} >
                  <ListItemText inset primary="Tax Management" className={history.location.pathname === '/TaxManagement' ? 'list-item text-red' : 'list-item'} />
            </ListItem>} 

            <ListItem button onClick={() => history.push('/ClientApi')} className={history.location.pathname === '/ClientApi' ? '' : ''}>            
              <ListItemText inset primary="Client API Management" className={history.location.pathname === '/ClientApi' ? 'list-item text-red' : 'list-item'} />
            </ListItem>
            </List>

          </Collapse>
          
        </List>}
        <List>
<ListItem button onClick={() => history.push('/QrValidation')} className={history.location.pathname === '/QrValidation' ? 'active-bdr' : 'inactive'} >
              <CropFreeOutlinedIcon className="icon-minwidth" />
              <ListItemText inset primary="Ticket Scanner" className={history.location.pathname === '/QrValidation' ? 'list-item text-red' : 'list-item'} />
            </ListItem>
</List>

{currentUser.roleid !== 3&&<ListItem button onClick={() => history.push('/Allocations')} className={history.location.pathname === '/Allocations' || history.location.pathname === '/Allocations' ? 'active-bdr' : 'inactive'}>
            <TheatersIcon className="icon-minwidth" />
            <ListItemText inset primary="Allocations" className={history.location.pathname === '/Allocations' ? 'list-item text-red' : 'list-item'} />
          </ListItem>}
          {currentUser.roleid !== 3&&<ListItem button onClick={() => history.push('/TicketTypes')} className={history.location.pathname === '/TicketTypes' || history.location.pathname.includes('/TicketTypeConfiguration') ? 'active-bdr' : 'inactive'}>
            <LayersIcon className="icon-minwidth" />
            <ListItemText inset primary="TicketTypes" className={history.location.pathname === '/TicketTypes' ? 'list-item text-red' : 'list-item'} />
          </ListItem>}
          {currentUser.roleid !== 3 && 
          <ListItem button onClick={() => history.push('/Reports')} className={history.location.pathname === '/Reports' ? 'active-bdr' : 'inactive'} >
            <CropFreeOutlinedIcon className="icon-minwidth" />
            <ListItemText inset primary="Reports" className={history.location.pathname === '/Reports' ? 'list-item text-red' : 'list-item'} />
          </ListItem> }
</div>
      <Divider />
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.header}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Header display="flex" justifyContent="flex-end" />

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.	
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
export default SideBar;	