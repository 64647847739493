import React, { useEffect, useState, useRef } from 'react';
import { Grid, Checkbox, FormControlLabel, FormControl, FormGroup, Box, Divider } from '@material-ui/core';
import Controls from '../../components/controls/Controls';

const initialFValues = {
    numberoftickets: 1,
    teamDetails: [],
    paymentteammapping: [],
    upsellInfo: []
};

const PartialRefundForm = ({withTeam,teamData,teamMapping, exchangeMode=false,
  ticketdetails,eventDetails,nooftickets,handleClose,handleContinue, paymentcount, individualupselldetail, sameTimeExchange}) => {

    const [data,setData] = useState(initialFValues);
    const [upsells,setUpsells] = useState({});
    const [tickettypeUpsell,setTickettypeUpsell] = useState([]);
    const [upsellDetails,setUpsellDetails] = useState([]);
    const [errors, setErrors] = useState({});
    const requestAbortController = useRef(true);
    const {numberoftickets, teamDetails, upsellInfo, paymentteammapping} = data;

    useEffect(()=>{
      if(requestAbortController.current){
        if (teamDetails.length === 0) {
          if (withTeam) {
            let count = 0;
            let condition = true;
            const ticketDetails = ticketdetails.filter(x=>x.ticketstatus !== 2 );
            teamMapping.sort((a, b) => a.teamid - b.teamid);
            for (const x of teamMapping) {
              const playerid = (x.playerid != null && x.playerid != '') ? x.playerid.toString().split(',') : [];
              for (const y of playerid) {
                condition = true;
                for (let i = 0; i < teamData.length && condition; i++) {
                  let z = teamData[i];
                  if (Number(y) === Number(z.playerid) && x.teamid === z.teamid && x.paymentteammapid === z.paymentteammapid) {
                    teamData[i].ticketid = ticketDetails[count].ticketid
                    teamData[i].variantname = ticketDetails[count].variantname
                    teamData[i].tickettypeid = ticketDetails[count].tickettypeid
                    count++;
                    condition = false;
                  }
                }
              }
            }
            
          
            setData({...data,teamDetails:teamData,numberoftickets:0,paymentteammapping:[]}); 
          } else {
            setData({ ...data, numberoftickets: 0, paymentteammapping: [] });
          }
        }
      }
            validate();
          
            return () => {
              // abort request on unmount
              requestAbortController.current = false;   
            }
       
    },[numberoftickets])

  useEffect(() => {
    const withoutTicketTypeUpsells = [];
    individualupselldetail.forEach(element => {
      if (element.ticketid && element.tickettypeid && (paymentcount > 1 || nooftickets > 1)) {
        const id = element.ticketid;
        const value = element.upsell.upsellname;
        setUpsells((prevState) => ({ ...prevState, [id]: { name: value, individualupsellid: element.individualupsellid } }))
      } else {
        withoutTicketTypeUpsells.push(element);
      }
    });
    setUpsellDetails(withoutTicketTypeUpsells);
  }, [])

    const validate = (fieldValues = data) => {
      const temp = { ...errors };
  
      if ("numberoftickets" in fieldValues) {
        console.log('numberoftickets', nooftickets, 'fieldValues.numberoftickets', fieldValues.numberoftickets);
        if (exchangeMode && sameTimeExchange && nooftickets !== fieldValues.numberoftickets) {
          temp.numberoftickets = "Since you are exchanging the same performance, please choose all tickets and reselect your seats";
        } else if(exchangeMode && !fieldValues.numberoftickets){
          temp.numberoftickets = "Enter / Select tickets to be exchanged";
        }else if(fieldValues.numberoftickets === nooftickets && paymentcount === 1){
          temp.numberoftickets = "Cannot select all tickets for Partial Refund";
        }else if(fieldValues.numberoftickets > nooftickets ){
          temp.numberoftickets = `Please enter a value less than ${nooftickets}`;
        }else{
          temp.numberoftickets = "";  
      }
        if (fieldValues.numberoftickets === nooftickets && paymentcount > 1 && Number(individualupselldetail.length) > 0 && Number(upsellInfo.length) < Number(individualupselldetail.length)) {
          temp.upsellInfo = "Please choose all upsells and continue";
        } else {
          temp.upsellInfo = "";
        }
    }  
      setErrors(temp);
      return Object.values(temp).every((x) => x === "");
    };

    const handleChanged = (event,item) => {
       const {checked, value} = event.target;
       let teams = paymentteammapping;
       if(checked){
       const {eventid, occurrenceid, eventticketdetailid} = eventDetails;
       delete item.paymentteammap;
       teams.push({...item,...{eventid: eventid, eventdetailid: occurrenceid, eventticketdetailid: eventticketdetailid}});
       setData({...data,paymentteammapping: teams, numberoftickets: Number(teams.length),
        upsellInfo: (upsells[item.ticketid]?.individualupsellid ? [...upsellInfo, upsells[item.ticketid].individualupsellid] : upsellInfo)});
       setTickettypeUpsell([...tickettypeUpsell, item.ticketid]);
       }else{
         teams = teams.filter(x=>Number(x.playerteamid) !== Number(value));
         setData({...data,paymentteammapping: teams, numberoftickets: Number(teams.length),
          upsellInfo: upsellInfo.filter((x)=>x!== upsells[item.ticketid]?.individualupsellid)});
         setTickettypeUpsell(tickettypeUpsell.filter((x) => x !== item.ticketid));
       }
      };

      const handleWithOutTeam=(event,item)=>{
        const {checked, value} = event.target;
        let ticketTypeDetails = paymentteammapping;
        if(checked){
          const { eventticketdetailid } = eventDetails;
          ticketTypeDetails.push({ticketid:item.ticketid,tickettypeid:item.tickettypeid,eventticketdetailid: eventticketdetailid})
           setData({...data,paymentteammapping: ticketTypeDetails, numberoftickets: Number(ticketTypeDetails.length),
           upsellInfo: (upsells[item.ticketid]?.individualupsellid ? [...upsellInfo, upsells[item.ticketid].individualupsellid] : upsellInfo)});
           setTickettypeUpsell([...tickettypeUpsell, item.ticketid])
        }else{
          ticketTypeDetails = ticketTypeDetails.filter(x=>Number(x.ticketid) !== Number(value));
          setData({...data,paymentteammapping: ticketTypeDetails, numberoftickets: Number(ticketTypeDetails.length),
            upsellInfo: upsellInfo.filter((x)=>x!== upsells[item.ticketid]?.individualupsellid)});
            setTickettypeUpsell(tickettypeUpsell.filter((x) => x !== item.ticketid));
        }
      }
      
  const handleUpsellChanged = (event, item, type) => {
    const { checked, value } = event.target;
    if (checked) {
      setData({ ...data, upsellInfo: [...upsellInfo, Number(value)] });
      if (type === 'tickettype') setTickettypeUpsell([...tickettypeUpsell, item.ticketid]);
    } else {
      if (type !== 'tickettype' || !paymentteammapping.find(x => x.ticketid === item.ticketid)) {
        setData({ ...data, upsellInfo: upsellInfo.filter((id) => id !== Number(value)) });
        if (type === 'tickettype') setTickettypeUpsell(tickettypeUpsell.filter((x) => x !== item.ticketid));
      }
      else alert("You have selected ticket type that includes upsell, so it cannot be unchecked.");
    }
  };
    
  const handleNext = () => {
    if(validate()){
      handleContinue(data);
     }
   };
return(
    <div style={{minHeight:'auto', maxHeight:'400px', minWidth: '290px', maxWidth: 'auto'}}>
    {(paymentcount > 1 || nooftickets > 1) ? <> {
        withTeam ? <>
        <strong>{`Which tickets would you like to ${exchangeMode ? "exchange" : "refund"} ?`}</strong>
        <br/>
        <br/>
         <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" className="m-0 w-100">
      <Grid >
      <FormGroup>
        {teamDetails.map((item,index) => (
          <>
          <FormControlLabel key = {index}
            control={
              <Checkbox value={item.playerteamid} checked={item.checked} onChange={(e)=>handleChanged(e,item)} name={`${item?.team?.teamname}: Player ${item.playerid}`} />
            }
            label={`${item.team?.teamname}: Player ${item.playerid} - ${item.variantname || "No Variant"}`}
          />
          {upsells[item.ticketid] ? <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
           <FormControlLabel 
          control={
            <Checkbox value={upsells[item.ticketid].individualupsellid} checked={tickettypeUpsell.includes(item.ticketid)} onChange={(e)=>handleUpsellChanged(e, item, 'tickettype')}  />
          }
          label={`${upsells[item.ticketid].name}`}
        /> </Box> : null}
          </>
          ))}  
          
        </FormGroup>
        </Grid>
        </FormControl>
          <p style={{color:'red'}}>{errors.numberoftickets ? `* ${errors.numberoftickets}` : null}</p>
        </> : <>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" className="m-0 w-100">
            <Grid >
              <FormGroup>
                {ticketdetails.map((item, index) => (
                  <>
                    <FormControlLabel key={index}
                      control={
                        <Checkbox value={item.ticketid} checked={item.checked} onChange={(e) => handleWithOutTeam(e, item)} name={item?.ticket_type?.name ? item.ticket_type.name : "Normal Ticket"} />
                      }
                      label={`${item?.ticketid} - ${item?.ticket_type?.name ? item.ticket_type.name : "Normal Ticket"} - ${item.variantname|| "No Variant"}`}
                    />
                    {upsells[item.ticketid] ? <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    <FormControlLabel 
                      control={
                        <Checkbox value={upsells[item.ticketid].individualupsellid} checked={tickettypeUpsell.includes(item.ticketid)} onChange={(e) => handleUpsellChanged(e, item, 'tickettype')} />
                      }
                      label={`${upsells[item.ticketid].name}`}
                    /> </Box> : null}
                  </>
                ))}

              </FormGroup>
            </Grid>
          </FormControl>
          <p style={{ color: 'red' }}>{errors.numberoftickets ? `* ${errors.numberoftickets}` : null}</p>
        </>
    }</>:null}
    <Divider />
    { upsellDetails?.length > 0 &&
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" className="m-0 w-100">
      <Grid >
        <br/>
      <strong>{`Which upsells would you like to ${exchangeMode ? "exchange" : "refund"} ?`}</strong>
      <br/>
        <FormGroup>
          {upsellDetails.map((item, index) => (
              <FormControlLabel key={index}
                control={
                  <Checkbox value={item.individualupsellid} checked={upsellInfo.includes(item.individualupsellid)}
                   onChange={(e) => handleUpsellChanged(e, item, 'nonTicketType')}/>
                }
                label={`${item?.upsell?.upsellname}`}
              />
          ))}

        </FormGroup>
        <p style={{ color: 'red' }}>{errors.upsellInfo ? `* ${errors.upsellInfo}` : null}</p> 
      </Grid>
    </FormControl>
    }
    <br/>
    <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Controls.Button onClick={()=>{handleClose()}} size="small" text="Cancel" className="link-gray"/>
            
    <Controls.Button onClick={handleNext} color="primary" size="small" text="Continue" autoFocus className="link-red"/>
    </Grid>
    <br/>
    </div>
)
}
export default PartialRefundForm;