import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const http = axios.create({
  baseURL :process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


 async function getAllUsers(userid){
  const currentUser = cookies.get('currentUser');
  return await 
    http
      .post(`/users/getAllUsers`,userid, {
        headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}})
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}



const editUsersput = async(user) =>{
  const currentUser = cookies.get('currentUser');
    try{
      return await http.post(`/users/updateUser`,user,{
        headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}});
      
    } catch ( err ) {
  throw err;

  }    
  }
  const manageUsersput = async(user) =>{
    const currentUser = cookies.get('currentUser');
    try{
      return await http.post(`/users/manageUsers`,user,{
        headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}});
    } catch ( err ) {

  throw err;
  }    
  }
  const deleteUser = async(userid) =>{
    const currentUser = cookies.get('currentUser');
    try{
      return await http.get(`/users/deleteUser?userId=${userid}`,{
        headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}});
    } catch ( err ) {
      throw err;
  }    
  }
 

    const getUserRoles = async() => {
      const currentUser = cookies.get('currentUser');
      return await
        http
          .get("/users/getUserRoles",{
            headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}})
            .then((response) => {
              return response.data;
          })
          .catch((error) => {
            throw error;
          });
    }
    
      const getUserAccounts = async() => {
        const currentUser = cookies.get('currentUser');
        return await
          http
            .get("/users/getUserAccounts",{
              headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}})
              .then((response) => {
                return response.data;
            })
            .catch((error) => {
              throw error;
            });
      }
      
      const getUserById= async(userid)=>{

        const currentUser = cookies.get('currentUser');
        return await
          http
          .get(`/users/getUserById?userId=${userid}`,{
            headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}})
            .then((response) => {
              return response.data;
          })
          .catch((error) => {
            throw error;
          });
      }
      const Changepassword = async(user) =>{
        const currentUser = cookies.get('currentUser');
        try{
          return await http.post(`/users/changePassword`,user,{
            headers: {'Authorization':currentUser?`Bearer ${currentUser.token}`:``}});
        } catch ( err ) {
          throw err;
      }    
      }
  
  const getUserAccount = async () => {
    const currentUser = cookies.get('currentUser');
    return await
      http
        .get("/users/getUserAccount",{
          headers: {'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }})
          .then((response) => {
            return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
      

export default {
  getAllUsers,
  manageUsersput,
  editUsersput,
  deleteUser,
  Changepassword,
  getUserRoles,
  getUserAccounts,
  getUserById,
  getUserAccount,
};