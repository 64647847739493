import React from 'react';
import {Paper, Box, IconButton, DialogActions, styled, Grid } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import "./mapping.scss";


const Item =styled (Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const MappingPreview =({inputList,setOpenPopup,setOpenPopupMapping,eventid,addorEdit,remove,finalvalue,setTeamDetail,setMapinventoryCheck}) => {
   
   
 
 const handleContinueAdd=()=>{
  
    if(addorEdit === 'Edit'){

      let count = 0;
     const teamdetail= finalvalue.map((e,i)=>{
      let isdel = e.isdelete===undefined ? "0":e.isdelete
      for(let a=0;a<remove.length;a++){
        if(e.teamid===remove[a]){
          isdel="1"
       }
      }
      if(isdel==="1"){
        count++;
      }        
        return{
          "teamid":e.teamid,
          "teamname":e.teamname,
          "noofplayers":e.noofplayers,
           "isdelete":isdel
        }
       
      }) 
      setTeamDetail(teamdetail);
      setMapinventoryCheck(true);
      if(teamdetail.length===count){
        setMapinventoryCheck(false); 
      }
  setOpenPopup(false)
  setOpenPopupMapping(false)

    }
   else{
  setTeamDetail(inputList);
  setOpenPopup(false)
  setOpenPopupMapping(false)
  }
}
 
      
    return(
        <div>
            {inputList.map((team,key )=>{
          
             
              const Values = [...Array(Number(team.noofplayers))].map((_,i)=>i+1)
if(team.isdelete!=="1"){
         return (
    
        <tr key={key}>
        <Grid container
            
            >
           <td>  <Item className="playerbox teamname">               
                    <Box className="bottom">                    
                     <Box>{team.teamname}</Box>
                    </Box>    
            </Item></td>
           { Values.map(e=>(
            <td> <Item className="playerbox">
                <Box className="top">PLAYER {e}</Box>
                <Box className="bottom">
                    <Box><IconButton className="addplayer"><AddIcon/></IconButton></Box>
                    <Box className="pointer">Select</Box>
                </Box>    
            </Item>
            </td>
          ))   
                  } 
            </Grid>
            </tr>
         )
            } })
      }
        
            
            <DialogActions dividers>          
         <Controls.Button text="Edit"  onClick={(e)=>setOpenPopup(false)} className="link-gray" />
        
         <Controls.Button type="submit" onClick={handleContinueAdd} text="Continue" className="link-red" />
            </DialogActions>  
      </div>
    ); 
}

export default MappingPreview;