import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Validatereset } from '../services/authService';
import queryString from 'query-string';
import {resetPassword} from '../redux/actions/authActions'
import { useDispatch } from 'react-redux';
import { Snackbar, makeStyles, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  root: {
      top: theme.spacing(9)
  }
}));

const AuthRouter = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const classes = useStyles();


  const [loading, setLoading] = useState(true)
  const [error,setError]=useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const Uritoken = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  useEffect(() => {

    Validatereset(Uritoken)
      .then((resp) => {
        setLoading(false)
        dispatch(resetPassword({userid:resp.userid}));
        setError(false);
      }).catch((error) => {
        setLoading(false);
        setError(true);
        setNotify({
          isOpen: true,
          message: 'Password reset link is invalid or has expired',
          type: 'error'
        })
      });

  }, [])

  if (loading && !error) {
    return (
      <Route
        {...rest}
        render={() => {
          return <div>Loading...</div>;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => {
        return !loading && !error ? (
          <Component {...props} />
        ) : (
          <Snackbar
          className={classes.root}
          open={notify.isOpen}   
            
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} //autoHideDuration={3000} onClose={handleClose}
          >
          <Alert
              severity={notify.type}>
              {notify.message}
              <div className="pt-3">
                <Button variant="outlined" size="small" color="primary" className="link-red btn-sm" onClick={() => {
              history.push('/ForgotPassword')
              }} >
                Redirect to Forgot Password
            </Button>
            </div>
          </Alert>
          
      </Snackbar>
      
          );
      }}
    />
  );
};

export default AuthRouter;