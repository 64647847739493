import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function CheckoutClickService(event) {
  
  return await
    http
      .post(`/payment/createStripePaymentIntent`, event, {
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function checkoutPaymentStatusIntentid(data) {

  return await
    http
      .post(`/payment/paymentStatusintentid`, data, {
        headers: {}
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function checkoutPaymentStatusSessionId(data) {

  return await
    http
      .post(`/payment/paymentStatussessionid`, data, {
        headers: {}
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updatePaymentStatus(data) {
  return await
    http
      .post(`/payment/updateStatus`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function eventExchangeDetails(exchnageData) {

  const currentUser = cookies.get('currentUser');

  return await
      http
          .post(`/payment/eventExchangeDetails`, exchnageData, {
              headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
          })
          .then((response) => {
              return response.data;
          })
          .catch((error) => {
              throw error;
          });
}



export default {
  CheckoutClickService,
  checkoutPaymentStatusIntentid,
  checkoutPaymentStatusSessionId,
  updatePaymentStatus,
  eventExchangeDetails,
}