import React from "react";

import {
  Typography,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
 

const UpsellTable = ({ upsellDetails,currencytype,eventimage, status, revenue, unitSold, inPopup }) => { 

  return (
  <div className="upsell" style={{ minWidth: inPopup ? '350px' : null }}>
        { !inPopup && (
          <Typography fontSize="small" component="div" align="right" fontWeight="fontWeightRegular" my={2} color="textSecondary">
            <strong>Upsell Details</strong>
          </Typography>
        )}
        
        <List className="upsell-list scrollbar" style={{ maxHeight: 170, height:'auto',overflowX: 'hidden', }}>
        {upsellDetails?.length>0?(upsellDetails.map((upsell,index)=>(
             <div key={index}>
                     <ListItem alignItems="flex-start" className="p-0">
      <ListItemAvatar>
          <Avatar alt="Upsell Name" src={upsell.image} />
        </ListItemAvatar>
        <ListItemText
          primary={ 
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                className="text-mute"
              >
                {upsell.upsellname}
              </Typography>
              <small className="float-right mr-3">{upsell.activestatus === 1 ? (<span className="text-success">Active</span>):(<span style={{color:'#c4201b'}}>InActive</span>)}</small>
            </>
          } 
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                className="text-mute"
              >
                {currencytype==='USD'?'$':'£'}{upsell.totalUpsellCost}
              </Typography>
              <span className="float-right mr-3 text-mute">{upsell.soldcount+'/'+upsell.totalcount} sold</span>
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
             </div>
              
          ))) : (
            <div className="p-1 text-warning">No Upsells found</div>
          )
          }
       </List>
        </div>
  );
};

export default UpsellTable;
