import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography, } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(0),
        /*position: 'absolute',
        top: theme.spacing(5),   */
         maxHeight: 'calc(100vh - 100px)',
    },
    dialogTitle: {
        background: '#be1915',
        color: '#ffffff',
        padding: '2px 10px 2px 16px',
    },
    
}));

const Popup = (props) => {

    const { title, children, openPopup, setOpenPopup } = props;
    const classes = useStyles();
    

    return (
        <Dialog open={openPopup} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }} className="dialogHead">
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, padding: '8px 0px', fontSize: '18px' }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color="inherit"
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon className="closeIcon" />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>   
            <DialogContent dividers className="p-3">
                {children}
            </DialogContent>
        </Dialog>
    )
};

export default Popup;