import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import BackToTop from "../../components/controls/BackToTop";
import "./style.scss";
import { useHistory } from "react-router-dom";
import userService from '../../services/userService';
import TicketBuyers from "./TicketBuyers";
import ReportBuilder from "./ReportBuilder";
import Map from "./Map";
import {
  makeStyles,
  Grid,
  Divider,
  Typography,
  Box,
  Tabs,
  Tab,
  Toolbar,
} from "@material-ui/core";
import Notification from "../../components/Notification";
import MetabaseDashboard from "./MetabaseDashboard";
import Loading from "../../components/controls/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
    }  
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

}));
const drawerWidth = 250;
const DefaultDashboard = () => {
  const cookies = new Cookies();
  const classes = useStyles();
  const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
      <div hidden={value !== index}
        id={`map-tabpanel-${index}`}
      >
        <Box>{children}</Box>
      </div>
    );
  }
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [allUserAccounts, setAllUserAccounts] = useState([]);
  const currentUser = cookies.get('currentUser');
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const firstName = currentUser.firstName? currentUser.firstName:''
  const lastName = currentUser.lastName? currentUser.lastName:''
  const [value, setValue] = useState(0);
  const [currentUserAccount, setCurrentUserAccount] = useState(null);
  const [metabaseDashboardEmbedUrl, setMetabaseDashboardEmbedUrl] = useState(null);
  const history = useHistory();
  const [isDashboardReady, setDashboardReady] = useState(null);
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };
  
  const errorHandle=(error)=>{
    if(!error && !checkParam(Object.keys(error))){
      notification(error, 'error');
     } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data : error.message;
     messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
     }
  }

  useEffect(() => {
    if (currentUser.roleid === 1) { // roleid 1 = OTS Admin
      let cancel = false;
      userService.getUserAccounts()
        .then(userAccounts => {
          if (cancel) return;
          setAllUserAccounts([...userAccounts]);
          const currentUserAcc = userAccounts.filter(account => account.accountid === currentUser.accountid)[0]
          setCurrentUserAccount(currentUserAcc);
          setDashboardReady(true);
        })
        .catch((error) => {
          errorHandle(error)
         });
         return () => {
          cancel = true;
        }
    }
    else {
      userService.getUserAccount()
        .then(userAccount => {
          setCurrentUserAccount({
            accountid: userAccount.accountid,
            metabase_dashboard_number: userAccount?.account?.metabase_dashboard_number,
            metabase_jwt_token: userAccount?.account?.metabase_jwt_token
          });
          setDashboardReady(true);
      }).catch(error => {
        errorHandle(error)
      })
    }
  }, []);

  const getAccountidDetails = (e) => {
    const currentUserAcc = allUserAccounts.filter(userAccount => userAccount.accountid === parseInt(e.target.value))[0]
    setCurrentUserAccount(currentUserAcc);
    setSelectedAccountId(e.target.value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
       cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  return (
    <div>
      <Helmet title="Dashboard" />
      <main className={classes.content}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>                      
            <Grid item lg={6} md={7} sm={7} xs={12}>
              <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                Reporting Dashboard
              </Typography>
              <Box variant="subtitle1" className="welcome-msg">
                <span style={{ flexGrow: '1', paddingBottom: '12px' }}>Welcome, <strong>{firstName} {lastName}</strong></span>
              </Box>
            </Grid>
            <Grid item lg={6} md={5} sm={5} xs={12}>
              <Toolbar className="selectbar">                     
                <Box>
                  {allUserAccounts.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={getAccountidDetails}
                      className="select-box"
                    >
                      <option value="0">Select Customer</option>
                      {(allUserAccounts).map((v, k) => {
                        if (v.roleid !== 1)
                          return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                        else
                          return null
                      })}
                    </select>
                  ) : (<div></div>)}
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>   

        <Divider />
        
        <div className={classes.pageContent}>
          {
            isDashboardReady ? 
              currentUserAccount?.metabase_dashboard_number ? <MetabaseDashboard 
              currentUserAccount={currentUserAccount} 
              errorHandle={errorHandle} 
              metabaseDashboardEmbedUrl={metabaseDashboardEmbedUrl}
              setMetabaseDashboardEmbedUrl={setMetabaseDashboardEmbedUrl}
            /> : 
            (<Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl className="pt-0">
                <Box sx={{ width: "100%", }} component="div">
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} className="global-tab" onChange={handleChange}>
                      <Tab label="Sales by Location" className="btn" />
                      <Tab label="Ticket Buyers" className="btn" />
                      <Tab label="Report Builder" className="btn" />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                  { ![1, 2].includes(value) ?
                    <Map AccountId={selectedAccountId} AccountName={currentUser.firstName} />
                    : <></>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {value === 1 ?
                    <TicketBuyers AccountId={selectedAccountId} />
                    : <></>}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {value === 2 ?
                    <ReportBuilder AccountId={selectedAccountId} />
                    : <></>}
                </TabPanel> 

                </Box>
              </Grid>
            </Grid>
          )
            : <Loading />
          }
      
        </div>
        <BackToTop />
      </main>
      <Footer />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default DefaultDashboard;
