import React, { useState, useEffect } from 'react'
import useTable from '../../components/useTable'
import {
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { Alert } from '@material-ui/lab'
import BackToTop from '../../components/controls/BackToTop'
import Loading from '../../components/controls/Loading'
import { getAllotEventManagement } from '../../services/allotmentService'

const drawerWidth = 250
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  pageContent: {
    padding: '0px 15px',
    margin: '0px auto',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  addBar: {
    float: 'right',
    padding: '0px',
  },
}))

const headCells = [
  { id: 'allocationName', label: 'Name of Holds' },
  { id: 'allocationsSold', label: 'Sold' },
  { id: 'available', label: 'Available' },
]

const Holds = ({ isLoading, eventOccurrenceId }) => {
  const classes = useStyles()
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })
  const [condition, setCondition] = useState(false)
  const [eventOccurrenceHolds, setEventOccurrenceHolds] = useState([])
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(eventOccurrenceHolds, headCells, filterFn, condition)

  useEffect(() => {
    async function fetchData() {
      const apiConfig = {
        "action_type": "list",
        "eventdetail_id": eventOccurrenceId,
        "eventid": '',
        "allocations": []
      }
      const eventOccurrenceHoldsList = await getAllotEventManagement(apiConfig);
      setEventOccurrenceHolds(eventOccurrenceHoldsList.data);
    }
    eventOccurrenceId && fetchData();
  }, [eventOccurrenceId]);

  return (
    <div>
      <Helmet title="Holds" />
        <Typography variant="h5" gutterBottom className="breadcrumbs">
          Holds
        </Typography>
        <div className={classes.pageContent}>
          <TblContainer className={classes.container}>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((hold, index) => (
                <TableRow key={index}>
                  <TableCell>{hold.allocationname}</TableCell>
                  <TableCell>{hold.allocationcount - hold.availablecount}</TableCell>
                  <TableCell>{hold.availablecount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length && (
                <TableRow style={{ textAlign: 'center' }} key={0}>
                  {isLoading ? (
                    <TableCell className="p-1 text-left w-100" colSpan="7">
                      <Loading />
                    </TableCell>
                  ) : (
                    <TableCell className="p-1 text-left" colSpan="7">
                      {<Alert severity="warning">No holds found</Alert>}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </TblContainer>
          <TblPagination gutterLeft />
        </div>
        <BackToTop />
    </div>
  )
}

export default Holds;