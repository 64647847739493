import React,{useState,useEffect} from 'react';
import {Typography, Grid, Box, makeStyles, Toolbar, Divider , Breadcrumbs, Link } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { useSelector} from 'react-redux';
import { NavLink,useHistory } from "react-router-dom";
import Footer from "../../components/layouts/Footer";
import Controls from '../../components/controls/Controls';
import {  useDispatch } from 'react-redux';
import "./template.scss"; 
import Cookies from 'universal-cookie';
import AddLogo from "./AddLogo";
import TempFooter from "./TempFooter";
import { Template } from "../../redux/actions/authActions";
import {getTemplateDetails,getemailTemplateManagement} from '../../services/brandingServices';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,ContentState,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Notification from '../../components/Notification';


import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { BottomNavigation, BottomNavigationAction, TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  }, 
  pageContent:{
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar, 
  
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
    }
  },
  titleIcon: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.secondary.main,
    '&:hover': {
        backgroundColor: theme.palette.error.dark,
        cursor: 'default'
    },
    '& .MuiSvgIcon-root': {
        fontSize: '4rem',
    }
}

}));

const drawerWidth = 250;

const EmailTemplate = ({ input, meta }) => {
  const history = useHistory();
  const brandEventId = useSelector(state => state.authReducer.Template);
  const [records,setRecords]=useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [addLogo,setAddLogo] =useState(null);
  const cookies = new Cookies();
  const [disable,setDisable]=useState(false);
  const dispatch = useDispatch();
  const [sent,setSend]=useState(null);


  
 const[editorState, setEditorState]=useState( () => EditorState.createEmpty(),);
  const  [convertedContent, setConvertedContent] = useState(null);
  const classes = useStyles();
  const [uploadedImages,setUploadedImage] = useState([])

  const [tweet, setTweet] = React.useState("");
  const [fblink, setFblink] = React.useState("");
  const [instalink, setInstalink] = React.useState("");
  const [linkedinlink,setLinkedinlink] = React.useState("");
  const [utube,setUtube]= React.useState("");
  

  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
    if(!error && !checkParam(Object.keys(error))){
      notification(error, 'error');
     } else{
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
     messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
     }
    }
  useEffect(() => {

    const html = '<p>Dear ##User_name##<br/> Congratulations! Your ticket has been booked successfully.<br/> Please find your ticket in the attachment.</p>'
    if (brandEventId !== null) {
      setSend(brandEventId.data)
      setDisable(brandEventId.validate === "Edit")
      const brandUser = {
        "action_type": "emailtemplate list",
        event_id: brandEventId.id
      }
      
      getTemplateDetails(brandUser).then((result) => {
        
        const Logo = result.data !== undefined && (result.data).length > 0 ? (result.data[0].logourl !== "" ? result.data[0].logourl : brandEventId.data.thumbnailimage) : brandEventId.data.thumbnailimage;
        const message = result.data !== undefined && (result.data).length > 0 ? (result.data[0].message !== "" ? result.data[0].message : html) : html


        setAddLogo(Logo)
        setRecords(result.data)
        if ((result.data).length > 0) {
          setTweet(result.data[0].twitter)
          setFblink(result.data[0].facebook)
          setInstalink(result.data[0].instagram)
          setLinkedinlink(result.data[0].linkedin)
          setUtube(result.data[0].youtube)
        }
        setConvertedContent(message)

        /** Convert html string to draft JS */
        const contentBlock = htmlToDraft(message);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const defaultMessage = EditorState.createWithContent(contentState);


        setEditorState(defaultMessage);

        dispatch(Template(null));
      }).catch((error) => {
        errorHandle(error)
      })
    }
  }, [brandEventId])


 


const timeOutSet = () => {
  notification("Your Session Expired....Try again...","error");
  setTimeout(() => {
     cookies.remove('currentUser');history.push('/');
  }, 2000);
}

const notification = (message, type) => {
  setNotify({
    isOpen: true,
    message: message,
    type: type
  })
}

  const handleSave = () => {


    if (sent != null && disable) {

      const formData = new FormData();
      const logoimage = records[0] !== undefined && records[0].logourl === addLogo ? addLogo : addLogo.name !== undefined ? addLogo.name : "";
      const logofile = records[0] !== undefined && records[0].logourl === addLogo ? addLogo : addLogo.name !== undefined ? addLogo : "";
      const emailTemplate = {

        "action_type": records[0] !== undefined && records[0].emailbookingid !== undefined ? "edit" : "add",//(add/ edit)
        "template_id": records[0] !== undefined && records[0].emailbookingid !== undefined ? records[0].emailbookingid : "0", //(add- 0/ edit - created id from DB)         
        "event_id": sent.id,
        "eventname": sent.eventname,
        "logo_url": logoimage,
        "message": convertedContent,
        "twitter_url": tweet,
        "facebook_url": fblink,
        "instagram_url": instalink,
        "linkedin_url": linkedinlink,
        "youtube_url": utube
      }

      formData.append('emailTemplate', JSON.stringify(emailTemplate));
      formData.append('file', logofile);

      getemailTemplateManagement(formData).then((result) => {
        setDisable(false)
        notification('Changes Saved Successfully', 'success');

      }).catch(error => {
        notification('Not changed  ', 'error');
        errorHandle(error);
      })


    }

  }




const uploadImageCallBack=async(file)=> {

  let uploadedImage = uploadedImages;

  const base64 = await convertBase64(file);
  setUploadedImage(base64);
  uploadedImage.push(base64);
  setUploadedImage( uploadedImage )

  return new Promise(
    (resolve, reject) => {
      resolve({ data: { link: base64 } });
    
    }
  );

  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <div >
      <Helmet title="Email Template" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                    <Grid item lg={6} md={7} sm={7} xs={12}>
                      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                      Email Template
                        </Typography>
                        <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
                        <Link component={NavLink} exact to="/Branding" underline="hover" color="inherit">
                        Branding
      </Link>
                            <Typography color="textPrimary">Email Template</Typography>
                        </Breadcrumbs>
                      </Grid>
                      <Grid item lg={6} md={5} sm={5} xs={12}>
                      <Toolbar className="selectbar">                     
                      <Box>
                      {disable?<Controls.Button text="Save Changes" onClick={handleSave} className="link-red"/>:<></>}
                      </Box>
                      </Toolbar>
                      </Grid>
            </Grid>
       </Grid>
        <Divider />  
      
        <div className={disable?"":"disabled preview"}>
        <div className={classes.pageContent}>         
       <AddLogo getlogo={setAddLogo} setlogos={addLogo} disable={disable} />
 
      <Typography variant="h6" gutterBottom component="div" className="my-2">
        Ticket Booking Confirmation
      </Typography>
      <Typography variant="subtitle2" gutterBottom component="div" className="my-2">
      Message
        </Typography>
    
        <Editor
              editorState={editorState}
              defaultEditorState={editorState}
              toolbarClassName="toolbarClassName"

              wrapperClassName="card w-100"
              editorClassName="card-body"
              wrapperStyle={{ width: 1010, border: "1px solid black" }}

              onEditorStateChange={newState => {
                setEditorState(newState)
                setConvertedContent(draftToHtml(convertToRaw(newState.getCurrentContent())))
              }}



              toolbar={{
                options: ['inline', 'list', 'textAlign', 'image'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },

                list: {
                  options: ['unordered', 'ordered', 'indent', 'outdent'],
                },
                textAlign: {
                  options: ['left', 'center', 'right'],
                },
                image: {
                  uploadCallback: uploadImageCallBack,
                  previewImage: true,
                  alt: { present: true, mandatory: false },
                 inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                },
                
              }}
            />


        <Typography variant="subtitle2" gutterBottom component="div" className="my-2">
        Social Links
        </Typography>
        {/* <SocialLinks /> */}
        <div>       
<Grid container item spacing={2} lg={6} md={16}>        
    <Grid item lg={12} md={12} sm={12} xs={12} className="icons">
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <BottomNavigation showLabels   >
          <BottomNavigationAction value="recents" icon={<TwitterIcon className="twitter"/>} />
          </BottomNavigation>
          <TextField style={{width:"100%",display: 'flex'}} size="medium" value={tweet} label="https://www.twitter.com/" variant="outlined" onChange={(e) => setTweet(e.target.value)}/>
    </Box> 
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <BottomNavigation showLabels   >
          <BottomNavigationAction value="recents" icon={<FacebookIcon className="facebook" />} />
          </BottomNavigation>
          <TextField style={{width:"100%",display: 'flex'}} size="medium" value={fblink} onChange={(e) =>  setFblink(e.target.value)} label="https://www.facebook.com/" variant="outlined" />
    </Box>  
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <BottomNavigation showLabels  >
          <BottomNavigationAction value="recents" icon={<InstagramIcon className="instagram" />} />
          </BottomNavigation>
          <TextField style={{width:"100%",display: 'flex'}} size="medium" value={instalink} onChange={(e) =>  setInstalink(e.target.value)} label="https://www.instagram.com/" variant="outlined" />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <BottomNavigation showLabels  >
          <BottomNavigationAction value="recents" icon={<LinkedInIcon className="linkedin" />} />
          </BottomNavigation>
          <TextField style={{width:"100%",display: 'flex'}} size="medium" value={linkedinlink} onChange={(e) =>  setLinkedinlink(e.target.value)} label="https://www.linkedin.com/" variant="outlined" />
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <BottomNavigation showLabels  >
          <BottomNavigationAction value="recents" icon={<YouTubeIcon className="youtube" />} />
          </BottomNavigation> 
          <TextField style={{width:"100%",display: 'flex'}} size="medium" value={utube} onChange={(e) =>  setUtube(e.target.value)} label="https://www.youtube.com/" variant="outlined" />
    </Box>  
    </Grid>
</Grid>

    </div>
    <TempFooter/> 

        </div>
        </div>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </main>
      <Footer />
    </div> 
   
  );
}

export default EmailTemplate;