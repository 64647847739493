import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function createEvent(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/createEvent`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getALLEvent() {
  //const currentUser = cookies.get('currentUser');  
  return await
    http
      .post('/events/getAllEvent',1,{
        headers: {
          //'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export const getEventById = async (data) => {
  return await
    http
    .post(`/events/getEventById`, data, {
    }).then( async(response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getVenueById = async (venueid) => {
return await http.post(`/customers/getVenueDetails`,{action_type : "Venue Details", id :venueid}).then(response=>{ 
  return response.data;
}).catch(error=>{
  throw error;
})
}



export async function getVenue(id) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/getVenue`, id, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function deleteEvent(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/deleteEvent`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateEvent(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/updateEvent`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-type": "multipart/form-data"   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function getEventByAccountId(id) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post('/events/getEventByAccountId',id,{
        headers: {
          'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {        
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getUpsellByEventId(inputData) {
  //const currentUser = cookies.get('currentUser');
  return await
    http
      .post('/events/getUpsellByEventId',inputData,{
        headers: {
         // 'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {
          return response.data[0];
      })
      .catch((error) => {
        throw error;
      });
}

export async function createUpsell(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/createUpsell`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateUpsell(formData) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/updateUpsell`, formData, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,
          "Content-Type": formData?.action_type === "status_update" ? "application/json" : "multipart/form-data",     
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function deleteUpsell(event) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/deleteUpsell`, event, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}
export async function getBookingDetails(paymentIntentId) {
  //const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getBookingDetails`, paymentIntentId, {
        headers: { 
          //'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {        
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getBookingDetailsindividual(paymentIntentId) {
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/manageIndividualTicket`, paymentIntentId, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {       
        return response.data.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateEventDetails(data){
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/updateEventDetails`, data, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function updateEventDetailsBulk(data){
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/updateEventDetailsBulk`, data, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function deleteOcurrence(data){
  ;
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/events/deleteEventDetails`, data, {
        headers: { 
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
    }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function checkBookingDetails(paymentIntentId) {    
  return await
    http
      .post(`/events/checkBookingDetails`, paymentIntentId, {
        headers: {}
      })
      .then((response) => {        
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function checkBookingDetailsindividual(paymentIntentId) { 
  const currentUser = cookies.get('currentUser');    
  return await
    http
      .post(`/events/manageIndividualTicket`, paymentIntentId, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``, 
        }
      })
      .then((response) => {      
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function individualUpsellReedem(paymentIntentId) { 
  const currentUser = cookies.get('currentUser');     
  return await
    http
      .post(`/events/manageIndividualUpsell`, paymentIntentId, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``, 
        }
      })
      .then((response) => {     
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getEventDashboardByAccountId(bodyData, pageLength, pageNumber) {  
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getDashboardEvent?pagelength=${pageLength}&pagenumber=${pageNumber}`, bodyData, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
      .then((response) => {                    
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getTopsellingEvent(data) {  
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getTopsellingEvent`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
      .then((response) => {                    
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

// export async function getUpcomingEvent(data,get) {  
//   const currentUser = cookies.get('currentUser');  
//   return await
//     http
//       .post(`/events/getUpcomingEvent?pagelength=${get.PL}&pagenumber=${get.PN}&sortorder=${get.SO}`, data, {
//         headers: {
//           'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
//         }
//       })
//       .then((response) => {                    
//         return response.data;
//       })
//       .catch((error) => {
//         throw error;
//       });
// }
export async function getEventBarChartByAccountId(data) {  
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getEventBarChartByAccountId`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
      .then((response) => {                    
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getEventReport(data) {  
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getEventReport`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
      .then((response) => {                    
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

export async function getEventPerformanceViewData(eventDetailId) {  
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post(`/events/getEventPerformanceViewData/${eventDetailId}`, { eventDetailId }, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
      .then((response) => {                    
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}


export default {
    createEvent,getALLEvent, getEventById, getVenueById, getEventDashboardByAccountId, getEventReport, getEventPerformanceViewData
}