import React from 'react'
import { Button, Typography, Link} from '@material-ui/core';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Page404 = () => {
  const history = useHistory();

    return (
     
        // <Wrapper>
        <div className="login-bg">
          <div display="flex" align="center" className="w-100">
          <Helmet title="404 Error" />
          <Typography component="h1" variant="h3" align="center" gutterBottom>
            404
          </Typography>
          <Typography component="h2" variant="h5" align="center" gutterBottom>
            Page not found.
          </Typography>
          <Typography component="h2" variant="body1" align="center" gutterBottom>
            The page you are looking for might have been removed.
          </Typography>
    
          <Button            
            variant="contained" className="link-red" align="center" 
            mt={2} onClick={()=>history.push("/EventDashboard")} >
            Return to Dashboard
          </Button>
          </div>
          </div>
      );
}

export default Page404;
