import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const http = axios.create({
    //baseURL:'http://54.156.48.192/OTS/v1',
    // baseURL:'http://localhost:5000/v1',
    //baseURL:'http://3.218.210.234/OTS/v1',
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
  });


  async function searchOrderDetails(order) {
    const currentUser = cookies.get('currentUser');
   
    return await
      http
        .post(`/events/searchOrderDetails`, order, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {     
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }


   async function getOrderDetails(order) {
    const currentUser = cookies.get('currentUser');
   
    return await
      http
        .post(`/events/getOrderDetails`, order, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {     
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }


  async function exchangeEventTicket(order){
    const currentUser = cookies.get('currentUser');

    return await
      http
        .post(`/events/exchangeEventTicket`, order, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  async function resendEmail(order, emailOverride) {
    const currentUser = cookies.get('currentUser');
    const { 
      eventdetailid, 
      paymentid, 
      email, 
      endusername, 
      accountid } = order;

    return await
      http
        .post(`/events/sendOrderEmailToSupport`, {
          eventdetailid, 
          payment_id: paymentid, 
          email, 
          endusername, 
          accountid,
          emailOverride
        }, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  
  async function refundOrder(order) {
    const currentUser = cookies.get('currentUser');
 
    return await
      http
        .post(`/payment/paymentRefundByOrder`, order, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  async function cancelReservation(reservationId) {
    const currentUser = cookies.get('currentUser');
 
    return await
      http
        .post(`/payment/cancelReservation`, {
          reservationId,
        }, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  async function markReservationOfflinePurchased(reservationId, clientid) {
    const currentUser = cookies.get('currentUser');
 
    return await
      http
        .post(`/payment/markReservationOfflinePurchased`, {
          reservationId,
          clientid,
        }, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  

  async function updatePaymentUserData(user){
    const currentUser = cookies.get('currentUser');
    return await
    http
      .post(`/events/updatePaymentUserData`, user, {
        headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });

  }

  async function updateMarketingOptin(data) {  
    const currentUser = cookies.get('currentUser');  
    return await
      http
        .post(`/events/updateMarketingOptin`, data, {
          headers: {
            'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
          }
        })
        .then((response) => {                    
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  export async function updateOrderEmailService(eventTicketDetailId, email, eventTicketDetails) {
    const currentUser = cookies.get('currentUser');
    return await 
      http
        .put(`/orders/updateOrderEmail/${eventTicketDetailId}`, { email, eventTicketDetails }, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  export async function partialRefundDetails(data) {
    const currentUser = cookies.get('currentUser');
    return await 
      http
      .post(`/payment/partialRefundDetails`, data, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  export async function partialRefund(data) {
    const currentUser = cookies.get('currentUser');
    return await 
      http
      .post(`/payment/partialRefund`, data, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  export async function sendReserveLink(data) {
    const currentUser = cookies.get('currentUser');
    return await 
      http
      .post(`/orders/sendReserveLink`, data, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  export async function updateOrderService(eventTicketDetailId, updateObj) {
    const currentUser = cookies.get('currentUser');
    return await 
      http
        .put(`/orders/updateOrder/${eventTicketDetailId}`, { updateObj }, {
          headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }

  export default {
    searchOrderDetails,
    getOrderDetails,
    exchangeEventTicket,
    refundOrder,
    updatePaymentUserData,
    updateMarketingOptin, 
    resendEmail,
    cancelReservation,
    markReservationOfflinePurchased,
    sendReserveLink,
    updateOrderService,
  }