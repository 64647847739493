import React, { useEffect } from 'react'
import { getMetabaseDashboardToken } from '../../services/reportService';
import { Grid } from "@material-ui/core";
import Loading from '../../components/controls/Loading';

export default function MetabaseDashboard({ currentUserAccount, errorHandle, metabaseDashboardEmbedUrl, setMetabaseDashboardEmbedUrl }) {
  const METABASE_SITE_URL = "https://on-the-stage.metabaseapp.com";

  function isMetabaseTokenExpired(token) {
    let isExpired = null;
    if (!token) {
      isExpired = true;
    } else {
      const [headerBase64, payloadBase64, signature] = token.split('.');
      const payload = JSON.parse(atob(payloadBase64));
      const expirationTime = payload.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      if (expirationTime < currentTime) {
        isExpired = true  // The JWT token has expired.
      } else {
        isExpired = false // The JWT token is still valid.
      }
    }
    return isExpired;
  }

  const handleGetMetabaseDashboardUrl = async (accountId) => {
    let iframeUrl;
    try {
      if (isMetabaseTokenExpired(currentUserAccount?.metabase_jwt_token)) {
        const token = await getMetabaseDashboardToken(accountId);
        iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
        setMetabaseDashboardEmbedUrl(iframeUrl);
      } else {
        iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + currentUserAccount?.metabase_jwt_token + "#bordered=true&titled=true"
        setMetabaseDashboardEmbedUrl(iframeUrl);
      }
    } catch (error) {
      errorHandle(error);
      setMetabaseDashboardEmbedUrl(null)
    }
  };

  useEffect(() => {
    if (currentUserAccount?.metabase_dashboard_number) {
      handleGetMetabaseDashboardUrl(currentUserAccount?.accountid);
    }
  }, [currentUserAccount])

  return (
    <Grid container justifyContent='center'>
      {(currentUserAccount?.metabase_dashboard_number && metabaseDashboardEmbedUrl) ? (
        <iframe
          title="metabase dashboard"
          frameBorder={0}
          src={metabaseDashboardEmbedUrl}
          width="100%"
          height={4000}
          allowtransparency="true"
        />
      ) : <Loading />}
    </Grid>
  )
}