import { Grid, makeStyles, DialogActions, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import taxService from '../../services/taxService';
import Notification from '../../components/Notification';




const useStyles = makeStyles(theme => ({
  root: {
    '& > + ': {
      margin: theme.spacing(2),
    },
  },
  select: {
    width: '100% !important',
  },
}));

const initialFValues = {

  taxcategory: 0,
  taxpercentage: "",
  upselltaxcategory:"",
  upselltaxpercentage:"",
  userid: 0

};

const TaxConfigForm = (props) => {
  const classes = useStyles();
  const [category, setCategory] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [edit,setEdit]= useState(true);
  const [fields, setFields] = useState([{taxcategoryid:"",upselltaxcategoryid:"",upselltaxpercentage:""}]); 
  const [addFields,setaddFields] = useState([{taxcategoryid:"",taxcategory:"",upselltaxcategoryid:"",upselltaxcategory:"",upselltaxpercentage:""}]); 
  useEffect(() => {
    let cancel = false;
    const users = {
      action_type: "add list"
    }

    taxService.getAddListTaxCategory(users)
      .then(result => {
        console.log(result[1],'resulttas')
        if (cancel) return;
        if(result.length===0){
        result.push({taxcategoryid: 0, taxcategory: 'Select', taxpercentage: ''})
        }
        setCategory([...result]);
      })
      .catch((error) => {
        const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
        notification(messages,'error');      
      });

      return () => {
        cancel = true;
      }
  }, [])

  


  const { addOrEdit, recordForEdit,subCategory, cancelbutton } = props;

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("taxcategory" in fieldValues) {
      if(!edit){
        temp.taxcategory=""
      }else if(fieldValues.taxcategory === 'Select'){
        temp.taxcategory = "All Categories are added"; 
      }
      else{
      temp.taxcategory = fieldValues.taxcategory ? "" : "Taxcategory is  required";
      }
    }
    if ("taxpercentage" in fieldValues) {
      temp.taxpercentage = fieldValues.taxpercentage ? "" : "TaxPercentage is required";
    }


    setErrors({ ...temp });
    return Object.values(errors).every((x) => x === "");


  };
  const formIsValid = (fieldValues = values) => {
    if (validate()) {
      const isValid =
        fieldValues.taxcategory &&
        fieldValues.taxpercentage &&
        Object.values(errors).every((x) => x === "");

      return isValid;
    }
  };

  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formIsValid()) {
       addOrEdit(values,fields,addFields);
       resetForm()
    }
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }
const [count,setCount] =useState(0)
  useEffect(() => {
    let cancel = false;
    if (recordForEdit !== null){
      if (cancel) return;
      setValues(recordForEdit)
      const sub = subCategory.filter(x=>x.taxcategoryid===recordForEdit.taxcategoryid)
      const subField = sub.filter(x=>x.upselltaxpercentage!=null && x.upselltaxpercentage!=="null")
      setFields(subField)
      setaddFields(sub)
      setCount(subField.length)
      setEdit(false);
    }
    return () => {
      cancel = true;
    }
  }, [recordForEdit]);

  

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const error = fields.find(x => x.upselltaxcategoryid === value);
    if (error === undefined) {
      const list = [...fields];
      list[index][name] = value;
      list[index]['taxcategoryid'] = addFields[0].taxcategoryid
      setFields(list);
    }
    else {
      notification("This field is already choosen", 'error');
      const list = [...fields];
      list.splice(index, 1);
      setFields(list);
    }

  } 
  let addFormFields = () => {
    setFields([...fields, {upselltaxcategory: "", upselltaxpercentage: "" }]);
  }

  
const onKeyPressHandler1 = e=> {
  if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
  }
  else{
    e.preventDefault(); 
  }
}
 

  return (

    <Form onSubmit={handleSubmit} className="create-user">
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
           <Grid container item spacing={2} lg={12} md={12}>
        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          
       { edit?  <Controls.Select
            name="taxcategory"
            label="Tax Category"
            value={values.taxcategory || ""}
            options={category}
            onChange={handleInputChange}
            keyName="taxcategory"
            valueName="taxcategory"
            error={errors.taxcategory} className={classes.select}
          />:<Controls.Input
          name="taxcategory"
          label="Tax Category"
          value={values.taxcategory || ''}
          disabled={true}
          onChange={handleInputChange}
          error={errors.taxcategory} className="smaller-input" />}

        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>

          <Controls.Input
          
            name="taxpercentage"
            label="%"
            onKeyPress={onKeyPressHandler1} 
            value={values.taxpercentage || ''}
            onChange={handleInputChange}
            error={errors.taxpercentage} className="smaller-input" />
        </Grid>
        
      
        {fields && fields.map((field, idx) => {
        return (
          < >           
            <Grid item lg={6} md={12} sm={12} xs={12}>
       {!edit?<Controls.Select
               name="upselltaxcategoryid"
               label="Sub Category"
               value={field.upselltaxcategoryid || ""}
               options={addFields}
               onChange={(e) => handleChange(e,idx)}
               keyName="upselltaxcategoryid"
               valueName="upselltaxcategory"
               readOnly={idx < count}
               error={""} className={classes.select}
       />:<></>}
           </Grid>
         
     
           <Grid item lg={6} md={12} sm={12} xs={12}>
          { !edit?<Controls.Input
               className="ml10"
               type="text"
               onKeyPress={onKeyPressHandler1} 
               name="upselltaxpercentage"
               label="%"
               value={field.upselltaxpercentage || ''}
               onChange={(e) => handleChange(e,idx)}
              // error={errors.upselltaxpercentage} className="smaller-input" 
              />:<></>}
           </Grid> 
           <div>
         
         </div>
           
         </>
         
        );
      })}
       <Grid item lg={12} md={12} sm={12} xs={12} className="float-right">
      {!edit&&fields.length  < addFields.length &&<Controls.Button text="Add more"size="small" variant="outlined" className="btn-redoutline" onClick={() => addFormFields()}>
           Add More
     </Controls.Button>}
         </Grid>

        <Divider />
      
      
        <DialogActions className="w-100">
          <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>            
            <Controls.Button
              text="Clear"
              color="default" size="medium"
              onClick={()=>{resetForm(); cancelbutton();}} className="link-gray" />
              <Controls.Button
              type="submit"
              text="Submit" size="medium" className="link-red" />
          </Grid>
        </DialogActions>
      </Grid>

    </Form>
  )
};

export default TaxConfigForm;