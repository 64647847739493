import React, { useState, useEffect, } from "react";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Promocode } from '../../redux/actions/authActions';
import Footer from "../../components/layouts/Footer";
import BackToTop from "../../components/controls/BackToTop";
import Controls from '../../components/controls/Controls';
import EventExpand from './EventExpand';
import EventDatePicker from './EventDatePicker';
import Notification from '../../components/Notification';
import { 
    makeStyles,
    Grid, 
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio, 
    Divider,
    Box,
    Typography,
    Toolbar,
    Link,
    Breadcrumbs,
    Paper,
} from "@material-ui/core";
import {   
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, 
} from "@material-ui/core";
import { NavLink, useParams } from "react-router-dom";
import useForm from '../../components/useForm';
import { promocodeManagement,getPromocodeDetails } from '../../services/promoCodeServices';
import accounting from "accounting";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
        root: {
          width: "100%",
        },
        content: {
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`, 
            marginLeft: drawerWidth,
          },
        },
        pageContentMain: {
          margin: theme.spacing(2),
          padding: theme.spacing(2),
          [theme.breakpoints.up('xs')]: {
            margin: '0px auto',
            width: ' 89%',
          },
          [theme.breakpoints.up('sm')]: {
            margin: '0px auto',
            width: '90%',
          },
          [theme.breakpoints.up('md')]: {
            margin: '0px auto',
            width: '93%',
      
          },
          [theme.breakpoints.up('lg')]: {
            margin: '0px auto',
            width: '80%',
          },
        },
          
        drawerPaper: {
          width: drawerWidth,
        },
        Toolbar: {
          width: '50% !important',
          padding: '0px',
          [theme.breakpoints.down("sm")]: {
            width: '100% !important',
          }
        },
      }));
      const drawerWidth = 250;

const initialFValues = {
giftCardEmail: "",
promocode: "",
originalAmount:"0",
isamountorperc: "1",
maxredemption: "0",
ticketororder: "1",
promocodeid: "0",
promocodestartdate:"",
promocodeenddate:"",
eventwindowstartdate:"",
eventwindowenddate:"",
accountid:"",
promocodeeventmapid:"",
eventid:"",
eventdetailid:"",
isGiftCard: 0,
};     
const PromoCode = () =>{
const { id = 0, promoname = '' } = useParams();
const isGiftCard = Number(id) ? true : false;
const classes = useStyles();
const cookies = new Cookies();
const history = useHistory();
const check = useSelector(state => state.authReducer);
const dispatch = useDispatch(); 
const [promocodestartdate,setPromoCodeStartdate]=useState(null);
const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
const [promocodeenddate,setPromoCodeEnddate]=useState(null);
const [eventwindowstartdate,setEventWindowStartdate]=useState(null);
const [eventwindowenddate,setEventWindowEnddate]=useState(null);
const [editableDiscountValue, setEditableDiscountValue] = useState(null);
const [defaultAmountSpent, setDefaultAmountSpent] = useState(null);
const [dbsend, setDbsend] = useState("");
const [checked, setChecked] = useState([]);
const [eventmap, seteventmap] = useState([]);
const [events, setEvents] = useState([]);
const PromoCookies = cookies.get('Promocode');
const [maxValidation, setMaxValidation] = useState(null);
const [overall,setOverall] = useState([])
const [giftCardOrders, setGiftCardOrders] = useState([]);
const [giftCardUsedAmount, setGiftCardUsedAmount] = useState(0);
const checkParam = (reqParams, param_key) => {
  return (reqParams.includes(param_key));
};

const errorHandle = (error) => {
  if (!error && !checkParam(Object.keys(error.response.data.message))) {
    notification(error, 'error');
  } else {
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
    messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
  }
}

const validate = (fieldValues = values) => {
  const temp = { ...errors };
  
  if (("giftCardEmail" in fieldValues) && isGiftCard) {
    if (values.giftCardEmail) {
      temp.giftCardEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.giftCardEmail)
        ? "" : "Email is not valid.";
    } else {
      temp.giftCardEmail = fieldValues.giftCardEmail ? "" : "Email is required"
    }
  }
  if ("promocode" in fieldValues) {
    temp.promocode = fieldValues.promocode ? "" : "Promo Code is required";
  }
  if ("originalAmount" in fieldValues) {
    if (Number(values.isamountorperc) === 2) {
      temp.originalAmount = fieldValues.originalAmount && fieldValues.originalAmount!=="0" ? Number(fieldValues.originalAmount) > 100 ? "Percentage value cannot be greater than 100" : '' : "Percentage Value is required";
    } else {
      temp.originalAmount = fieldValues.originalAmount&&fieldValues.originalAmount!=="0"? "" : "Amount is required" ;
    }
  }  
  if ("maxredemption" in fieldValues) {
    console.log('vales.availablecount', values.availablecount);
    console.log('maxValidation', maxValidation);
    if(dbsend==="Edit"  && Number(values.availablecount) < Number(maxValidation) && !isGiftCard){
      temp.maxredemption = (Number(maxValidation)-Number(values.availablecount))<Number(fieldValues.maxredemption) ? "" :`Already ${Number(maxValidation)-Number(values.availablecount)} ticket is reedmed`
    } else {
      temp.maxredemption = ''
    }
  }
  setErrors({ ...temp });
  console.log('validation', temp);
  return Object.values(temp).every((x) => x === "");
}

const { values,
  setValues,
  errors,
  setErrors,
  handleInputChange,
  resetForm } = useForm(initialFValues, true, validate);

const DateChange=(newValue,name)=>{
  if(newValue!==null&&newValue!=='Invalid Date'){
    
  
  const Dates =  newValue!==null&&newValue!=='Invalid Date'?`${newValue.getMonth()+1}-${newValue.getDate()}-${newValue.getFullYear()}`  : null;
  const Times =  newValue!==null&&newValue!=='Invalid Date'?`${newValue.getHours()>12?newValue.getHours()-12:newValue.getHours()!==0?newValue.getHours():12}:${newValue.getMinutes()} ${newValue.getHours()>12?'PM':'AM'}`  : null;
  return Dates!==null?(`${Dates},${Times}`):null;
  }
  else {
    if (!isGiftCard) return name ? notification(`${name} is required`, "error") : null;
    return null;
  }
}

const timeOutSet = () => {
  notification("Your Session Expired....Try again...", "error");
  setTimeout(() => {
    cookies.remove('currentUser'); history.push('/');
  }, 2000);
}
const notification = (message, type) => {
  setNotify({
    isOpen: true,
    message: message,
    type: type
  })
}
const formIsValid = async () => {
  
  const promostartDate=DateChange(promocodestartdate,"Available Start Date & Time")
  const promoendDate=DateChange(promocodeenddate,"Available End Date & Time")
  const eventwinstartDate=DateChange(eventwindowstartdate,"EventWindow Start Date & Time")
  const eventwinenddate=DateChange(eventwindowenddate,"EventWindow End Date & Time")
  let eventvalidate=true
  if (checked.length === 0 && !isGiftCard) {
    notification('"Select at least one Event / Event Occurrance"', 'error')
    eventvalidate = false
  }
  const isValid = validate();
  console.log('isValid', isValid)
  debugger;
  if (isValid && (((promostartDate || isGiftCard) &&promoendDate&&eventwinstartDate &&eventwinenddate&&eventvalidate))){

    if (dbsend !== 'Edit') {
    const giftCardValue = (checked.length || (isGiftCard && 1)) || 0;
    let promocodeArray = [];
    for(let i = 0; i < giftCardValue; i +=1){
      let eventId = null;
      let eventDetail = null;
      if (checked.length) {
      events.forEach( (e) => {
        e.eventdetailfile.forEach(x => {
          if(checked[i] === x._id){
            eventId =e.id
            eventDetail = checked[i]
          }
        })
      })
    }
      
      const promocodeList ={
        promocodeid: "0",
        promocode:values.promocode,
        discountvalue: values.originalAmount,
        originalAmount: values.originalAmount,
        maxredemption: isGiftCard ? null : values.maxredemption,
        isamountorperc: values.isamountorperc,
        promocodestartdate: promostartDate,
        promocodeenddate:promoendDate,
        eventwindowstartdate:eventwinstartDate,
        eventwindowenddate:eventwinenddate,
        ticketororder:values.ticketororder,
        accountid:PromoCookies.eventAccountId,
        promocodeeventmapid:"0",
        eventid:eventId,
        eventdetailid:eventDetail,
        isGiftCard: isGiftCard,
        giftCardEmail: isGiftCard ? values.giftCardEmail : null,
      };
      promocodeArray.push(promocodeList);
    }
    let json = {
      action_type: "add",
      account_id:PromoCookies.eventAccountId,
      promocodedetails:promocodeArray
    }
    promocodeManagement(json).then((result) => {
      if(result.message!=="Failed"){
      notification(`${ isGiftCard ? "Giftcode" : "Promocode"} added successfully.`, "success");
      resetForm();
      setTimeout(() => {
        history.push('/PromoCodes');
     }, 2000);
      }else{
        notification(`This ${ isGiftCard ? "Giftcode" : "Promocode"} name is already added.`, "error");
      }
    }).catch(error => {
      errorHandle(error);
    })
   
  } else {
    let promocodeArray = [];
    let amountSpent = defaultAmountSpent;
    if (isGiftCard && editableDiscountValue !== values.discountvalue) {
      // amount off is changed
      if (editableDiscountValue < (editableDiscountValue - values.discountvalue)) {
        // error
        notification(`GiftCard value - ${(editableDiscountValue - values.discountvalue)} is less than the actual value: ${editableDiscountValue}`, "error");
        return;
      }

      amountSpent += editableDiscountValue - values.discountvalue;
    }

    for (let i = 0; i < overall.length; i += 1) {
      const promoMapping = eventmap.find(x => x.eventdetailid === overall[i].eventdetailid)
      const promocodeList = {
        promocodeid: values.promocodeid,
        promocode: values.promocode,
        discountvalue: values.discountvalue,
        originalAmount: values.originalAmount,
        maxredemption: values.maxredemption,
        isamountorperc: values.isamountorperc,
        promocodestartdate: promostartDate,
        promocodeenddate: promoendDate,
        eventwindowstartdate: eventwinstartDate,
        eventwindowenddate: eventwinenddate,
        ticketororder: values.ticketororder,
        accountid: PromoCookies.eventAccountId,
        promocodeeventmapid: promoMapping ? promoMapping.promocodeeventmapid : "0",
        eventid: overall[i].eventid || null,
        eventdetailid: overall[i].eventdetailid || null,
        isGiftCard: isGiftCard,
        giftCardEmail: isGiftCard ? values.giftCardEmail : null,
        amountSpent: amountSpent
      };
      promocodeArray.push(promocodeList);
    }
    let json = {
      action_type: "edit",
      account_id:PromoCookies.eventAccountId,
      promocodedetails:promocodeArray
    }
  
    promocodeManagement(json).then((result) => {
      notification("Promocode Edited successfully ", "success");
      setTimeout(() => {
        history.push('/PromoCodes');
      }, 2000);
      resetForm();
    }).catch(error => {
      errorHandle(error);
    })
    
  }
}
};

const handleSubmit = () => {
  formIsValid();
};

useEffect(() => {
  let cancel = false;   

  if (isGiftCard) {
    setValues({ ...values, ticketororder: "2" });
  }

  if (promoname) {
      
    const promocodeDetail = {
    account_id: PromoCookies.eventAccountId,
    "action_type": "promocode list",
    promo_code: promoname
    }

    if (cancel) return;

    const dataBinding = (data) => {
    setValues(data);

    const dateConverter = (date) => {
      if (date) return moment(date, "MM-DD-YYYY,hh:mm A").toDate();
      return null;
    }

      setPromoCodeStartdate(dateConverter(data.promocodestartdate));
      setPromoCodeEnddate(dateConverter(data.promocodeenddate));
      setEventWindowStartdate(dateConverter(data.eventwindowstartdate));
      setEventWindowEnddate(dateConverter(data.eventwindowenddate)); 
      setEditableDiscountValue(data.discountvalue);
      setDefaultAmountSpent(data.amountSpent ? Number(data.amountSpent) : null);
    
    
    setDbsend('Edit');
    console.log('max redemption', data.maxredemption);
    setMaxValidation(data.maxredemption);
    }
    if (check.Promocode != null && check.Promocode.length !== 0) {
    dataBinding(check.Promocode);
    }
    const empty = [];
    dispatch(Promocode(empty));
    getPromocodeDetails(promocodeDetail).then((res) =>{
      let datas =[];
      let mapevent =[];
      let overalldata =[]
      if (res.giftCardOrders) {
        setGiftCardOrders(res.giftCardOrders);
        setGiftCardUsedAmount(res.giftCardUsedAmount);
      }
      if (!check.Promocode || check.Promocode.length === 0) {
      dataBinding(res.data[0]);
      }
      for(let i = 0; i < res.data.length; i +=1){
        
        datas.push(res.data[i].eventdetailid)
        mapevent.push({eventdetailid:res.data[i].eventdetailid,promocodeeventmapid:res.data[i].promocodeeventmapid})
        overalldata.push({eventid:res.data[i].eventid,eventdetailid:res.data[i].eventdetailid})
      }
      
      setChecked(datas);
      setOverall(overalldata)
      seteventmap(mapevent);
    });
  }
  return () => {
    cancel = true;
  }
}, [promoname])

const onKeyPressHandler = e=> {
  if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
  }
  else{
    e.preventDefault(); 
  }
}
const onKeyPressHandler1 = e=> {
if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
}
else{
 e.preventDefault(); 
}
}

  return(
    <>
    <div>
      <Helmet title="Promo Code" />
      <main className={classes.content}>    
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                    <Grid item lg={6} md={7} sm={7} xs={12}>
                      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                          Promo Codes
                        </Typography>
                        <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
                          <Link component={NavLink} exact to="/PromoCodes" underline="hover" color="inherit">
                            Promo Codes
                          </Link>
                          {dbsend? <Typography color="textPrimary">{ isGiftCard ?  "Edit Gift Card": "Edit Promo Code" }</Typography>:<Typography color="textPrimary">{ isGiftCard ?  "Create Gift Card": "Create Promo Code" }</Typography>}
                          </Breadcrumbs>
                      </Grid>
                      <Grid item lg={6} md={5} sm={5} xs={12}>
                      <Toolbar className="selectbar">                     
                      <Box>
                      <Controls.Button text="Save Changes" onClick={handleSubmit}  className="link-red"/>
                      </Box>
                      </Toolbar>
                      </Grid>
            </Grid>
       </Grid>
       <Divider className="mb-3" />   
      <Paper className={classes.pageContentMain}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Controls.Input
            type="text"
            name="promocode"
            value={values.promocode} 
            onChange={handleInputChange}
            label={isGiftCard ? 'Gift Code': 'Promo Code'}
            disabled={dbsend==='Edit'}
            error={errors.promocode} className="w-100"/>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        { isGiftCard ?   <Controls.Input
             type="text"
             name="giftCardEmail"
             disabled={dbsend === 'Edit'}
             value={values.giftCardEmail} 
             onChange={handleInputChange}
             label={"Email"}
             error={errors.giftCardEmail} className="w-100"/>  : null }
        </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-3">            
        <FormControl>  
        Discount Type         
        <RadioGroup aria-labelledby="radio-buttons" value={values.isamountorperc?.toString()} name="isamountorperc" onChange={handleInputChange}>
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      ><FormControlLabel value="1" control={<Radio />} label="Amount Off" />
        <FormControlLabel value="2" control={<Radio />} disabled={isGiftCard} label="Percent Off" /></Box>
        </RadioGroup> 
      </FormControl> 
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
        {dbsend !== 'Edit' && <Controls.Input  
           type="number"     
            name="originalAmount"
            value={values.originalAmount}  
            label={values.isamountorperc?.toString()==="1"?"Amount Off":"Percentage Off"}
            onChange={handleInputChange}
            onKeyPress={onKeyPressHandler1}
              inputProps={ {
                   min: 0
               } }
            error={errors.originalAmount} className="w-100"/>}
        {dbsend === 'Edit' && <Controls.Input
          type="number"
          name="discountvalue"
          disabled={dbsend === 'Edit' && isGiftCard === false}
          value={values.discountvalue}
          label={values.isamountorperc?.toString() === "1" ? "Amount Off" : "Percentage Off"}
          onChange={handleInputChange}
          onKeyPress={onKeyPressHandler1}
          inputProps={{
            min: 0
          }}
          error={errors.discountvalue} className="w-100" />}
        </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-4">
        Available        
        <EventDatePicker startDate={promocodestartdate} endDate={promocodeenddate} modifyStart={setPromoCodeStartdate} modifyEnd={setPromoCodeEnddate} displayStartdate = {!isGiftCard}/>         
        </Grid>

        { !isGiftCard && 
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <Controls.Input
            type="number"
              name="maxredemption" 
              value={values.maxredemption} 
              label="Maximum Total Redemptions *"
              onKeyPress={onKeyPressHandler}
              InputProps={{
                inputProps: { 
                    min: 0
                }
            }}
            onChange={handleInputChange} 
            error={errors.maxredemption}
            className="w-100"/>
              
              <small className="ml-3">Leave blank for no maximum</small>
          </Grid>
        }

        </Grid>
      { !isGiftCard && 
      <Grid item lg={6} md={6} sm={12} xs={12}>    
      <FormControl className="w-100 radio-option">
      <RadioGroup aria-labelledby="radio-buttons" value={values.ticketororder.toString()} onChange={handleInputChange} name="ticketororder">
        <FormControlLabel value="1" control={<Radio />} disabled={isGiftCard} label="Apply to each ticket" className="mr-1" /> <Box className="example" name="ticketororder"> (e.g. $2 off each ticket)</Box>        
      </RadioGroup>
    </FormControl>     
    </Grid>
      }  

      <Grid item lg={6} md={6} sm={12} xs={12}> 
      <FormControl className="w-100 radio-option">
        <RadioGroup aria-labelledby="radio-buttons" value={values.ticketororder.toString()} onChange={handleInputChange} name="ticketororder" disabled={dbsend==='Edit'}>          
          <FormControlLabel value="2" control={<Radio />} label="Apply to full order" className="mr-1" /> <Box className="example"> (e.g. $2 off everything in the order)</Box>
        </RadioGroup>
      </FormControl>   
      </Grid>       
        <Grid item lg={6} md={6} sm={12} xs={12}>
       Event Window
        <EventDatePicker startDate={eventwindowstartdate} endDate={eventwindowenddate} modifyStart={setEventWindowStartdate} modifyEnd={setEventWindowEnddate} displayStartdate = {true}/> 
        </Grid> 
        <Grid item lg={6} md={6} sm={12} xs={12}>
        Events
        <EventExpand Start={DateChange(eventwindowstartdate)} End={DateChange(eventwindowenddate)} 
        checked={checked} setChecked={setChecked} events={events} setEvents={setEvents} 
        accountid={PromoCookies.eventAccountId} dbsend={dbsend} setOverall={setOverall} overall={overall}/>
        </Grid> 

      </Grid>
      {
        giftCardOrders?.length ? 
        <Grid item lg={12} md={12} sm={12} xs={12}> 
        <Divider className="my-2" />
        <Typography variant="h5" gutterBottom>Gift Card Usage</Typography>
        <Table className="qr-tbl mb-2">
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-left">Order Id</TableCell>
                          <TableCell className="text-center">Email</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell className="text-right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>  
                        
                          {
                            giftCardOrders.map((order) => {
                              return <TableRow key={order.paymentid}>
                                  <TableCell className="text-left">
                                      <Link component={NavLink} className="text-red pb-2 pointer" to={`/OrderDetails/${order.eventticketdetailid}`}>{order.paymentid}</Link>
                                  </TableCell>
                                  <TableCell>{order.email}</TableCell>
                                  <TableCell>{moment(order.insertedtime).format('MM-DD-YYYY hh:mm a')}</TableCell>
                                  <TableCell className="text-right">{order.Promodiscount}</TableCell>
                                  </TableRow>
                            })
                          }

                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell className="text-right">
                              <b>{accounting.formatMoney(giftCardUsedAmount, '')}</b>
                              <br/>
                              Balance Remaining: {accounting.formatMoney(values.discountvalue, '')}
                          </TableCell>
                        </TableRow>                             
                          
                        
                      </TableBody> 
 
                      </Table>

        </Grid> : null 
      }


      </Paper>
      <BackToTop/>
      </main>
     <Footer /> 
     <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </div>         
    </>
);
}
export default PromoCode; 