import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Header from '../components/layouts/Header';
import SideBar from '../components/layouts/SideBar';

export const PrivateRouter = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const cookies = new Cookies();
        const currentUser = cookies.get('currentUser');
        const currentUsers = currentUser ? false : true;
        if (currentUsers) {
            return <Redirect to='/' />
        } else if (currentUser.roleid === 3) {
            console.log('props', props);
            if (props.location.pathname === '/EventDashboard' || props.location.pathname === '/QrValidation'
                || props.location.pathname === '/UserProfile' || props.location.pathname === '/ChangePassword'
                || props.location.pathname === '/Orders'  || props.match?.path === '/OrderDetails/:eventTicketDetailId'
                || props.match?.path === '/ExchangeTicket/:eventTicketDetailId/:eventid/:eventdetailid') {
                return <>
                    <Header />
                    <SideBar />
                    <Component {...props} /></>
            } else {
                return <Redirect to='/404Page' />
            }
        }
        else if (currentUser.roleid === 2) {
            if ( props.location.pathname === '/Customers' || props.location.pathname === '/TaxManagement') {
                return <Redirect to='/404Page' />
            } else {
                return <>
                    <Header />
                    <SideBar />
                    <Component {...props} /></>
            }
        }
        else {
            return <>
                <Header />
                <SideBar />
                <Component {...props} />
            </>
        }
    }} />
)