import * as types from "../../constants";
import {
  updateOrderEmailService,
  updateOrderService
} from "../../services/orderService";


export function getOrderAction(order) {
 return async (dispatch) => {
  dispatch({ type: types.GET_ORDER_SUCCESS, payload: {
    email: order.email,
    eventticketdetailid: order.eventticketdetailid,
    order_comments: order.order_comments
  }});  
 } 
}

export function updateOrderEmailAction(eventTicketDetailId, email, eventTicketDetails) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORDER_EMAIL_REQUEST });
    return updateOrderEmailService(eventTicketDetailId, email, eventTicketDetails)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORDER_EMAIL_SUCCESS,
          payload: {
            email: response.email,
            eventticketdetailid: eventTicketDetailId
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_ORDER_EMAIL_FAILURE });
        throw error;
      });
  };
};

export function updateOrderAction(eventTicketDetailId, updateObj) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORDER_REQUEST });
    return updateOrderService(eventTicketDetailId, updateObj)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORDER_SUCCESS,
          payload: {
            orderDetails: response.updatedOrder,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_ORDER_FAILURE });
        throw error;
      });
  };
};