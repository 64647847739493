import { Grid, makeStyles, DialogActions, Divider  } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import userService from '../../services/userService';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
//import classes from '*.module.scss';

const useStyles = makeStyles(theme=>({
    root: {
        '& > * + *': {
          margin: theme.spacing(2),
        },    
      },
    select:{
        width: '100% !important',
    },
  }));

const initialFValues = {
    firstname : "",
    lastname : "",
    emailid : "",
    mobilenumber : 0,
    accountid : 0,
    roleid : 0,
    userid:0,
    active:1
};

const UserForm = (props) =>{
    const history = useHistory();
    const classes = useStyles();
    const [role,setRole]=useState([]);
    const [accountTypes,setAccountTypes]=useState([]);
    const [accountID,setAccountID]=useState(0);   
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
    const check = useSelector(state=>state.authReducer);
    const cookies = new Cookies();


    useEffect(()=>{
       
        const userRole = userService.getUserRoles();
        userRole.then(result=>{
            if(check.user.roleid!==1){
                
                const roles =result.filter(i => i.roleid!==1);
                setRole(roles);
            }
            else{
            setRole(result);
            }
        }).catch(error=>{
          const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            if (messages === 'Invalid Token') {
              notification('Your session expired', 'error');
              timeOutSet();
            }
            else {
              notification(messages, 'error');
            }
  })
        

        const accountType = userService.getUserAccounts();
        accountType.then(result=>setAccountTypes(result)).catch(error=>{
          const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            if (messages === 'Invalid Token') {
              notification('Your session expired', 'error');
              timeOutSet();
            }
            else {
              notification(messages, 'error');
            }
  })

        if(check.user.roleid!==1){
            setAccountID(check.user.accountid)
        }
    },[])

    
    const { addOrEdit, recordForEdit ,disable ,cancelbutton } = props;

    const validate = (fieldValues = values)=>{
      
        const temp = {...errors};
        if("firstname" in fieldValues){
            temp.firstname = fieldValues.firstname?"":"First Name is required";
        }
        if("lastname" in fieldValues){
            temp.lastname = fieldValues.lastname?"":"Last Name is required";
        }
        if("emailid" in fieldValues){
            temp.emailid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.emailid)
            ? "": "Email is not valid.";
        }
        if("mobilenumber" in fieldValues){
            temp.mobilenumber = fieldValues.mobilenumber.length > 9 ? "" : "Minimum 10 numbers required";
        }
        
      if ("accountid" in fieldValues) {
        if (check.user.roleid !== 1) {
          temp.accountid = "";
        }
        else {
          temp.accountid = fieldValues.accountid ? "" : "Customer Account is required";
        }
      }

      if ("roleid" in fieldValues) {
        temp.roleid = fieldValues.roleid ? "" : "Role is required";

        if (fieldValues.roleid === 1 && check.user.accountid === 1) {
          temp.accountid = "";
        }
      }
      setErrors({ ...temp });
      return Object.values(temp).every((x) => x === "");
   


    };
    const formIsValid = (fieldValues = values) => {
      if( validate()) {
        const isValid =
          fieldValues.firstname &&
          fieldValues.lastname &&
          fieldValues.emailid &&
          fieldValues.roleid &&
          Object.values(errors).every((x) => x === "");
    
        return isValid;
        }
      };

    const {values,
           setValues,
           errors,
           setErrors,
           handleInputChange,
           resetForm } = useForm(initialFValues,true,validate);

    const handleSubmit = (event)=> {
        event.preventDefault();
        if (formIsValid()) {
            addOrEdit(values, resetForm);
          

        }
    };

    const notification = (message,type)=>{
        setNotify({
          isOpen: true,
          message: message,
          type: type
        })
      }
      
  const timeOutSet =()=> {
    setTimeout(() => {
      cookies.remove('currentUser');history.push('/');
    }, 1000);
  }

    useEffect(() => {
        if (recordForEdit !== null)
            setValues(recordForEdit)
    }, [recordForEdit]);

    return(
        
        <Form onSubmit={handleSubmit} className="create-user">
     <Notification
          notify={notify}
          setNotify={setNotify}
      />
            <Grid container item spacing={2} md={12}>

                <Grid item lg={6} md={6} sm={6} xs={12}>

                    <Controls.Input
                            name="firstname"
                            label="First Name"
                            value={values.firstname||''}
                            onChange={handleInputChange}
                            error={errors.firstname} className="smaller-input"/>
                            </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Controls.Input
                            name="lastname"
                            label="Last Name"
                            value={values.lastname||''}
                            onChange={handleInputChange}
                            error={errors.lastname} className="smaller-input"/>
                     </Grid>
                     <Grid item lg={6} md={6} sm={6} xs={12} className="w-100 smaller-input">
                    <Controls.Select
                    name="accountid"
                    label="Customer Account"
                    value={values.accountid||accountID||''}
                    onChange={handleInputChange}
                    options={accountTypes}
                    error={errors.accountid}
                    keyName ="accountid"
                    valueName="accountname"
                    rolecheck={check.user.roleid} className={classes.select}
                />
                
                </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} className="w-100 smaller-input">
                    <Controls.Select
                        name="roleid"
                        label="Role"
                        value={values.roleid||''}
                        onChange={handleInputChange}
                        options={role}
                        keyName ="roleid"
                        valueName="role"
                        error={errors.roleid} className={classes.select}
                    />
                                                           
                </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Controls.Input
                            name="emailid"
                            label="Email"
                            value={values.emailid||''}
                             disabled={disable}
                            readOnly = {disable}
                            onChange={handleInputChange}
                            error={errors.emailid} className="smaller-input" />

                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  
                <Controls.Input
                            name="mobilenumber"
                            type="number"
                            label="Mobile"
                            value={values.mobilenumber||''}
                            onChange={handleInputChange}
                            error={errors.mobilenumber} className="smaller-input" />

                </Grid>
                   
                <Divider />
                <DialogActions className="w-100">
                <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controls.Button
                            text="Clear"
                            onClick={()=>{resetForm();cancelbutton();}} className="link-gray"/>
                        <Controls.Button
                            type="submit"
                            text="Submit" className="link-red"/>
                   </Grid>
                </DialogActions>                
            </Grid> 

        </Form>
    )
};

export default UserForm;