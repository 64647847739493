import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function getAllallotment(userid) {

    const currentUser = cookies.get('currentUser');
    return await
        http
            .post(`/events/allocationManagement`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}
export async function getClientapidetails(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/allocationClientManagement`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}
export async function getAllotEventManagement(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/allocationEeventOccurrenceManagement`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getAllotEventManagementall(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/allocationEeventOccurrenceManagement`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export default {getAllotEventManagementall}


