import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import { universaldate } from "../../utils/dateFormat";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    minHeight: "50px",
    maxHeight: "300px",
    overflowY: "auto",
  },
}));

const NestedTable = ({ expanded, eventDetailRows }) => {
  const classes = useStyles();
  const hasSubcategories = eventDetailRows.filter(eventObj => eventObj.hasOwnProperty('event_subcategory'))

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 100,
  });

  const handleNestedTableChangePage = (event, newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
  };

  const handleNestedTableChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPagination(() => ({
      page: 0,
      rowsPerPage: newRowsPerPage,
    }));
  };

  return (
    <div>
      {expanded && (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="p-1">Event Date Time</TableCell>
                  {hasSubcategories.length > 0 && <TableCell className="p-1">Subcategory</TableCell>}
                  <TableCell className="p-1">Id</TableCell>
                  <TableCell className="p-1">On sale date</TableCell>
                  <TableCell className="p-1">End date</TableCell>
                  <TableCell className="p-1">Ticket Cost</TableCell>
                  <TableCell className="p-1">Number of Tickets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventDetailRows
                  .slice(
                    pagination.page * pagination.rowsPerPage,
                    pagination.page * pagination.rowsPerPage +
                      pagination.rowsPerPage
                  )
                  .map((eventDetailRow) => (
                    <TableRow key={eventDetailRow._id}>
                      <TableCell className="p-1" component="td" scope="row">
                        {universaldate(eventDetailRow.eventdatetime)}
                      </TableCell>
                      {hasSubcategories.length > 0 && (
                        <TableCell className="p-1">
                          {eventDetailRow.event_subcategory}
                        </TableCell>
                      )}
                      <TableCell className="p-1">
                        {eventDetailRow._id}
                      </TableCell>
                      <TableCell className="p-1">
                        {universaldate(eventDetailRow.onsaledate)}
                      </TableCell>
                      <TableCell className="p-1">
                        {universaldate(eventDetailRow.enddate)}
                      </TableCell>
                      <TableCell className="p-1">
                        {eventDetailRow.ticketcost}
                      </TableCell>
                      <TableCell className="p-1">
                        {eventDetailRow.numberoftickets}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={eventDetailRows.length}
            page={pagination.page}
            onPageChange={handleNestedTableChangePage}
            rowsPerPage={pagination.rowsPerPage}
            onRowsPerPageChange={handleNestedTableChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 100, 500, 1000]}
          />
        </>
      )}
    </div>
  );
};

export default NestedTable;
