import React from 'react';
import { makeStyles }  from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';

import {
    Menu,
    IconButton as MuiIconButton,
    MenuItem,
    Box,
  } from "@material-ui/core";

 import {
    /*Dehaze as DehazeIcon,
    Notifications as NotificationsIcon,*/
    AccountCircle,
  } from "@material-ui/icons";
import { signOut } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import {
  boot as bootIntercom,
} from "../../pages/dashboard/intercom"

const useStyles = makeStyles((theme) => ({
    menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
      background: '#bf1914',
      width: '100%', 
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  headerLeft: {
    flexGrow: 1,  
    height: '30px', 
    margin: '0', 
    padding: '0 0 0 10px', 
    borderTop: '64px solid #000000', 
    lineHeight: '37px', 
    borderRight: '40px solid transparent',        
  },
  headerRight:{
    width: '55%',
  },
  
}));


const drawerWidth = 250;
const IconButton = styled(MuiIconButton)`
  svg {
    width:  34px;
    height: 34px;    
  }
`;


function Header() {
const cookies = new Cookies();
const classes = useStyles();
const history = useHistory();
const [anchorEl, setAnchorEl] = React.useState(false);
const dispatch = useDispatch();


 
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
 
const logout=()=>{
cookies.remove('ExchangeTicket');
cookies.remove('eventaddedit');
cookies.remove('upselladdedit');
  dispatch(signOut());
  cookies.remove('currentUser');
  history.push('/')
  bootIntercom(true);
}
  return (
    <div className={classes.appBar}>
      <Box display="flex" p={0}>
      <Box display="flex" justifyContent="flex-start" className={classes.headerLeft}>
      </Box>
      <Box display="flex" justifyContent="flex-end" className={classes.headerRight}>
      <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClick} size="medium"
                color="inherit" className="text-white">
                <AccountCircle size="medium" className="text-white icon-medium"/>
              </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>{history.push('/UserProfile');setAnchorEl(null);}}>Profile</MenuItem>
        <MenuItem onClick={()=>{history.push('/ChangePassword');setAnchorEl(null);}}>Change Password</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      </Box>
      </Box>
    </div>
  );
}

export default Header;
