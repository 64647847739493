import React ,{useState,useEffect} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Bar } from "react-chartjs-2";
import SwitchToggle from '../../components/controls/SwitchToggle';
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
Paper, 
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu, 
  MenuItem, 
  Box,
  Grid,
} from "@material-ui/core";
import Cookies from 'universal-cookie';
import {getEventBarChartByAccountId} from '../../services/eventService';
import XLSX from 'xlsx'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { universaldate } from "../../utils/dateFormat";

const Overview = ({ chartRevenue1, chartDate1,eventFullDetails1,visible}) => {
  const cookies = new Cookies();
  const [chartDate,setChartDate] = useState([]);
  const [chartRevenue,setChartRevenue] = useState([]);
  const [chartTicket,setChartTicket] = useState([]);
  const [eventFullDetails,setEventFullDetails] = useState([]);
  const [barStart,setBarStart] = useState({Start:0,End:7});
  const [tickRev,setTickRev] = useState(2);
  const currentUser = cookies.get('currentUser');
  useEffect(()=>{
    if(chartRevenue1.length>0&&chartDate1.length>0&&eventFullDetails1.length>0){
    setChartDate(chartDate1);
    setChartRevenue(chartRevenue1);
    setEventFullDetails(eventFullDetails1);
    }else{
      setChartDate([]);
      setChartRevenue([]);
      setEventFullDetails([]);
    }
  },[chartRevenue1, chartDate1,eventFullDetails1])
const [alignment, setAlignment] = React.useState('left');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
};
  
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

const handleClose = () => {
    setAnchorEl(null);
  };

  const firstDatasetColor = "#cccccc";
  const secondDatasetColor = "#cb3837";

  const data = {
    labels: chartDate,
    datasets: [      
      {
        label: "Revenue",
        backgroundColor: secondDatasetColor,
        borderColor: secondDatasetColor,
        hoverBackgroundColor: secondDatasetColor,
        hoverBorderColor: secondDatasetColor,
        data: chartRevenue,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
      {
        label: "Tickets",
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetColor,
        hoverBackgroundColor: firstDatasetColor,
        hoverBorderColor: firstDatasetColor,
        data: chartTicket,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false,
      boxWidth: 10,
    },
  };

  const handleweek=(start,end)=>{
    setBarStart({Start:start,End:end})
    if(tickRev===1){
    let value=eventFullDetails.slice(start,end);
    let date=[];
        let Ticket=[];
    value.forEach((chart)=>{            
      date.push(chart.eventDate);
      Ticket.push(chart.totalsoldcount)          
  })
  setChartDate(date);
  setChartRevenue([]);
  setChartTicket(Ticket)
    }else{
      let value=eventFullDetails.slice(start,end);
    let date=[];
        let revenue=[];
    value.forEach((chart)=>{            
      date.push(chart.eventDate);
      revenue.push(chart.totalEventCost)        
  })
  setChartDate(date);
  setChartRevenue(revenue);
  setChartTicket([])
    }
  }
  const exportFile = () => {

    const downloadfile = eventFullDetails.map((e)=>{
      const cost = e.currencytype==="USD"?'$':'£'
      return{ 
        "Date":e.eventDate,
        "Total Tickets Sold":e.totalsoldcount,
        "Total Revenue":cost+''+e.totalEventCost
      }
      			 	 		
    })
    
		const ws = XLSX.utils.json_to_sheet(downloadfile);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "UpcomingBargraph.xlsx");
  };
  const BarChart = (e)=>{
    if(e){
      setTickRev(2);
      let value=eventFullDetails.slice(barStart.Start,barStart.End);
      let date=[];
          let revenue=[];
      value.forEach((chart)=>{            
        date.push(chart.eventDate);
        revenue.push(chart.totalEventCost)         
    })
    setChartDate(date);
    setChartRevenue(revenue);
    setChartTicket([])
    
    }else{
      setTickRev(1);
      let value=eventFullDetails.slice(barStart.Start,barStart.End);
      let date=[];
          let Ticket=[];
      value.forEach((chart)=>{            
        date.push(chart.eventDate);
        Ticket.push(chart.totalsoldcount)          
    })
    setChartDate(date);
    setChartRevenue([]);
    setChartTicket(Ticket);
    }
  } 

 
  const chartPDF = ()=> {
    let input = window.document.getElementById("div2PDF");
    html2canvas(input).then(canvas => {
    const img = canvas.toDataURL("image/png");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const pdf = new jsPDF(orientation, unit, size);
    const title = `Next 6 Days - ${currentUser.firstName}${currentUser.lastName} `;
    const marginLeft = 20;
   pdf.text(title,marginLeft,50);
    pdf.addImage(
        img,
        "png",
        100,
        100,
        input.clientWidth,
        input.clientHeight
      );
      pdf.save(`Next6Days${currentUser.firstName}${currentUser.lastName}.pdf`);
    });  
  };

  return (
   <div>
             {chartDate.length>0?(
        <Card mb={3} className="upcoming-events tile-card">
            <Box sx={{ display: 'flex'}}>
                <Box sx={{flexGrow: 1}}>
        <CardHeader title="NEXT 6 DAYS" className="title pb-0"/>
        </Box>
        <Box sx={{p: 1}} >
              <IconButton aria-label="settings" className="v-button">
                <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}/>
              </IconButton>            
            </Box>	
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose} 
          >
        <MenuItem onClick={chartPDF}>Download PDF</MenuItem>
        <MenuItem onClick={exportFile}>Download Excel</MenuItem>
          </Menu> 
          <CardContent p={1} className="tile-title">
		   <Box sx={{ display: 'flex'}}>
                <Box sx={{flexGrow: 1}}>
		  <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment" size="small"
             className="btn-sm-group">
              <ToggleButton value="left" aria-label="left aligned" onClick={()=>{handleweek(0,7)}} >
                DAYS 1-6
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered" onClick={()=>{handleweek(7,14)}}>
              DAYS 7-14
              </ToggleButton>
              
            </ToggleButtonGroup>
			</Box>
			 <Box className="p-2"><SwitchToggle charts={BarChart} />	</Box>
			</Box>
            <Paper>			
              <Bar id="div2PDF" data={data} options={options} style={{height:'250px'}}/>
            </Paper>
        </CardContent> 
        </Card>   
             ):(
          <Card mb={3} className="upcoming-events tile-card">
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>
                <CardHeader title="NEXT 6 DAYS" className="title pb-0" />
              </Box>
            </Box>
            <CardContent p={1} className="tile-title">
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment" size="small"
                    className="btn-sm-group">
                 <ToggleButton value="left" aria-label="left aligned" onClick={() => { handleweek(0, 7) }} >
                 DAYS 1-6
                 </ToggleButton>
                 <ToggleButton value="center" aria-label="centered" onClick={() => { handleweek(7, 14) }}>
                 DAYS 7-14
                 </ToggleButton>
                 </ToggleButtonGroup>
                </Box>
              <Box className="p-2"><SwitchToggle charts={BarChart} />	</Box>
            </Box>
            <Grid className="w-100">{<Alert severity="warning">No events found</Alert>}</Grid>   
            </CardContent>
          </Card>   
             )}              
        </div>  
  ); 
};

export default Overview;
