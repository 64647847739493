import { Button, Grid, makeStyles, Divider, Paper, Container,  InputAdornment, Dialog, IconButton,FormControlLabel,Checkbox,FormControl,FormGroup,Box } from '@material-ui/core';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Snackbar } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import EventOccurrence from './EventOccurrence';
import { createEvent, updateEvent } from '../../services/eventService';
import taxService from '../../services/taxService';
import { eventedit} from '../../redux/actions/authActions';
import { Alert } from "@material-ui/lab";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { dateformat } from '../../utils/dateFormat';
import AddFields from './AddFields';
import Popup from '../../components/Popup'
import moment from 'moment';
import { getEventById } from '../../services/eventService';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
  root: {
    '& > + ': {
      margin: theme.spacing(2),
    },
  },
  select: {
    width: '100% !important',
  },
  pageContentMain: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: '50px',
    [theme.breakpoints.up('xs')]: {
      margin: '0px auto',
      width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '70%',
    },
  },
  pageContent: {
    padding: theme.spacing(2),
  },
}));

const initialFValues = {
  eventname: "",
  applicablefees: "",
  eventdescription: "",
  bio: "",
  ticket_label: "",
  venueid: 0,
  mincount:'',
  maxcount:'',
  fb_pixelid:'',
  mapinventory:false
};

const EventCreation = forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const check = useSelector(state => state.authReducer);
  const cookies = new Cookies();
  const [titlename, setTitlename] = useState(false);
  const currentUser = cookies.get('eventaddedit');
  const currentUser1 = cookies.get('currentUser');
  const [fileImage, setFileImage] = useState(null);
  const [filenameImage, setFilenameImage] = useState("");
  const [filePromo, setFilePromo] = useState(null);
  const [filenamePromo, setFilenamePromo] = useState("");
  const [uploadedFile, setUploadedFile] = useState({fileimage:'',filepromo:''});
  const [newEventOccurrences, setNewEventOccurrences] = useState([]);
  const [venue, setVenue] = useState([]);
  const [dbsend, setDbsend] = useState("");
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = useState(false);
  const [imageFileForPreview, setImageFileForPreview] = useState(null);
  const dispatch = useDispatch();
  const [venuenames, setVenueNames] = useState([]);
  const [checkImage, setCheckImage] = useState(null);
  const [checkPromo, setCheckPromo]=useState(null);
  const [resetEvent,setResetEvent]=useState(false);
  const [currency,setCurrency]=useState('');
  const [imageValue, setImageValue] = useState("");  
  const [promoValue, setPromoValue] = useState("");  
  const [imageName, setImageName] = useState(false);  
  const [isDisabled, setIsDisabled] = useState(false);  
  const [checkedEvent, setCheckedEvent] = useState(false);
  const [mapinventory,setMapinventory]= useState(false);
  const [mapinventoryCheck,setMapinventoryCheck]= useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [teamdetail, setTeamDetail] = useState([]);
  const [addorEdit, setaddorEdit] = useState(null);
  const [existingEvent, setExistingEvent] = useState(null);
  const [eventSubcategories, setEventSubcategories] = useState([])

  const errorHandle=(error)=>{
    const messages = error.response?.data?.message || ((error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message);
     messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
    }

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    const getIds = currentUser?currentUser.eventAccountId:currentUser1.accountid
    const users = {
      id: getIds
    }
    taxService.venueByAccountId(users).then((result) => {
      setVenueNames(result);
      setVenue(result);
      
    }).catch((error) => {
      errorHandle(error);
    });
    return () => {
      cancel = true;
    }
  }, [])

  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("eventname" in fieldValues) {
      temp.eventname = fieldValues.eventname ? "" : "Event Name is required";
    }
    if ("venueid" in fieldValues) {
      temp.venueid = fieldValues.venueid ? "" : "Venue is required";
    }
    if ("eventdescription" in fieldValues) {
      temp.eventdescription = fieldValues.eventdescription ? "" : "Event Description is required";
    }
    if ("bio" in fieldValues) {
      temp.bio = fieldValues.bio ? "" : "Bio is required";
    }
   
    if ("mincount" in fieldValues) {
      if (values.maxcount !== "") {
        if (Number(fieldValues.mincount) > Number(values.maxcount)) {
          temp.mincount = "Minimum ticket count should not be higher than Maximum count";
        } else {
          temp.mincount = ""
        }
      } else {
        temp.mincount = fieldValues.mincount ? "" : "Minimum ticket count is required";
      }
    }
    
    if ("maxcount" in fieldValues) {
      if(Number(fieldValues.maxcount)<Number(values.mincount)){
        temp.maxcount = "Maximum ticket count is lower than Minimum count";
      }else{
      temp.maxcount = fieldValues.maxcount ? "" : "Maximum ticket count is required";
      }
    }
    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");

  };
 
  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
      cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  const formIsValid = (fieldValues = values) => {

    let validFiles = true;
   
    if(fileImage===null||filePromo===null||imageName){
      validFiles=false;
      setUploadedFile({fileimage:fileImage===null?'Please upload Thumbnail image':'',filepromo:filePromo===null?'Please upload Event Promo image':''});
    }
    if (validate() && validFiles) {
      const name = venuenames.find(e=>e.venueid===values.venueid);
      const venueName = name.venuename;
      const currencyValue = name.currencytype;
      const customDateFormat = currentUser1.customdateformat !== undefined ? currentUser1.customdateformat : 'MM-DD-YYYY';
      const getDBDate = (d) => {
        const dt = moment(d, customDateFormat.toUpperCase()).format('MM-DD-YYYY');
        return dt; // convert to default format that DB understands
      }
      const getIds = currentUser ? currentUser.eventAccountId : currentUser1.accountid;
      const arrayValue = (newEventOccurrences.map((e) => {
        return {
          eventdatetime: getDBDate(e.EventDate) + "," + e.EventTime,
          numberoftickets: Number(e.NumberofTickets),
          activestatus: 1,
          ticketcountstatus: Number(e.NumberofTickets),
          onsaledate: getDBDate(e.Onsaledate) + "," + e.OnsaleTime,
          enddate: getDBDate(e.Enddate) + "," + e.EndsaleTime,
          accountid: getIds,
          ticketcost:e.TicketCost,
          onsaleflag:(dbsend !== 'Edit'?1:(e.onsaleflag!==undefined?e.onsaleflag:1)),
          event_subcategory: e.Subcategory
        }
      }))

      if (dbsend !== 'Edit') { //create?

        if (newEventOccurrences.length === 0) {
          newEventOccurrences.length===0&&notification('Please upload Event occurrence excel ','error');
          return;
        }
        let json = {
          eventname: values.eventname,
          eventimage:filenameImage,
          eventdescription: values.eventdescription,
          ticketcost:0,
          applicablefees: Number(values.applicablefees),
          bio: values.bio,
          accountid: getIds,
          ticket_label: values.ticket_label,
          thumbnailimage: filenameImage,
          eventpromo:filenamePromo,
          venuename:venueName,
          venueid:values.venueid,
          eventdetailfile: arrayValue,
          currencytype:currencyValue,
          mincount:Number(values.mincount),
          maxcount:Number(values.maxcount),
          includingtax:(checkedEvent?1:0),
          mapinventory:(mapinventory&&teamdetail.length >0 ? 1 : 0),
          fb_pixelid:values.fb_pixelid,
          event_subcategories: eventSubcategories
        }
        const formData = new FormData();
        formData.append('event', JSON.stringify(json));
        formData.append('teams', JSON.stringify(teamdetail));
        formData.append('file', fileImage);
        formData.append('file', filePromo);
        for (let i = 0; i < eventSubcategories.length; i++) {
          if (eventSubcategories[i].file) {
            const file = eventSubcategories[i].file;
            formData.append('file', file);
          }
        }
        createEvent(formData).then((result) => {
          setOpen(true);
          handleClear();
        }).catch(error => {
          errorHandle(error);
        })
      
      }
      else { //upate existing event
        const formData = new FormData();
        const image = checkImage===values.eventimage?values.eventimage:filenameImage;
        const promo = checkPromo===values.eventpromo?values.eventpromo:filenamePromo;
        
        let json = {
          eventname: values.eventname,
          id:values.id,
          eventimage: image,
          eventdescription: values.eventdescription,
          newEventOccurrences: arrayValue,
          ticketcost:0,
          applicablefees: Number(values.applicablefees),
          bio: values.bio,
          accountid: getIds,
          ticket_label: values.ticket_label,
          thumbnailimage: image,
          eventpromo:promo,
          venuename:venueName,
          venueid:values.venueid,
          currencytype:currencyValue,
          mincount:Number(values.mincount),
          maxcount:Number(values.maxcount),
          includingtax:(checkedEvent?1:0),
          mapinventory:(mapinventory && mapinventoryCheck ? 1 : 0),  
          fb_pixelid:values.fb_pixelid,
          event_subcategories: eventSubcategories
        }
        
        formData.append('event', JSON.stringify(json));
        formData.append('teams', JSON.stringify(teamdetail));
        checkImage!==values.eventimage&&formData.append('file', fileImage);
        checkPromo!==values.eventpromo&&formData.append('file', filePromo);
        for (let i = 0; i < eventSubcategories.length; i++) {
          if (eventSubcategories[i].file) {
            const file = eventSubcategories[i].file;
            formData.append('file', file);
          }
        }
        updateEvent(formData).then((result) => {
          setOpen(true);
          handleEditClear();
        }).catch(error => {
          errorHandle(error);
        })
      }
    }
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = () => {
    formIsValid();
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
    handleClear: handleClear
  }));

const handleClear = () => {
  resetForm();
  setResetEvent(!resetEvent);
  setDbsend('');
  setImageValue('');
  setPromoValue('');
  setFileImage(null);
  setFilePromo(null);
  setNewEventOccurrences([]);
  setUploadedFile({fileimage:'',filepromo:''});
  setIsDisabled(false);
  setCheckedEvent(false);
  setMapinventory(false);
  setTeamDetail([]);
  setImageFileForPreview(null)
  setEventSubcategories([])
}

const handleEditClear = () => {
  resetForm();
  setResetEvent(!resetEvent);
  setImageValue('');
  setPromoValue('');
  setFileImage(null);
  setFilePromo(null);
  setCheckedEvent(false);
  setNewEventOccurrences([]);
  setMapinventory(false);
  setTeamDetail([]);
  setImageFileForPreview(null)
  setEventSubcategories([])
}
  const uploadFilePromo = (e) => {

    setPromoValue(e.target.value)
    let reader = new FileReader(); // HTML5 FileReader API
    let file = e.target.files[0];
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      setFilePromo(file);
      setFilenamePromo(e.target.files[0].name);
      setCheckPromo(file);
      if( filenameImage !== ""){
        if(e.target.files[0].name === filenameImage ){
          notification('Image file should not be same name','error');
          setPromoValue('');  
          setImageName(true);
          setFilePromo(null);
        }else{
          setImageName(false)
          setUploadedFile({filepromo:''});
        }
        }
    }

  }
  const uploadFile = (e) => {
    setImageValue(e.target.value)
    let reader = new FileReader(); // HTML5 FileReader API
    let file = e.target.files[0];
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      setFileImage(file);
      setFilenameImage(e.target.files[0].name);
      setCheckImage(file);
      if( filenamePromo !== ""){
        if(e.target.files[0].name === filenamePromo ){
        notification('Image file should not be same name','error');
        setImageValue('');
        setImageName(true);
        setFileImage(null);
        }else{
          setImageName(false)
          setUploadedFile({fileimage:''});
        }
        } 
    } 
  }
  const setNewEventTicketDetailArray = (eventTicketDetails) => {  
    setNewEventOccurrences(eventTicketDetails);
  }

  useEffect(() => {
    const load = async () => {
      if (check.event && Object.keys(check.event).length !== 0) {
        setFileImage(check.event.eventimage);
        setFilePromo(check.event.eventpromo);
        setCheckImage(check.event.eventimage);
        setCheckPromo(check.event.eventpromo);
        setValues(check.event);
        setCheckedEvent(check.event.includingtax);
        setMapinventory(check.event.mapinventory);
        setDbsend('Edit');
        setCurrency(check.event.currencytype);
        editDisable(check.event.eventdetailfile);
        setEventSubcategories(check.event.event_subcategories)
        const empty = [];
        dispatch(eventedit(empty));
      } else if (props.eventId) {
        // fetch from DB
        const res = await getEventById({
          eventid: props.eventId
        });
        const eventData = res.data.length > 0 ? res.data[0]: null;
        if (!eventData) {
          return;
        }
        setFileImage(eventData.eventimage);
        setFilePromo(eventData.eventpromo);
        setCheckImage(eventData.eventimage);
        setCheckPromo(eventData.eventpromo);
        setValues(eventData);
        setCheckedEvent(eventData.includingtax);
        setMapinventory(eventData.mapinventory);
        setDbsend('Edit');
        setCurrency(eventData.currencytype);
        editDisable(eventData.eventdetailfile);
        setExistingEvent(eventData);
        setEventSubcategories(eventData.event_subcategories)
      }
    }

    load();
  }, [])

  useEffect(()=>{
    symbolValue();
  },[values.venueid])

  const symbolValue=()=>{
    const symbol = [
      {label:"$",value:"USD"},
      {label:"£",value:"GBP"},
  ]  
    const paisa = venuenames.find(e=>e.venueid===values.venueid);
   if(paisa!==undefined){
    const symbolLable = symbol.find(e=>e.value===paisa.currencytype)
    setCurrency(symbolLable.label)
    }
  }
  const onKeyPressHandler = e=> {
    if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
    }
    else{
      e.preventDefault(); 
    }
}

const onKeyPressHandler1 = e=> {
   if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
   }
   else{
     e.preventDefault(); 
   }
}

const editDisable=(eventData)=>{
  let d = new Date();
  let today = (d.getMonth()+1) + "/" + d.getDate() + "/" + d.getFullYear();
  let count=0;
  eventData.forEach((saledate) => {
    const saleStart=(saledate.onsaledate).split(',')[0]
    if(dateformat(saleStart) <= new Date(today)){
      count=count+1;
      }    
})
if(count>0){
  setIsDisabled(true);
}else{
  setIsDisabled(false);
}

 
}

const handleChkEvent=(e)=>{
  setCheckedEvent(e.target.checked)
}



const setUp=()=>{
  if(mapinventory){
    setOpenPopup(true);
    setTitlename(true);
   
  }else{
   notification("Please check Map Inventory","error")
  }
}

const handleAddEventSubategory = (event, index) => {
  const { name, value } = event.target;
  let subcategories = [...eventSubcategories];
  subcategories[index][name] = value;
  setEventSubcategories(subcategories);
}

const addEventSubcategory = () => {
  setEventSubcategories([...eventSubcategories, {
    name: '',
    description: '',
    image: '',
    file: ''
  }]);
}

const removeEventSubcategory = (index) => {
  let data = [...eventSubcategories];
  data.splice(index, 1);
  if (data.length === 0) {
    setEventSubcategories([]);
  } else {
    setEventSubcategories(data);
  }
}

const uploadEventSubcategoryImage = (e, index) => {
  let reader = new FileReader(); // HTML5 FileReader API
  let file = e.target.files[0];
  if (e.target.files && e.target.files[0]) {
    reader.readAsDataURL(file);
    const { name } = e.target;
    let subcategories = [...eventSubcategories];
    subcategories[index][name] = e.target.files[0].name;
    subcategories[index]['file'] = file;
    setEventSubcategories(subcategories)
  } 
}

const handleImagePreview = async (imageFile) => {
  setImageFileForPreview(imageFile);
  setPreview(true);
}

  return (

    <div>
      <Paper className={classes.pageContentMain}>
        <div aria-disabled={isDisabled}
    className={(isDisabled) ? 'is-disabled' : ''}>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <Grid container item spacing={2} md={12} className="w-auto m-0">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controls.Input
              name="eventname"
              label="Event Name"
              value={values.eventname || ''}
              onChange={handleInputChange}
              error={errors.eventname} className="smaller-input" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controls.Select
              name="venueid"
              label="Venue"
              value={values.venueid || ''}
              options={venue}
              onChange={handleInputChange}
              keyName="venueid"
              valueName="venuename"
              input={<input name="venueid" id="venueid" />}
              error={errors.venueid} className={classes.select}
              fullWidth
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controls.Input
              name="eventdescription"
              label="Event Description"
              value={values.eventdescription || ''}
              onChange={handleInputChange}
              error={errors.eventdescription} className="smaller-input" />
          </Grid>

          {eventSubcategories?.map((subcategory, index) => (
            <>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Controls.Input
                  name="name"
                  label="Subcategory Name"
                  value={subcategory.name || ''}
                  onChange={(e) => handleAddEventSubategory(e, index)}
                  className="smaller-input" 
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Controls.Input
                  name="description"
                  label="Subcategory Description"
                  value={subcategory.description || ''}
                  onChange={(e) => handleAddEventSubategory(e, index)}
                  className="smaller-input" 
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={10} className="position-relative">
                <input className="choosefile"
                  type='file'
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => uploadEventSubcategoryImage(e, index)}
                />
                <label className="upload-label">Subcategory Image</label>
            
                {dbsend && !filenameImage ? (
                  <Controls.Button 
                    size="small" 
                    variant="outlined" 
                    className="btn-redoutline" 
                    text="Preview Image" 
                    onClick={() => handleImagePreview(subcategory.image)} />
                  ) : null }   
              </Grid>
              <Box >
                <div className="my-4 ml-1">
                  <DeleteOutlinedIcon 
                    fontSize="medium" 
                    variant="outlined" 
                    className="text-red pointer" 
                    onClick={() => removeEventSubcategory(index)}
                  />                
                </div>
              </Box>
            </>
          ))}

          <Grid item lg={12} md={12} sm={12} xs={10}>
            <Button
              size="medium" 
              variant="outlined" 
              className="btn-redoutline" 
              onClick={() => addEventSubcategory()}
            >
              <AddCircleOutlineIcon color="primary" className="text-red" /><b>&nbsp;Add Subcategory</b>
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="bio"
              label="Bio" maxRows={4}
              value={values.bio || ''}
              onChange={handleInputChange}
              error={errors.bio} className="smaller-input" />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="ticket_label"
              label="Ticket Label" 
              maxRows={4}
              value={values.ticket_label || ''}
              onChange={handleInputChange}
              error={errors.ticket_label} 
              className="smaller-input" 
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="applicablefees"
              type='number'
              label="Booking Fee"
              onKeyPress={onKeyPressHandler1} 
              inputProps={{
                maxLength: 5,
              }}
              value={values.applicablefees || ''}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
              }}
              error={errors.applicablefees} className="smaller-input" />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="mincount"
              //type='number'
              label="Minimum ticket count"
              onKeyPress={onKeyPressHandler} 
              inputProps={{
                maxLength: 3,
              }}
              value={values.mincount || ''}
              onChange={handleInputChange}
              error={errors.mincount} className="smaller-input" />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="maxcount"
              //type='number'
              label="Maximum ticket count"
              onKeyPress={onKeyPressHandler} 
              inputProps={{
                maxLength: 3,
              }}
              value={values.maxcount || ''}
              onChange={handleInputChange}
              error={errors.maxcount} className="smaller-input" />
          </Grid>
          
          <Divider />
          <Grid item lg={6} md={12} sm={12} xs={12} className="position-relative">
            <input className="choosefile"
              type='file'
              name="imageUpload"
              accept=".png, .jpg, .jpeg"
              value={imageValue}
              onChange={uploadFile} />
            <label className="upload-label">Upload Thumbnail Image</label>
            <span style={{color:'red'}}>{uploadedFile.fileimage}</span>
        
        {dbsend&& !filenameImage?<Controls.Button size="small" variant="outlined" className="btn-redoutline" text="Preview Image" onClick={() => handleImagePreview(values.eventimage)} />:null}   
    
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} className="position-relative">           
            <input className="choosefile"
              type='file'
              name="imagePromoUpload"
              accept=".png, .jpg, .jpeg"
              value={promoValue}
              onChange={uploadFilePromo} />
            <label className="upload-label">Upload Event Promo</label>
            <span style={{color:'red'}}>{uploadedFile.filepromo}</span><br/>
      {dbsend &&!filenamePromo?<Controls.Button size="small" variant="outlined" className="btn-redoutline" text="Preview Image" onClick={() => handleImagePreview(values.eventpromo)} />:null}  
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Input
              name="fb_pixelid"
              label="Facebook Pixel Account ID"
              value={values.fb_pixelid || ''}
              onChange={handleInputChange}
              error={""} className="smaller-input" />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
          <FormControl> 
              <FormGroup>
                  <FormControlLabel
                  control={
                   <Checkbox checked={checkedEvent} onChange={handleChkEvent} name="email" />

                  }label="Include tax in ticket and upsell price"
                  />
               </FormGroup>
             </FormControl>
             </Grid>
             <Grid item lg={3} md={6} sm={6} xs={6}>
             <FormControl> 
              <FormGroup>
                  <FormControlLabel
                  control={
                   <Checkbox checked={mapinventory} onChange={(e)=>{setMapinventory(e.target.checked)}} name="email" />

                  }label="Map Inventory"
                  />
               </FormGroup>               
             </FormControl>
             </Grid>
             <Grid item lg={3} md={6} sm={6} xs={6}>
             <Controls.Button text="Setup" className="link-red" onClick={setUp} />
{dbsend==='Edit'&&values.mapinventory?<Box sx={{display: 'flex'}} className="ml-1"><small>Status:Mapped</small></Box>:<Box sx={{display: 'flex'}} className="ml-1"><small>Status:Not yet Mapped</small></Box>}
             </Grid>

        
        
        </Grid>
        </div>
      </Paper>
      <div className={classes.pageContent}>
      <EventOccurrence key={existingEvent ? existingEvent.id : "default"} setNewEventTicketDetailArray={setNewEventTicketDetailArray} newEventOccurrences={newEventOccurrences} reset={resetEvent} name={values.eventname} venueidfrcrt={values.venueid} venueName={venuenames} eventData={existingEvent}/>
      </div>
      <Snackbar
        style={{
          height: "100%",
          backgroundcolor: "#DB7093 "
        }}
        anchororigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        onClose={() => setOpen(true)}
      >
        <Alert color="success" severity="success"
          key={`top,center`}
          anchororigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <p>{dbsend!=='Edit'?
          'Event Created Successfully':'Event Updated Successfully'}</p> <br/>
          <p>
          Are you sure to move to Events screen ?
          </p>
          <Container>
            <div className="pt-3">
              <Controls.Button text="Cancel" color="default" size="medium" className="link-gray"
                onClick={() => {handleClear();setOpen(false);setDbsend('')}} />
              <Controls.Button text="OK" size="medium" className="link-red"
                onClick={() => { history.push('/Events') }} />
            </div>
          </Container>
        </Alert>
       </Snackbar>

     {/* <Snackbar 
       style={{
          height: "100%",
        }}
        anchororigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={preview}
        onClose={() => setPreview(false)}>
          <div className='events__body__image shadow'>
          {imageFileForPreview?<LazyLoadImage src={values.eventimage} alt={values.eventimage}/>:<LazyLoadImage src={values.eventpromo} alt={values.eventpromo}/> }

		</div>
          </Snackbar> */}
          
          <Dialog
        modal={false}
        open={preview}
        onClose={() => setPreview(false)}
      >

        <div className='events__body__image shadow'>
          <IconButton onClick={() => setPreview(false)} className="close-preview"><HighlightOffOutlinedIcon/></IconButton>
          <div className="p-2 h-100">
            <LazyLoadImage src={imageFileForPreview} alt={imageFileForPreview} />
          </div>
        </div>

      </Dialog>
      <Popup
          title={titlename ? "Mapping Table" : "Mapping Table"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
      >
          <AddFields setOpenPopupMapping={setOpenPopup}
            eventid={ values.id} dbsend={dbsend}
            teamdetail={teamdetail}
            setTeamDetail={setTeamDetail}
            addorEdit={addorEdit}
            setaddorEdit={setaddorEdit}
            setMapinventoryCheck={setMapinventoryCheck}
          /> 
      </Popup>

    </div>

  )
});
export default EventCreation;