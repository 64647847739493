import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import useForm from '../../components/useForm';
import orderService from "../../services/orderService";
import Form from '../../components/Form';
import Notification from '../../components/Notification';
import { useHistory } from "react-router-dom";

const initialFValues = {
  onlineUrl: process.env.REACT_APP_PAYMENT_MODAL_LINK || '',
};

const PaymentModalLink = (props) => {
  const { data, cancelButton, setResendEmailurl } = props;
  const history = useHistory();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const url = `?app=ontheStage&reservationId=${data.reservationId}`;
  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };


  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }



  const validate = (fieldValues = values) => {

    const temp = { ...errors };
    if ("onlineUrl" in fieldValues) {
      temp.onlineUrl = fieldValues.onlineUrl ? "" : "Payment Modal Link is required";
    }
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");



  };

  const formIsValid = (fieldValues = values) => {
    if (validate()) {
      const isValid =
        fieldValues.onlineUrl &&
        Object.values(errors).every((x) => x === "");

      return isValid;
    }
  };

  const { values,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      history.push('/');
    }, 2000);
  }

  const handleSubmit = (event) => {
     
    event.preventDefault();
    setResendEmailurl(false);
     if (formIsValid()) {
    const paymentDetails = {
      "paymentUrl": values.onlineUrl + url,
      "thumbnailurl": data.thumbnailurl,
      "endusername": data.endusername,
      "email": data.email,
      "eventid": data.eventid
    }
    
     orderService.sendReserveLink(paymentDetails).then((response)=>{
     notification(response.message, 'success');
        setResendEmailurl(true);
     }).catch((error)=>{
      errorHandle(error);
        setResendEmailurl(true)
     })
     }else{
       notification("Please Provide the PaymentLink");
       setResendEmailurl(true)
     }
  }

  return (
    <Form onSubmit={handleSubmit} className="create-user">
      <Notification notify={notify} setNotify={setNotify} />
      <Grid item lg={15} md={15} sm={15} xs={15}>
        <Controls.Input
          name="onlineUrl"
          label="Payment Modal Link"
          value={values.onlineUrl || ''}
          onChange={handleInputChange}
          className="smaller-input" />
      </Grid>
      
      <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Controls.Button
          text="Cancel"
          onClick={() => { resetForm(); cancelButton(); }}
          className="link-gray" />
        <Controls.Button
          type="submit"
          text="Send"
          className="link-red" />
      </Grid>
    </Form>
  )
}

export default PaymentModalLink
