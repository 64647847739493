import React, { useState, useEffect } from "react";
import {
  IconButton,
  Collapse,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getEventByAccountId } from "../../services/eventService";
import { dateformat } from "../../utils/dateFormat";
import { Alert } from "@material-ui/lab";
import Loading from "../../components/controls/Loading";


const EventExpand = ({
  Start,
  End,
  checked,
  setChecked,
  events,
  setEvents,
  accountid,
  dbsend,
  overall,
  setOverall
}) => {
  const [expanded, setExpanded] = useState({});
  const [checkedAll, setCheckedAll] = useState({});
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [count, setCount] = useState(0);

  const errorHandle = (error) => {
    const messages =
      error.response && !error.response.data.message
        ? error.response.data.error
          ? error.response.data.error
          : error.response.data
        : error.message;
        console.log(messages);
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    const user = {
      accountid: accountid,
    };
    getEventByAccountId(user)
      .then((res) => {
        setShowLoading(false);
        setRecords(res.data);
      })
      .catch((error) => {
        setShowLoading(false);
        errorHandle(error);
      });
    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    if (records.length > 0) {
      if (Start !== null && End !== null) {
        const endDate = records.map((e) => {
          const ends = e.eventdetailfile.filter(
            (x) =>
              dateformat(x.enddate.replace("AM", " AM").replace("PM", " PM")) -
                dateformat(Start) >
                0 &&
              dateformat(x.enddate.replace("AM", " AM").replace("PM", " PM")) -
                dateformat(End) <
                0
          );
          return {
            eventname: e.eventname,
            id: e.id,
            eventdetailfile: ends,
          };
        });
        const endDate1 = endDate.filter((y) => y.eventdetailfile.length !== 0);
        setEvents(endDate1);
      }
    }
  }, [Start, End, records]);

  useEffect(() => {
    if (dbsend === "Edit" && events.length > 0 && checked.length > 0 && count === 0) {
      let selectall = {};     
      events.forEach((event) => {  
        for (let a = 0,add=0; a < checked.length; a++){
        event.eventdetailfile.forEach((eventdetail) => {        
          if (eventdetail._id === checked[a]) {
            add = add + 1;
          }
          if (event.eventdetailfile.length === add) {
            selectall[event.id] = true;
          }
        });
      }
      });
    
    setCheckedAll(selectall);
    setCount(1);

    }
  }, [events, dbsend, checked]);


  const selectAll = (value, record) => {
    setCheckedAll({
      ...checkedAll,
      [record.id]: !checkedAll[record.id],
    });
    if (value) {
      let arrayVal = [];
      const getData = record.eventdetailfile.map((x) => {
        arrayVal.push(x._id)
        return {
        eventid:record.id,
        eventdetailid:x._id
        }
      })
  setOverall([...overall,...getData])
      setChecked([
        ...checked,
        ...new Set(arrayVal),
      ]);
    } else {
      let remove = checked;
      let removeoverall = overall;
       removeoverall= removeoverall.filter((e) => e.eventid!== record.id)
      record.eventdetailfile.forEach((x) => {
      
        remove = remove.filter((e) => e !== x._id);
      });
       setOverall(removeoverall)
      setChecked(remove);
    }
  };

  const handleClick = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const toggleCheck = (e, index,eventid) => {
    let check = e.target.checked;
    var addData = [...checked, index];
    const adds = [...new Set(addData)];
    let selectall = checkedAll
    if (check) {

      setChecked([...new Set(addData)]);
      setOverall([...overall,{eventid:eventid,eventdetailid:index}])
const EventIDS=events.filter(x=>x.id===eventid)
         EventIDS.forEach((event) => {
          for (let a = 0,add=0; a < adds.length; a++) {
          event.eventdetailfile.forEach((eventdetail) => {
            if (eventdetail._id === adds[a]) {
              add = add + 1;
            }
            if (event.eventdetailfile.length === add) {
              selectall[event.id] = true;
            }
          });
        }
        });
      
      setCheckedAll(selectall);
    } else {
      addData = addData.filter(find => find !== index);
      setChecked(addData);
      setOverall(overall.filter(x=>x.eventdetailid!==index))
      setCheckedAll({
        ...checkedAll,
        [eventid]: false,
      });
      
    }
  };

  return (
    <div className="w-100 scrollbar" style={{minHeight:'auto', maxHeight:'250px', overflow: 'auto', minWidth: 'auto', maxWidth: '100%'}}>
      <Table>
        <TableBody>
          {events.length > 0 ?
            events.map((record, index) => (
              <>
                <TableRow className="vv">
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleClick(record.id)}
                    >
                      {expanded[record.id] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    {record.eventname}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => selectAll(e.target.checked, record)}
                          checked={checkedAll[record.id] ? true : false}
                          className="p-0 m-0"
                        />
                      }
                      label="All"
                      className="p-0 mb-0"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan="2" className="text-left">
                    <Collapse
                      in={expanded[record.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {record.eventdetailfile.map((a, index) => (
                        <>
                          <List>
                            <ListItem button key={index} className="py-0">
                              <FormControlLabel
                                label={a.eventdatetime}
                                value={a._id}
                                type="checkbox"
                                name={a._id}
                                checked={checked.includes(a._id)}
                                control={<Checkbox color="primary" />}
                                onClick={(e) => toggleCheck(e, a._id,record.id)} className="p-0 mb-0"
                              />
                            </ListItem>
                          </List>
                        </>
                      ))}
                    </Collapse>
                  </TableCell>
                </TableRow>
                    </> )): (showLoading ? <TableRow> <TableCell className="p-1 text-left w-100" colSpan="2"> <Loading /> </TableCell></TableRow>
                    : <TableRow> <TableCell><Alert severity="warning">No records found</Alert></TableCell></TableRow>)}
        </TableBody>
      </Table>
</div>
  );
};

export default EventExpand;
