import React, { useState } from 'react';
import Controls from '../../components/controls/Controls';
import PartialRefundForm from './PartialRefundForm';
import {partialRefundDetails, partialRefund} from "../../services/orderService";
import { CircularProgress } from '@material-ui/core';


const PartialRefund = ({ setOpenPopup, popupClose, withTeam, teamData, teamMapping, 
    ticketdetails, eventDetails, nooftickets, currencySymbol, paymentcount, individualupselldetail }) => {

    const [data, setData] = useState(null);
    const [oneClick, setOneClick] = useState(false);

    const handleClose = () => {
        setOpenPopup(false);
    };
    const handleContinue = async(values) => {
        let ticketTypeObject = values.paymentteammapping.reduce(function (r, a) {
            r[a.ticketid] = r[a.ticketid] ? { ticketid: a.ticketid, quantity: ++r[a.ticketid].quantity } : { ticketid: a.ticketid, tickettypeid: a.tickettypeid, quantity: 1 };
            return r;
        }, {});

        const ticketTypeGroup = Object.values(ticketTypeObject);

        try {
            const getData = await partialRefundDetails({ ...eventDetails, nooftickets: values.numberoftickets, ticketinfo: ticketTypeGroup, upsellInfo: [...new Set(values.upsellInfo)]  })
            let refundAmount = Number(getData.refundamount);
            if(getData.upsellOnly && getData.upsellOnly?.length > 0){         
                (getData.upsellOnly).forEach(v=>{
                      refundAmount += Number(v.total);
                }) 
            }
            setData({ ...getData, ...values, ticketinfo: ticketTypeGroup, refundamount: refundAmount})
        }catch(err){
            
        }
    };

    const Continue = async() => {
        setOneClick(true);
        const {eventid, occurrenceid, eventticketdetailid, paymentid, accountid, eventname, eventdatetime } = eventDetails;
        const object = {
            accountid: accountid,
            paymentid: paymentid,
            eventticketdetails: [{
                eventid: eventid, eventdetailid: occurrenceid, nooftickets: data.numberoftickets,
                eventname: eventname, eventdatetime: eventdatetime,eventticketdetailid: eventticketdetailid,
                ispartialflag: '1', noofupsell: '', upsellamount: ''
            }],
            paymentTeamMapping: data.paymentteammapping,
            ticketinfo: data.ticketinfo,
            upsellInfo: [...new Set(data.upsellInfo)]
        }
        try{
            const getData = await partialRefund(object);
            popupClose('Refund Updated Successfully','success');
            }catch(err){
            popupClose(err,'error');  
            setOneClick(false);  
            }
    };
    return (
        <>
        {!data ? 
            <PartialRefundForm
                withTeam={withTeam}
                teamData={teamData}
                teamMapping={teamMapping}
                ticketdetails={ticketdetails}
                eventDetails={eventDetails}
                nooftickets={nooftickets}
                handleClose={handleClose}
                handleContinue={handleContinue}
                paymentcount={paymentcount}
                individualupselldetail={individualupselldetail}
            /> : <>
            <strong>Refund Summary</strong>
            <br/><br/>
            {data?.numberoftickets > 1 ? data?.numberoftickets + '- Tickets' :  data?.numberoftickets + '- Ticket' }
            <br/>
            {data?.upsellInfo.length > 0  ? data?.upsellInfo.length  + '- Upsells' : (0 + '- Upsells' )}
            <br/>
            Total Refund <strong>{currencySymbol}{data?.refundamount}</strong>
            <br/><br/>
            <Controls.Button onClick={() => { handleClose() }} size="small" text="Cancel" className="link-gray" />

            <Controls.Button disabled={oneClick} onClick={Continue} color="primary" size="small" 
            endIcon={!oneClick ? null : <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
            text={!oneClick ? "Proceed": "Processing..."} autoFocus className="link-red" />
            </>
        }  
        </>
    )
}
export default PartialRefund;