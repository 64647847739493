import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, 
  TextField,
  DialogContent, DialogActions
} from '@material-ui/core';
import { 
  EditOutlined as EditOutlinedIcon 
} from '@material-ui/icons';
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import { updateOrderEmailAction } from "../../redux/actions/orderActions";
import Notification from '../../components/Notification';
import { Formik } from "formik";
import * as Yup from 'yup';
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

const Alert = styled(MuiAlert)(spacing);

export default function OrderEmailDialog({ localStateOrder }) {
  const dispatch = useDispatch();
  const orderDetails = useSelector(state => state.orderReducer.orderDetails);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  return (
          <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                  <span className="text-gray">Email Address</span>
                  <h6><strong>{orderDetails.eventticketdetailid === localStateOrder.eventticketdetailid ? orderDetails.email : localStateOrder.email}</strong></h6>
                </Grid>
                <Grid item xs={4}>
                  <Controls.ActionButton 
                    className="outlined-primary"
                    onClick={() => setOpenPopup(true)}>
                      <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                  </Controls.ActionButton>
                </Grid>
              </Grid>

              <Popup
                title="Edit Email"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
              >
                <Formik initialValues={{ email: orderDetails.eventticketdetailid === localStateOrder.eventticketdetailid ? orderDetails.email : localStateOrder.email }}
                    validationSchema={Yup.object().shape({ email: Yup.string().email("Must be a valid email").max(255).required("Email is required")})}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                      try {
                        await dispatch(updateOrderEmailAction(localStateOrder.eventticketdetailid, values.email, localStateOrder));
                        setOpenPopup(false);
                        notification("Email updated!", "success");
                        setStatus({ success: true });
                        setSubmitting(true);
                      } catch (error) {
                        const message = error.response?.data?.message || error.response?.data || error.message || "Something went wrong";
                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      status,
                    }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        {errors.submit && (
                          <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                          </Alert>
                        )}
                        <DialogContent style={{ width: 400 }}>
                          <TextField
                            type="text"
                            name="email"
                            label="Email"
                            value={values.email}
                            variant="outlined"
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={3}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Controls.Button 
                            text="Cancel" className="link-gray" 
                            onClick={() => setOpenPopup(false)}>
                          </Controls.Button>
                          <Controls.Button
                            my={3}
                            type="submit"
                            text="Save" 
                            className="link-red"
                            disabled={isSubmitting}>
                          </Controls.Button>
                        </DialogActions>
                      </form>
                    )}
                </Formik>
              </Popup>

              <Notification notify={notify} setNotify={setNotify} />
          </>
  )
}
