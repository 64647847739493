import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import Input from '../../components/controls/Input';
import { Alert } from "@material-ui/lab";
import {
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Box,
  IconButton,
  Toolbar,
  InputAdornment,
  Divider,
  Grid,
} from "@material-ui/core";
import userService from '../../services/userService';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import { Search } from "@material-ui/icons";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from "universal-cookie";
import { eventedit } from "../../redux/actions/authActions";
import { deleteEvent,getEventByAccountId } from '../../services/eventService';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import taxService from "../../services/taxService";
import Loading from '../../components/controls/Loading';
import NestedTable from "./NestedTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pageContent: {
      width: '100%',
      padding: '0px 15px',
      marginBottom: '50px',
      margin: '0px auto',  
    },
  
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
 
  
}));

const drawerWidth = 250;
const headCells = [  
  { id: "actions", label: "Actions", disableSorting: true },  
  { id: "eventname", label: "Event Name"},
  { id: "venuename", label: "Venue" },
  { id: "ticketcost", label: "Ticket Cost" },
  { id: "applicablefees", label: "Booking Fee" },  
  { id: "id", label: "Event ID"},  
];

const EventList = () => {
  const classes = useStyles();
  const history = useHistory();
  // const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [condition, setCondition] = useState(false);
  const [accountid,setAccountID]= useState([]);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const currentUser = cookies.get('currentUser');
  const [events, setEvents] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const [showLoading, setShowLoading] = useState(true);

  const errorHandle=(error)=>{
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
     messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
    }


    useEffect(() => {
      let cancel = false;
      
        userService.getUserAccounts()
          .then(result => {
            if (cancel) return;
            setAccountID([...result]) 
            
           
           })
           .catch((error) => {
            errorHandle(error);
           
           });
           return () => {
            cancel = true;
          }
  
    }, []);

  useEffect(() => {
    let cancel = false;
    if (cancel) return;
    const ids = cookies.get('eventaddedit');
    const getId = ids ? ids.eventAccountId:selectedAccountId;
    setSelectedAccountId(getId)
 
    getEventId(getId);
    
    // const users = {
    //   id: getId
    // }
    // taxService.venueByAccountId(users).then((result) => {
      
    //   setRecords(result)
      
    // }).catch((error) => {
    //   errorHandle(error);
    // });
    return () => {
      cancel = true;
    }


  }, [])

  const getEventId = (e) => {
    let cancel = false;
    cookies.set("eventaddedit", { eventAccountId: e });
    if (cancel) return;
    const user={
      accountid:e
    }
    getEventByAccountId(user).then((res) => {
     setShowLoading(false);
      setEvents(res.data);
    })
    .catch((error) => {
      setShowLoading(false);
      errorHandle(error);
    });
    return () => {
      cancel = true;
    }
  };

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } =
    useTable(events, headCells, filterFn, condition);

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
      cookies.remove("currentUser");
      history.push("/");
    }, 2000);
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    });
  };

  const openInPopup = (item,paise) => {

  const eventData = {
    applicablefees:item.applicablefees,
    bio:item.bio,
    ticket_label: item.ticket_label,
    eventdescription:item.eventdescription,
    eventdetailfile:item.eventdetailfile,
    eventimage:item.eventimage,
    eventname:item.eventname,
    eventpromo:item.eventpromo,
    id:item.id,
    thumbnailimage:item.thumbnailimage,
    ticketcost:item.ticketcost,
    venueid:item.venueid,
    venuename:item.venuename,
    currencytype:paise,
    mincount:item.mincount,
    maxcount:item.maxcount,
    includingtax:item.includingtax===1,
    mapinventory:Number(item.mapinventory)===1,
    fb_pixelid:item.fb_pixelid,
    event_subcategories: item.event_subcategories
  }
    dispatch(eventedit(eventData));
    history.push(`/EventConfiguration/${item.id}`);
  };
  const handleSearch = e => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {
          return items;
        }
        else {
          return items.filter(x => (x.eventname.toLowerCase().includes(searchString.toLowerCase())

          ))
        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }

  };
  const onDelete = (id) => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    
    deleteEvent({eventid:id}).then(res=>{
      notification("Event Deleted Successfully",'success');
   
      getEventId(selectedAccountId);
          }).catch((error)=>{
            errorHandle(error);
          });
  };


  const [expanded, setExpanded] = useState({});

    const handleClick = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id]
    });
  }


  const priceShowed=(record)=>{
    const symbol = record.currencytype==='USD'?'$':'£'
    let lowest = Number(record.eventdetailfile[0].ticketcost);
    let greatest = 0;
if(record.eventdetailfile[0].ticketcost!==undefined&&record.eventdetailfile[0].ticketcost!==0){
    for(let i=0;i<record.eventdetailfile.length;i++){
      if(Number(record.eventdetailfile[i].ticketcost)<lowest){
        lowest=Number(record.eventdetailfile[i].ticketcost)
      }
      if(Number(record.eventdetailfile[i].ticketcost)>greatest){
        greatest=Number(record.eventdetailfile[i].ticketcost)
      }
    }
    if(lowest===greatest){
      return `${symbol}${Number(lowest).toFixed(2)}`
    }else{
      return `${symbol}${Number(lowest).toFixed(2)} - ${symbol}${Number(greatest).toFixed(2)}`
    }
  }else{
    return `${symbol}${Number(record.ticketcost).toFixed(2)}`
  }
  }
  const getAccountidDetails = (e) => {
   
    cookies.set('eventaddedit',{eventAccountId:e.target.value})
    setEvents([]);
    setShowLoading(true);
    setSelectedAccountId(e.target.value)
    getEventId(e.target.value)
    
    
   }
  return (
    <div>
      <Helmet title="Events" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                      <Grid item lg={6} sm={6} xs={6}>
                      <Typography variant="h5" gutterBottom className="breadcrumbs">
                        Events 
                        </Typography>
                      </Grid>
                      <Grid item lg={6} sm={6} xs={6}>
                      <Toolbar className="selectbar singleline">
                      <Box>
                      {currentUser.roleid===1&&accountid.length > 0 ? (
                        <select
                          value={selectedAccountId}
                          onChange={getAccountidDetails}
                          className="select-box mt-0 mb-0"
                        >
                          <option value="0">Select Customer</option>
                          {(accountid).map((v, k) => {
                            if (v.roleid !== 1)
                              return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                            
                          })}
                        </select>
                      ) : (<div></div>)}
            
                      </Box>
                      </Toolbar>
                      </Grid>
            </Grid>
       </Grid>
      <Divider />
        <div className={classes.pageContent}>
        <Toolbar className="searchbar">            
           <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />
                      <Controls.ActionButton
                        onClick={() => {
                           history.push('/EventConfiguration')
                        }} className="outlined-primary">
                        <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
                      </Controls.ActionButton>
          </Toolbar> 
        

          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                  <TableCell className="p-1">
                      <Controls.ActionButton
                        onClick={() => {
                          openInPopup(record,record.currencytype==='USD'?'$':'£');
                        }}
                        className="outlined-primary"
                      >
                        <EditOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="primary"
                        />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure you want to delete the event [ ${record.eventname} ]?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(record.id);
                            },
                          });
                        }}
                      >
                        <DeleteOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="secondary"
                        />
                      </Controls.ActionButton>
                    </TableCell>                    
                    <TableCell className="p-1">
                      {record.eventname}
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleClick(record.id)}
                      >
                        {expanded[record.id] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton> 
                    </TableCell>
                    <TableCell className="p-1">{record.venuename}</TableCell>
                    <TableCell className="p-1">{record.eventdetailfile?.length > 0 ? priceShowed(record) : 0}</TableCell>
                    <TableCell className="p-1">{record.currencytype==='USD'?'$':'£'} {record.applicablefees}</TableCell>
                    <TableCell className="p-1">                  
                       {record.id}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0}}
                      colSpan={6}
                    >
                      <NestedTable
                        expanded={expanded[record.id]}
                        eventDetailRows={record.eventdetailfile}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length && (
                <TableRow style={{ textAlign: "center" }} key={0}> 
                {showLoading?(<TableCell className="p-1 text-left w-100" colSpan="5"><Loading /></TableCell>):                 
                  <TableCell className="p-1 text-left" colSpan="6">{ <Alert severity="warning">No records found</Alert>  }</TableCell> }
                </TableRow>
              )}
            </TableBody>
          </TblContainer>
        </div>

        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </main>
      <Footer />
    </div>
  );
};

export default EventList;
