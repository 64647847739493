import React, { useEffect } from 'react';
import { Box, Button, Typography } from "@material-ui/core";
import ImageIcon from '@material-ui/icons/Image';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const AddLogo = ({getlogo,setlogos,disable}) => {
  const [logo,setLogo]= React.useState();
  const [condition,setCondition]= React.useState(false);
  const [edit,setEdit]= React.useState(true);
  const hiddenFileInput = React.useRef(null);
 
  useEffect(()=>{
    if(setlogos!=null){
    setLogo(setlogos);
    setCondition(true);
    }
  },[setlogos])

  const handleClick = event => {
    hiddenFileInput.current.click();
  }; 

 const addingLogo=(e)=>{
  setEdit(false);
  setCondition(false);
  let reader = new FileReader();
  let file = e.target.files[0];
  if (e.target.files && e.target.files[0]) {
    reader.readAsDataURL(file);
    setLogo(file);
    getlogo(file);
}
}
    return (
      
      <div style={{display:"flex", alignItems: "center"}}>
        <Box className="addLogo">
          {!logo ? <ImageIcon alt="Add Logo" className="logo"/> :
          <LazyLoadImage src={condition&&edit?logo:URL.createObjectURL(logo)}
           alt="Thumb" className="logo"/>}
         </Box> 

        <div className="header-bar">
        <Box display="flex" p={0}> 
          <Box display="flex" justifyContent="flex-start" className="header-left">
          </Box>
          <Box display="flex" justifyContent="flex-end" className="header-right">      
          </Box>
        </Box>
      </div>
         
         {disable ? <Box className="addLogo-right">
            <Typography variant="caption" display="block" gutterBottom>
                Logo
            </Typography>
            <input type="file"
            accept="image/*"
             ref={hiddenFileInput}
             onChange={addingLogo}
             style={{display:'none'}} 
      /> 
            <Button onClick={handleClick} type='file' size="small" className="btn-redoutline">Add Logo</Button>
            <Typography variant="caption" display="block" gutterBottom className="note">
                105px by 45px
            </Typography>        
          </Box> : null }
               


    </div>
      ); 
} 

 
export default AddLogo; 