import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Button from "../../components/controls/Button";
import { Alert } from "@material-ui/lab";
import accounting from "accounting";

export default function StripePaymentForm({clientSecret, currency}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [amount, setAmount] = useState();
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        setAmount(accounting.formatMoney(paymentIntent.amount / 100, currency));
        switch (paymentIntent.status) {
        
          case "succeeded":
            setMessage("Payment succeeded!");
            setPaymentSucceeded(true);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage('');
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      } else {
        setMessage("Something went wrong.");
      }

    });
  }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (result.paymentIntent?.type)
    if (result.error) {
      if (result?.error?.type === "card_error" || result?.error?.type === "validation_error") {
        setMessage(result.error.message);
      } else if (result.error.type === 'invalid_request_error') {
        setMessage(result?.error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }  
    }
    if (result.paymentIntent?.status === 'succeeded') {
      setMessage('Payment Complete! Please Refresh')
      setPaymentSucceeded(true);
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}> 
      {!paymentSucceeded ? <PaymentElement id="payment-element" /> : null}      
      {amount && <Button id="submit" type="submit" size="small" disabled={paymentSucceeded || isLoading || !stripe || !elements} text={isLoading ? 'Please Wait...' : `Pay ${amount}`} className="link-red text-white py-0" />}
      {/* Show any error or success messages */}
      {message && <Alert id="payment-message">{message}</Alert>}
    </form>
  );
}