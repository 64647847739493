import React, { useState, useEffect } from "react";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import Controls from '../../components/controls/Controls';
import { Paper, Box, IconButton, DialogActions, Grid, Typography, Divider, styled } from '@material-ui/core';
import Notification from '../../components/Notification';
import { getPlayereventDetails } from "../../services/mapInventoryService";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';
import { exchangeticket } from "../../redux/actions/authActions";
import Loading from "../../components/controls/Loading";
import "./mapping.scss";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const MapforOrder = ({ mapEvents, setOpenPopup, sameOccurrence }) => {
  const numberOfTicketsToExchange = mapEvents.numberoftickets; // how many tickets the user is going to exchange

  const change = useSelector(state => state.authReducer.exchangeticket);
  const dispatch = useDispatch();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });//open and close model dialog
  const [teamList, setTeamList] = useState([]);//To display team details
  const [selectedSeats, setSelectedSeats] = useState([]);//  the selected seats
  const [maxValue, setMaxValue] = useState(0);//To set number of players in each team
  const [allocations, setAllocations] = useState([]);//Allocation details
  const [originalSelectedSeats ,setOriginalSelectedSeats] = useState([]); // if the exchange is on the same performance date and time
  console.log('mapEvents', mapEvents);
  useEffect(() => {
    console.log('change', change);
    const user = {
      "action_type": "player list",
      "eventdetailid": mapEvents.occurenceid,
      "eventid": mapEvents.eventid
    }

    getPlayereventDetails(user).then((res) => {
      setAllocations(res.allocations)
      let largest = 0;
      let count = 0
      for (let i = 0; i < res.data.length; i++) {
        count = count + Number(res.data[i].noofplayers)
        if (res.data[i].noofplayers > largest) {
          largest = res.data[i].noofplayers
          setMaxValue(res.data[i].noofplayers);
        }
      }
      debugger;
      if (res.allocations.length > 0) {
        for (let k = res.data.length - 1; k >= 0; k--) {
          for (let j = 0; j < res.allocations.length; j++) {
            if (res.data[k].teamid === res.allocations[j].teamid) {
              let count = 0;
              let playerid = res.data[k].playerid ? res.data[k].playerid.toString().split(',') : ""; //Doubt
              let soldplayerid = res.data[k].playerid ? res.data[k].playerid : "";
              if (res.allocations[j].isapi == 1) {
                for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {
                  if (playerid.includes(`${m}`)) {
                    count++
                  } else {
                    if (soldplayerid === "") {
                      soldplayerid = m

                    } else {
                      soldplayerid = soldplayerid + ',' + m
                      res.data[k].playerid = soldplayerid;
                    }
                  }
                }
              } else if (res.allocations[j].isboxoffice === 1) {
                for (let m = res.data[k].noofplayers; m > (Number(res.data[k].noofplayers) - (Number(res.allocations[j].allocationcount) - Number(res.allocations[j].soldcount))) - count; m--) {
                  if (playerid.includes(`${m}`)) {
                    count++
                  } else {
                    if (soldplayerid === "") {
                      soldplayerid = m
                    } else {
                      soldplayerid = soldplayerid + ',' + m
                      res.data[k].playerid = soldplayerid;
                    }
                  }
                }
              }
            }
          }
        }
      }

      setTeamList(res.data)
    }).catch((error) => {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      notification(messages, "error");
    })

  console.log('in use efefect');
  if (change.length && sameOccurrence) {
    console.log('same event, pre selecting esats');
    const arr = [];
    const originalArr = [];
    change[0].ticketinfo.forEach(t => {
      arr.push({ teamid: t.teamid, playerid: t.playerid });
      originalArr.push({ teamid: t.teamid, playerid: t.playerid })
    })    
    console.log('pre selecting seats', arr);
    setSelectedSeats(arr);
    setOriginalSelectedSeats(originalArr)
  }



  }, [])

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const selectPlayer = (checked, p, teamid) => {
    if (checked) { // selecting
      if (selectedSeats.length < numberOfTicketsToExchange) {
        const newSelectedSeats = [...selectedSeats, { teamid: teamid, playerid: p }];
        console.log('sellecting seats after selecting player', newSelectedSeats);
        setSelectedSeats(newSelectedSeats);
      } else {
        notification(`Please select ${numberOfTicketsToExchange} seats`, 'error');
      }
    } else { // unselecting
      const remainingSeats = selectedSeats.filter(x => x.teamid !== teamid || x.playerid !== p);
      console.log('sellecting seats after removing player', remainingSeats);
      setSelectedSeats(remainingSeats);
    }
  }

  const next = () => {
    let arr = [];
    let arrFilter = [];
    if (selectedSeats.length === Number(numberOfTicketsToExchange)) {
      if (allocations.length > 0) {
        teamList.forEach(x => {
          var playerids = '';
          var playerids1 = '';
          var playerids2 = '';
          var playerids3 = '';

          let addallocation = 0;

          let count = 0;
          let count1 = 0;

          // what in the absolute hell is this
          let allocationid1 = null;
          let allocationid2 = null;
          let allocationid3 = null;

          // what in the absolute hell is this
          let objectone = {};
          let objectall = {};
          let objectnull = {};
          let objectnullelse = {}

          const selectedData = selectedSeats.filter(x1 => x1.teamid === x.teamid);

          if (selectedData.length > 0) {



            let countallocation = allocations.filter(n => n.teamid === x.teamid && n.isapi !== 1);

            if (allocations.length > 0 && countallocation.length > 0) {

              countallocation.forEach(z => {
                addallocation++;

                selectedData.forEach(y => {

                  if (y.teamid === z.teamid) {
                    //  if(checkone.channel === "boxoffice"){

                    if (z.isboxoffice === 1 && ((z.allocationcount - z.soldcount) > count)) {
                      if (!playerids1.toString().split(',').includes(`${y.playerid}`)) {
                        playerids2 = '';
                        objectnull = {};
                        if (playerids === '') {
                          playerids = y.playerid;
                          count++;
                          allocationid1 = z.allocationid
                        }
                        else {
                          playerids = playerids + ',' + y.playerid;
                          count++;
                          allocationid1 = z.allocationid
                        }
                        objectone = {
                          "eventdetailid": mapEvents.occurenceid,
                          "eventid": mapEvents.eventid, paymentid: mapEvents.paymentid,
                          "teamid": x.teamid, paymentteammapid: 0,
                          "allocationid": allocationid1, "playerid": playerids
                        }

                      }
                    }
                    else if (z.isall === 1 && ((z.allocationcount - z.soldcount) > count1)) {
                      if (!playerids.toString().split(',').includes(`${y.playerid}`)) {
                        playerids2 = '';
                        objectnull = {};
                        if (playerids1 === '') {
                          playerids1 = y.playerid;
                          count1++;
                          allocationid2 = z.allocationid
                        }
                        else {
                          playerids1 = playerids1 + ',' + y.playerid;
                          count1++;
                          allocationid2 = z.allocationid
                        }

                        objectall = {
                          "eventdetailid": mapEvents.occurenceid,
                          "eventid": mapEvents.eventid, paymentid: mapEvents.paymentid,
                          "teamid": x.teamid, paymentteammapid: 0,
                          "allocationid": allocationid2, playerid: playerids1
                        }

                      }
                    }
                    else {
                      if (!playerids.toString().split(',').includes(`${y.playerid}`) && !playerids1.toString().split(',').includes(`${y.playerid}` && !playerids2.toString().split(',').includes(`${y.playerid}` && countallocation.length === addallocation))) {
                        if (playerids2 === '') {
                          playerids2 = y.playerid;
                          allocationid3 = null
                        }
                        else {
                          playerids2 = playerids2 + ',' + y.playerid;
                          allocationid3 = null
                        }

                        objectnull = {
                          "eventdetailid": mapEvents.occurenceid,
                          "eventid": mapEvents.eventid, paymentid: mapEvents.paymentid,
                          "teamid": x.teamid, paymentteammapid: 0,
                          allocationid: allocationid3, playerid: playerids2
                        }



                      }
                    }


                    // }
                  }
                })

              });
            } else {
              selectedData.forEach(y => {
                if (playerids3 === '') {
                  playerids3 = y.playerid;

                }
                else {

                  playerids3 = playerids3 + ',' + y.playerid

                }
                objectnullelse = {
                  "eventdetailid": mapEvents.occurenceid,
                  "eventid": mapEvents.eventid, paymentid: mapEvents.paymentid,
                  "teamid": x.teamid, paymentteammapid: 0,
                  "allocationid": allocationid1, "playerid": playerids3
                }

              });

            }
          }

          arr.push(objectone, objectall, objectnull, objectnullelse)

        })
        arrFilter = arr.filter(value => Object.keys(value).length !== 0);

      } else {

        teamList.forEach(x => {
          var playerids = '';
          const selectedData = selectedSeats.filter(x1 => x1.teamid === x.teamid);
          if (selectedData.length > 0) {
            selectedData.forEach(y => {
              if (playerids === '') {
                playerids = y.playerid;

              }
              else {
                playerids = playerids + ',' + y.playerid

              }
            });
          }
          if (playerids !== '') {
            arr.push({
              "eventdetailid": mapEvents.occurenceid, paymentteammapid: 0, paymentid: mapEvents.paymentid,
              "eventid": mapEvents.eventid, "teamid": x.teamid, "allocationid": null, "playerid": playerids
            })
          }
        })

        arrFilter = arr.filter((ele, ind) => ind === arr.findIndex(elem => elem.teamid === ele.teamid))
      }
      dispatch(exchangeticket([...change, arrFilter]));
      setOpenPopup(false);
    } else {
      notification(`Please select ${numberOfTicketsToExchange} seats`, 'error');
    }

  }
  return (
    <div className="mapping-widgetpage">
      <Helmet title="Select a Team" />

      <main>

        <Box sx={{ display: 'flex' }} className="page-heading">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="div" className="title">
              Select a team
            </Typography>
          </Box>
        </Box>
        <Divider className="my-2" />
        <Box sx={{ display: 'grid' }}>
          {teamList.length > 0 ? teamList.map((team, key) => {
            const PlayerNumbers = [...Array(Number(maxValue))].map((_, i) => i + 1)

            const soldPlayers = team.playerid ? team.playerid.toString().split(',') : "";

            return (


              <div style={{ width: '100%' }} key={key}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item lg={1} md={1} sm={1} xs={1}>
                      <Box
                        sx={{
                          display: 'flex', justifyContent: 'center'
                        }}
                      >
                        <Item className="playerbox teamname">
                          <Box className="bottom">
                            <Box className="player-name">{team.teamname}</Box>

                          </Box>
                        </Item>
                      </Box>
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                      >
                        {PlayerNumbers.map((playerNumber) => (

                          ((!soldPlayers.includes(`${playerNumber}`) && team.noofplayers >= playerNumber) || originalSelectedSeats.filter((x) => x.teamid === team.teamid && x.playerid === playerNumber).length > 0 ) ?

                            <>


                              {!selectedSeats.filter((x) => x.teamid === team.teamid && x.playerid === playerNumber).length > 0 ?
                                <Item className="playerbox" key={`${team.teamid}-${playerNumber}`}  >
                                  <Box className="top">PLAYER {playerNumber}</Box>
                                  <Box className="bottom"
                                    // selecting a player
                                    onClick={(x) => { selectPlayer(true, playerNumber, team.teamid) }}
                                  >

                                    <Box className="plus-icon"><IconButton className="addplayer"><AddIcon /></IconButton></Box>
                                    <Box className="pointer">Select</Box>

                                  </Box>
                                </Item> :
                                <Item className="playerbox selected" key={`${team.teamid}-${playerNumber}`}>

                                  <Box className="top">PLAYER {playerNumber}</Box>
                                  <Box className="bottom"
                                    // UNselecting a player
                                    onClick={(x) => { selectPlayer(false, playerNumber, team.teamid) }}>


                                    <Box className="plus-icon"><IconButton className="addplayer"><CheckIcon /></IconButton></Box>
                                    <Box className="pointer">Selected</Box>

                                  </Box>

                                </Item>}
                            </> : <Item className="playerbox sold"key={`${team.teamid}-${playerNumber}`} >
                              <Box className="top">PLAYER {playerNumber}</Box>
                              <Box className="bottom"

                              >


                                <Box><IconButton className="addplayer"><LocalMallOutlinedIcon /></IconButton></Box>
                                <Box className="pointer">Sold</Box>
                              </Box>
                            </Item>                            
                        ))
                        }</Box>
                    </Grid>
                  </Grid>

                </Box>

              </div>
            )
          })
            : <Loading />}
        </Box>

        <DialogActions dividers="true" className="w-100">
          <Controls.Button type="submit" size="medium" onClick={next} text="Continue" className="btn-action" />
        </DialogActions>

        <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </main>
    </div>
  );
}

export default MapforOrder;