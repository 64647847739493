import React, { useEffect,useState } from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from "@material-ui/lab";
import { 
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow, 
  TableCell,
  Box,
  Chip,
  IconButton,
  Menu, 
  MenuItem,
  Link,
} from "@material-ui/core";
import XLSX from 'xlsx'
import TopsellPopup from '../dashboard/TopsellPopup';
import Popup from "../../components/Popup";
import Loading from "../../components/controls/Loading";
const TopSellingEvents = ({ eventFullDetails,showLoadingTopsell,id}) => {
const [anchorEl, setAnchorEl] = React.useState(null);
const [eventDetails, setEventDetails] = React.useState([]);
const [openPopup, setOpenPopup] = useState(false);
useEffect(()=>{  
  setEventDetails(eventFullDetails.slice(0,10))
},[eventFullDetails]);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
const showAllTopsell =()=>{
    setOpenPopup(true);  
  }
const handleClose = () => {
    setAnchorEl(null);
  };

  const exportFile = () => {

    const downloadfile = eventFullDetails.map((e)=>{
      const cost = e.currencytype==='USD'?'$':'£'
      return{
        "Date/Time":e.customerdateformat,
        "Event Name":e.eventname,
        "Venue":e.venuename,
        "TicketSold":(e.soldcount +'/'+e.numberoftickets),
        "Revenue":cost+''+e.totalEventCost,
        "Subcategory": e.event_subcategory,
      }
      			 	 		
    })
    
		const ws = XLSX.utils.json_to_sheet(downloadfile);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "TopSellingEvents.xlsx");
	};
  console.log('eventDetailseventDetailseventDetails', eventDetails);
  return (
   <div>
        
        <Card mb={3} className="top-selling tile-card">
          <CardHeader
              title="Top Selling Events"
              action={
                eventDetails.length>0? <IconButton aria-label="settings" className="v-button">
                 <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}/>
                </IconButton>:<div></div>}
              
             className="title pb-0"/>
             <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={exportFile}>Download Excel</MenuItem>
            </Menu> 
            <CardContent p={1} className="tile-title">
            <div className="scrollbar" style={{height:'250px', overflow: 'auto'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell className="center">Ticket Sold</TableCell>
              <TableCell>Revenue</TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>     
          {eventDetails.length>0?(eventDetails.map((events,index)=>(
              <TableRow key={index}>
              <TableCell>{events.eventname}</TableCell>
              <TableCell>{events.customerdateformat.split(',')[0]} <small>{events.eventdatetime.split(',')[1]}</small></TableCell>
              <TableCell>{events.venuename}</TableCell>
              <TableCell className="center"><Box className="chip"><Chip label={events.soldcount +'/'+events.numberoftickets} variant="outlined" size="small"></Chip></Box></TableCell>                
              <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.totalEventCost}</TableCell>
              </TableRow>
          ))):(showLoadingTopsell?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="5"><Loading /></TableCell></TableRow>):(<TableRow><TableCell className="pt-1 p-0" colSpan="5">{<Alert severity="warning">No events found</Alert>}</TableCell></TableRow>))
          }
          </TableBody>
        </Table>
    </div>
          </CardContent>
          </Card>
      {eventDetails.length > 4 ? (
        
        <Link  className="float-right text-red pb-2 pointer" onClick={showAllTopsell}>See More Results</Link>
      ) : (
          <div></div>
        )}
      <Popup title="Show Top Selling Event" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <TopsellPopup eventFullData={eventFullDetails} id={id} />
      </Popup>

        </div>  
  );
};

export default TopSellingEvents;
