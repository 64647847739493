import React, { useEffect,useState } from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from "@material-ui/lab";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow, 
  TableCell,
  IconButton,
  Menu, 
  MenuItem,
  Box,
  Chip,
  Link,
} from "@material-ui/core";
import XLSX from 'xlsx'
import EventPopup from "./EventPopup";
import Popup from "../../components/Popup";
import Loading from "../../components/controls/Loading";
const UpcomingEvents = ({eventFullDetails,showLoadingUpComing,id }) => {
const [anchorEl, setAnchorEl] = React.useState(null);
const [openPopup, setOpenPopup] = useState(false);
const [eventDetails, setEventDetails] = React.useState([]);

useEffect(()=>{
  setEventDetails(eventFullDetails.slice(0,10))
},[eventFullDetails]);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
const showAllEvents =()=>{
    setOpenPopup(true);  
  }
const handleClose = () => {
    setAnchorEl(null);
  };
  const exportFile = () => {

    const downloadfile = eventFullDetails.map((e)=>{
      const cost = e.currencytype==='USD'?'$':'£'
      return{
        "Date/Time":e.customerdateformat,
        "Event Name":e.eventname,
        "Venue":e.venuename,
        "TicketCost":e.ticketcost,
        "Booking Fee":e.applicablefees,
        "TicketSold":e.soldcount,
        "Number Of Tickets":e.numberoftickets,
        "Revenue":cost+''+e.totalEventCost,
        "Subcategory": e.event_subcategory
      }
      			 	 		
    })
    
		const ws = XLSX.utils.json_to_sheet(downloadfile);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "UpcomingEvents.xlsx");
	};

  console.log('eventDetails', eventDetails);
  return (
   <div>
        
        <Card mb={3} className="upcoming-events tile-card">
          <CardHeader
              title="Upcoming Events"
                action={
                eventDetails.length>0? <IconButton aria-label="settings" className="v-button">
                 <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}/>
                </IconButton>:<div></div>}
              
             className="title pb-0"/>
             <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={exportFile}>Download Excel</MenuItem>
            </Menu> 
            <CardContent p={1} className="tile-title">
            <div className="scrollbar" style={{minHeight:'350px', overflow: 'auto'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell className="center">Ticket Cost</TableCell>  
              <TableCell className="center">Booking Fee</TableCell>  
              <TableCell className="center">Ticket Sold</TableCell> 
              <TableCell className="center">Revenue</TableCell>           
            </TableRow>
          </TableHead>
          <TableBody>  
          {eventDetails.length>0?(eventDetails.map((events,index)=>(
             <TableRow key={index}>             
             <TableCell>{events.event_subcategory || events.eventname}</TableCell>
             <TableCell>{events.customerdateformat.split(',')[0]} <small>{events.eventdatetime.split(',')[1]}</small></TableCell>         
             <TableCell>{events.venuename}</TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.ticketcost}</TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.applicablefees}</TableCell>
             <TableCell className="center"><Box className="chip"><Chip label={events.soldcount +'/'+events.numberoftickets} variant="outlined" size="small"></Chip></Box></TableCell>
             <TableCell className="center"><span>{events.currencytype==='USD'?'$':'£'}</span>{events.totalEventCost}</TableCell>
           </TableRow>
          ))):(showLoadingUpComing?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="7"><Loading /></TableCell></TableRow>):(<TableRow><TableCell className="pt-1 p-0" colSpan="7">{<Alert severity="warning">No records found</Alert>}</TableCell></TableRow>))
          }
          </TableBody>
        </Table>
        </div> 
          </CardContent>
          </Card>
      {eventDetails.length > 6 ? (
        <Link className="float-right text-red pb-2 pointer" onClick={showAllEvents}> See More Results </Link>

      ) : (
          <div></div>
        )}
      <Popup title="Show Upcoming Event List" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <EventPopup eventFullData={eventFullDetails} id={id}/>
      </Popup> 
          
        </div>  
  );
};

export default UpcomingEvents;
