import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, Link, TableCell, Toolbar, makeStyles, InputAdornment, Breadcrumbs, Typography, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Loading from '../../components/controls/Loading';
import Popup from "../../components/Popup";
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TaxConfigForm from "../events/TaxConfigForm";
import taxService from '../../services/taxService';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  breadcrumbs: {
    margin: '0px !important',
    padding: '0px 16px 0px 16px !important',
    display: 'flex !important',
    [theme.breakpoints.down("sm")]: {
      padding: '16px 16px 0px 16px !important',
    }
  },
  pageContent: {
    margin: theme.spacing(3),
    marginBottom: '50px', 
    [theme.breakpoints.up('xs')]: {
       margin: '0px auto',
       width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '60%',
    },
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  
  addBar: {
    float: 'right',
    padding: '0px',   
  },
  select: {
    width: '100% !important',
  },

}));

const drawerWidth = 250;


const headCells = [

  { id: "taxcategory", label: " Category" },
  { id: "taxpercentage", label: " Percentage" },
  { id: 'actions', label: 'Actions', disableSorting: true }

];


const TaxConfig = () => {

  const classes = useStyles();

  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [subCategory,setsubCategory]= useState([]);
  const [disable, setDisable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [titlename, setTitlename] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [condition, setCondition] = useState(false);
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
 
 
  
 
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
  if(error && checkParam(Object.keys(error))){
    notification(error, 'error');
   } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
   }
  }

  useEffect(() => {
    let cancel = false;
    const users = {
      action_type: "list"
    }
    taxService.getAllTaxCategory(users).then((result) => {
     
      if (cancel) return;
        setRecords([...result.category])
        setsubCategory([...result.subcategory])
        setShowLoading(false)
      }).catch((error) => {
        setShowLoading(false)
        errorHandle(error); 
      });

      return () => {
        cancel = true;
      }
  }, [])


  

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn, condition);

  const handleSearch = e => {


    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {

          return items;
        }
        else {
          return items.filter(x => (x.taxcategory.toLowerCase().includes(searchString.toLowerCase())

          ))

        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }

  };


  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
     cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const addOrEdit = (user,fields) => {


    if (user.userid === 0) {
      const users = {
        action_type: "edit",
        tax_category:user.taxcategory,
        tax_percentage:user.taxpercentage,
        user_id:currentUser.userid,
        upselltaxmapping:[]
       
      }
      taxService.updateTaxCategory(users).then((res)=>{
        taxService.getAllTaxCategory({action_type: "list"}).then((result) =>{ 
          setRecords([...result.category]);
          setsubCategory([...result.subcategory])
          notification("Tax added successfully ", "success");
          
         
        });
      }).catch((err)=>{
        errorHandle(err);
      });
    } else {
      const users = {
        action_type: "edit",
        tax_category:user.taxcategory,
        tax_percentage:user.taxpercentage,
        user_id:currentUser.userid,
        upselltaxmapping:(fields)
      }
      taxService.updateTaxCategory(users).then(res => {
        taxService.getAllTaxCategory({ action_type: "list"}).then((result) =>{ 
          setRecords([...result.category]);
          setsubCategory([...result.subcategory])
          notification("Tax updated successfully ", "success");
         
        });
      }).catch(error => {
        errorHandle(error);
      });
    }
   
    setRecordForEdit(null)
    setOpenPopup(false)

  };

  const openInPopup = item => {

    setRecordForEdit(item);
    setOpenPopup(true);
    setDisable(true);
    setTitlename(false);
  };

  const onDelete = id => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    const users = {
      action_type: "edit",
      tax_category:id.taxcategory,
      tax_percentage:"",
      user_id:currentUser.userid,
      upselltaxmapping:[""]
    }
    taxService.deleteTaxCategory(users).then(res => {
    taxService.getAllTaxCategory({ action_type: "list"}).then((result) =>{ 
      setRecords([...result.category]);
      notification("Tax deleted successfully ", "success");
    });

    }).catch((error) => {
      errorHandle(error);
    });
  };

  return (
    <div >
      <Helmet title="Tax Management" />
      <main className={classes.content}>
      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
      Tax Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
      <Link underline="hover" color="inherit">
      Admin Tools 
      </Link>
          <Typography color="textPrimary">Tax Management</Typography>
      </Breadcrumbs>
      <Divider className="my-2" />
        <div className={classes.pageContent}> 
        <Toolbar style={{float:'right', padding:'0px'}} className={classes.addBar}>
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />
            
          </Toolbar>         
          <TblContainer className={classes.container}>         
            <TblHead className="p-1" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>
                   
                    <TableCell className="p-1">{record.taxcategory}</TableCell>
                    <TableCell className="p-1">{record.taxpercentage +'%'}</TableCell>


                    <TableCell className="p-1">

                      <Controls.ActionButton
                        onClick={() => { openInPopup(record) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to delete the record of the user [ ${record.taxcategory} ]?`,
                            subTitle: "You can't undo this operation",
                             onConfirm: () => { onDelete(record) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length && <TableRow style={{ textAlign: 'center' }}>
              {showLoading?(<TableCell className="p-1 text-left w-100" colSpan="4"><Loading /></TableCell>):<TableCell className="p-1 text-left" colSpan="4">{ <Alert severity="warning">No records found</Alert> }</TableCell>} 
              </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination />
        </div>
        <Popup
          title={titlename ? "Create Tax Configuration" : "Edit Tax Configuration"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup} maxWidth="xs"
        >
          <TaxConfigForm
            recordForEdit={recordForEdit}
            subCategory={subCategory}
            addOrEdit={addOrEdit}
            disable={disable}
            cancelbutton={cancelbutton}
          />
        </Popup>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default TaxConfig;