import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow,Box, TableCell, Grid, Toolbar, Tabs, Tab, makeStyles, InputAdornment, Typography, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search} from '@material-ui/icons';
import Notification from '../../components/Notification';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";
import { useDispatch } from 'react-redux';
import { Promocode } from '../../redux/actions/authActions';
import Loading from '../../components/controls/Loading';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Controls from '../../components/controls/Controls';
import { promocodeManagement, getPromocodeDetails } from '../../services/promoCodeServices';
import userService from '../../services/userService';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pageContent: {
    width: '100%',
    padding: '0px 15px',
    margin: '0px auto',
  },
  select: {
    width: '100% !important',
  },
  controlSm: {
    fontSize: '12px',
    padding: '0px 20px',
  },
  pageContentMain: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      margin: '0px auto',
      width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '70%',
    },
  },

  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  addBar: {
    float: 'right',
    padding: '0px',
  },

}));

const drawerWidth = 250;
const headCells = [
  { id: "promocode", label: "Code" },
  { id: "discountvalue", label: "Discount Type" },
  { id: "maxredemption", label: "Max Redemptions" },
  { id: "availablecount", label: "Available Redemptions" },
  { id: "isGiftCard", label: "Is Gift Card" },
  { id: 'actions', label: 'Actions', disableSorting: true }
];

const PromoCodeList = () => {

  const classes = useStyles();
  const history = useHistory();
  const [records, setRecords] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [condition, setCondition] = useState(false);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [accountid, setAccountID] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const [value, setValue] = useState(0);


  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error.response.data.message))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }

  useEffect(() => {
    let cancel = false;
    const ids = cookies.get('Promocode');
    const getId = ids ? ids.eventAccountId:selectedAccountId;
    setSelectedAccountId(getId)
    promocode(getId)
    
    if (!cancel) {
      userService.getUserAccounts().then(result => {
      setAccountID([...result]) 
      }).catch((error) => {
        errorHandle(error);
      });  
    }
    return () => {
      cancel = true;
    }
  }, [])

const promocode =(e)=>{
 
  cookies.set('Promocode',{eventAccountId:e})
  const user = {
    "action_type": "promomaster list",
    promo_code: "",
    account_id: e  
    
  }
  getPromocodeDetails(user).then((res) => {

    if (res.data && res.data?.length) {
      let object = {};
      res.data.forEach(element => {
        if (object[element.isGiftCard]) object[element.isGiftCard].push(element)
        else object[element.isGiftCard] = [element]
      });
      setRecords(object);
    }
    setShowLoading(false);
  })
    .catch((error) => {
      setShowLoading(false)
      errorHandle(error);
    })
}

  const getModifiedHeadCells = () => {
    if (value && headCells?.length) {
      const modifiedHeadCells = JSON.parse(JSON.stringify(headCells));//This is for clone headercell without any reference.
      modifiedHeadCells[2] = {
        id: 'giftCardEmail',
        label: 'Gift Card: Email'
      };
      modifiedHeadCells[3] = {
        id: 'giftCardPaymentId',
        label: "Gift Card: Payment ID"
      };
      return modifiedHeadCells;
    }
    return headCells;
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records[value] || [], getModifiedHeadCells() , filterFn, condition, 100);

  const handleSearch = e => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {
          return items;
        }
        else {
          return items.filter(x => (x.promocode.toLowerCase().includes(searchString.toLowerCase()) || 
          (x.isGiftCard && x.giftCardEmail?.toLowerCase().includes(searchString.toLowerCase()))
          ))
        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const openInAdd = () => {   
    history.push(`/PromocodeConfiguration/${value}`);
  }
  const openInEdit = (item) => {
    dispatch(Promocode(item));
    history.push(`/PromocodeConfiguration/${value}/${item.promocode}`);
  };

  const getAccountidDetails = (e) => {
    cookies.set('Promocode',{eventAccountId:e.target.value})
    setRecords({})
    setShowLoading(true)
    promocode(e.target.value)
    setSelectedAccountId(e.target.value)
  }

  const onDelete = (record) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    
    const user = {
      "action_type": "delete",
      account_id: record.accountid,
      promocodedetails: [{ "promocodeid": record.promocodeid, "promocode": record.promocode, "accountid": record.accountid }]
    }
    promocodeManagement(user).then((res) => {
      notification("Promocode Deleted successfully ", "success");
      promocode(selectedAccountId)
    
    })
      .catch((error)=>{
        errorHandle(error);
      })
  };

  return (
    <div >
      <Helmet title="Promo Code" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                    <Grid item lg={6} md={7} sm={7} xs={12}>
      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
      Promo Codes
      </Typography>
      <Box variant="subtitle1" className="welcome-msg">
                        <span style={{ flexGrow: '1', paddingBottom: '12px' }}>Welcome, <strong>{currentUser.firstName} {currentUser.lastName}</strong></span>
                      </Box>
    
      </Grid>
      <Grid item lg={6} md={5} sm={5} xs={12}>
                      <Toolbar className="selectbar">                     
                      <Box>
                        {currentUser.roleid===1 && accountid.length > 0 ? (
                          <select
                            value={selectedAccountId}
                            onChange={getAccountidDetails}
                            className="select-box mt-0 mb-0"
                          >
                            <option value="0">Select Customer</option>
                            {(accountid).map((v, k) => {
                              if (v.roleid !== 1)
                                return <option id={k} value={v.accountid}>{v.accountname}</option>
                              
                            })}
                          </select>
                        ) : null}
                      </Box>
                      </Toolbar>
                      </Grid>
                      </Grid>
                      </Grid>
         
        <Divider className="mb-1" />
        <div className={classes.pageContent}>

            <Grid container item spacing={2} xs={12} sm={12} md={12} lg={12} xl className="pt-4">
             <Grid item lg={6} md={6} sm={12} xs={12}>                     
                <Tabs
                  value={value}
                  className="global-tab"
                  style={{paddingTop: "12px", marginBottom: "0px !important"}}
                  onChange={(e, newValue)=> setValue(newValue)}
                >
                  <Tab label="Promo Code" className="btn" value={0} />
                  <Tab label="Gift Card" className="btn"  value={1} />
                </Tabs>
                </Grid> 
                <Grid item lg={6} md={6} sm={12} xs={12}>      
          <Toolbar className={classes.addBar}>
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />

            <Controls.ActionButton
              onClick={() => openInAdd()} className="outlined-primary">
              <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
            </Controls.ActionButton>
          </Toolbar>
          </Grid>
       </Grid>    

          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>
                    <TableCell >{record.promocode}</TableCell>
                <TableCell >{record.originalAmount}{record.isamountorperc===1?" Flat":"%"} {record.ticketororder===1?"Per Ticket":"Per Order"}</TableCell>
                    <TableCell >{!record.isGiftCard ? record.maxredemption!=null?record.maxredemption:"UNLIMITED" : `Gift Card: ${record.giftCardEmail || ''}`} </TableCell>
                    <TableCell >{!record.isGiftCard ? record.availablecount!=null?record.availablecount:"UNLIMITED" : `Payment Id: ${record.giftCardPaymentId}`} </TableCell>
                    <TableCell >{record.isGiftCard === 1 ? 'Yes': 'No'} </TableCell>
                    <TableCell className="p-1">
                   <Controls.ActionButton
                        onClick={() => { openInEdit(record) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: ` Are you sure to delete the record of the PromoCode   [${record.promocode}] ?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete(record) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length &&
                <TableRow style={{ textAlign: 'center' }}>
                  {showLoading ? (<TableCell className="p-1 text-left w-100" colSpan="5"><Loading /></TableCell>) :
                    <TableCell className="p-1 text-left" colSpan="6">{<Alert severity="warning">No records found</Alert>}</TableCell>}
                </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination rowsPerPageOptions={[10,15,20,100]} />
        </div>

        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default PromoCodeList;