import React, { useEffect, useState , memo} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from "@material-ui/lab";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  Grid,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Box

} from "@material-ui/core";
import Map from '../../components/map';
import { getEventWorldReport } from '../../services/mapService';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import XLSX from 'xlsx';



const MapContainer = (props) => {

  const {AccountId,AccountName}=props;
  const [mapData, setMapData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [range, setRange] = useState([0, 0])
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {

    setAnchorEl(null);
  };

  useEffect(() => {
    
    getReport();
  }, [countryId, stateId])

  const getReport = async () => {
    
    try {
      const revenue = await getEventWorldReport({
        accountid: AccountId,
        countryname: countryId,
        statename: stateId
      });
      setTableData(revenue.data);
     if (revenue && revenue.data.length > 0) {
        const country = {}
       revenue.data.filter(v => v.region !== null).forEach(v => {
         let code = v.region.toUpperCase();
         if (countryId !== 'GB') {
           if (countryId && stateId) {
             code = countryId + '-' + stateId
           } else if (countryId && !stateId) {
             code = countryId + '-' + code
           }
           country[code] = v.nooforder;
           const rev = Object.values(country);
           rev.sort(function (a, b) {
             return a - b
           })
           setRange([rev[0], rev[rev.length - 1]])
           setMapData(country)
         }
          else {
            if (countryId && stateId) {
              code = countryId + '-' + (stateId).toUpperCase()
            } else if (countryId && !stateId) {
              code = countryId + '-' + (code).toUpperCase()
            }
            country[code] = v.nooforder;
            const rev = Object.values(country);
            rev.sort(function (a, b) {
              return a - b
            })
            setRange([rev[0], rev[rev.length - 1]])
            setMapData(country)        
         }

       

       })
        
      }
    } catch (err) {
      setTableData([]);
    }
  }


  const chartPDF = ()=> {
 
    const zoomin=window.document.getElementsByClassName('jvectormap-zoomin');
    
    
      while(zoomin.length > 0){
      zoomin[0].parentNode.removeChild(zoomin[0]);
       
      }
    
      
      const zoomout=window.document.getElementsByClassName('jvectormap-zoomout');
      while(zoomout.length > 0){
      
      zoomout[0].parentNode.removeChild(zoomout[0]);
      
      }
let input = window.document.getElementById("div2PDF");

    html2canvas(input).then(canvas => {
    

      const img = canvas.toDataURL("image/png");
      const unit = "pt";
    const size = [600,600]; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const pdf = new jsPDF(orientation, unit, size);
    const title = "OTS Map Report";
    const marginLeft = 20;
    pdf.setTextColor(0, 0, 0);

    pdf.text(title, marginLeft, 50);
    
      pdf.addImage(
       
        img,
        "png",
        100,
        100,
        400,
       400,
       
      );
      pdf.save("MapReport.pdf");
      

    });

 
  };

  const exportFile = () => {

        const downloadfile = tableData.map((e)=>{
          return{
            "Location":e.location,
            "Orders":e.nooforder,        
            "Tickets":e.nooftickets,
            "Revenue":e.currencysymbol+''+e.eventprice,
            "Order %":e.orderpercentage,
          }
                        
        })
        
        const ws = XLSX.utils.json_to_sheet(downloadfile);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, `MapReport${AccountName}.xlsx`);
      };


  return (
    <div>

      <Card mb={3} className="tile-card">
        <CardHeader
          title="Sales by Location"
          action={
            tableData.length>0? <IconButton aria-label="settings" className="v-button">
              <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick} />
            </IconButton>:<div></div>
          }
          className="card-title mb-0" />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={exportFile}>Download Excel</MenuItem>
          <MenuItem onClick={chartPDF}>Download PDF</MenuItem>
        </Menu>
        <CardContent p={1} className="tile-title">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl className="pt-0">
              <Box className="map-wrapper" id="div2PDF">
                <Map  mapData={mapData} setStateId={setStateId} setCountryId={setCountryId} range={range} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl className="pt-0">
            <div className="scrollbar" style={{height:'350px', overflow: 'auto'}}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell className="center">Orders</TableCell>
                    <TableCell className="center">Tickets</TableCell>
                    <TableCell className="center">Revenue</TableCell>
                    <TableCell className="center">Order %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((v, k) => {
                    return (<TableRow>
                      <TableCell>{v.location}</TableCell>
                      <TableCell className="center">{v.nooforder}</TableCell>
                      <TableCell className="center">{v.nooftickets}</TableCell>
                    <TableCell className="center">{v.currencysymbol}{v.eventprice}</TableCell>
                      <TableCell className="center">{v.orderpercentage}</TableCell>
                    </TableRow>)
                  })}

                  {tableData && tableData.length === 0 ?
                    <TableRow>
                      <TableCell colSpan={5} className="center"><Alert severity="warning">No data found</Alert></TableCell>
                    </TableRow> : null}


                </TableBody>
              </Table>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>


    </div>
  );
};

export default memo(MapContainer);