import { useState } from "react";

const useForm = (initialFValues, validateOnChange=false, validate) => {
    const [values,setValues] = useState(initialFValues);
    const [errors,setErrors] = useState({});

    const handleInputChange = (event)=>{        
        const {name,value} = event.target;
        setValues({
            ...values,
            [name] : value
        });
        if(validateOnChange){
            validate({[name]:value});
        };
    };

    const resetForm = (event)=>{
        setValues(initialFValues);
        setErrors({});
    };

    const setFieldValue = (fieldName, value) => {
        setValues({
            ...values,
            [fieldName]: value
        });
        if(validateOnChange){
            validate({[fieldName]:value});
        };
    }

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        setFieldValue
    }
};

export default useForm;