import { Avatar,Paper,Grid, makeStyles, Typography, Divider ,Card,
  CardContent } from '@material-ui/core';
import React, {  useState } from 'react';
import { useDispatch } from "react-redux";
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { forgotActionPassword } from '../../redux/actions/authActions';




const useStyles = makeStyles((theme) => ({
  root: {
    '& >  + ': {
      marginLeft: theme.spacing(2),
    },    
  },
   wrapper:{
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '20px',
  width: '30%',
  margin: '0px auto',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
 
  },
paper: {
    shadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',     
    borderRadius: '20px',
  },
  card: {
    borderRadius: '20px',   
  },
  link:{


    textTransform: 'capitalize',
    fontWeight: 'normal',
    textDecoration: 'underline',
    fontSize: '14px',
    "&:hover":{


      textDecoration: 'none',
     }
  }, 
  btnRadius: {
      borderRadius: '20px', 
      background: 'linear-gradient(45deg, #fc8b87 5%, #c4201b 90%)',
      minWidth: '130px',
      outline: '0px !important',
      fontWeight: '400 !important', 
      color: '#fffff',
      textTransform: 'capitalize !important',
    }, 
  title:{
      fontFamily: 'OstrichSans-Medium',
      letterSpacing: '1.5px !important',
      color:'#bf1914 !important',
      fontSize: '22px !important',
      textTransform: 'uppercase',
      lineHeight: '70px',
      fontWeight: '600',
    },  
}));



const initialFValues = {
  email:"",
 
  
}; 

const ForgotPassword = (props) =>{
 

  const classes = useStyles();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
  const dispatch = useDispatch();
  const history = useHistory();
 

  const validate = (fieldValues = values)=>{
    
      const temp = {...errors};
      if("email" in fieldValues){
          if(values.email){
          temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? "": "Enter a registered Email address";
          }else{
              temp.email=fieldValues.email?"":"Email is required"
          }
      }
    
     
   
     
      setErrors({...temp});
      return Object.values(errors).every((x) => x === "");


  };
  const formIsValid = (fieldValues = values) => {
      if( validate()) {
              const isValid =
              fieldValues.email &&
             
              
              Object.values(errors).every((x) => x === "");
         
             return isValid;
              }
           };

  const {values,
         errors,
         setErrors,
         handleInputChange,
         resetForm } = useForm(initialFValues,true,validate);
       

         
const handleSubmit = async(event)=> {
          event.preventDefault();
          if (formIsValid()) {
              
              try {

                  await dispatch(

                      forgotActionPassword({ email: values.email })

                  );
                  resetForm();
                  const messages = "Password link sent to the registered email address";
                  notification(messages,'success');  
              } catch (error) {
                resetForm();
                  const messages = (error.response) && !error.response.data.message ? error.response.data:error.message;
                  notification(messages,'error'); 
              }

          }
      };
  
const notification = (message,type)=>{
          setNotify({
            isOpen: true,
            message: message,
            type: type
          })
        } 
     
             
     
  return(
      <div className="login-bg">
             <Helmet title="Sign In" />

          <div display="flex" justifycontent="center" className="w-100">
           <Avatar alt="OTS Logo"  onClick={()=>history.push('/')} src="/static/img/login-logo.png" variant="rounded" className="loginLogo"/> 
           <div className="loginTop" variant="rounded">&nbsp;</div>
           <Paper className={classes.paper}>
                          <Card className={classes.card}>
                          <CardContent>
                          <Typography value="title" variant="h5" className={classes.title}>
                         FORGOT PASSWORD
                          </Typography>
      
     <Form onSubmit={handleSubmit} className="create-user">
   <Notification
        notify={notify}
        setNotify={setNotify}
    />
          <Grid container item spacing={2} width="100">
          <Grid item lg={12} sm={12} xs={12}>
              
          <Controls.Input
                          name="email"
                          label="Email Address"
                          value={values.email||''}
                          
                          onChange={handleInputChange}
                          error={errors.email} className="smaller-input" />
          </Grid>
         
          <Divider />
          <Grid item lg={12} sm={12} xs={12}>
           <Controls.Button
                          type="submit"
                          text="send" size="medium" className="link-red"/>      
                 <Controls.Button
                          type="submit"
                          text="cancel" size="medium"
                          onClick={() => history.push('/')} className="link-gray"/>      
            </Grid> 
             </Grid> 

      </Form>
      

</CardContent>
</Card>
</Paper>
</div>          
</div>
  )
};

export default ForgotPassword