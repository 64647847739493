import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

export async function getTicketTypeByAccountId(data) {  
    const currentUser = cookies.get('currentUser');  
    return await
      http
      .post(`/tickettype/getTicketTypeByAccountId`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
        .then((response) => {                    
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  export async function createTicketType(data) {  
    const currentUser = cookies.get('currentUser');  
    return await
      http
      .post(`/tickettype/createTicketType`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
        .then((response) => {                    
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  
  export async function updateTicketType(data) {  
    const currentUser = cookies.get('currentUser');  
    return await
      http
      .post(`/tickettype/updateTicketType`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
        .then((response) => {                    
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }
  
  export async function deleteTicketType(data) {  
    const currentUser = cookies.get('currentUser');  
    return await
      http
      .post(`/tickettype/deleteTicketType`, data, {
        headers: {
          'Authorization': currentUser ? `Bearer ${currentUser.token}` : ``,   
        }
      })
        .then((response) => {                    
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
  }