import Cookies from 'universal-cookie';
import Moment from 'moment';

const cookies = new Cookies();

export function dateformat(dateString) {
let dateConvert = new Date(dateString.replace(/-/g,'/'));
return dateConvert;
}

    export function universaldate(dateString) {
        const currentUser = cookies.get('currentUser');
        if(currentUser){
        try{
        const formats = (currentUser.customdateformat).toUpperCase();
        const date = dateString.split(',')[0] ? dateString.split(',')[0].replace(/-/g,'/') : dateString.replace(/-/g,'/');
        const time = dateString.split(',')[1] ? ',' + dateString.split(',')[1] : '';
        const FinalValue = `${Moment(date).format(formats)}`
       return (FinalValue!=='Invalid date' ? `${FinalValue}${time}` : dateString);
        }catch{
               return dateString;
        }
        }else{
              return dateString;
        }
  }

  function isToday(date) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
  
    return (date >= today && date < tomorrow) || date > tomorrow;
  }

  export function timeValidation(datetime) {
      const exchangeDate = datetime.replace('AM',' AM').replace('PM',' PM')
         if(isToday(dateformat(exchangeDate))){
         return true;
       }else{
         return false;
       }
    }  