import {
    Grid, DialogActions, Divider, Box, MenuItem, Select, FormControl, FormControlLabel, Switch, InputLabel, Checkbox, Typography,
    Link,
    Breadcrumbs,
    makeStyles,
    Paper,
    InputAdornment
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { getEventByAccountId, getUpsellByEventId } from '../../services/eventService';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import locale from 'date-fns/locale/en-GB';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EventsFilter from "../../components/EventsFilter";
import { eventedit } from '../../redux/actions/authActions';
import { createTicketType, updateTicketType } from '../../services/ticketTypeService';

if (locale && locale.options) {
    locale.options.weekStartsOn = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    content: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    pageContentMain: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        [theme.breakpoints.up('xs')]: {
            margin: '0px auto',
            width: ' 89%',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '0px auto',
            width: '90%',
        },
        [theme.breakpoints.up('md')]: {
            margin: '0px auto',
            width: '93%',

        },
        [theme.breakpoints.up('lg')]: {
            margin: '0px auto',
            width: '80%',
        },
    },

    drawerPaper: {
        width: drawerWidth,
    },
    Toolbar: {
        width: '50% !important',
        padding: '0px',
        [theme.breakpoints.down("sm")]: {
            width: '100% !important',
        }
    },
}));
const drawerWidth = 250;

const initialFValues = {
    action_type: "add",
    name: "",
    ticketcount: 1,
    description: "",
    event_id: "",
    matching_rule: null,
    price_adjustment_type: "",
    adjustment_amount: 0,
    per_performance: 0,
    maximum_ticket_per_event: 0,
    until_time: "00:00:00",
    all_ocurrences: true,
    upsells: [0],
    selectedTime: new Date(),
    day: 8,
    activestatus: true,
    occurrences: [],
    boxofficeonly: false,
    excludeBookingFee: false,
    startDate: null,
    endDate: null,
};

const TicketTypeForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { accountId } = useParams();
    const classes = useStyles();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [upsells, setUpsells] = useState([]);
    const check = useSelector(state => state.authReducer);
    const cookies = new Cookies();
    const [event, setEvent] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState("USD");
    //const [eventSelected, setEventSelected] = useState([]);



    const checkParam = (reqParams, param_key) => {
        return (reqParams.includes(param_key));
    };

    const errorHandle = (error) => {
        if (!error && !checkParam(Object.keys(error))) {
            notification(error, 'error');
        } else {
            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
        }
    }


    const validate = (fieldValues = values) => {
        const temp = { ...errors };
        if ("name" in fieldValues) {
            temp.name = fieldValues.name ? "" : "Name is required";
        }
        if ("ticketcount" in fieldValues) {
            temp.ticketcount = fieldValues.ticketcount ? "" : "Minimum Tickets in cart is required";
        }

        if ("description" in fieldValues) {
            temp.description = fieldValues.description ? "" : "Description is required";
        }

        if ("event_id" in fieldValues) {
            temp.event_id = fieldValues.event_id ? "" : "Event name is required";
        }

        if (!values.all_ocurrences) {
            const matchingValues = !(fieldValues.matching_rule === null || fieldValues.matching_rule?.days_of_week?.length === 0);
            temp.matching_rule = matchingValues ? "" : "Matching rule is required if All Performances is set to false";
        } else {
            temp.matching_rule = ""
        }

        if ("until_time" in fieldValues) {
            if (fieldValues.until_time) {
                const checkTimeFormat = fieldValues.until_time.toString().split(':');
                if (checkTimeFormat.length !== 3) {
                    temp.until_time = "Incorrect time format, Please provide valid time format(i.e HH:MM:SS)";
                } else if (checkTimeFormat[0].toString().length !== 2 || Number(checkTimeFormat[0]) > 24) {
                    temp.until_time = "Incorrect time, please provide valid time.";
                } else if (checkTimeFormat[1].toString().length !== 2 || Number(checkTimeFormat[1]) > 59) {
                    temp.until_time = "Incorrect time, Please Provide Minutes  from 00 to 59.";
                } else if (checkTimeFormat[2].toString().length !== 2 || Number(checkTimeFormat[2]) > 59) {
                    temp.until_time = "Incorrect time, Please Provide Seconds  from 00 to 59.";
                } else if (Number(checkTimeFormat[0]) === 24 && (Number(checkTimeFormat[1]) > 0 || Number(checkTimeFormat[2]) > 0)) {
                    temp.until_time = "Incorrect time, 24hrs time (Minutes and Seconds) cannot be exist more than 00";
                }
                else {
                    temp.until_time = ""
                }
            } else {
                temp.until_time = ""
            }
        }
        if ("adjustment_amount" in fieldValues) {
            if (fieldValues.price_adjustment_type) {
                temp.adjustment_amount = fieldValues.adjustment_amount !== "" ? "" : "Price/Percentage is required";
            }
        }
        if ("per_performance" in fieldValues) {
                temp.per_performance = fieldValues.per_performance !== "" ? "" : "Please provide the value";
        }
        if ("maximum_ticket_per_event" in fieldValues) {
            if (values.per_performance !== "" && fieldValues.maximum_ticket_per_event === "") {
                temp.maximum_ticket_per_event = "Please provide the value";
            } else if (values.per_performance  !== "" && Number(fieldValues.maximum_ticket_per_event) !== 0) {
                temp.maximum_ticket_per_event = Number(values.per_performance) <= Number(fieldValues.maximum_ticket_per_event) ? "" : "Max Limit for Per performance should be greater than Max Tickets per events";
            } else {
                temp.maximum_ticket_per_event = ""
            }
        }
        if (fieldValues.startDate && fieldValues.endDate) {
            temp.endDate = moment(fieldValues.endDate).isSameOrAfter(moment(fieldValues.startDate)) ? "" : "End date must be after the start date";
        }

        setErrors({ ...temp });
        return Object.values(temp).every((x) => x === "");

    };

    useEffect(() => {
        const users = {
            accountid: accountId
        }
        getEventByAccountId(users).then((result) => {

            if (result.data != null) {
                setEvent(result.data)

            }
        }).catch((error) => {

            errorHandle(error);
        });

    }, []);


    const { values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm } = useForm(initialFValues, true, validate);


    useEffect(() => {
        //const selectedEvent = event.filter(x => x.id === values.event_id);
        //setEventSelected(selectedEvent);
        // if(selectedEvent.length > 0 && values.action_type === "add"){
        //     setValues({
        //         ...values,
        //         "occurrences": selectedEvent[0].eventdetailfile.map(x=> x._id)
        //     });
        // }
        if (event.length > 0 && values.event_id) {
            const event_Currency = event.find(x=>x.id === values.event_id);
            if(event_Currency){
                setCurrencySymbol(event_Currency.currencytype);
            }
            getUpsellByEventId({ eventid: values.event_id, status: "all", channel: "boxoffice" }).then((res) => {
                const filteredUpgrade= res.filter(x => x.upselltaxcategoryid === 8);
                setUpsells(filteredUpgrade);  
            })
                .catch((error) => {
                    errorHandle(error);
                });
        }
    }, [values.event_id, event.length]);


    const handleSubmit = async (events) => {
        events.preventDefault();
        if (validate()) {
            if (values.until_time === "") {
                values.until_time = "00:00:00";
            }
            values.venueid = event?.find(x => x.id === values.event_id)?.venueid;
            values.upsells = values.upsells.filter(x => x !== 0);
            try {
                if (values.action_type === "add") {
                    const res = await createTicketType(values)
                    notification(res.message, "success");
                } else {
                    const res = await updateTicketType(values)
                    notification(res.message, "success");
                }
                resetForm();
                setTimeout(() => {
                history.push('/TicketTypes');                  
                }, 500);
            } catch (error) {
                errorHandle(error);
            }
        }
    };

    const notification = (message, type) => {
        setNotify({
            isOpen: true,
            message: message,
            type: type
        })
    }

    const timeOutSet = () => {
        setTimeout(() => {
            cookies.remove('currentUser'); history.push('/');
        }, 1000);
    }

    useEffect(() => {
        let cancel = false;
        if (check.event.length !== 0) {
            const ticketType = check.event.ticket_types;
            ticketType.action_type = "edit";
            ticketType.ticketTypeid = ticketType._id;
            ticketType.upsells = ticketType.upsells.length > 0 ? ticketType.upsells : [0];
            setValues(ticketType);
            const empty = [];
            dispatch(eventedit(empty));
        }
        return () => {
            cancel = true;
        }
    }, []);


    const onKeyPressHandler = e => {
        if (e.charCode !== 101 || e.charCode === 46) {
        }
        else {
            e.preventDefault();
        }
    }

    const onKeyPressHandler1 = e => {
        if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101)) {
        }
        else {
            e.preventDefault();
        }
    }

    const onKeyPressHandlerforTime = e => {
        if (!(e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101 && e.target.value?.length <= 7)) {
            e.preventDefault();
        }
    }

    const handleInputforTime = event => {
        let timeValues = event.target.value;
        if ((timeValues?.length === 2 || timeValues?.length === 5)) {
            timeValues = timeValues.toString() + ":";
        }
        //collapse double colons
        timeValues = timeValues?.replace(/:+/g, ":");
        setValues({ ...values, "until_time": timeValues });
        validate({ "until_time": timeValues });
    }

    const handleOnEventFilterChange = e => {
        // e is formatted data as event_id, days_of_week, day_times
        setValues({
            ...values,
            "matching_rule": e
        });
        validate({ "matching_rule": e });
    };

    const handleFormChange = (event, index) => {
        const { name, value } = event.target;
        let data = [...values.upsells];
        data[index] = value;
        setValues({
            ...values,
            [name]: data
        });
    }

    const removeFields = (index) => {
        let data = [...values.upsells];
        data.splice(index, 1);
        if (data.length > 0)
            setValues({
                ...values,
                "upsells": data
            });
        else
            setValues({
                ...values,
                "upsells": [0]
            });


    }

    const DateCalender = ({ name, label, value }) => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                    name={name}
                    label={label}
                    format="dd-MMM-yyyy"
                    inputVariant="outlined"
                    value={value || null}
                    {...(errors[name] && { error: true, helperText: errors[name] })}
                    clearable
                    onChange={(date) => {
                        if (date) {
                        setValues({
                            ...values,
                            [name]: moment(date).format("YYYY-MM-DD")
                        });
                    } else {
                        setValues({
                            ...values,
                            [name]: null
                        });
                    }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Event />
                            </InputAdornment>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>
        );
    }

    return (
        <>
            <Helmet title="TicketType Configuration" />
            <main className={classes.content}>
                <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>
                        <Grid item lg={6} md={7} sm={7} xs={12}>
                            <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                                TicketType Configuration
                       </Typography>
                            <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
                                <Link component={NavLink} exact to="/TicketTypes" underline="hover" color="inherit">
                                    Ticket Types
                         </Link>
                                {values.action_type !== "add" ? <Typography color="textPrimary">Edit Ticket Type</Typography> : <Typography color="textPrimary">Create Ticket Type</Typography>}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className="mb-3" />
                <Paper className={classes.pageContentMain}>
                    <Form onSubmit={handleSubmit} className="create-user">
                        <Notification
                            notify={notify}
                            setNotify={setNotify}
                        />
                        <Grid container item spacing={2} md={12}>

                            <Grid item lg={6} md={6} sm={6} xs={12}>

                                <Controls.Input
                                    name="name"
                                    label="Name"
                                    value={values.name || ''}
                                    onChange={handleInputChange}
                                    error={errors.name} className="smaller-input" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Input
                                    name="ticketcount"
                                    label="Minimum Tickets in cart"
                                    type="number"
                                    inputProps={{ min: 1 }}
                                    onKeyPress={onKeyPressHandler}
                                    value={values.ticketcount || ''}
                                    onChange={handleInputChange}
                                    error={errors.ticketcount} className="smaller-input" />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Controls.Input
                                    multiline
                                    minRows={2}
                                    name="description"
                                    label="Description"
                                    value={values.description || ''}
                                    onChange={handleInputChange}
                                    error={errors.description} className="smaller-input" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Select
                                    name="event_id"
                                    label="Event"
                                    value={values.event_id || ''}
                                    options={event}
                                    onChange={handleInputChange}
                                    readOnly={values.action_type !== "add"}
                                    keyName="id"
                                    valueName="eventname" size="small"
                                    input={<input name="event_id" id="event_id" />}
                                    error={errors.event_id} className="control"
                                />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Box className="decimal ticket-allotment">
                                    {values.upsells.map((upsell, index) => {
                                        return (
                                            <Grid container spacing={2} >
                                                <Grid item lg={11} md={12} sm={12} xs={12}>
                                                    <FormControl
                                                        variant="outlined"
                                                        size="medium"
                                                    >
                                                        <InputLabel>{"Upsell"}</InputLabel>
                                                        <Select
                                                            label="Upsell"
                                                            value={upsell}
                                                            name="upsells"
                                                            onChange={e => handleFormChange(e, index)}
                                                            className={classes.select}
                                                        >{
                                                                upsells.map(
                                                                    (value, i) => (<MenuItem key={i} style={{ display: values.upsells.find(x => x === value.upsellid ) ? "none" : "" }} value={value.upsellid}>{value.upsellname} - {(currencySymbol === "USD" ? "$" : "£") + Number(value.cost)}</MenuItem>)
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Box className="my-4 ml-1" >
                                                    <DeleteOutlinedIcon fontSize="medium" variant="outlined" className="text-red pointer" onClick={() => removeFields(index)} />
                                                </Box>
                                                {/* <Grid item lg={12} md={12} sm={12} xs={12}>

                                                    {(values.upsells.length - 1 === index) ? <Button size="medium" variant="outlined" className="btn-redoutline" onClick={() => addFields(index)}>
                                                        <AddCircleOutlineIcon color="primary" className="text-red" /><b>&nbsp;Add Upsells</b></Button> : null}

                                                </Grid> */}
                                            </Grid>
                                        );
                                    })}
                                </Box>

                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12} className="w-100 smaller-input">
                                <Controls.Select
                                    name="price_adjustment_type"
                                    label="Price Adjustment"
                                    disabled={values.event_id ? false : true}
                                    value={values.price_adjustment_type || ''}
                                    options={[//Please Change event.model (String to Number)
                                        { id: "fixed", type: "Fixed Amount" },
                                        { id: "percentage", type: "Percentage" },
                                        { id: "flat", type: "Flat" }
                                    ]}
                                    keyName="id"
                                    valueName="type"
                                    onChange={handleInputChange}
                                    error={errors.price_adjustment_type} className="smaller-input" />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Input
                                    name="adjustment_amount"
                                    label="Price/Percentage"
                                    disabled={(values.event_id && values.price_adjustment_type) ? false : true}
                                    type="number"
                                    onKeyPress={onKeyPressHandler}
                                    value={values.adjustment_amount.toString()}
                                    onChange={handleInputChange}
                                    error={errors.adjustment_amount} className="smaller-input" />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Input
                                    name="per_performance"
                                    label="Max Limit Per Performance"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    onKeyPress={onKeyPressHandler1}
                                    disabled={values.event_id ? false : true}
                                    value={values.per_performance.toString()}
                                    onChange={handleInputChange}
                                    error={errors.per_performance} className="smaller-input" />

                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Input
                                    name="maximum_ticket_per_event"
                                    label="Max Tickets Per Event"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    onKeyPress={onKeyPressHandler1}
                                    disabled={values.event_id ? false : true}
                                    value={values.maximum_ticket_per_event.toString()}
                                    onChange={handleInputChange}
                                    error={errors.maximum_ticket_per_event} className="smaller-input" />

                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <br />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.all_ocurrences}
                                            onChange={(newValue, checkedValue) => {
                                                setValues({
                                                    ...values,
                                                    "all_ocurrences": checkedValue,
                                                });
                                            }}
                                            value={values.all_ocurrences}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label={"All Performance"}
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Controls.Input
                                    name="until_time"
                                    label="Sell Until Time"
                                    disabled={values.event_id ? false : true}
                                    value={values.until_time || ''}
                                    onKeyPress={onKeyPressHandlerforTime}
                                    onChange={handleInputforTime}
                                    error={errors.until_time} className="smaller-input" />

                            </Grid>
                            {!values.all_ocurrences && values.event_id ? <>
                             <Grid item lg={12} md={12} sm={12} xs={12}>
                                <EventsFilter occurrences={[]} accountid={accountId} event_id={values.event_id} days={values.matching_rule ? values.matching_rule.days_of_week : []} dayTimes={values.matching_rule ? values.matching_rule.day_times : []} onChange={(e) => handleOnEventFilterChange(e)} useAdditionalTimeslots={true} />
                                <p style={{ color: "red" }}>{!values.matching_rule || errors?.matching_rule ? errors?.matching_rule : ""}</p>
                            </Grid> 
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                            <DateCalender name="startDate" label="From Performance Date" value={values.startDate}/>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                            <DateCalender name="endDate" label="To Performance Date" value={values.endDate}/>
                            </Grid>

                            </>
                            : null}

                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <FormControlLabel
                                    control={<Checkbox checked={values.boxofficeonly}
                                        onClick={(newValue) => {
                                            setValues({
                                                ...values,
                                                "boxofficeonly": newValue.target.checked
                                            });
                                        }}
                                        name="boxofficeonly" />} label="Box Office Only" />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <FormControlLabel
                                    control={<Checkbox checked={values.excludeBookingFee}
                                        onClick={(newValue) => {
                                            setValues({
                                                ...values,
                                                "excludeBookingFee": newValue.target.checked
                                            });
                                        }}
                                        name="excludeBookingFee" />} label="Exclude Booking Fee" />
                            </Grid>
                            <Divider />
                            <DialogActions className="w-100">
                                <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Controls.Button
                                        text="Clear"
                                        onClick={() => { resetForm() }} className="link-gray" />
                                    <Controls.Button
                                        type="submit"
                                        text="Submit" className="link-red" />
                                </Grid>
                            </DialogActions>
                        </Grid>

                    </Form>
                </Paper>
            </main>
        </>
    )
};

export default TicketTypeForm;