import React, { useEffect } from 'react';
import { Grid, Button, IconButton, Typography } from "@material-ui/core";
import { deleteAdsInTicketTemplate } from '../../services/brandingServices';
import ConfirmDialog from "../../components/ConfirmDialog";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Controls from '../../components/controls/Controls';

const Ads = ({ addoredit, eventid, getimages, setimages, notification, disable, allImage ,setAdvert,getadvert}) => {

  const [ads, setAds] = React.useState(getimages);
  const [adsLogo1, setAdsLogo1] = React.useState();
  const [adsLogo2, setAdsLogo2] = React.useState();
  const [adsLogo3, setAdsLogo3] = React.useState();
  const [condition1, setCondition1] = React.useState(false);
  const [condition2, setCondition2] = React.useState(false);
  const [condition3, setCondition3] = React.useState(false);
  const [close1, setClose1] = React.useState(false);
  const [close2, setClose2] = React.useState(false);
  const [close3, setClose3] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {

    if (allImage != null && allImage.adimage) {
      getimages.length > 0 && getimages.forEach(x => {
        if (Number(x.adssequence) === 1) {
          setAdsLogo1(x.ads);
          setCondition1(true)
        }
        else if (Number(x.adssequence) === 2) {
          setAdsLogo2(x.ads);
          setCondition2(true)

        }
        else {
          setAdsLogo3(x.ads);
          setCondition3(true)
        }
      })
    }
  }, [allImage])
  const readURL = (e, sequence) => {
    const deletes = getimages.filter(x => Number(x.adssequence) !== sequence);

    let reader = new FileReader();
    let files = e.target.files !== undefined ? e.target.files[0] : e.dataTransfer !== undefined ? e.dataTransfer.files[0] : false;
    if (files) {
      reader.readAsDataURL(files);
      const data = {
        "eventadid": "0",//addoredit, //(add- 0/ edit - created id from DB)
        "eventid": eventid,
        "ads": files.name,
        "adssequence": sequence
      }
      setimages([...deletes, { ...data, files }])
    } if (sequence === 1) { setAdsLogo1(files); setClose1(true); }
    else if (sequence === 2) { setAdsLogo2(files); setClose2(true); }
    else { setAdsLogo3(files); setClose3(true); }
  }

  const onDelete = (eventadid, sequence) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteAdsInTicketTemplate({ "id": eventadid, "event_id": eventid }).then(res => {
      const deletes = getimages.filter(x => Number(x.adssequence) !== sequence)
      setAds(deletes);
      setimages(deletes);
      if (sequence === 1) { setAdsLogo1(); setCondition1(false); }
      else if (sequence === 2) { setAdsLogo2(); setCondition2(false); }
      else { setAdsLogo3(); setCondition3(false); }
      notification("Deleted Successfully", "success");
    }).catch((err) => {
      notification("Not Deleted Successfully", "error");
    })
  }

  const deleteAdsImage = (sequence) => {
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure to delete this ADS Image?`,
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        const Eventadid = getimages.find(x => Number(x.adssequence) === sequence);
        onDelete(Eventadid.eventadid, sequence);
      },
    });
  }

  const normalRemove = (sequence) => {
    const deletes = getimages.filter(x => Number(x.adssequence) !== sequence);
    setimages(deletes);
    if (sequence === 1) { setAdsLogo1(); setClose1(false); }
    else if (sequence === 2) { setAdsLogo2(); setClose2(false); }
    else { setAdsLogo3(); setClose3(false); }
  }

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e, seq , dis) => {
    e.preventDefault();
    e.stopPropagation();
    if(dis){
    var files = e.dataTransfer.files;
    for (let x = 0; x < files.length; x++) {
      files[x].type.split("/")[0] === 'image' ? readURL(e, seq) : notification('This is not an image', 'error');
    }
  }
  };

  return (
    <div className="adds-outer">
        <Typography variant="subtitle2" gutterBottom component="div">
               
               {disable? <Controls.Input
              name="advertise"
              label="Advertise"
              value={setAdvert || ''}
              onChange={(e)=>{getadvert(e.target.value)}}
               />:<b>{setAdvert}</b>}
        </Typography>
      <Grid container item spacing={2} lg={12} md={12} className="adds-inside">
        <Grid item lg={12} md={12} sm={12} xs={12} className="p-0"
          onDrop={e => handleDrop(e, 1 , !condition1)}
          onDragOver={e => handleDragOver(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragLeave={e => handleDragLeave(e)}
        >
          <div className="first-ads">
            {disable && adsLogo1 && !condition1 && close1 ? <IconButton onClick={() => normalRemove(1)} className="close"><HighlightOffOutlinedIcon /></IconButton> : null}
            {!condition1 ?
              <label>Drop your image here or <span>browse</span></label> : null}
            {!condition1 ? <> <input type='file' accept='image/*' onChange={(e) => readURL(e, 1)} />
              <p>Format: JPEG,PNG </p>
              <p>Recommended size: 680px by 230px</p></> : null}
            <div className="preview"> {adsLogo1 && <LazyLoadImage src={condition1 ? (adsLogo1) : URL.createObjectURL(adsLogo1)} alt="No Image" id="img" />}
              {disable && condition1 ? <Button onClick={() => { deleteAdsImage(1) }} size="small" variant="outlined" className="link-red">Remove&nbsp;Image</Button> : null}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={2} lg={12} md={12} className="bottom">
        <Grid item lg={12} md={12} sm={12} xs={12} className="p-0">
          <div className="fist-half" onDrop={e => handleDrop(e, 2 ,!condition2)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}>
            <div className="first-ads">
              {disable && adsLogo2 && !condition2 && close2 ? <IconButton onClick={() => normalRemove(2)} className="close"><HighlightOffOutlinedIcon /></IconButton> : null}
              {!condition2 ?
                <label>Drop your image here or <span>browse</span></label> : null}
              {!condition2 ? <> <input type='file' accept='image/*' onChange={(e) => readURL(e, 2)} />
                <p>Format: JPEG,PNG </p>
                <p>Recommended size: 680px by 230px</p></> : null}
              <div className="preview">{adsLogo2 && <LazyLoadImage src={condition2 ? (adsLogo2) : URL.createObjectURL(adsLogo2)} alt="No Image" className="fist-half" />}

                {addoredit && disable && condition2 ? <Button onClick={() => { deleteAdsImage(2) }} size="small" variant="outlined" className="link-red">Remove&nbsp;Image</Button> : null}
              </div></div>
          </div>

          <div className="second-half" onDrop={e => handleDrop(e, 3 , !condition3)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}>
            <div className="first-ads">
              {disable && adsLogo3 && !condition3 && close3 ? <IconButton onClick={() => normalRemove(3)} className="close"><HighlightOffOutlinedIcon /></IconButton> : null}
              {!condition3 ?
                <label>Drop your image here or <span>browse</span></label> : null}
              {!condition3 ? <> <input type='file' accept='image/*' onChange={(e) => readURL(e, 3)} />
                <p>Format: JPEG,PNG </p>
                <p>Recommended size: 680px by 230px</p> </> : null}
              <div className="preview">{adsLogo3 && <LazyLoadImage src={condition3 ? (adsLogo3) : URL.createObjectURL(adsLogo3)} alt="No Image" className="second-half" />}

                {addoredit && disable && condition3 ? <Button onClick={() => { deleteAdsImage(3) }} size="small" variant="outlined"
                  className="link-red">Remove&nbsp;Image</Button> : null}
              </div></div></div>
        </Grid>
      </Grid>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}
export default Ads;