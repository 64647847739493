import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


export async function getemailTemplateManagement(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/emailTemplateManagement`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getticketTemplateManagement(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/ticketTemplateManagement`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` ,
                "Content-Type": "multipart/form-data",  
            }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}


export async function deleteAdsInTicketTemplate(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/deleteAdsInTicketTemplate`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getTemplateDetails(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/getTemplateDetails`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function publishTemplateDetails(user) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/publishTemplateDetails`, user, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function styleManagement(user) {
  const currentUser = cookies.get("currentUser");

  return await http
    .post(`/events/styleManagement`, user, {
      headers: {
        authorization: currentUser ? `Bearer ${currentUser.token}` : ``,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function deleteStyleManagement(user) {
  const currentUser = cookies.get("currentUser");

  return await http
    .post(`/events/deleteStyle`, user, {
      headers: {
        authorization: currentUser ? `Bearer ${currentUser.token}` : ``,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getCustomerStyles(user) {
  const currentUser = cookies.get("currentUser");

  return await http
    .post(`/events/getCustomerStyles`, user, {
      headers: {
        authorization: currentUser ? `Bearer ${currentUser.token}` : ``,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getBase64File(user) {
  const currentUser = cookies.get("currentUser");

  return await http
    .post(`/events/getBase64File`, user, {
      headers: {
        authorization: currentUser ? `Bearer ${currentUser.token}` : ``,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}