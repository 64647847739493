import React, { useState, useEffect } from "react";
import { getEventByAccountId } from "../services/eventService";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const errorHandle = (error) => {
  const messages =
    error.response && !error.response.data.message
      ? error.response.data.error
        ? error.response.data.error
        : error.response.data
      : error.message;
  console.log(messages);
};

const getTimeslots = (occurrences, useAdditionalTimeslots) => {
  if (!occurrences?.length) { //This IF condition is using for Tickettypes.
    let startTime = "2022-12-12 00:00:00"; //In TicketType we are using 24hrs time format
    let endTime = "2022-12-12 23:59:59";
      //Parse In
  const parseIn = function (date_time) {
    var d = new Date();
    d.setHours(date_time.substring(11, 13));
    d.setMinutes(date_time.substring(14, 16));
    return d;
  }

  //make list
  const getTimeIntervals = function (time1, time2) {
    var arr = [];
    while (time1 < time2) {
      const ampm = time1.getHours() < 12 ? 'am' : 'pm';
      arr.push(time1.toTimeString().substring(0, 5) + ampm);
      if (useAdditionalTimeslots) {
        const minute = time1.getMinutes();
        let count = 0;
        if (minute >= 30)
          count = -5;

        arr.push(moment(time1).add((15 + count), 'minutes').toDate().toTimeString().substring(0, 5) + ampm);
        arr.push(moment(time1).add((20 + count), 'minutes').toDate().toTimeString().substring(0, 5) + ampm);
      }
      time1.setMinutes(time1.getMinutes() + 30);
    }
    return arr;
  }

  startTime = parseIn(startTime);
  endTime = parseIn(endTime);

  const intervals = getTimeIntervals(startTime, endTime);
  return intervals;
  }
  let timeslots = new Set();
  occurrences.forEach(o => {
    timeslots.add(o.EventTime);
  });
  return Array.from(timeslots).sort((a, b) => {
    // This checks if the time is in the AM or PM and sorts accordingly
    return moment(a, 'h:mm A').diff(moment(b, 'h:mm A'));
  });
}

const dayOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const getFormattedEvent = (event_id, daysOfWeek, dayTimes) => {


  let formattedData = {
    days_of_week: [],
    day_times: []
  };
  if (event_id) {
    formattedData.event_id = event_id;
  }

  if (daysOfWeek.length > 0) {
    formattedData.days_of_week = daysOfWeek;
  }

  if (dayTimes.length > 0) {
    formattedData.day_times = dayTimes;
  }

  return formattedData;
}

const EventsFilter = ({
  accountid,
  onChange,
  showEvent = false,
  event_id = null,
  days = [],
  dayTimes = [],
  occurrences = [],
  useAdditionalTimeslots = false
}) => {

  const classes = useStyles();
  const [eventsData, setEventsData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDay, setSelectedDay] = useState(days);
  const [selectedTime, setSelectedTime] = useState(dayTimes);
  const [timeslots] = useState(getTimeslots(occurrences, useAdditionalTimeslots));
  const isAllSelected = dayOptions.length > 0 && selectedDay.length === dayOptions.length;

  const handleEventChange = (e) => {
    const selectedEventData = eventsData.find(et => et.id === e.target.value);
    if (selectedEventData) {
      setSelectedEvent(selectedEventData);
      if (onChange !== undefined) {
        const evt = getFormattedEvent(selectedEventData, selectedDay, selectedTime);
        onChange(evt);
      }
    }
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedDay(selectedDay.length === dayOptions.length ? [] : dayOptions);
      if (onChange !== undefined) {
        const evt = getFormattedEvent(showEvent ? selectedEvent.id : event_id, selectedDay.length === dayOptions.length ? [] : dayOptions, selectedTime);
        onChange(evt);
      }
      return;
    }
    setSelectedDay(value);
    if (onChange !== undefined) {
      const evt = getFormattedEvent(showEvent ? selectedEvent.id : event_id, value, selectedTime);
      onChange(evt);
    }
  };

  const handleHourChange = (e) => {
    const value = e.target.value;
    setSelectedTime(value);
    if (onChange !== undefined) {
      const evt = getFormattedEvent(showEvent ? selectedEvent.id : event_id, selectedDay, value);
      onChange(evt);
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        const eventsResponse = await getEventByAccountId({
          accountid
        });
        setEventsData(eventsResponse.data);
      } catch (error) {
        errorHandle(error);
      }
    };
    if (showEvent)
    load();
  }, []);

  return [
    <Grid container spacing={2} md={12}>
      {showEvent && <Grid item lg={6} md={6} sm={6} xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-event-label">Select Event</InputLabel>
          <Select
            labelId="select-event-label"
            id="select-event"
            value={selectedEvent !== null ? selectedEvent.id : null}
            onChange={handleEventChange}
            label="Select Event">
            {eventsData.map(e => {
              return <MenuItem value={e.id}>{e.eventname}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>}

      <Grid item lg={3} md={3} sm={3} xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="mutiple-select-label">Select Day</InputLabel>
          <Select
            labelId="mutiple-select-label"
            multiple
            value={selectedDay}
            onChange={handleDayChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selectedDay.length > 0 && selectedDay.length < dayOptions.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {dayOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selectedDay.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item lg={3} md={3} sm={3} xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="mutiple-select-label">Select Hour</InputLabel>
          <Select
            labelId="mutiple-select-label"
            multiple
            value={selectedTime}
            onChange={handleHourChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}>
            {timeslots.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selectedTime.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>,
  ];
};

export default EventsFilter;