import React from 'react'
import { makeStyles, IconButton as MuiIconButton, } from '@material-ui/core';
import styled from "styled-components/macro";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        margin: theme.spacing(0.1)
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color: theme.palette.secondary.main,
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
        }
    },
}))
const IconButton = styled(MuiIconButton)`
  svg {
    width:  24px;
    height: 24px;    
  }
`;
const ActionButton = (props) => {

    const { color, children, onClick } = props;
    const classes = useStyles();

    return (
        <IconButton className={`${classes.root} ${classes[color]}`}
            onClick={onClick} size="medium" >
            {children} </IconButton>
       
    )
}

export default ActionButton;
