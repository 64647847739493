import CF  from '@react-jvectormap/africa/dist/africaMerc.json';
import DZ  from '@react-jvectormap/algeria/dist/dzMill.json';
import AS  from '@react-jvectormap/asia/dist/asiaMerc.json';
import AU  from '@react-jvectormap/australia/dist/auMerc.json';
import AT  from '@react-jvectormap/austria/dist/atMerc.json';
import BE  from '@react-jvectormap/belgium/dist/beMerc.json';
import BR  from '@react-jvectormap/brazil/dist/brMill.json';
import CA  from '@react-jvectormap/canada/dist/caMerc.json';
import CH  from '@react-jvectormap/china/dist/cnMerc.json';
import CO  from '@react-jvectormap/colombia/dist/coMerc.json';
import DK  from '@react-jvectormap/denmark/dist/dkMerc.json';
import FR  from '@react-jvectormap/france/dist/frMerc.json';
import DE  from '@react-jvectormap/germany/dist/deMerc.json';
import IN  from '@react-jvectormap/india/dist/inMerc.json';
import IR  from '@react-jvectormap/iran/dist/iranMill.json';
import IT  from '@react-jvectormap/italy/dist/itMerc.json';
import NL  from '@react-jvectormap/netherlands/dist/nlMerc.json';
import NZ  from '@react-jvectormap/newziland/dist/nzMerc.json';
import NO  from '@react-jvectormap/norway/dist/noMerc.json';
import PL  from '@react-jvectormap/poland/dist/plMerc.json';
import PT  from '@react-jvectormap/portugal/dist/ptMerc.json';
import PR  from '@react-jvectormap/puertorico/dist/puertoRico.json';
import RU  from '@react-jvectormap/russia/dist/ruMerc.json';
import ZA  from '@react-jvectormap/southafrica/dist/zaMerc.json';
import KR  from '@react-jvectormap/southkorea/dist/krMerc.json';
import ES  from '@react-jvectormap/spain/dist/esMerc.json';
import SE  from '@react-jvectormap/sweden/dist/seMerc.json';
import CHE  from '@react-jvectormap/switzerland/dist/chMerc.json';
import TH  from '@react-jvectormap/thailand/dist/thMerc.json';
import TR  from '@react-jvectormap/turkey/dist/trMill.json';
import US  from '@react-jvectormap/unitedstates/dist/useMerc.json';
import AE  from '@react-jvectormap/unitedstates/dist/usAea.json';
//import GB  from '@react-jvectormap/unitedstates/dist/useMerc.json';
import VE  from '@react-jvectormap/venezuela/dist/veMerc.json';
import UK  from '@react-jvectormap/ukregions/dist/ukRegionsMerc.json';
//import GB  from '@react-jvectormap/ukregions/dist/ukRegionsMerc.json';
import GB from './ukreg.json';
import WORLD  from '@react-jvectormap/world/worldMerc.json';
import CONTINENT from '@react-jvectormap/continents/dist/continentsMerc.json';

// import FranceRegions2016  from '@react-jvectormap/france/dist/frMill.json';
// import esMill  from '@react-jvectormap/europe/dist/europeMill.json';
// import esMill  from '@react-jvectormap/chicago/dist/usIlChicagoMill.json';
// import FranceRegions2016  from '@react-jvectormap/italyregions/dist/itRegionsMill.json';
// import FranceRegions2016  from '@react-jvectormap/newyork/dist/usNyNewYorkMill.json';
// import FranceRegions2016  from '@react-jvectormap/northamerica/dist/northAmericaMill.json';
// import FranceRegions2016  from '@react-jvectormap/oceania/dist/oceaniaMill.json';
// import FranceRegions2016  from '@react-jvectormap/russiafederaldistricts/dist/ruFdMill.json';
// import FranceRegions2016  from '@react-jvectormap/southamerica/dist/southAmericaMill.json';
// import FranceRegions2016  from '@react-jvectormap/unitedkingdomcountries/dist/ukCountriesMill.json';

const loadmap = {
  DEFAULT:{
      WORLD,
      CONTINENT
  },
  COUNTRIES:{
    CF,
    DZ,
    AS,
    AU,
    AT,
    BE,
    BR,
    CA,
    CH,
    CO,
    DK,
    FR,
    DE,
    IN,
    IR,
    IT,
    NL,
    NZ,
    NO,
    PL,
    PT,
    PR,
    RU,
    ZA,
    KR,
    ES,
    SE,
    CHE,
    TH,
    TR,
    US,
    AE,
    GB,
    VE,
    UK
  }
}

export default loadmap;