import { Grid, DialogActions, Divider, Checkbox ,Box,FormLabel, FormControlLabel, FormControl, FormGroup} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { getAllClient } from '../../services/clientApiServices';
import {getClientapidetails,getAllallotment} from '../../services/allotmentService'
import {
  Table,
  TableHead,
  TableBody,
  TableRow, 
  TableCell,
 } from "@material-ui/core";
 import { Alert } from "@material-ui/lab";
import Loading from '../../components/controls/Loading';


const initialFValues = {

    clientname: "",
    apiid:"",
    apikey:"",
    clientid:"",
};


const ClientApiForm = (props) => {
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const history = useHistory();
  const [sale, setSale] = useState([]);
  const [report, setReport] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [state, setState] = React.useState({
    sales: false,
    reporting: false,
  });
  const [status, setStatus] = React.useState(false);
  const [checkValue, setCheckValue] = useState("");
  const [loading , setLoading] = useState(true);
  const [checkboxess,setCheckboxess]= useState([]);
  const [showStatus,setShowstatus]= useState("");
  const [clinetlist,setClientlist]= useState([]);
  const [removed,setRemoved]= useState([]);

  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
  if(!error && !checkParam(Object.keys(error))){
    notification(error, 'error');
   } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
   }
  }
  
  

  useEffect(() => {
    let cancel = false;
    const users = {
        "action_type":"active list"
    }
    getAllClient(users).then(result => {
      if (cancel) return;
        const dsale = (result.data).filter(e=>e.apicategory==="sales");
        const dreport = result.data.filter(e=>e.apicategory==="report");
        setSale(dsale);
        setReport(dreport);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandle(error);
      });
      return () => {
        cancel = true;
      }
  }, [])


  const getall = (e) => {
    const user = {
      "action_type": "list",
      "account_id": e
    }
    getAllallotment(user).then((res) => {
      if (res.data.length > 0) {
        let arraydata = []
        const users = {
          "action_type": "list",
          "client_id": recordForEdit.clientid,
          "allocations": []
        }
        getClientapidetails(users).then((result) => {
          setClientlist(result.data)
          res.data.forEach(x => {
            let item = {}
            item.allocationid = x.allocationid;
            item.allocationname = x.allocationname;
            if (result.data.length > 0) {
              const condition = result.data.find(e => e.allocationid === x.allocationid)
              if (condition) {
                item.allocatechecked = true
                item.allocationclientid = condition.allocationclientid
              } else {
                item.allocatechecked = false
                item.allocationclientid = 0

              }

            } else {
              item.allocatechecked = false
              item.allocationclientid = 0
            }
            arraydata.push(item)
          })
          setCheckboxess(arraydata)
        })


      }


    }).catch((error) => {
      errorHandle(error);
    });



  }
 
  
  


  const { addOrEdit, recordForEdit, cancelbutton } = props;
  

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("clientname" in fieldValues) {
      temp.clientname = fieldValues.clientname ? "" : "Clientname is  required";  
    }

    

    setErrors({ ...temp });
    return Object.values(errors).every((x) => x === "");


  };
  const formIsValid = (fieldValues = values) => {
    if (validate()) {
      const isValid =
        fieldValues.clientname &&
        Object.values(errors).every((x) => x === "");

      return isValid;
    }
  };

  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();

    const active=status?1:0;
    if (formIsValid()) {
       addOrEdit(values,checkValue,active,checkboxess,removed);
    }
  };

 

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
       cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  useEffect(() => {
    let cancel = false;
    if (recordForEdit !== null){
      getall(recordForEdit.accountid)
      setShowstatus("Edit")
      if (cancel) return;
      let condition= false;
      let condition1= false;
      setValues(recordForEdit)
      const salesReport = (recordForEdit.apicategory).toString().split(',');
      salesReport.forEach(e=>{
        if(e==="sales"){
        condition = true;
        }else if(e==="report"){
          condition1=true
        }else{}
      })
      setState({sales: condition,
        reporting: condition1})
        setStatus(recordForEdit.active!==0);
    }
    return () => {
      cancel = true;
    }
  }, [recordForEdit]);
  const handleChange=(event)=>{
        setState({
            ...state,
            [event.target.name]: event.target.checked,
          });  
  }
  const handleChange1=(event)=>{
      setStatus(event.target.checked);    
}
useEffect(()=>{

    if(state.sales||state.reporting){
        let set1="";
        let set2="";
      if(state.sales){
         const data=sale.map(e=>e.apiid)
         set1=data.toString()
      }else{
        set1=""
      }
      if(state.reporting){
        const data=report.map(e=>e.apiid)
        set2=data.toString()
     }else{
        set2=""
     }
     if(state.sales&&state.reporting)
     setCheckValue(set1+","+set2);
     else if(state.sales && !state.reporting)
     setCheckValue(set1);
     else
     setCheckValue(set2);
    }else{
      setCheckValue("");
    }
    },[state.sales,state.reporting,sale,report])

  const { sales, reporting } = state;
  const handleChanged = (event) => {
  
    const data=event.target.name
    
    if(event.target.checked){

  const dataValue=checkboxess.map(x=>{
     
        if(x.allocationid===Number(data)){
       return{
          allocationid:x.allocationid,
          allocationname:x.allocationname,
          allocatechecked:event.target.checked,
          allocationclientid:x.allocationclientid
       }
        }else{
          return{
            allocationid:x.allocationid,
            allocationname:x.allocationname,
            allocatechecked:x.allocatechecked,
            allocationclientid:x.allocationclientid
         }
        }
 
      })
      
      setCheckboxess(dataValue)
    }else{
    
    

    clinetlist.forEach((number, index, array) => {
      if(number.allocationid===Number(event.target.name)){
      let valued={}
      valued.allocationclientid=number.allocationclientid
      valued.allocationid=number.allocationid
      valued.isaction="delete"
    

      setRemoved([...removed, valued])
      }
         
  });
 
        const dataValue=checkboxess.map(x=>{
     
          if(x.allocationid===Number(event.target.name)){
         return{
            allocationid:x.allocationid,
            allocationname:x.allocationname,
            allocatechecked:event.target.checked,
            allocationclientid:x.allocationclientid
         }
          }else{

            return{
              allocationid:x.allocationid,
              allocationname:x.allocationname,
              allocatechecked:x.allocatechecked,
              allocationclientid:x.allocationclientid
           }
          }
   
        })
   
        setCheckboxess(dataValue)
   
    }
  
  
   
  };
  
  
  return (

    <Form onSubmit={handleSubmit} className="create-user">
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <Grid container item spacing={2} md={12}>
        <Grid item lg={9} md={9} sm={6} xs={12}>
          
      <Controls.Input
          name="clientname"
          label="Client Name"
          value={values.clientname || ''}
          onChange={handleInputChange}
          error={errors.clientname} />

        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12} className="mt-1">
       
        <FormControlLabel control={<Checkbox checked={status} onChange={handleChange1} name="status" />} label="Active" />            
        </Grid>
       
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormControl
        required
      > <FormGroup>
        
        <div className="scrollbar" style={{minHeight:'auto', maxHeight:'250px', overflow: 'auto', minWidth: 'auto', maxWidth: '100%'}}>
        <fieldset className="scrollbar" style={{minHeight:'auto', maxHeight:'250px', overflow: 'auto', minWidth: 'auto', maxWidth: '100%'}}>
    <legend><FormControlLabel
            control={
              <Checkbox checked={sales} onChange={handleChange} name="sales" />
            }
            label="Sales API"
          /></legend>
        <Table stickyHeader aria-label="sticky table" className="mb-3">
          <TableHead>
            <TableRow>              
              <TableCell>API Name</TableCell>
              <TableCell>API Description</TableCell>
              <TableCell className="text-left">API URL</TableCell>         
            </TableRow>
          </TableHead>
          <TableBody>     
          {sale.length>0?(sale.map((events,index)=>(
              <TableRow key={index}>
               <TableCell>{events.apiname}</TableCell>
               <TableCell>{events.apidescription}</TableCell>             
              <TableCell className="text-left apikey">{events.apiurl}</TableCell>
           </TableRow>
         ))):(loading?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="8"><Loading /></TableCell></TableRow>):(<TableRow><TableCell colSpan={8} className="center"><Alert severity="warning">No records found</Alert></TableCell></TableRow>))
          }
          </TableBody>
        </Table> 
       
     
        </fieldset> </div>
        </FormGroup></FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-2">
        <FormControl
        required
      > <FormGroup>
       
    <fieldset className="scrollbar mb-2" style={{minHeight:'auto', maxHeight:'250px', overflow: 'auto', minWidth: 'auto', maxWidth: '100%'}}>
    <legend>
        <FormControlLabel
            control={
              <Checkbox checked={reporting} onChange={handleChange} name="reporting" />
            }
            label="Reporting API"
          /></legend>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>              
              <TableCell>API Name</TableCell>
              <TableCell>API Description</TableCell>
              <TableCell className="text-left">API URL</TableCell>         
            </TableRow>
          </TableHead>
          <TableBody>
          {report.length>0?(report.map((events,index)=>(
              <TableRow key={index}>
               <TableCell>{events.apiname}</TableCell>
               <TableCell>{events.apidescription}</TableCell>             
              <TableCell className="text-left apikey">{events.apiurl}</TableCell>
           </TableRow>
         ))):(loading?(<TableRow><TableCell className="p-1 text-left w-100" colSpan="8"><Loading /></TableCell></TableRow>):(<TableRow><TableCell colSpan={8} className="center"><Alert severity="warning">No records found</Alert></TableCell></TableRow>))
          }
          </TableBody>
        </Table></fieldset>
        </FormGroup></FormControl>

        {showStatus!==""?<>
        <b>Assign Available Holds To Client</b>
        <Box sx={{ display: 'flex' }}>
       
       <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" className="m-0">
     
        {/* <FormLabel component="legend">Assign Available Holds To Client</FormLabel> */}
        <Grid className="scrollbar" style={{minHeight:'auto', maxHeight:'250px', overflow: 'auto', minWidth: 'auto', maxWidth: '100%'}}>
      <FormGroup>
        {checkboxess.map(item => (
          <FormControlLabel
            control={
              <Checkbox value={item.allocationid}checked={item.allocatechecked} onChange={handleChanged} name={item.allocationid} />
            }
            label={item.allocationname}
          />
          
          ))}  
          
        </FormGroup>
        </Grid>
       
       
        
      </FormControl>
    
      </Box></>:null}
        </Grid>
   

        <Divider />
        <DialogActions className="w-100">
          <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>            
            <Controls.Button
              text="Clear"
              color="default" size="medium"
              onClick={()=>{resetForm(); cancelbutton()}} className="link-gray" />
              <Controls.Button
              type="submit"
              text="Submit" size="medium" className="link-red" />
          </Grid>
        </DialogActions>
      </Grid>

    </Form>
  )
};

export default ClientApiForm;