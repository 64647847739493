export const initWidget = (accountid,eventid,eventdetailid,upsellData={},venueDetail, reservationId) => {
    const modal = document.getElementById("myModal");
    sessionStorage.clear()
    sessionStorage.setItem('widgetCustomerId',accountid?accountid:'')
    sessionStorage.setItem('widgetEventId',eventid?eventid:'')
    sessionStorage.setItem('widgetOccurrenceid',eventdetailid?eventdetailid:'')
    sessionStorage.setItem('widgetUpsellData',upsellData?JSON.stringify(upsellData):{})
    sessionStorage.setItem('widgetVenueDetail',venueDetail?venueDetail:'')
    sessionStorage.setItem('reservationId',reservationId?reservationId:'')
    const widget =  document.getElementById('widget-onthe-stage-iframe');
    if(widget){
        widget.remove();
    }
    window.initWidget('widget-onthe-stage');
    modal.style.display = "block";
}