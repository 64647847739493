import { combineReducers } from "redux";

import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import eventReducer from "./eventReducer";

export const rootReducer = combineReducers({
  authReducer,
  orderReducer,
  eventReducer,
});
