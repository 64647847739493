import { Paper,Grid, makeStyles, Typography,Divider} from '@material-ui/core';
import React, { useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import {  signOut } from "../../redux/actions/authActions";
import Cookies from 'universal-cookie';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import userService from "../../services/userService";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import {
    boot as bootIntercom,
  } from "../../pages/dashboard/intercom"

const useStyles = makeStyles(theme => ({
  root: {
      width: "100%"
  },
  breadcrumbs: {
    margin: '0px !important',
    padding: '0px 16px 0px 16px !important',
    display: 'flex !important',
    [theme.breakpoints.down("sm")]: {
      padding: '16px 16px 0px 16px !important',
    }
  },  
  pageContent: {
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      marginBottom: '50px',
      width: '40%',
     
      [theme.breakpoints.up('xs')]: {
          width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '90%',
        },
        [theme.breakpoints.up('md')]: {
          width: '50%',
          margin: '0px auto',
        },
        [theme.breakpoints.up('lg')]: {
          width: '40%',
        },
  },
  head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
  },
  content: {
      [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          marginTop: '10px !important',
      },
  },
  drawerPaper: {
      width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  modalTitle: {
      background: '#be1915',
      color: '#ffffff'
  }
}));

const drawerWidth = 250;


  const initialFValues = {
    password: "",
    confirmpassword: "",
 }; 

const ChangePassword = (props) =>{
   
   
    const history = useHistory();
    const classes = useStyles();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
    const cookies = new Cookies();
    const dispatch = useDispatch();
    

    const validate = (fieldValues = values)=>{
   
        const temp = {...errors};

        if ("password" in fieldValues) {
            if(fieldValues.password.length <= 8){
            temp.password = "Password must be at least 8 character";
            }
            else if(!fieldValues.password.match(/[A-Z]+/)) {
                temp.password = "Password must contain atleast One uppercase character";
            }
            else if(!fieldValues.password.match(/[a-z]+/)) {
                temp.password = "Password must contain atleast One lowercase character";
            }
            else if(!fieldValues.password.match(/[0-9]+/)) {
                temp.password = "Password must contain  atleast least one numeric";
            }
            else if(!fieldValues.password.match(/[@$!%*#?&]+/)) {
                temp.password = "Password must contain  atleast One special character";
            }
            else{
                temp.password = ""
            }
        }
        if ("confirmpassword" in fieldValues) {
            if(!fieldValues.confirmpassword){
                temp.confirmpassword = "Confirm Password is required";
            }
            else if(values.password!==fieldValues.confirmpassword){
            temp.confirmpassword = "Passwords do not match.";
            }
            else{
                temp.confirmpassword = ""
            }
            
        }
       
        setErrors({...temp});
        return Object.values(errors).every((x) => x === "");


    };
    const formIsValid = (fieldValues = values) => {
 if( validate()) {
         const isValid =
         fieldValues.password &&
          fieldValues.confirmpassword &&
         Object.values(errors).every((x) => x === "");
    
        return isValid;
         }
      };

    const {values,  
           errors,
           setErrors,
           handleInputChange, } = useForm(initialFValues,true,validate);

  const handleSubmit = (event)=> {
            event.preventDefault();
            if (formIsValid()) {
                const currentUser = cookies.get('currentUser');
                const clientid = currentUser.userid
                let cancel = false;
                const result = {
                            userid:clientid ,
                            password: values.password,
                           
                        }
                        userService.Changepassword(result)
                        .then(() => {
                            
                          
                            if (cancel) return;
                            cookies.remove('currentUser');
                            bootIntercom(true);
                            dispatch(signOut());
                            history.push('/');
                        
                            })
                        .catch(error => {
                            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
                            notification(messages, 'error');
                          
                        });
                        return ()=>{
                            cancel = true;
                        }
            }
        };
    
 const notification = (message,type)=>{
            setNotify({
              isOpen: true,
              message: message,
              type: type
            })
          }
 
       
               
       
    return(
        <div  className={classes.wrapper}>
               <Helmet title="Change Password" />
            <div display="flex" justifycontent="center" className="w-100">
           
            <main className={classes.content}>
            <Typography variant="h5" gutterBottom display="inline" className={classes.breadcrumbs}>
                Change Password
            </Typography>
            <Divider className="my-2 mb-4" />
            
        <Paper className={classes.pageContent}>
       <Form onSubmit={handleSubmit} className="create-user">
     <Notification
          notify={notify}
          setNotify={setNotify}
      />
            <Grid container item spacing={3} width="100">

            <Grid item lg={12} sm={12} xs={12}>
                
                    <Controls.Input
                            type="password"
                            name="password"
                            label="Password"
                            value={values.password}
                            onChange={handleInputChange}
                            error={errors.password} className="smaller-input"/>
                            </Grid>
                            <Divider />

                            <Grid item lg={12} sm={12} xs={12}>

                    <Controls.Input
                             type="password"
                            name="confirmpassword"
                            label="Confirm Password"
                           value={values.confirmpassword}
                            onChange={handleInputChange}
                            error={errors.confirmpassword} className="smaller-input"/>
                     </Grid>
                     <Grid item lg={12} sm={12} xs={12} className="pt-3">
                     <Controls.Button
                            type="submit"
                            text="Submit" size="medium" className="link-red"/>
                       </Grid>
            </Grid> 

        </Form>
        </Paper>
                        </main>
 </div>        
 <Footer />
 </div>
    )
};

export default ChangePassword;