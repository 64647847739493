import { Avatar,Paper,Grid, makeStyles, Typography, Divider ,Snackbar,Card,
    CardContent } from '@material-ui/core';
import React, {  useState } from 'react';
import styled from "styled-components/macro";
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { resetPassword } from '../../services/authService';
import queryString from 'query-string';
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet";


const Alert = styled(MuiAlert)(spacing);


  const useStyles = makeStyles((theme) => ({
    root: {
      '& >  + ': {
        marginLeft: theme.spacing(2),
      },    
    },
    wrapper:{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '20px',
      width: '30%',
      margin: '0px auto',
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '60%',
      },
      [theme.breakpoints.up('md')]: {
        width: '35%',
      }
      },
  paper: {
      shadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',     
      borderRadius: '20px',
    },
    card: {
      borderRadius: '20px',   
    },
    link:{
      textTransform: 'capitalize',
      fontWeight: 'normal',
      textDecoration: 'underline',
      fontSize: '14px',
      "&:hover":{
        textDecoration: 'none',
       }
    },  
    title:{ 
        fontFamily: 'OstrichSans-Medium',
        letterSpacing: '1.5px !important',
        color:'#bf1914 !important',
        fontSize: '22px !important',
        textTransform: 'uppercase',
        lineHeight: '70px',
        fontWeight: '600',
      },  
  }));



  const initialFValues = {
    password: "",
    confirmpassword: "",
 }; 

const ResetPassword = (props) =>{
   
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const classes = useStyles();
    const Uritoken = queryString.parse(window.location.search);
    const userid = useSelector(state => state.authReducer.userid);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
    const [updated, setUpdate] = useState(false)
  
    

    const validate = (fieldValues = values)=>{
      
        const temp = {...errors};

        if ("password" in fieldValues) {
            if(fieldValues.password.length <= 8){
            temp.password = "Password must be at least 8 character";
            }
            else if(!fieldValues.password.match(/[A-Z]+/)) {
                temp.password = "Password must contain atleast One uppercase character";
            }
            else if(!fieldValues.password.match(/[a-z]+/)) {
                temp.password = "Password must contain atleast One lowercase character";
            }
            else if(!fieldValues.password.match(/[0-9]+/)) {
                temp.password = "Password must contain  atleast least one numeric";
            }
            else if(!fieldValues.password.match(/[@$!%*#?&]+/)) {
                temp.password = "Password must contain  atleast One special character";
            }
            else{
                temp.password = ""
            }
        }
        if ("confirmpassword" in fieldValues) {
            if(!fieldValues.confirmpassword){
                temp.confirmpassword = "Confirm Password is required";
            }
            else if(values.password!==fieldValues.confirmpassword){
            temp.confirmpassword = "Passwords do not match.";
            }
            else{
                temp.confirmpassword = ""
            }
            
        }
       
        setErrors({...temp});
        return Object.values(errors).every((x) => x === "");


    };
    const formIsValid = (fieldValues = values) => {
 if( validate()) {
         const isValid =
         fieldValues.password &&
          fieldValues.confirmpassword &&
         Object.values(errors).every((x) => x === "");
    
        return isValid;
         }
      };

    const {values,
           errors,
           setErrors,
           handleInputChange,
           resetForm } = useForm(initialFValues,true,validate);

  const handleSubmit = (event)=> {
            event.preventDefault();
            if (formIsValid()) {
                const result = {
                            userid:userid ,
                            password: values.password,
                            token: Uritoken.token
                        }
                        resetPassword(result)
                        .then(() => {
                            
                            setUpdate(true);
                           
                            })
                        .catch(error => {
                            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
                            notification(messages,'error')
                          
                        });
            }
        };
    
 const notification = (message,type)=>{
            setNotify({
              isOpen: true,
              message: message,
              type: type
            })
          }
  const Container = styled.div`
          text-align: center;
         `;  
       
               
       
    return(
        <div className="login-bg">
               <Helmet title="Reset Password" />

             {!updated &&   <div display="flex" justifycontent="center" className="w-100">
             <Avatar alt="OTS Logo"  onClick={()=>history.push('/')} src="/static/img/login-logo.png" variant="rounded" className="loginLogo"/> 
             <div className="loginTop" variant="rounded">&nbsp;</div>
             <Paper className={classes.paper}>
                            <Card className={classes.card}>
                            <CardContent>
                            <Typography value="title" variant="h5" className={classes.title}>
                            Reset Password
                            </Typography>
        
       <Form onSubmit={handleSubmit} className="create-user">
            <Grid container item spacing={2} width="100">

            <Grid item lg={12} sm={12} xs={12}>
                
                    <Controls.Input
                            type="password"
                            name="password"
                            label="Password"
                            value={values.password}
                            onChange={handleInputChange}
                            error={errors.password} className="smaller-input"/>
                            </Grid>
                            <Divider />

                            <Grid item lg={12} sm={12} xs={12}>

                    <Controls.Input
                             type="password"
                            name="confirmpassword"
                            label="Confirm Password"
                           value={values.confirmpassword}
                            onChange={handleInputChange}
                            error={errors.confirmpassword} className="smaller-input"/>
                     </Grid>

                     
                    
           
               
                     <Grid item lg={6} sm={6} xs={7}>
                        <Controls.Button
                            type="submit"
                            text="Submit" size="medium" className="link-red ml-0"/>
                        <Controls.Button
                            text="Clear"
                            color="default" size="medium"
                            onClick={resetForm}
                             className="link-gray"/>
                   </Grid>
                            
            </Grid> 

        </Form>
        

</CardContent>
 </Card>
 </Paper>
 </div>          }
            {updated && (

                <Snackbar
                    style={{
                        height: "100%",
                        backgroundcolor: "#DB7093 "
                    }}
                    anchororigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={open}
                    onClose={() => setOpen(true)}
                >
                    <Alert  color="success" severity="success"
                        key={`top,center`}
                        anchororigin={{ vertical: 'top', horizontal: 'center' }}
                    >

                        <p>
                            Your password has been successfully reset, please try logging in again.
         </p>
                        <Container>
                            <div className="pt-3">
                                <Controls.Button variant="contained" text="Login" size="small" className="link-red"
                                    onClick={() => { history.push('/') }} /> 
                            </div>
                        </Container>
                    </Alert>
                </Snackbar>


            )}
                    
                    <Notification
          notify={notify}
          setNotify={setNotify}
      />  
        </div>
    )
};

export default ResetPassword;