import * as types from "../../constants";

export function setEventFilterData(data) {
 return async (dispatch) => {
  dispatch({ 
      type: types.SET_EVENT_FILTER_DATA, 
      data
    });  
 } 
}

export function setFilteredEventDetails(events) {
 return async (dispatch) => {
  dispatch({ 
      type: types.SET_FILTERED_EVENT_DETAILS, 
      events
    });  
 } 
}