import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import Input from '../../components/controls/Input';
import { Alert } from "@material-ui/lab";
import {
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Divider,
  Toolbar,
  InputAdornment,
  Box, Grid,
  Switch
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Search } from "@material-ui/icons";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from "universal-cookie";
import { upselledit } from "../../redux/actions/authActions";
import { getUpsellByEventId, deleteUpsell, getEventByAccountId, updateUpsell } from '../../services/eventService';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useForm from '../../components/useForm';
import Form from '../../components/Form';
import Loading from '../../components/controls/Loading';
import userService from '../../services/userService';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pageContent: {
    width: '100%',
    padding: '0px 15px',
    margin: '0px auto',
  },
  select: {
    width: '100% !important',
  },
  controlSm: {
    fontSize: '12px',
    padding: '0px 20px',
  },
  pageContentMain: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      margin: '0px auto',
      width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '70%',
    },
  },

  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  addBar: {
    float: 'right',
    padding: '0px',
  },

}));

const drawerWidth = 250;
const headCells = [
  { id: "upsellname", label: "Name" },
  { id: "upselldescription", label: "Description" },
  { id: "availablecount", label: "Count" },
  { id: "activestatus", label: "Status" },
  { id: "cost", label: "Online Cost" },
  { id: "invenuecost", label: "In Venue Cost" },
  { id: "activestatus", label: "OffSale/OnSale" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const initialFValues = {
  eventid: ""
};

const UpsellList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [condition, setCondition] = useState(false);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const currentUser = cookies.get('currentUser');
  const [upsells, setupsells] = useState([]);
  const [event, setEvent] = useState([]);
  const [eventID, setEventID] = useState(0);
  const [currency, setCurrency] = useState('');
  const [accountid, setAccountID] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
 
  const [show, setShow] = useState({showLoading: true, disableSwitch: false});
  const { showLoading, disableSwitch } = show;
  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {

    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }

  useEffect(() => {
    let cancel = false;

    userService.getUserAccounts()
      .then(result => {
        if (cancel) return;
        setAccountID([...result])
      })
      .catch((error) => {
        errorHandle(error);

      });
    const ids = cookies.get('upselladdedit');
    const getId = ids ? ids.eventAccountId : selectedAccountId;
    EventByAccount(getId);
    setSelectedAccountId(getId);
    return () => {
      cancel = true;
    }

  }, []);

  const EventByAccount = (e) => {
    let cancel = false;
    cookies.set('upselladdedit', { eventAccountId: e })
    const users = {
      accountid: e
    }
    getEventByAccountId(users).then((result) => {
      if (cancel) return;
      if (result.data != null) {
        setEvent(result.data)
        if (result.data.length > 0) {
          setEventID(result.data[0].id);
          getUpsellList(result.data[0].id);

        }
      }
    }).catch((error) => {
      setShow({...show, showLoading: false});
      errorHandle(error);
    });
    return () => {
      cancel = true;
    }
  }

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(upsells, headCells, filterFn, condition);

  const { values,
    errors } = useForm(initialFValues, true, "");

  const timeOutSet = () => {
    notification("The Time Gets Expired.....Try again...", "error");
    setTimeout(() => {
      cookies.remove('upselladdedit');
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }

  const handleSearch = e => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {
          return items;
        }
        else {
          return items.filter(x => (x.upsellname.toLowerCase().includes(searchString.toLowerCase())

          ))
        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };
  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    });
  };

  const openInPopup = (item) => {

    const data = {
      ...item,
      currencytype: currency,
      upselltaxcategory: item.upselltaxcategoryid,
    }

    dispatch(upselledit(data));
    history.push('/UpsellConfiguration');
  };

  const onDelete = (upsellid, eventid) => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    deleteUpsell({ action_type: 'delete', id: upsellid }).then(res => {
      notification("Upsell Deleted Successfully", 'success');
      getUpsellList(eventid);
    }).catch((error) => {
      errorHandle(error);
    });
  };


  const handleInputChange = (data) => {
    setEventID(data.target.value);
    getUpsellList(data.target.value);
  };

  const getUpsellList = (eventid) => {
    let cancel = true;
    getUpsellByEventId({ eventid: eventid, status: "all", channel: "boxoffice" }).then((res) => {

      if (cancel) {
        setupsells(res);

        setShow({...show, showLoading: false});
      }
    })
      .catch((error) => {
        setShow({...show, showLoading: false});
        errorHandle(error);
      });
    return () => {
      cancel = false;
    }

  }

  useEffect(() => {
    venueId(eventID);
  }, [eventID])

  const venueId = (id) => {
    const symbol = [
      { label: "$", value: "USD" },
      { label: "£", value: "GBP" },
    ]

    const paisa = event.find(e => e.id === id);
    if (paisa !== undefined) {
      const symbolLable = symbol.find(e => e.value === paisa.currencytype)
      setCurrency(symbolLable.label)
    }
  }

  const getAccountidDetails = (e) => {

    cookies.set('upselladdedit', { eventAccountId: e.target.value })
    setSelectedAccountId(e.target.value)
    setShow({...show, showLoading: true});
    setupsells([]);
    setEvent([])
    EventByAccount(e.target.value);
  }

  const handleActiveStatus = async (status, id) => {
  try{
    setShow((prev)=>({...prev, disableSwitch: true}));

    let object = {
      action_type: 'status_update',
      id: id,
      active_status: status ? 1 : 0
    }
    notification("Status is updating... Please wait","info");
    const upsellUpdate = await updateUpsell(object);

   if(upsellUpdate !== null && upsellUpdate.status === "Success"){
      getUpsellList(eventID);
      notification("Status is updated","success");
      setShow({...show, disableSwitch: false});
   }else{
     notification("Status is failed","error");
     setShow({...show, disableSwitch: false});
   }
    
  }catch{
    setShow({...show, disableSwitch: false});
  }
  }


  return (
    <div>
      <Helmet title="Upsells" />
      <main className={classes.content}>

        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={6} sm={6} xs={6}>
              <Typography variant="h5" gutterBottom className="breadcrumbs">Upsells</Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6}>
              <Toolbar className="selectbar singleline">
                <Box>
                  {currentUser.roleid === 1 && accountid.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={getAccountidDetails}
                      className="select-box mt-0 mb-0"
                    >
                      <option value="0">Select Customer</option>
                      {(accountid).map((v, k) => {
                        if (v.roleid !== 1)
                          return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                        else
                          return null
                      })}
                    </select>
                  ) : (<div></div>)}

                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <Divider />

        <div className={classes.pageContent}>
          <Toolbar className={classes.addBar}>
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" style={{ marginRight: 10 }} />

            <Form className="control-sm">
              <Controls.Select
                name="eventid"
                label="Event"
                value={values.eventid || eventID || ''}
                options={event}
                onChange={handleInputChange}
                keyName="id"
                valueName="eventname" size="small"
                input={<input name="eventid" id="eventid" />}
                error={errors.eventid} className="control"
              />
            </Form>
            <Controls.ActionButton style={{ display: 'flex', justifyContent: 'flex-end', align: 'right', transform: 'translatex(+800%)' }}
              onClick={() => {
                history.push('/UpsellConfiguration')
              }} color="default" size="medium" className="link-gray">
              <AddCircleOutlineIcon fontSize="medium" />
            </Controls.ActionButton>

          </Toolbar>

          <TblContainer className="upsell-tbl">
            <TblHead className="p-1" />
            <TableBody className="upsell-tbl">
              {recordsAfterPagingAndSorting().map((record, index) => (

                <TableRow key={index}>

                  <TableCell className="p-1">{record.upsellname}</TableCell>
                  <TableCell className="p-1 desc">{record.upselldescription}</TableCell>
                  <TableCell className="p-1">{record.availablecount}</TableCell>
                  <TableCell className="p-1">{record.activestatus === 1 ? 'Active' : 'InActive'}</TableCell>
                  <TableCell className="p-1">{currency} {record.cost}</TableCell>
                  <TableCell className="p-1">{currency} {record.invenuecost}</TableCell>
                  <TableCell className="p-1">
                      <Switch checked={record.activestatus === 1} onChange={(e) => handleActiveStatus(e.target.checked, record.upsellid)} value={index} disabled = {disableSwitch} />
                 </TableCell>
                  <TableCell className="p-1">
                    <Controls.ActionButton
                      onClick={() => {
                        openInPopup(record);
                      }}
                      className="outlined-primary"
                    >
                      <EditOutlinedIcon
                        fontSize="small"
                        variant="outlined"
                        color="primary"
                      />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: `Are you sure to delete the upsell [ ${record.upsellname} ]?`,
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(record.upsellid, record.eventid);
                          },
                        });
                      }}
                    >
                      <DeleteOutlinedIcon
                        fontSize="small"
                        variant="outlined"
                        color="secondary"
                      />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
            <TableBody className="upsell-tbl">
              {!recordsAfterPagingAndSorting().length && (
                <TableRow style={{ textAlign: "center" }} key={0}>
                  {showLoading ? (<TableCell className="p-1 text-left w-100" colSpan="6"><Loading /></TableCell>) :
                    <TableCell className="p-1 text-left" colSpan="7">{<Alert severity="warning">No events found</Alert>}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </div>

        <Notification notify={notify} setNotify={setNotify} />

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default UpsellList;