import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

const QrCode = () => {
  
    return (
      <div>
        <List className="qr-outer">
                  <ListItem>
                  <ListItemAvatar>
                    <Avatar alt="QR" src="/static/img/qr.png" className="qrcode" variant="square"/> 
                  </ListItemAvatar>
                  <ListItemText primary="Check in for this event" secondary="Scan this QR code at the event to check in." />
                </ListItem>
                </List>

       
    </div>
      ); 
} 
export default QrCode; 