import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const http = axios.create({
 baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


export async function getEventWorldReport(payload) {
  const currentUser = cookies.get('currentUser');  
  return await
    http
      .post('events/getEventWorldReport',payload,{
        headers: {
          'Authorization':currentUser?`Bearer ${currentUser.token}`:``
        }
      }).then((response) => {
          return response.data;
      })
      .catch((error) => {
        throw error;
      });
}


export default {
    getEventWorldReport
}