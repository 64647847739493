import React, { useEffect, useState } from 'react'
import { getPlayereventDetails } from '../../services/mapInventoryService'
import {
  Paper,
  Box,
  IconButton,
  Grid,
  Typography,
  Divider,
  styled,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Check as CheckIcon,
  LocalMallOutlined as LocalMallOutlinedIcon
} from '@material-ui/icons';
import { Helmet } from 'react-helmet'
import Loading from '../../components/controls/Loading';
import Notification from '../../components/Notification';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export default function TeamStatsDialog({ eventId, eventDetailId }) {
  const [allocations, setAllocations] = useState([]) // Allocation details
  const [teamList, setTeamList] = useState([]) // To display team details
  const [maxValue, setMaxValue] = useState(0) // To set number of players in each team
  const [selectPosition, setSelectPosition] = useState([]) // To select player position
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const team = []

  useEffect(() => {
    const eventDetails = {
      action_type: 'player list',
      eventdetailid: eventDetailId,
      eventid: eventId,
    }
    getPlayereventDetails(eventDetails)
      .then((res) => {
        setAllocations(res.allocations)
        let largest = 0
        let count = 0
        for (let i = 0; i < res.data.length; i++) {
          count = count + Number(res.data[i].noofplayers)
          if (res.data[i].noofplayers > largest) {
            largest = res.data[i].noofplayers
            setMaxValue(res.data[i].noofplayers)
          }
        }
        if (res.allocations.length > 0) {
          let arr = []
          for (let k = res.data.length - 1; k >= 0; k--) {
            for (let j = 0; j < res.allocations.length; j++) {
              if (res.data[k].teamid === res.allocations[j].teamid) {
                let count = 0
                let playerid = res.data[k].playerid
                  ? res.data[k].playerid.toString().split(',')
                  : '' //Doubt
                let soldplayerid = res.data[k].playerid
                  ? res.data[k].playerid
                  : ''

                if (res.allocations[j].isapi == 1) {
                  for (
                    let m = res.data[k].noofplayers;
                    m >
                    Number(res.data[k].noofplayers) -
                      (Number(res.allocations[j].allocationcount) -
                        Number(res.allocations[j].soldcount)) -
                      count;
                    m--
                  ) {
                    if (playerid.includes(`${m}`)) {
                      count++
                    } else {
                      if (soldplayerid === '') {
                        soldplayerid = m
                      } else {
                        soldplayerid = soldplayerid + ',' + m
                        res.data[k].playerid = soldplayerid
                      }
                    }
                  }
                } else {
                  res.data[k].playerid = soldplayerid
                }
              }
            }
            for (let a = 0; a < team.length; a++) {
              if (res.data[k].teamid === team[a].teamid) {
                team[a].playerid
                  .toString()
                  .split(',')
                  .forEach((element) => {
                    if (
                      res.data[k].playerid == null ||
                      !res.data[k].playerid
                        .toString()
                        .split(',')
                        .includes(`${element}`)
                    ) {
                      arr.push({
                        teamid: Number(team[a].teamid),
                        playerid: Number(element),
                      })
                    }
                  })
              }
            }
          }
          setSelectPosition(arr)
        } else {
          if (team.length > 0) {
            let arr = []
            for (let x = 0; x < res.data.length; x++) {
              for (let a = 0; a < team.length; a++) {
                if (res.data[x].teamid === team[a].teamid) {
                  team[a].playerid
                    .toString()
                    .split(',')
                    .forEach((element) => {
                      if (
                        res.data[x].playerid == null ||
                        !res.data[x].playerid
                          .toString()
                          .split(',')
                          .includes(`${element}`)
                      ) {
                        arr.push({
                          teamid: Number(team[a].teamid),
                          playerid: Number(element),
                        })
                      }
                    })
                }
              }
            }
            setSelectPosition(arr)
          }
        }
        setTeamList(res.data)
      })
      .catch((error) => {
        const messages =
          error.response && !error.response.data.message
            ? error.response.data.error
              ? error.response.data.error
              : error.response.data
            : error.message
        notification(messages, 'error')
      })
  }, [])

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    })
  }

  return (
    <div className="mapping-widgetpage">
      <Helmet title="Select a Team" />
      <main>
        <Box sx={{ display: 'flex' }} className="page-heading">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="div" className="title">
              View team
            </Typography>
          </Box>
        </Box>
        <Divider className="my-2" />
        <Box sx={{ display: 'grid' }}>
          {teamList.length > 0 ? (
            teamList.map((team, key) => {
              const Values = [...Array(Number(maxValue))].map((_, i) => i + 1)

              const match = team.playerid
                ? team.playerid.toString().split(',')
                : ''

              return (
                <div style={{ width: '100%' }} key={key}>
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item lg={2} md={2} sm={1} xs={1}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Item className="playerbox teamname">
                            <Box className="bottom">
                              <Box className="player-name">{team.teamname}</Box>
                            </Box>
                          </Item>
                        </Box>
                      </Grid>
                      <Grid item lg={10} md={10} sm={11} xs={11}>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          {Values.map((e, i) =>
                            !match.includes(`${e}`) && team.noofplayers >= e ? (
                              <>
                                {!selectPosition.filter(
                                  (x) =>
                                    x.teamid === team.teamid && x.playerid === e
                                ).length > 0 ? (
                                  <Item className="playerbox">
                                    <Box className="top">PLAYER {e}</Box>
                                    <Box
                                      className="bottom"
                                      // onClick={(x) => {
                                      //   selectPlayer(
                                      //     true,
                                      //     e,
                                      //     team.teamid,
                                      //     team.teamname,
                                      //     team.noofplayers
                                      //   )
                                      // }}
                                    >
                                      <Box className="plus-icon">
                                        <IconButton className="addplayer">
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                      <Box>Select</Box>
                                    </Box>
                                  </Item>
                                ) : (
                                  <Item className="playerbox selected">
                                    <Box className="top">PLAYER {e}</Box>
                                    <Box
                                      className="bottom"
                                      // onClick={(x) => {
                                      //   selectPlayer(false, e, team.teamid)
                                      // }}
                                    >
                                      <Box className="plus-icon">
                                        <IconButton className="addplayer">
                                          <CheckIcon />
                                        </IconButton>
                                      </Box>
                                      <Box className="pointer">Selected</Box>
                                    </Box>
                                  </Item>
                                )}
                              </>
                            ) : (
                              <>
                                {' '}
                                <Item className="playerbox sold">
                                  <Box className="top">PLAYER {e}</Box>
                                  <Box className="bottom">
                                    <Box>
                                      <IconButton className="addplayer">
                                        <LocalMallOutlinedIcon />
                                      </IconButton>
                                    </Box>
                                    <Box>Sold</Box>
                                  </Box>
                                </Item>
                              </>
                            )
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              )
            })
          ) : (
            <Loading />
          )}
        </Box>
        <Notification notify={notify} setNotify={setNotify} />
      </main>
    </div>
  )
}
