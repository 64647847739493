import * as types from "../../constants";
const initialstate = {
  user: {},
  userid: null,
  event:[],
  order:[],
  orderupsell:[],
  exchangeticket:[],
  Template:null,
  Promocode:null
}
export default function reducer(state = initialstate, actions) {

  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.payload,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: {},
      };
      case types.AUTH_USER_DATA_SUCCESS:
        return {
          ...state,
          user: actions.payload,
        };
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        userid: actions.payload,
      };
      case types.EDIT_EVENT_LIST:
        return {
          ...state,
          event: actions.payload,
        };
    case types.ORDER_EVENT_LIST:
        return {
          ...state,
          order: actions.payload,
        };

    case types.ORDERUPSELL_EVENT_LIST:
        return {
          ...state,
          orderupsell: actions.payload,
        }
    case types.Exchange_Ticket:
          return {
            ...state,
            exchangeticket: actions.payload,
          }
      case types.EDIT_UPSELL:
          return {
            ...state,
            upsell: actions.payload,
      };
      case types.Coupon_Promo_Code:
        return{
          ...state,
          Promocode:actions.payload,
        }
      case types.Brand_Template:
        return {
          ...state,
          Template: actions.payload,
    };


   

    default:
      return state;
  }
}