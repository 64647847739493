import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, Link, TableCell, Toolbar, makeStyles, InputAdornment, Breadcrumbs, Typography, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Popup from "../../components/Popup";
import CustomerForm from './CustomerForm';
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import customerService from '../../services/customerService';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Loading from '../../components/controls/Loading';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },  
  pageContent: {
    margin: theme.spacing(3),
    marginBottom: '50px',
    [theme.breakpoints.up('xs')]: {
       margin: '0px auto',
       width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '60%',
    },
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,  
  addBar: {
    float: 'right',
    padding: '0px',   
  },
  select: {
    width: '100% !important',
  },

}));

const drawerWidth = 250;


const headCells = [

  { id: "accountid", label: "Customer ID" },
  { id: "accountname", label: " Name" },
  { id: "stripeid", label: " Stripe Account ID" },
  { id: 'actions', label: 'Actions', disableSorting: true }

];


const CustomerAccount = () => {

  const classes = useStyles();
  const history = useHistory();
  const [records, setRecords] = useState([]);

  const [titlename, setTitlename] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [condition, setCondition] = useState(false);
  const check = useSelector(state => state.authReducer.user);
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
 
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
  if(!error && !checkParam(Object.keys(error.response.data.message))){
    notification(error, 'error');
   } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
   }
  }

  useEffect(() => {  
    let cancel = false;  
    const currentUser = cookies.get('currentUser');
    const id = currentUser ? currentUser.userid : '';
    customerService.getAllCustomerAccount(id)
      .then(result => {
        if (cancel) return;
        setRecords([...result[0]])
        setShowLoading(false)
      })
      .catch((error) => {
        setShowLoading(false)
        errorHandle(error);
      }
      );

    return () => {
     cancel = true;
    }
  }, [])



  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn, condition);

  const handleSearch = e => {


    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {

          return items;
        }
        else {
          return items.filter(x => (x.accountname.toLowerCase().includes(searchString.toLowerCase())

          ))

        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }

  };


  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const timeOutSet =()=> {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
      cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const addOrEdit = (user,inclusiveTax, resetForm) => {    
    if (user.userid === 0) {
      const users =  {
        "id":0,
        "account_name":user.accountname,
        "action_type":"add",
        "user_id":1,
        "stripe_id":user.stripeid,
        "ticket_fee":Number(user.ticketfee),
        "isticketflator_perc":user.isticketflatorperc,
        "upsell_fee":Number(user.upsellfee),
        "isupsellflator_perc":user.isupsellflatorperc,
        "order_fee":Number(user.orderfee),
        "isorderflator_perc":user.isorderflatorperc,
        "istax_inclusive":inclusiveTax
    }
    

      customerService.insertCustomerAccount(users).then(res => {        
       customerService.getAllCustomerAccount({ userid: check.userid }).then(
        result =>{ setRecords([...result[0]]);
          notification(res, 'success');

        });
      }).catch(error => {        
        errorHandle(error);
      })
    }
    else {
      const users =   {
        "id":user.accountid,
        "account_name":user.accountname,
        "action_type":"edit",
        "user_id":currentUser.userid,
        "stripe_id":user.stripeid,
        "ticket_fee":Number(user.ticketfee),
        "isticketflator_perc":user.isticketflatorperc,
        "upsell_fee":Number(user.upsellfee),
        "isupsellflator_perc":user.isupsellflatorperc,
        "order_fee":Number(user.orderfee),
        "isorderflator_perc":user.isorderflatorperc,
        "istax_inclusive":inclusiveTax
    }
    
    
      customerService.updateCustomerAccount(users).then(res => {
        customerService.getAllCustomerAccount({ userid: check.userid }).then(
          (result) =>{ setRecords([...result[0]]);
            notification(res, 'success');
          });
      }).catch(error => {
        errorHandle(error);
      });
    }

    resetForm()
    setRecordForEdit(null)
    setOpenPopup(false)

  };

  const openInPopup = item => {    
    setRecordForEdit(item);
    setOpenPopup(true);
    setTitlename(false);
  };

  const onDelete = (user) => {    
    const users = {
      action_type: "delete",
      id: user.accountid
    }
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    customerService.deleteCustomerAccount(users).then(res => {
      customerService.getAllCustomerAccount({ userid: check.userid }).then(
        (result) =>{ setRecords([...result[0]]);
          notification(res, 'success');
        });

    }).catch((error) => {
      errorHandle(error);
    });
  };

  return (
    <div >
      <Helmet title="Customers" />
      <main className={classes.content}>
      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
      Customers
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
      <Link underline="hover" color="inherit">
      Admin Tools
      </Link>
          <Typography color="textPrimary">Customers</Typography>
      </Breadcrumbs>
      <Divider className="my-2" /> 
        <div className={classes.pageContent}>
          <Toolbar className={classes.addBar}>
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />
            
            <Controls.ActionButton
                        onClick={() => {
                          setOpenPopup(true); setRecordForEdit(null);
                          setTitlename(true); 
                        }} className="outlined-primary">
                        <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
                      </Controls.ActionButton>
            
          </Toolbar>
          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>
                    <TableCell className="p-1">{record.accountid}</TableCell>
                    <TableCell className="p-1">{record.accountname}</TableCell>
                    <TableCell className="p-1">{record.stripeid}</TableCell>

                    <TableCell className="p-1">

                      <Controls.ActionButton
                        onClick={() => { openInPopup(record) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to delete the record of the Account [ ${record.accountname} ]?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete(record) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length && <TableRow style={{ textAlign: 'center' }}>
            {showLoading?(<TableCell className="p-1 text-left w-100" colSpan="4"><Loading /></TableCell>):<TableCell className="p-1 text-left" colSpan="4">{ <Alert severity="warning">No records found</Alert> }</TableCell>} 
              </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination />
        </div>
        <Popup
          title={titlename ? "Create Customer Account" : "Edit Customer Account"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <CustomerForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}

            cancelbutton={cancelbutton}
          />
        </Popup>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default CustomerAccount;