import { Grid, makeStyles, Typography, Box, Paper, Avatar, Divider  } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import userService from '../../services/userService';   
import { useSelector ,useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import Footer from "../../components/layouts/Footer";
import { Helmet } from "react-helmet";
import Notification from '../../components/Notification';
import { getUserData } from '../../redux/actions/authActions';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme=>({
  root:{
    width: '100%',
  },
    pageContent:{
      margin: theme.spacing(3),
      padding: theme.spacing(3)
    }, 
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  }, 
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  rounded: {
    borderRadius:'100px',
    width: '200px',
    height: '200px',
    border: 'solid 1px #ccc',
  },
  }));
  
  const drawerWidth = 250;

const initialFValues = {
    firstname : "",
    lastname : "",
    emailid : "",
    mobilenumber : "",
    accountname : "",
    role : "",
    userid:0,
    active:1
};
const UserProfile = (props) =>{
  const history = useHistory();
   const classes = useStyles();
   const [mode, setMode] = useState('view');
   const cookies = new Cookies();
   const profileValues = useSelector(state=>state.authReducer.user);
   const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
   const currentUser = cookies.get('currentUser');
   const dispatch = useDispatch();

    const validate = (fieldValues = values)=>{
        const temp = {...errors};
        if("firstname" in fieldValues){
            temp.firstname = fieldValues.firstname?"":"FirstName is required";
        }
        if("lastname" in fieldValues){
            temp.lastname = fieldValues.lastname?"":"LastName is required";
        }
        
        if("mobilenumber" in fieldValues){
            temp.mobilenumber = fieldValues.mobilenumber.length > 9 ? "" : "Minimum 10 numbers required";
        }
        setErrors({...temp});

    };
    const formIsValid = (fieldValues = values) => {
        validate();
        const isValid =
          fieldValues.firstname &&
          fieldValues.lastname &&
          fieldValues.mobilenumber &&
          Object.values(errors).every((x) => x === "");
    
        return isValid;
      };
    const {values,
           setValues,
           errors,
           setErrors,
           handleInputChange,
           resetForm } = useForm(initialFValues,true,validate);
            useEffect(()=>{
            if(Object.keys(profileValues).length !== 0){
                //cookies.set('currentUser', {userid:profileValues.userid});
                setValues(profileValues)
              } else {
                userService.getUserById(currentUser.userid)
                  .then((result) => {
                    setValues(result);
                    dispatch(getUserData(result));
                  })
                  .catch((error) => {
                  
                    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
                    if (messages === 'Invalid Token') {
                      notification('Your session expired', 'error');
                      timeOutSet();
                    } else {
                      notification(messages, 'error');
                    }
                  });
              }
            }, [])

    const handleSubmit = (event)=> {
        event.preventDefault();
        if (formIsValid()) {
            addOrEdit(values, resetForm);

        }
    };

    const notification = (message,type)=>{
      setNotify({
        isOpen: true,
        message: message,
        type: type
      })
    }
    
const timeOutSet =()=> {
  setTimeout(() => {
    cookies.remove('currentUser');history.push('/');
  }, 1000);
}

    const addOrEdit = (user, resetForm) => {
      
          userService.editUsersput(user).then(res => {
            if (res) {

                setMode('view');
                notification('User updated Successfully','success')

                userService.getUserById(currentUser.userid)
                .then((result) => {
                  setValues(result)
                dispatch( getUserData(result));
             }) 
            .catch(error=>{
              const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
              if (messages === 'Invalid Token') {
                notification('Your session expired', 'error');
                timeOutSet();
              }
              else {
                notification(messages, 'error');
              }
    });  
                                
            }
          }).catch(error=>{
            const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
            if (messages === 'Invalid Token'){
            //notification(messages,'error');
            notification('Your session expired', 'error');
            timeOutSet();
            }
            else {
              notification(messages, 'error');
            }
          });
       
                
          
    
        };
        
                return(   
                                  
                    
            <div>
                <Helmet title="User Profile" />
                <Notification
          notify={notify}
          setNotify={setNotify}
      />
             {mode === 'view'?
             <div>
            <main className={classes.content}> 
            <Typography variant="h5" gutterBottom className="breadcrumbs">
              User Profile
              </Typography>
              <Divider />
            <Paper className={classes.pageContent}>
                <Grid container spacing={5} width="100">
                    <Grid item lg={3} xs={12} className="bg-profile" style={{display:'flex', justifyContent:'center', verticalAlign:'middle'}}> 
                    <Avatar alt="User" src="/static/img/profile-default.png" variant="rounded" className={classes.rounded}/>   
                    </Grid>
                <Grid item lg={9} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Box fontSize="h6.fontSize">{values.firstname} {values.lastname}</Box>
                      </Grid>
                      <Grid item lg={3} sm={12} xs={12}>
                        <b>Role</b>
                      </Grid>
                      <Grid item lg={9} sm={12} xs={12}>
                      {values.role}
                      </Grid>
                      <Grid item lg={3} sm={12} xs={12}>
                        <b>Customer Account</b>
                      </Grid>
                      <Grid item lg={9} sm={12} xs={12}>
                        {values.accountname}
                      </Grid>
                      <Grid item lg={3} sm={12} xs={12}>
                        <b>Email Id</b>
                      </Grid>
                      <Grid item lg={9} sm={12} xs={12}>
                      {values.emailid}
                      </Grid>
                      <Grid item lg={3} sm={12} xs={12}>
                        <b>Mobile</b>
                      </Grid>
                      <Grid item lg={9} sm={12} xs={12}>
                        {values.mobilenumber}
                      </Grid>
                      <Grid item lg={3} sm={12} xs={12}>
                      <Controls.Button
                            text="Edit"
                            variant="contained" color="primary" size="medium" className="link-red"                            
                            onClick={() => setMode('edit') }/> 
                      </Grid>
                  </Grid>
                
                </Grid>
                </Grid>
                
            </Paper>
            
            </main> 
             <Footer /> 
            </div>               
               
               
                    :
                        <div >
                    <main className={classes.content}> 
                      <Typography variant="h5" gutterBottom className={classes.breadcrumbs}>
                      User Profile
                      </Typography>
                       <Divider />
                        <Form onSubmit={handleSubmit}>
                        <Paper className={classes.pageContent}>   
                        <Grid container spacing={5} width="100">                            
                            <Grid item lg={3} xs={12} className="bg-profile" style={{display:'flex', justifyContent:'center', verticalAlign:'middle'}}>
                            <Avatar alt="User" src="/static/img/profile-default.png" variant="rounded" className={classes.rounded}/>    
                            </Grid>
                            <Grid item lg={9} sm={12} xs={12}>
                            <Grid container spacing={1}>                           
                            <Grid item lg={3} sm={12} xs={12} >
                              <b>Role</b>
                            </Grid>
                            <Grid item lg={9} sm={12} xs={12} >
                            {values.role}
                            </Grid>
                            <Grid item lg={3} sm={12} xs={12}>
                              <b>Customer Account</b>
                            </Grid>
                            <Grid item lg={9} sm={12} xs={12}>
                            {values.accountname}
                            </Grid>
                            <Grid item lg={3} sm={12} xs={12}>
                              <b>Email Id</b>
                            </Grid>
                            <Grid item lg={9} sm={12} xs={12}>
                            {values.emailid}
                            </Grid>
                            <Grid item lg={7} sm={12} xs={12}>
                            <Controls.Input
                                                name="firstname"
                                                label="First Name" size="medium"
                                                value={values.firstname||''}
                                                onChange={handleInputChange}
                                                error={errors.firstname} className="w-100 ml-0"/>
                            </Grid>
                            <Grid item lg={7} sm={12} xs={12}>
                            <Controls.Input
                                                name="lastname"
                                                label="Last Name" size="medium"
                                                value={values.lastname||''}
                                                onChange={handleInputChange}
                                                error={errors.lastname} className="w-100 ml-0"/>
                            </Grid>
                            <Grid item lg={7} sm={12} xs={12}>
                            <Controls.Input
                                                name="mobilenumber"
                                                label="Mobile" size="medium"
                                                value={values.mobilenumber||''}
                                                onChange={handleInputChange}
                                                error={errors.mobilenumber} className="w-100 ml-0"/>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <Controls.Button
                                                type="submit"
                                                text="Update" size="medium" className="link-red"/>
                                            <Controls.Button
                                                text="Cancel"
                                                color="default" size="medium" className="link-gray"
                                                onClick={() =>{resetForm(); setValues(profileValues); setMode('view')} }/>
                                </Grid>
                            </Grid>
                           
                            </Grid>
                        </Grid>
                                </Paper> 
                            </Form>
                            </main>
                   <Footer /> 
                   
                   </div>
                  
                }  
                </div>
)};

export default UserProfile;