import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});

async function getAllCustomerAccount(userid) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/customers/getAllCustomerAccount`, userid, {
        headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}



async function insertCustomerAccount(user) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/customers/insertCustomerAccount`, user, {
        headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

async function updateCustomerAccount(user) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/customers/updateCustomerAccount`, user, {
        headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}




async function deleteCustomerAccount(userid) {
  const currentUser = cookies.get('currentUser');
  return await
    http
      .post(`/customers/deleteCustomerAccount`, userid, {
        headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
}

async function getAllTaxCategory(user){
  const currentUser = cookies.get('currentUser');
  return await 
  http
  .post(`/customers/getAllTaxCategory`, user, {
    headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    throw error;
  });
}

async function getTaxSubcategory(user){
  const currentUser = cookies.get('currentUser');
  return await 
  http
  .post(`/customers/getTaxSubcategory`, user, {
    headers: { 'Authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    throw error;
  });
}




export default {
  getAllCustomerAccount, insertCustomerAccount, updateCustomerAccount,
  deleteCustomerAccount,getAllTaxCategory,getTaxSubcategory
};