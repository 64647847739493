import React from 'react';
import EventCreation from "./EventCreation";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import { NavLink, useParams } from "react-router-dom";
import Form from '../../components/Form';
import Controls from '../../components/controls/Controls';

import {
  makeStyles,
  Breadcrumbs,
  Typography,
  Link,
  Grid,
  Divider,
} from "@material-ui/core";
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  }, 
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
 
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
buttonRight:{
  display: 'grid',
  justifyContent: 'flex-end',
}
}));
const drawerWidth = 250;



const EventForm = () => {
  const classes = useStyles();
  const childRef = useRef();
  const params = useParams();

  const handleSubmited = (event) => {
    event.preventDefault();
    childRef.current.handleSubmit();

  };

  return (
    <div >
      <Helmet title="Event Configuration" />
      <main className={classes.content}>

      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
      Event Configuration
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
      <Link component={NavLink} exact to="/Events" underline="hover" color="inherit">
            Events
      </Link>
          <Typography color="textPrimary">Event Configuration</Typography>
      </Breadcrumbs>
      <Divider className="mb-3" />
          <Form onSubmit={handleSubmited} className="create-user">
            <EventCreation ref={childRef} eventId={params !== undefined ? params.eventId : null} />            
            <Grid container item spacing={2} lg={12} md={12} sm={12} xs={12} className={classes.buttonRight}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Controls.Button
              type="submit"
              text="Submit" className="link-red" />
              </Grid>
              </Grid>
          </Form>
      </main>
      <Footer />

    </div>
  );
};
export default EventForm;