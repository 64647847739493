import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, Dialog, DialogTitle, Tabs, Tab, TableCell, Divider, DialogActions, DialogContent, Toolbar, FormControl, Select, makeStyles, InputAdornment, Button, Typography, Grid, IconButton, MenuItem, Box } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import XLSX from 'xlsx'
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import { useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import orderService from "../../services/orderService";
import userService from '../../services/userService';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { order, orderupsell } from "../../redux/actions/authActions";
import "./order.scss";
import { dateformat, timeValidation, universaldate } from '../../utils/dateFormat';
import { Alert } from "@material-ui/lab";
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import BackToTop from "../../components/controls/BackToTop";
import moment from 'moment';
import Loading from '../../components/controls/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
    }
  },
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'center'
  },
  titleIcon: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      cursor: 'default'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
    }
  }

}));

const drawerWidth = 250;

const headCells = [
  { id: "endusername", label: "Name" },
  { id: "email", label: "Email" },
  { id: "paymentid", label: "Payment ID" },
  { id: "eventname", label: "Event" },
  { id: "eventdatetime", label: "Date & Time" },
  { id: "total", label: "Order Total" },
  { id: "insertedtime", label: "Event Purchased Date" },
  { id: "paymentstatus", label: "Status" },
  { id: 'actions', label: 'Actions', disableSorting: true }
];

const Order = () => {
  const classes = useStyles();
  const history = useHistory();
  const filterFn = { fn: items => { return items; } };
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [details, setDetails] = useState([]);
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [inputValue, setInputValue] = useState({ email: '', });
  const [open, setOpen] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const firstName = currentUser.firstName ? currentUser.firstName : ''
  const lastName = currentUser.lastName ? currentUser.lastName : ''
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [account, setAccount] = React.useState([]);
  const [pay, setPay] = React.useState("");
  const [value, setValue] = useState(0);

 
  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }
  useEffect(() => {
    let cancel = false;
    handleSearch(0);
    userService.getUserAccounts()
      .then(result => {
        if (cancel) return;
        setRecord([...result]);
        setSelectedAccountId(currentUser.accountid);
        setAccount(result)

      })
      .catch((error) => {

        errorHandle(error)

      });
    return () => {
      cancel = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const Orderdetail = (eventinfo) => {
    const orderdetails = eventinfo
    dispatch(order(orderdetails));
    const ticketdetail = eventinfo.eventticketdetailid
    const orderupsells = details.filter((e) => e.eventticketdetailid === ticketdetail)
    dispatch(orderupsell(orderupsells));
    history.push(`/OrderDetails/${ticketdetail}`)
  }

  const exchangeTicket = (eventinfo) => {
    history.push(`/ExchangeTicket/${eventinfo.eventticketdetailid}/${eventinfo.eventid}/${eventinfo.eventdetailid}`);
  }


  const DeleteOrder = (e) => {

    const paymentID = e.paymentid
    setPay(paymentID)
    const filterPaymentid = records.filter((e) => e.paymentid === paymentID)

    const now = new Date();
    const day = now.getDate() < 10 ? "0" + now.getDate() : now.getDate()
    const nowDate = (now.getMonth() + 1) + "/" + day + "/" + now.getFullYear()
    const nowTime = (now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds())

    let hours = nowTime.split(':')[0];
    let minutes = nowTime.split(':')[1];
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    const currentdate = (nowDate + ',' + strTime)


    let count = 0;
    filterPaymentid.forEach(ele => {
      const evedatetime = ele.eventdatetime
      const startT = evedatetime.replace('AM', ' AM').replace('PM', ' PM')

      if ((Number(ele.recordstatus) === 1) && dateformat(startT) < new Date(currentdate)) {
        notification("This order contains unused tickets. User info cannot be removed", 'error')
      }
      if (dateformat(startT) > new Date(currentdate) && (Number(ele.recordstatus)) === 1) {
        notification("This order contains events that have not occurred yet. User info cannot be removed", 'error')
      }
      if ((dateformat(startT) < new Date(currentdate)) && (Number(ele.recordstatus) === 2)) {
        count = count + 1;

      }
    })
    if (count > 0) {
      setOpen(true)
    }

  }



  const handleSearch = (e) => {

      const statusUpdate = e === 0  ? "order" : "Reserved"
      const user = { account_id: selectedAccountId, email_id: inputValue.email, eventticketdetail_id: 0, payment_method: statusUpdate };
      let cancel = false;
      orderService.searchOrderDetails(user).then((res) => {
        if (cancel) return;
        if (res.message.toLowerCase() === 'no data found') {
          setRecords([])
          setShowResults(true)
          setShow(false)
        } else {

          setRecords(res.data.eventinfo)
          setDetails(res.data.eventdetailinfo)
          setShowResults(true)
          setShow(true)

        }
      })
        .catch((error) => {
          errorHandle(error)
        });
      return () => {
        cancel = true;
      }

  
  };

  const getAccountidDetails = (e) => {
    setSelectedAccountId(e.target.value)
  }


  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }
  const handleChange = (e) => {
    e.persist();
    setInputValue(inputValue => ({
      ...inputValue,
      [e.target.name]: e.target.value
    }));

  }



  const exportFile = () => {
    const downloadfile = records.map((e) => {
     
     const datas = value !== 0 ? { "ReserveID" : e.reservationId } :{};    
      return {
        "Name": e.endusername,
        "Email": e.email,
        "PaymentID": e.paymentid,
        ...datas,
        "EventName": `${e.eventname} ${e.subcategory ? e.subcategory : ''}`,
        "EventDateTime": universaldate(e.eventdatetime),
        "OrderTotal": Number(e.total).toFixed(2),
        "Purchased Time": moment(e.insertedtime).format('dd-mmm-yyyy')
      }

    })

    const ws = XLSX.utils.json_to_sheet(downloadfile);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Orders.csv");
  };


  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    const deleteorder = {
      payment_id: pay
    }
    orderService.updatePaymentUserData(deleteorder).then((res) => {
      handleSearch(value);
      notification('Deleted Successfully', 'success')
      setOpen(false);
    }).catch((error) => {

      errorHandle(error)
      setOpen(false);
    })

  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn);

  const handleChanged = (event, newValue) => {
    handleSearch(newValue);
    setValue(newValue);
    setShowResults(false);
    setRecords([]);
  }

  return (
    <div >
      <Helmet title="Order" />
      <main className={classes.content}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={6} md={7} sm={7} xs={12}>
              <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                Orders
                        </Typography>
              <Box variant="subtitle1" className="welcome-msg">
                <span style={{ flexGrow: '1', paddingBottom: '12px' }}>Welcome, <strong>{firstName} {lastName}</strong></span>
              </Box>
            </Grid>
            <Grid item lg={6} md={5} sm={5} xs={12}>
              <Toolbar className="selectbar">
                <Box>
                  {currentUser.roleid === 1 && record.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={getAccountidDetails}
                      className="select-box mt-0 mb-0"
                    >
                      <option value="0">Select Customer</option>
                      {(record).map((v, k) => {
                        if (v.roleid !== 1)
                          return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                        else
                          return null
                      })}
                    </select>
                  ) : (<div></div>)}
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <Divider />

        <div className="pageContent">
         
            <Grid item xs={12} sm={12} md={12} lg={12} xl className="pt-4">
              
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    className="global-tab"
                    onChange={handleChanged}
                  >
                    <Tab label="Order Details" className="btn" value={0} />
                    <Tab label="In Progress" className="btn"  value={1} />
                  </Tabs>
                </Box>

              
            </Grid>

          
           <Toolbar className={classes.Toolbar}>
            { value === 0 ? 
            <>
            <Input
              type="email"
              autoComplete="off"
              name="email"
              label="Search for Order"
              placeholder="Enter Email, Last Name or Phone"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleChange}
              size="small" className="form-control form-control-md"
              value={inputValue.email}
              required /> 

            <Controls.Button text="search" onClick={() => handleSearch(value)} className="link-red">Search</Controls.Button>
            </>
            : null }
          </Toolbar>
          {showResults  ?
            (<Box sx={{ display: 'flex' }} className="page-title">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">
                  Results
                </Typography>
              </Box>
              {show&&showResults ?
                <Box>

                  <Button onClick={exportFile} size="small" className="btn-redoutline m-2" startIcon={<GetAppOutlinedIcon />} >Export-CSV</Button>

                </Box> : <div></div>}
            </Box>) : <div></div>}


             <TblContainer className={classes.container}>
              <TblHead />
              <TableBody>
                {
                  showResults && recordsAfterPagingAndSorting().map((items, index) => (
                    <TableRow key={index}>
                      {/* <TableCell className="p-1">{record.accountid}</TableCell> */}
                      <TableCell>{items.endusername}</TableCell>
                      <TableCell>{items.email}</TableCell>
                      <TableCell>{items.paymentid}</TableCell>
                      <TableCell>{items.eventname} {items.subcategory ? items.subcategory : ''}</TableCell>
                      <TableCell>{universaldate(items.eventdatetime)}</TableCell>
                      <TableCell>{Number(items.total).toFixed(2)}</TableCell>
                      <TableCell>{moment(items.insertedtime).format()}</TableCell>
                      <TableCell>{items.paymentstatus}{items.reservationId ? ' Reservation: ' + items.reservationId : ''}</TableCell>
                      <TableCell className="custom-control">
                        <FormControl>
                          <Select className="custom-morevertical">
                            <MenuItem onClick={() => Orderdetail(items)}>View Order Detail</MenuItem>
                            {value === 0 ?
                              <div>
                                {timeValidation(items.eventdatetime) && items.paymentstatus === "Success" && Number(items.ticketdetails?.filter(t => t.ticketstatus === 0).length) === Number(items.nooftickets) && !items.endusername?.toLowerCase().includes('rem_') && !items.email?.toLowerCase().includes('rem_') ? <MenuItem onClick={() => exchangeTicket(items)}>Exchange Ticket</MenuItem> : null}
                                {items.paymentstatus === "Success" && !items.endusername?.toLowerCase().includes('rem_') && !items.email?.toLowerCase().includes('rem_') ? <MenuItem onClick={() => DeleteOrder(items)}>Delete User Info</MenuItem> : null}

                              </div> : null}

                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))
                }

              </TableBody>
              <TableBody>
              {!recordsAfterPagingAndSorting().length && (
                <TableRow style={{ textAlign: "center" }} key={0}> 
                {!showResults?(<TableCell className="p-1 text-left w-100" colSpan="8"><Loading /></TableCell>):                 
                  <TableCell className="p-1 text-left" colSpan="9">{ <Alert severity="warning">No records found</Alert>  }</TableCell> }
                </TableRow>
              )}
              </TableBody>
            </TblContainer>

           <TblPagination />
        </div>

        <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialog }}>
          <DialogTitle className={classes.dialogTitle}>
            <IconButton disableRipple className={classes.titleIcon}>
              <NotListedLocationIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h6">
              Are you sure you want to delete the name and email from this order?
                </Typography>
            <Typography variant="subtitle2">
              This can’t be undone.
                </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Controls.Button
              text="Cancel"
              color="default" size="medium"
              onClick={handleClose} className="link-gray" />
            <Controls.Button
              text="Continue"
              color="secondary" size="medium"
              onClick={handleContinue} className="link-red" />
          </DialogActions>
        </Dialog>

        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <BackToTop />
      </main>
      <Footer />
    </div>

  );

};

export default Order;