import React from 'react';
import { Switch } from "@material-ui/core";

const SwitchToggle = ({charts}) => {
  const [chart,setChart]=React.useState(false)
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const change=()=>{
    setChart(!chart)
    charts(chart);
  }
    return (
      <div>
        <Switch {...label} onChange={change} size="small" className="switchToggle"/>
    </div>
      ); 
} 
export default SwitchToggle; 