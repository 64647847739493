
import { Grid, makeStyles, DialogActions, Divider, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { getAllVenue } from '../../services/venueService';
import { Autocomplete } from '@material-ui/lab';



const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  breadcrumbs: {
    margin: theme.spacing(3),
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    marginBottom: '50px',
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginTop: '10px !important',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  modalTitle: {
    background: '#be1915',
    color: '#ffffff'
  },
  addBar: {
    float: 'right',
    justifyContent: 'flex-end',
    width: 'auto',
    padding: '0px !important',
    position: 'absolute !important',
    top: '-11px',
    zIndex: '999',
    right: '19px',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      position: 'inherit !important',
      right: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      position: 'inherit !important',
      right: '0px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      position: 'absolute !important',
      right: '19px',

    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute !important',
      top: '-11px',
      zIndex: '999',
      right: '19px',
      width: 'auto',
    },
  },
  select: {
    width: '100% !important',
  },
  dialogWrapper: {
    padding: theme.spacing(0),
    position: 'absolute',
    top: theme.spacing(5),
    width: '50%',
  },
  dialogTitle: {
    paddingRight: '0px',
    background: '#be1915',
    color: '#ffffff',
    padding: '10px',
  },

}));
const drawerWidth = 250;


const initialFValues = {
  venueid: 0,
  venuename: "",
  address: "",
  cityid: 0,
  stateid: 0,
  countryid: 0,
  zipcodeid: 0,
  timezoneid: 0,
  currencyid: 0,
  taxcategoryid: 0,



};



const VenueForm = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [city, setCity] = useState([]);
  // const [state, setState] = useState([]);
  // const [country, setCountry] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [taxcategoryid, setTaxcategoryid] = useState([]);
  const [stateID, setStateID] = useState(0);
  const [countryID, setCountryID] = useState(0);
  const [stateName, setStateName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const cookies = new Cookies();
  const [valueTime,setValueTime]=useState('');
  const [zipvalue, setZipValue] = useState("");

  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
  if(!error && !checkParam(Object.keys(error))){
    notification(error, 'error');
   } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
   }
  }

  useEffect(() => {

    let cancel = false;
    const user = { action_id: 1, city_id: 0 };
    getAllVenue(user).then(result => {
      if (cancel) return;
      setCity(result[0]);
      setRecords(result[1]);
      // setState(result[1].map((e) => {
      //   return {
      //     stateid: e.stateid,
      //     state: e.state
      //   }
      // }))
      // setCountry(result[1].map((e) => {
      //   return {
      //     countryid: e.countryid,
      //     country: e.country
      //   }
      // }))
    }).catch((error) => {
      errorHandle(error);
    }
    );
    return () => {
      cancel = true;
    }
  }, [])
  useEffect(() => {

    let cancel = false;
    const user = { action_id: 3, city_id: 0 };
    getAllVenue(user).then(result => {
      if (cancel) return;
      setCurrency(result[0]);
    }).catch((error) => {
      errorHandle(error);
    }
    );
    return () => {
      cancel = true;
    }
  }, [])
  useEffect(() => {

    let cancel = false;
    const user = { action_id: 4, city_id: 0 };
    getAllVenue(user).then(result => {
      if (cancel) return;
      setTaxcategoryid(result[0]);
    }).catch((error) => {
      errorHandle(error);
    }
    );
    return () => {
      cancel = true;
    }
  }, [])

  useEffect(() => {

    let cancel = false;
    const user = { action_id: 5, city_id: 0 };
    getAllVenue(user).then(result => {
      if (cancel) return;
      setTimezone(result[0]);
    }).catch((error) => {
      errorHandle(error);
    }
    );
    return () => {
      cancel = true;
    }
  }, [])


  const { addOrEdit, recordForEdit, cancelbutton } = props;

  const validate = (fieldValues = values) => {

    const temp = { ...errors };
    if ("venuename" in fieldValues) {
      temp.venuename = fieldValues.venuename ? "" : 'VenueName is required';
    }
    if ("address" in fieldValues) {
      temp.address = fieldValues.address ? "" : 'Address is required';
    }
    if ("cityid" in fieldValues) {
      temp.cityid = fieldValues.cityid ? "" : 'City is required';
    }

    if ("stateid" in fieldValues) {
      if (values.cityid) {
        temp.stateid = "";
      }
      else {
        temp.stateid = fieldValues.stateid ? "" : 'State is required';
      }
    }
    if ("countryid" in fieldValues) {
      if (values.cityid) {
        temp.countryid = "";
      }
      else {
        temp.countryid = fieldValues.countryid ? "" : 'Country is required';
      }
    }

    if ("zipcodeid" in fieldValues) {
      if(fieldValues.zipcodeid=== -10){
        temp.zipcodeid="Enter Valid Zipcode"
      }
      else{
      temp.zipcodeid = fieldValues.zipcodeid ? "" : 'Zipcode is required';
      }
    }
    if ("timezoneid" in fieldValues) {
      temp.timezoneid = fieldValues.timezoneid ? "" : 'Timezone is required';
    }
    if ("currencyid" in fieldValues) {
      temp.currencyid = fieldValues.currencyid ? "" : 'Currency is required';
    }
    if ("taxcategoryid" in fieldValues) {
      temp.taxcategoryid = fieldValues.taxcategoryid ? "" : 'Tax is required';
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");


  };

  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      addOrEdit(values, stateID, countryID, resetForm);
    }
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
       cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  useEffect(() => {
    let cancel = false;
    if (recordForEdit !== null){
      setValues(recordForEdit)
      setValueTime(recordForEdit.timezone)
      setZipValue(recordForEdit.zipcode)
      const user = { action_id: 2, city_id: recordForEdit.cityid};
      getAllVenue(user).then(result => {
        if (cancel) return;
        setZipcode(result[0]);
      }).catch((error) => {
        errorHandle(error);
      }
      );
    }
    return () => {
      cancel = true;
    }
  }, [recordForEdit]);

  const handleInputChanged = (event) => {

    handleInputChange(event);
    const data = records.find((c) => c.cityid === event.target.value);

    setStateID(data.stateid);

    setCountryID(data.countryid)
    setStateName(data.state)
    setCountryName(data.country)
    const user = { action_id: 2, city_id: data.cityid };
    getAllVenue(user).then(result => {
      setZipcode(result[0]);
    }).catch((error) => {
      errorHandle(error);
    }
    );
  }

  return (

    <Form onSubmit={handleSubmit} className="create-user">
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <Grid container item spacing={2} md={12} className="m-0">
        <Grid item lg={12} md={6} md={6} xs={12}>
          <Controls.Input
            type="text"
            name="venuename"
            label="Name"
            value={values.venuename || ''}
            onChange={handleInputChange}
            error={errors.venuename} />
        </Grid>
        <Grid item lg={12} md={6} md={6} xs={12}>
          <Controls.Input
            type="text"
            name="address"
            label="Address"
            value={values.address || ''}
            onChange={handleInputChange}
            error={errors.address} />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          <Controls.Select
            name="cityid"
            label="City"
            value={values.cityid || ''}
            options={city}
            onChange={handleInputChanged}
            keyName="cityid"
            valueName="city"
            error={errors.cityid} className={classes.select}
            fullWidth

          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
            <Controls.Input
            type="text"
            name="stateid"
            label="State"
            value={stateName||values.state || ''}
            onChange={handleInputChange}
            disabled={true}
            error={errors.stateid} className="smaller-input" />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">

          <Controls.Input
            type="text"
            name="countryid"
            label="Country"
            value={countryName||values.country || ''}
            onChange={handleInputChange}
            disabled={true}
            error={errors.countryid} className="smaller-input" />
        </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          <Autocomplete
        freeSolo
        onInputChange={(_, val) => {
          setZipValue(val);

          const ZipCode = zipcode.find((e)=>e.zipcode===val);
          if(ZipCode){
            setValues({
              ...values,
              ['zipcodeid'] : Number(ZipCode.zipcodeid)
          });
          }

          else {
            setValues({
              ...values,
              ['zipcodeid'] : -10
          });
          }
        }}
        inputValue={zipvalue}
        name="zipcodeid"
        disableClearable
        options={zipcode.map((option) => option.zipcode)}
        defaultValue={zipvalue || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Zipcode/Postal Code"
            variant="outlined"
            fullWidth
            InputProps={{ ...params.InputProps, type: "search" }}
            error={values.zipcodeid<=0&&errors.zipcodeid}
           helperText={values.zipcodeid<=0?errors.zipcodeid:''}
           className={classes.select}
          />
        )}
        
        />
      </Grid>
      
      <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          
          <Autocomplete
        freeSolo
        onInputChange={(_, val) => {
          setValueTime(val);
          const TimeZone = timezone.find((e)=>e.timezone===val);
          if(TimeZone){
            setValues({
              ...values,
              ['timezoneid'] : Number(TimeZone.timezoneid)
          });
          }
        }}
        inputValue={valueTime}
        name="timezoneid"
        disableClearable
        options={timezone.map((option) => option.timezone)}
        defaultValue={valueTime || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Timezone"
            variant="outlined"
            fullWidth
            InputProps={{ ...params.InputProps, type: "search" }}
            error={values.timezoneid===0&&errors.timezoneid}
           helperText={values.timezoneid===0?errors.timezoneid:''}
           className={classes.select}
          />
        )}
      />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          <Controls.Select
            name="currencyid"
            label="Currency"
            value={values.currencyid || ''}
            options={currency}
            onChange={handleInputChange}
            keyName="currencyid"
            valueName="currencytype"
            error={errors.currencyid} className={classes.select}
            fullWidth
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className="w-100">
          <Controls.Select
            name="taxcategoryid"
            label="Tax"
            value={values.taxcategoryid || ''}
            options={taxcategoryid}
            onChange={handleInputChange}
            keyName="taxcategoryid"
            valueName="concatpercentage"
            error={errors.taxcategoryid} className={classes.select}
            fullWidth
          />
        </Grid>
        <Divider></Divider>
        <DialogActions className="w-100">
          <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Controls.Button
              text="Clear"
              onClick={()=>{resetForm(); cancelbutton()}} className="link-gray" />
            <Controls.Button
              type="submit"
              text="Submit" className="link-red" />
          </Grid>
        </DialogActions>
      </Grid>

    </Form>
  )
};

export default VenueForm;