import React, { useEffect,useState } from "react";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import {
    Card,
    CardHeader,
    CardContent,
    Table,
    TableHead,
    TableBody,
    TableRow, 
    TableCell,
    IconButton,
    Menu, 
    MenuItem,
   } from "@material-ui/core";
   import MoreVertIcon from '@material-ui/icons/MoreVert';
import eventService, { getEventByAccountId } from "../../services/eventService";
import { getEventById } from '../../services/eventService';
import XLSX from 'xlsx'
import { universaldate } from "../../utils/dateFormat";
import Notification from "../../components/Notification";


 const TicketBuyers = ({AccountId})=>{
  const [location,setLocation] = useState([]) 
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const cookies = new Cookies();
  const history = useHistory();
   const [eventName,setEventName] = useState([]); 
  const [headingName,setHeadingName] = useState([]); 
  const [selectValue, setselectValue] = useState('');
  const [showTiming,setShowTiming]=useState([]);
  const [timeValue,setTimeValue]=useState('');

  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
    if(!error && !checkParam(Object.keys(error))){
      notification(error, 'error');
     } else{
    const messages = (error.response) && !error.response.data.message ? error.response.data : error.message;
     messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
     }
    }

const handleChange=(e)=>{  
  setselectValue(e.target.value);
  eventReport(e.target.value,'');
  getEventById({eventid:e.target.value}).then((result) => {    
    setShowTiming(result.data[0].eventdetailfile);        
  })
}
  

useEffect(()=>{ 
  let cancel = false;
  getEventByAccountId({"accountid" : Number(AccountId)})
  .then((res) => {   
    if (cancel) return;
    if(res.data!==undefined){      
    setHeadingName(res.data);
    setEventName((res.data).map((item, i) => {      
      return ( <option key={i} value={item.id}>{item.eventname}</option> )
      }))
 
    }else{
      setLocation([]);
    }
  })
  .catch((error) => {
           
    errorHandle(error)
  
   });
   return () => {
    cancel = true;
  }
},[AccountId])
const eventReport=(eventids,eventDetail)=>{
  const users = {
      eventid:eventids,
      eventdetailid:eventDetail
    }
    let cancel = false;
    eventService.getEventReport(users).then((result) => {
      if (cancel) return;
      
      setLocation(result.data)
    }).catch((error) => {
      setLocation([]);      
      //errorHandle(error)
  

    });
    return () => {
      cancel = true;
    }
    }
  

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

const handleClose = () => {
    setAnchorEl(null);
  };
  const timeChange = (e) =>{      
    setTimeValue(e.target.value);
    eventReport(selectValue,e.target.value);

  }

  const exportFile = () => {
const pdfName = headingName.find(e=>e.id===selectValue);
const pdfFileName = pdfName ? pdfName.eventname:"Event";
    const downloadfile = location.map((e)=>{
      return{
        Email:e.email,
        EnduserName:e.endusername,        
        Country:e.country,
        ZipcodePostcode:e.zipcode,
        EventDateTime:universaldate(e.eventdatetime),
        TicketsPurchased:e.nooftickets,
        UpsellPurchased:e.upsellcount,
        TotalAmount:e.currencysymbol+''+Number(e.total).toFixed(2)      
        
        
      }
      			 	 		
    })
    
		const ws = XLSX.utils.json_to_sheet(downloadfile);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, `${pdfFileName}_TicketBuyersReport.xlsx`);
  };
  
  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
       cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

       return(
           <div>
     <div>
     <Card mb={3} className="tile-card">
          <CardHeader
              title="Ticket Buyers"
              action={
                location.length>0?   <IconButton aria-label="settings" className="v-button">
                  <MoreVertIcon aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}/>
                </IconButton>:<div></div>
              }
             className="card-title mb-0"/>
             <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={exportFile}>Download Excel</MenuItem>
            
            </Menu> 
            <CardContent className="tile-title pt-0">
            {eventName.length>0?(
          <select className="select-box mb-2 mr-2" value={selectValue} onChange={handleChange} style={{width: '200px'}}>
            	{ <option key='eventid' value="">Select Event</option> }
            {eventName}
          </select >):(
             <select className="select-box mb-2" value={selectValue} onChange={handleChange}>
             <option value="1" >Select</option>
           </select >
          )}
                  {showTiming.length>0?(
                   <select                    
                    value={timeValue}
                    onChange={timeChange}
                    className="select-box mb-2"
                  >
                     <option value="">All Shows</option>
                    {(showTiming).map((v, k) => {
                      return <option id={k} value={v._id}>{universaldate(v.eventdatetime)}</option>
                    })}
                  </select>        
        ):(<div></div>)} 
           <div className="scrollbar" style={{height:'400px', overflow: 'auto'}}>
            <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>                
            <TableCell>Email</TableCell>
            <TableCell>Buyer Name</TableCell>            
              <TableCell>Country</TableCell>
              <TableCell>Zip / Post Code</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell className="center">Ticket Purchased</TableCell>
              <TableCell className="center">Upsell Purchased</TableCell>  
              <TableCell className="center">Total Amount</TableCell>                
            </TableRow>
          </TableHead>
          <TableBody>  
          {location.length>0?(location.map((events)=>(
             <TableRow>
               <TableCell>{events.email}</TableCell>
               <TableCell>{events.endusername}</TableCell>             
             <TableCell>{events.country}</TableCell>
             <TableCell>{events.zipcode}</TableCell>
             <TableCell>{universaldate(events.eventdatetime.split(',')[0])} <small>{events.eventdatetime.split(',')[1]}</small></TableCell>
             <TableCell className="center">{events.nooftickets}</TableCell>
            <TableCell className="center">{events.upsellcount}</TableCell>
          <TableCell className="center">{events.currencysymbol} {Number(events.total).toFixed(2)}</TableCell>            
           </TableRow>
          ))):(<TableRow><TableCell colSpan={8} className="center"><Alert severity="warning">No records found</Alert></TableCell></TableRow>)
          }
          </TableBody>
        </Table>
        </div>
        </CardContent>
          </Card>
        </div>
        <Notification notify={notify}
          setNotify={setNotify}/>
           </div>
       )
   }

   export default TicketBuyers