import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, TableCell, Toolbar, makeStyles, InputAdornment, Zoom, Typography, Tooltip, Box, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Popup from "../../components/Popup";
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ClientApiForm from "../events/ClientApiForm";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";
import userService from '../../services/userService';
import { getIndividualList, validateSendgridKey } from '../../services/clientApiServices';
import Loading from '../../components/controls/Loading';
import SendGrid from '../events/Sendgrid';
import {getClientapidetails} from '../../services/allotmentService'

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  pageContent: {
    padding: '0px 15px',
    margin: '0px auto',
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  addBar: {
    float: 'right',
    padding: '0px',
    position: 'absolute',
    right: '15px',
    top: '64px',
    [theme.breakpoints.down("sm")]: {
      position: 'initial',
      margin: '5px 16px -5px 0px',
    }
  },
  Toolbar: {
    padding: '0px',
    float: 'right',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
      float: 'right',
    }
  },
}));

const drawerWidth = 250;


const headCells = [

  
  { id: "clientname", label: "Client Name" },
  { id: "apikey", label: "API Key" },
  { id: 'actions', label: 'Actions', disableSorting: true }

];


const ClientApi = () => {

  const classes = useStyles();

  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [disable, setDisable] = useState(false);
  const [titlename, setTitlename] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [selectedAccountId, setSelectedAccountId] = useState('0');
  const [condition, setCondition] = useState(false);
  const [sendGrid, setSendGrid] = useState({ open: false, Sendgridkey: '' });
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [account, setAccount] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const firstName = currentUser.firstName ? currentUser.firstName : ''
  const lastName = currentUser.lastName ? currentUser.lastName : ''


  const errorHandle = (error) => {
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
    messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
  }
  useEffect(() => {
    let cancel = false;
    setSelectedAccountId(currentUser.accountid);
    if (currentUser.roleid === 1) {
      userService.getUserAccounts()
        .then(result => {
          if (cancel) return;
          setAccount(result);
          paticularData(currentUser.accountid);
        })
        .catch((error) => {
          setShowLoading(false)
          errorHandle(error);
        });
    } else {
      paticularData(currentUser.accountid);
    }
    return () => {
      cancel = true;
    }
  }, [])

  const paticularData = (e) => {
    const users = {
      action_type: "active list",
      account_id: e
    }

    getIndividualList(users).then((result) => {
      setRecords([...result.data])
      setSendGrid({ open: false, Sendgridkey: result.customerinfo[0].Sendgridkey })
      setShowLoading(false)
    }).catch((error) => {
      setShowLoading(false)
      errorHandle(error);
    });
  }



  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn, condition);

  const handleSearch = e => {


    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {

          return items;
        }
        else {
          return items.filter(x => (x.clientname.toLowerCase().includes(searchString.toLowerCase())

          ))

        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }

  };


  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const addOrEditSendGrid = (user, resetForm) => {

    const users = {
      "accountid": selectedAccountId,
      "sendgridkey": user.Sendgridkey
    }
    validateSendgridKey(users).then(res => {
      if (res.message !== "Failed") {

        paticularData(selectedAccountId);
        setTimeout(() => {
          notification("Connected successfully ", "success");
        }, 1000);
      } else {
        notification("Connection Failed ", "error");
        setSendGrid({ ...sendGrid, open: false });

      }
    }).catch((err) => {
      notification("Connection Failed ", "error");
      setSendGrid({ ...sendGrid, open: false });

    });

    setRecordForEdit(null)

  };

  const addOrEdit = (user, checkValue,active, allotment,removed) => {
 
 

    const userchecking = allotment.filter(x => x.allocatechecked== true&& x.allocationclientid==0);

    const mapvalue = userchecking.map((e)=>{
      return {
        allocationclientid:0,
        allocationid:e.allocationid,
        isaction:"add"
      }
    })
    
    
     const json=removed.length>0?mapvalue.concat(removed):mapvalue
   
    if (user.clientid === "") {
      
      const users = {
        "action_type": "add",
        "client_name": user.clientname,
        "api_id": checkValue,
        "api_key": "",
        "apiexpiry_date": "",
        "account_id": selectedAccountId,
        "active_status": active
      }
      getIndividualList(users).then(res => {
        notification("Client-Api added successfully ", "success");
        paticularData(selectedAccountId);
      }).catch((err) => {
        errorHandle(err);
      });
    } else {
      const clientuser={
        
        "action_type":"all",
        "client_id":user.clientid,
        "allocations":json
    
    }
 
    getClientapidetails(clientuser).then(res=>{
      if((res.message).toLowerCase()==="created"){
       
        notification("Holder Edited successfully ", "success");
      }
    }).catch((err)=>{
      errorHandle(err);
    })
    
      const users = {
        action_type: "edit",
        client_name: user.clientname,
        api_id: checkValue,
        api_key: user.apikey,
        apiexpiry_date: user.apiexpirydate,
        client_id: user.clientid,
        account_id: selectedAccountId,
        active_status: active
      }
      getIndividualList(users).then(res => {
        notification("Client-Api updated successfully", "success");
        paticularData(selectedAccountId);
      }).catch(error => {
        errorHandle(error);
      });
    }

    setRecordForEdit(null)
    setOpenPopup(false)

  };

  const openInPopup = item => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setDisable(true);
    setTitlename(false);
  };

  const onDelete = id => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    const users = {
      action_type: "delete",
      client_id: id.clientid,
      active_status: 0
    }
    getIndividualList(users).then(res => {
      notification("Client-Api deleted successfully ", "success");
      paticularData(selectedAccountId);

    }).catch((error) => {
      errorHandle(error);
    });
  };

  const getAccountidDetails = (e) => {
    setSelectedAccountId(e.target.value);
    paticularData(e.target.value);
  }

  return (
    <div >
      <Helmet title="Client API Management" />
      <main className={classes.content}>
        <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
          Client API Management
      </Typography>
        <Box variant="subtitle1" className="welcome-msg">
          <span style={{ flexGrow: '1' }}>Welcome, <strong>{firstName} {lastName}</strong></span>
        </Box>
        <Toolbar className={classes.addBar}>
          <Box>
            {currentUser.roleid === 1 && account.length > 0 ? (
              <select
                value={selectedAccountId}
                onChange={getAccountidDetails}
                className="select-box mt-0 mb-0"
              >
                <option value="0">Select Customer</option>
                {(account).map((v, k) => {
                  if (v.roleid !== 1)
                    return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>

                })}
              </select>
            ) : (<div></div>)}
          </Box>
        </Toolbar>
        <Divider className="my-2" />
        <div className={classes.pageContent}>
          <Toolbar className={classes.Toolbar}>
            {!sendGrid.Sendgridkey ? <Controls.Button
              onClick={() => { setRecordForEdit(sendGrid); setSendGrid({ ...sendGrid, open: true }) }}
              className="link-red"
              text='Connect Sendgrid' /> :
              <Controls.Button
                onClick={() => { setRecordForEdit(sendGrid); setSendGrid({ ...sendGrid, open: true }) }}
                className="btn-redoutline"
                text='Edit Sendgrid' />}
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />
            <Controls.ActionButton
              onClick={() => {
                setOpenPopup(true); setRecordForEdit(null);
                setTitlename(true); setDisable(false)
              }} className="outlined-primary">
              <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
            </Controls.ActionButton>
          </Toolbar>
          <TblContainer className={classes.container}>
            <TblHead className="p-1 apikey-tbl" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>

                    <TableCell className="p-1">{record.clientname}</TableCell>
                    <TableCell className="p-1 apikey" onClick={() => { navigator.clipboard.writeText(record.apikey); notification("Copied", "success") }}>
                      <Tooltip TransitionComponent={Zoom} title="Click to copy" arrow><p>{record.apikey}</p></Tooltip></TableCell>

                    <TableCell className="p-1">
                      <Controls.ActionButton
                        onClick={() => { navigator.clipboard.writeText(record.apikey); notification("Copied", "success") }} className="outlined-primary">
                        <FileCopyRoundedIcon fontSize="small" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => { openInPopup(record) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to delete the record of the Client Api [ ${record.clientname} ]?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete(record) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length &&
                <TableRow style={{ textAlign: "center" }} key={0}>
                  {showLoading ? (<TableCell className="p-1 text-left w-100" colSpan="3"><Loading /></TableCell>) : <TableCell className="p-1 text-left" colSpan="3">{<Alert severity="warning">No records found</Alert>}</TableCell>}
                </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination />
        </div>
        {openPopup && <Popup
          title={titlename ? "Create Client - API Configuration" : "Edit Client - API Configuration"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup} maxWidth="sm"
        >
          <ClientApiForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            disable={disable}
            cancelbutton={cancelbutton}
          />
        </Popup>}
        {sendGrid.open && <Popup
          title={"API Key"}
          openPopup={sendGrid.open}
          setOpenPopup={() => { setSendGrid({ ...sendGrid, open: false }) }} maxWidth="sm"
        >
          <SendGrid
            recordForEdit={recordForEdit}
            addOrEdit={addOrEditSendGrid}
            cancelbutton={() => { setSendGrid({ ...sendGrid, open: false }) }}
          />
        </Popup>}
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default ClientApi;