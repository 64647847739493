/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import {
  Divider,
  makeStyles,
  Typography,
  Grid,
  Link,
  IconButton,
  Breadcrumbs,
  Toolbar,
  Box,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import Footer from '../../components/layouts/Footer';
import {
  RoomOutlined as RoomOutlinedIcon,
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon,
  PeopleOutlineOutlined as PeopleOutlineOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  ArrowUpwardOutlined as ArrowUpwardOutlinedIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon
} from '@material-ui/icons';
//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import { getBoldReports } from '../../services/reportService';
import userService from '../../services/userService';
import Notification from "../../components/Notification";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const viewerStyle = { 'height': '700px', 'width': '100%', padding: '12px' };
const parameterSettings = {
  dateTimePickerType: "DateTime",
  dateTimeFormat: "yyyy-MM-dd",
  timeDisplayFormat: "HH:mm",
  timeInterval: 60,
};
const SERVICE_API = "https://service.boldreports.com/api/Viewer";
const ONSTAGE_REPORT_API = "https://onthestage.boldreports.com/reporting/api";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  borderOutline: {
    padding: 20,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 8,
    boxShadow: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    flexGrow: 1
  },
  smallTitle: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.5,
    textAlign: 'left',
    color: '#878787',
  },
  smallText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.2,
    color: '#323232',
  },
  revenueTicketsStyle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: -0.5,
    color: '#323232',
  },
  redIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 20,
    gap: 10,
    width: 64,
    height: 64,
    background: 'rgba(255, 255, 255, 0.2)',
    color: '#9D1B33',
    border: '1px solid #9D1B33',
    borderRadius: 8,
  },
  mediumHeading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: -1,
    color: '#323232',
  },
  redLink: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: -0.5,
    color: '#9D1B33',
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
  },
}))

function ReportViewer() {
  const currentUser = cookies.get('currentUser');
  const classes = useStyles();
  const [reportData, setReportData] = useState(null);
  const [accountid, setAccountID] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const [selectedReport, setSelectedReport] = useState(null);

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    });
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  };

  const loadReports = async (accountid) => {
    try {
      const reportData = await getBoldReports(accountid);
      setReportData(reportData);
    } catch (error) {
      errorHandle(error);
    }
  };

  const handleChange = (event) => {
    const reportId = event.target.value;
    const report = reportData.reports.find(t => t.reportId === reportId);
    setSelectedReport(report);
  };

  const handleAccountChange = async (event) => {
    cookies.set('accountid', event.target.value);
    setSelectedAccountId(event.target.value);
    setReportData(null);
    await loadReports(event.target.value);
  }

  useEffect(() => {
    userService.getUserAccounts()
      .then(userAccounts => {
        setAccountID([...userAccounts])
      })
      .catch((error) => {
        errorHandle(error);
      });
      loadReports(currentUser.accountid);
    }, []);

    var pageSettings =  {
      orientation: ej.ReportViewer.Orientation.Landscape,
      paperSize: ej.ReportViewer.PaperSize.A4,
    };

  return (
    <div>
      <Helmet title="Report View" />
      <main className={classes.content}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={6} sm={6} xs={6}>
              <Typography variant="h5" gutterBottom className="breadcrumbs">Reports</Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6}>
              <Toolbar className="selectbar singleline">
                <Box>
                  {currentUser.roleid === 1 && accountid.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={handleAccountChange}
                      className="select-box mt-0 mb-0">
                      <option value="0">Select Customer</option>
                      {(accountid).map((v, k) => {
                        if (v.roleid !== 1)
                          return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                        else
                          return null
                      })}
                    </select>
                  ) : (<div></div>)}

                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <Divider />

        {reportData && <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="report-native-simple">Select a Report</InputLabel>
              <Select
                native
                onChange={handleChange}
                inputProps={{
                  name: 'report',
                  id: 'report-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                {reportData.reports.length > 0 && reportData.reports.map(op => {
                  return <option value={op.reportId}>{op.name}</option>;
                })}
              </Select>
            </FormControl>
          </Grid>

          {selectedReport && <Grid item xs={12} spacing={3} style={viewerStyle}>
            <BoldReportViewerComponent
              id="reportviewer-container"
              key={selectedReport.reportId}
              reportServiceUrl={SERVICE_API}
              reportServerUrl={ONSTAGE_REPORT_API}
              serviceAuthorizationToken={`Bearer ${reportData.access_token}`}
              pageSettings={pageSettings}
              reportPath={selectedReport.bold_report_path}
            >  
            </BoldReportViewerComponent>
          </Grid>}
        </Grid>
        }
      </main>

      <Notification notify={notify} setNotify={setNotify} />

      <Footer />
    </div>
  );
};

export default ReportViewer;