import { Grid, DialogActions, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';

const initialFValues = {
     accountid:0,
    accountname : "",
    userid:0,
    stripeid:"",
    isorderflatorperc: 1,
    istaxinclusive: 0,
    isticketflatorperc: 1,
    isupsellflatorperc: 1,
    orderfee: 0,
    ticketfee: 0,
    upsellfee: 0
   
};

const CustomerForm = (props) =>{
    const [tax,setTax]=useState(false);

    
    const { addOrEdit, recordForEdit ,cancelbutton } = props;

    const validate = (fieldValues = values)=>{      
        const temp = {...errors};

        if("accountname" in fieldValues){
            temp.accountname = fieldValues.accountname?"":"Account Name is required";
        }
        if("stripeid" in fieldValues){
          temp.stripeid = fieldValues.stripeid?"":"Stripe AccountID is required";
         }
         if("ticketfee" in fieldValues){
          temp.ticketfee = fieldValues.ticketfee?"":"Ticket is required";
         }
         if("upsellfee" in fieldValues){
          temp.upsellfee = fieldValues.upsellfee?"":"Upsell is required";
         }
         if("orderfee" in fieldValues){
          temp.orderfee = fieldValues.orderfee?"":"Order is required";
         }

        setErrors({...temp});
        return Object.values(temp).every((x) => x === "");

    };

    const {values,
           setValues,
           errors,
           setErrors,
           handleInputChange,
           resetForm } = useForm(initialFValues,true,validate);

    const handleSubmit = (event)=> {
        event.preventDefault();
        const inclusiveTax=tax?1:0;
       if (validate()) {
           addOrEdit(values,inclusiveTax,resetForm);
        }
    };

      

    useEffect(() => {
      let cancel = false;
        if (recordForEdit !== null){
          if (cancel) return;
            setValues(recordForEdit);
            setTax(recordForEdit.istaxinclusive!==0)
        }
        return () => {
          cancel = true;
        }
    }, [recordForEdit]);

    const checkbox=(event)=>{
      setTax(event.target.checked);
    }

    const handleChange = (event, newAlignment) => {
      setValues({
        ...values,
        [event.target.name]:newAlignment
      })
    };

    const onKeyPressHandler = e=> {

      if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
      }
      else{
        e.preventDefault(); 
      }
  }
    return(
        
        <Form onSubmit={handleSubmit} className="create-user">

            <Grid container item spacing={2} md={12} className="m-0">

                <Grid item lg={6} md={6} sm={6} xs={12}>

                 <Controls.Input
                            name="accountname"
                            label="Name"
                            value={values.accountname||''}
                            onChange={handleInputChange}
                            error={errors.accountname} className="smaller-input"/>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>

                 <Controls.Input
                            name="stripeid"
                            label="Stripe Account ID"
                            value={values.stripeid||''}
                            onChange={handleInputChange}
                            error={errors.stripeid} className="smaller-input"/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  OTS Platform Fee               
                </Grid>
          <Grid item lg={5} md={5} sm={5} xs={9}>
            <Controls.Input
              name="ticketfee"
              label="Ticket"
              type="number"
              onKeyPress={onKeyPressHandler}
              value={values.ticketfee || ''}
              onChange={handleInputChange}
              error={errors.ticketfee} className="smaller-input" />              
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={3} className="ots-fees">
          <ToggleButtonGroup size="small"
            color="primary"
            value={values.isticketflatorperc}
            exclusive
            onChange={handleChange} 
            name="isticketflatorperc"           
          >
            <ToggleButton value={1} name="isticketflatorperc" checked>$</ToggleButton>
            <ToggleButton value={2} name="isticketflatorperc">%</ToggleButton>
          </ToggleButtonGroup>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControlLabel control={<Checkbox  checked={tax} onClick={checkbox} name="tax" />} label="Platform Fee Inclusive Of Tax" />            
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={9}>
            <Controls.Input
              name="upsellfee"
              label="Upsell"
              type="number"
              onKeyPress={onKeyPressHandler}
              value={values.upsellfee || ''}
              onChange={handleInputChange}
              error={errors.upsellfee} className="smaller-input" />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={3} className="ots-fees">
          <ToggleButtonGroup size="small"
            color="primary"
            value={values.isupsellflatorperc}
            exclusive
            onChange={handleChange}
            name="isupsellflatorperc"
          >
            <ToggleButton value={1} name="isupsellflatorperc" checked>$</ToggleButton>
            <ToggleButton value={2} name="isupsellflatorperc" >%</ToggleButton>
          </ToggleButtonGroup>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            
            </Grid>
          <Grid item lg={5} md={5} sm={5} xs={9}>
            <Controls.Input
              name="orderfee"
              label="Orders"
              type="number"
              onKeyPress={onKeyPressHandler}
              value={values.orderfee || ''}
              onChange={handleInputChange}
              error={errors.orderfee} className="smaller-input" />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={3} className="ots-fees">
          <ToggleButtonGroup size="small"
            color="primary"
            value={values.isorderflatorperc}
            exclusive
            onChange={handleChange}
            name="isorderflatorperc"
          >
            <ToggleButton value={1} name="isorderflatorperc" checked>$</ToggleButton>
            <ToggleButton value={2} name="isorderflatorperc">%</ToggleButton>
          </ToggleButtonGroup>
          </Grid>
          
              <Divider />
                <DialogActions className="w-100">
                <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        
                        <Controls.Button
                            text="Clear"
                            onClick={()=>{resetForm();cancelbutton();}} className="link-gray"/>
                            <Controls.Button
                            type="submit"
                            text="Submit" className="link-red"/>
                   </Grid>
                </DialogActions>                
            </Grid> 

        </Form>
    )
};

export default CustomerForm;