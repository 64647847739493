import React, {useEffect,useState} from "react";
import { useDispatch } from 'react-redux';
import { Box,Typography } from "@material-ui/core";
import { exchangeticket } from "../../redux/actions/authActions";
import { universaldate } from "../../utils/dateFormat";
import Popup from "../../components/Popup";
import MapforOrder from "./MapforOrder"
import { Alert } from '@material-ui/lab';
import PartialRefundForm from './PartialRefundForm';

export const EventExpansion = ({eventDetail,order}) => {
  console.log('order', order);
  console.log('eventDetail', eventDetail);
 const dispatch = useDispatch();
 const [openPopup, setOpenPopup] = useState(false);
 const [openPopupPartialTeam, setOpenPopupPartialTeam] = useState(false);
 const [mapEvents,setMapEvents] = useState({eventid:'',occurenceid:''});
useEffect(()=>{
 

const chkradio = document.getElementsByName("radiovalues");

for(let i=0; i < chkradio.length; i++) {
  chkradio[i].checked = false;
}

},[eventDetail._id])

  const handleChange = () => {
    setOpenPopupPartialTeam(true);
  }

  const handleClose = () => {
    setOpenPopupPartialTeam(false);
};

  const handleContinue = (data) => {

    if (eventDetail.mapinventory === 1) {
      dispatch(exchangeticket([{
        eventid: eventDetail.id, eventdatetime: eventDetail.eventdatetime,
        ticketcountstatus: eventDetail.ticketcountstatus, occurenceid: eventDetail._id, ticketinfo: data.paymentteammapping,
        selectedTickets: eventDetail.PurcTicket, numberoftickets: data.numberoftickets, upsellInfo: [...new Set(data.upsellInfo)]
      }]));
      setMapEvents({ eventid: eventDetail.id, occurenceid: eventDetail._id, paymentid: order.paymentid,
      selectedTickets: eventDetail.PurcTicket, numberoftickets: data.numberoftickets });
      setOpenPopup(true);
      setOpenPopupPartialTeam(false);
    } else {
      dispatch(exchangeticket([{
        eventid: eventDetail.id, eventdatetime: eventDetail.eventdatetime,
        ticketcountstatus: eventDetail.ticketcountstatus, occurenceid: eventDetail._id, ticketinfo: data.paymentteammapping,
        numberoftickets: data.numberoftickets, upsellInfo: [...new Set(data.upsellInfo)]
      }]));
      setOpenPopupPartialTeam(false);
    }
  }
 
 return (
    <>
  
     
     <Box sx={{ display: 'inlineFlex', width: 'auto',float: 'left'}}>
          <Typography variant="h6" component="div" className="radio-datetime">
         
          </Typography>
          <Box sx={{flexGrow: 1, ml: 1}} className="ed-date-time">
						<p className="date" >{(universaldate(eventDetail.eventdatetime)).split(',').shift()}</p>
            {
              !eventDetail.onsaleflag && <p style={{margin: '10px'}} className=""><Alert severity="warning">Off Sale</Alert></p>
            }
            {
              eventDetail.event_subcategory ? <small className="text-center">{eventDetail.event_subcategory}</small> : null
            }

            {
              eventDetail._id === order.eventdetailid ? <small className="text-center">{'Current Order'}</small> : null
            }


            <p className="time p-0" onChange={()=>handleChange(eventDetail,eventDetail.mapinventory === 1)} > 
            <label className="exchangetime">
            <input type="radio" id='radiocheck'  value=''  onChange={()=>handleChange(eventDetail,eventDetail.mapinventory === 1)} name="radiovalues"></input>      
            <span>{(eventDetail.eventdatetime).split(',').pop()}</span>
            </label>
            </p>
						</Box>	
            </Box>	
          {openPopup ? <Popup
           title={"EXCHANGE TEAM"}
           openPopup={openPopup}
           setOpenPopup={setOpenPopup}
           >
           <MapforOrder mapEvents={mapEvents} setOpenPopup={setOpenPopup} sameOccurrence={eventDetail._id === order.eventdetailid}/>
          </Popup> : null}

          {openPopupPartialTeam && order ? <Popup
           title={" Exchange "}
           openPopup={openPopupPartialTeam}
           setOpenPopup={setOpenPopupPartialTeam}
           >
         <PartialRefundForm
                withTeam={ order?.playerteamMappings?.length > 0 ? true : false}
                teamData={ order?.playerteamMappings?.length > 0 ? order.playerteamMappings : []}
                teamMapping={ order?.paymentteamMapping?.length > 0 ? order?.paymentteamMapping : []}
                ticketdetails={order?.ticketdetails.length > 0 ? order?.ticketdetails : []}
                eventDetails={{ occurrenceid: order?.eventdetailid, eventid: order?.eventid, paymentid: order?.paymentid,
                accountid: order?.accountid, eventticketdetailid: order?.eventticketdetailid, eventname: order?.eventname, eventdatetime: order?.eventdatetime  }}
                nooftickets={order?.nooftickets}
                sameTimeExchange={eventDetail._id === order.eventdetailid}
                handleContinue={handleContinue}
                handleClose={handleClose}
                exchangeMode={true}
                paymentcount={2}//this one for select all tickets
                individualupselldetail={order?.individualupselldetail?.length > 0 ? order?.individualupselldetail:[] }
            />  
          </Popup> : null}
     
    </>
    
  )
};
