import * as React from "react";
import locale from 'date-fns/locale/en-GB'
import { TextField, Grid } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib


if (locale && locale.options) {
  locale.options.weekStartsOn = 1
 }



const EventDatePicker = ({startDate,endDate,modifyStart,modifyEnd, displayStartdate=true}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
         <Grid container spacing={3} className="promo-eventdate">
        { displayStartdate ?  <Grid item lg={6} md={6} sm={6} xs={6} className="pr-0">
        <DateTimePicker 
        label="Start Date & Time"
        value={startDate}
        onChange={(newValue) => {
          modifyStart(newValue);
        }}
        // inputProps={{disabled:true}}
        format="yyyy-MM-dd,hh:mm a"
        minDateTime={new Date()}
        // renderInput={(params) => <TextField  {...params} size="small" className="date w-100"/>}
      />
        </Grid> : null }
        <Grid item lg={6} md={6} sm={6} xs={6} className="pr-0">
        <DateTimePicker 
        label="End Date & Time"
        value={endDate}
        onChange={(newValue) => {
          modifyEnd(newValue);
        }}
        // inputProps={{disabled:true}}
        format="yyyy-MM-dd,hh:mm a"
        minDateTime={new Date(startDate)}
        // renderInput={(params) => <TextField {...params} size="small" className="date w-100"/>}
      />
        </Grid>
        </Grid>
    </MuiPickersUtilsProvider>
  );

}
export default EventDatePicker; 