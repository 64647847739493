import React, { useState, useEffect, } from "react";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import Notification from '../../components/Notification';
import {
  Grid,
  FormControlLabel,
  FormControl,
  Divider,
  FormLabel,
  DialogActions,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import useForm from '../../components/useForm';


const initialFValues = {
  allocationid:0,
  accountid:0,
  allocationname: "",
  
 
};


const Allotment = (props) => {

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [state, setState] = React.useState({
    isall: 1,
    isapi: 0,
    isboxoffice: 0,
  
  });
  const [value1, setValue1] = React.useState("isall");

  const handleChange = (event) => {
  
  if(event.target.value=="isall"){
    setState({isall:1,isapi:0,isboxoffice:0})
  }else if(event.target.value=="isapi"){
    setState({isall:0,isapi:1,isboxoffice:0})
  }else{
    setState({isall:0,isapi:0,isboxoffice:1})
  }
    
  setValue1(event.target.value);

  };


  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("allocationname" in fieldValues) {
      temp.allocationname = fieldValues.allocationname ? "" : "Hold name  is required";
    }
    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");


  }

 
  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm } = useForm(initialFValues, true, validate);


  const { addOrEdit, recordForEdit, cancelbutton } = props;


  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const radioValidation=()=>{
    if(value1===""){
      notification("Please choose any one","error");
      return false;
    }else{
      return true
    }
  }

  useEffect(() => {
   
    let cancel = false;
      if (recordForEdit !== null){
        
        
        if (cancel) return;
        setState(recordForEdit);
        setValues(recordForEdit)
        if(Number(recordForEdit.isall)===1){
          setValue1("isall")
         
        }else if(Number(recordForEdit.isapi)===1){
          setValue1("isapi")
        }else if(Number(recordForEdit.isboxoffice)===1){
          setValue1("isboxoffice")
        }
      }
      return () => {
        cancel = true;
      }
  }, [recordForEdit]);

  

  const handleSubmit = (event) => {

   event.preventDefault();
    if (validate()&&radioValidation()) {
      addOrEdit(values,state,resetForm);
      resetForm();
    }
  };



  return (
    <Form onSubmit={handleSubmit} className="create-user">
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <Grid container item spacing={2} md={12}>
        <Grid item lg={9} md={9} sm={6} xs={12}>
          <FormLabel component="legend">Name of the hold </FormLabel>
          <Controls.Input
            name="allocationname"
            label="Enter Name"
            value={values.allocationname || ''}
            onChange={handleInputChange}
            error={errors.holdername}
          />

        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>

        
        
<FormControl>
  <FormLabel id="demo-controlled-radio-buttons-group">Which of the following Apply ?</FormLabel>
  <RadioGroup
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={value1}
    
    onChange={handleChange}
  >
    {/* removing this logic because the all logic is so poor and not used by customers currently <FormControlLabel    value="isall"  control={<Radio   />} label="All Channels" /> */}
    <FormControlLabel  value="isapi" control={<Radio />} label="API Only" />
    <FormControlLabel  value="isboxoffice" control={<Radio />} label="Box Office Only" />
  </RadioGroup>
</FormControl>
        </Grid>

        <Divider />
        <DialogActions className="w-100">
          <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Controls.Button
              text="Cancel"
              color="default" size="medium"
              onClick={() => { resetForm(); cancelbutton() }} className="link-gray" />
            <Controls.Button
              type="submit"
              text="Submit" size="medium" className="link-red" />
          </Grid>
        </DialogActions>
      </Grid>

    </Form>
  );
}
export default Allotment; 