import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, TableCell, Toolbar,Grid, makeStyles,Box, InputAdornment, Divider, Typography, } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Button from '../../components/controls/Button';
import Popup from "../../components/Popup";
import Notification from '../../components/Notification';
import ConfirmDialog from '../../components/ConfirmDialog';
import Controls from '../../components/controls/Controls';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import VenueForm from './VenueForm';
import { getAllVenueList } from '../../services/venueService';
import XLSX from 'xlsx'
import { getEventByAccountId } from '../../services/eventService';
import { Alert } from "@material-ui/lab";
import userService from '../../services/userService';
import Loading from '../../components/controls/Loading';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  pageContent: {
    padding: '0px 15px',
    margin: '0px auto', 
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));


const drawerWidth = 250;


const headCells = [
  { id: "venuename", label: "Name" },
  { id: "address", label: "Address" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "country", label: "Country" },
  { id: "zipcode", label: "Zip / Post Code" },
  { id: 'timezone', label: 'Time Zone' },
  { id: "currencytype", label: "Currency" },
  { id: "concatpercentage", label: "Tax" },
  { id: 'actions', label: 'Actions', disableSorting: true }
];

const VenueCreations = () => {

  const classes = useStyles();

  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState([]);
  const [disable, setDisable] = useState(false);
  const [titlename, setTitlename] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [validateVenue, setValidateVenue] = useState([]);
  const [validateVenueEvent, setValidateVenueEvent] = useState(false);
  const [condition, setCondition] = useState(false);
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const [showLoading, setShowLoading] = useState(true);
  const checkParam =  (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle=(error)=>{
  if(!error && !checkParam(Object.keys(error))){
    notification(error, 'error');
   } else{
  const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
   }
  }

  useEffect(() => {
    let cancel = false;
    getVenueAll(selectedAccountId);
    getEventById(selectedAccountId);
      userService.getUserAccounts()
        .then(result => {
          if (cancel) return;
          setRecord([...result])  
         })
         .catch((error) => {
          errorHandle(error);
         
         });
         return () => {
          cancel = true;
        }

  }, []);

const getEventById=(e) => {

    let cancel = false;  
    const users={
      accountid:e
    }
    getEventByAccountId(users).then((res) => {
      if (cancel) return;
      setValidateVenue(res.data);
      setValidateVenueEvent(true);
    })
    .catch((error) => {
      errorHandle(error);
    });
    return () => {
      cancel = true;
    }
  }



  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting} = useTable(records, headCells, filterFn, condition);

  const handleSearch = e => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {
          return items;
        }
        else {
          return items.filter(x => (x.venuename.toLowerCase().includes(searchString.toLowerCase())
          ))
        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };


  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...","error");
    setTimeout(() => {
       cookies.remove('currentUser');history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const addOrEdit = (user, stateID, countryID, resetForm,e) => {
    if (user.venueid === 0) {
      const newuser = {
        action_type: "add",
        id: user.venueid,
        venue_name: user.venuename,
        venue_address: user.address,
        zipcode_id: user.zipcodeid,
        currency_id: user.currencyid,
        timezone_id: user.timezoneid,
        taxcategory_id: user.taxcategoryid,
        user_id: currentUser.userid,
        accountid:selectedAccountId
      }
      getAllVenueList(newuser).then(result => {
        notification("Venue created successfully ", 'success');
        getVenueAll(selectedAccountId);
      }).catch((error) => {
        errorHandle(error);
      }
      );
    }
    else {
      const edituser = {
        action_type: "edit",
        id: user.venueid,
        venue_name: user.venuename,
        venue_address: user.address,
        zipcode_id: user.zipcodeid,
        currency_id: user.currencyid,
        timezone_id: user.timezoneid,
        taxcategory_id: user.taxcategoryid,
        user_id: currentUser.userid,
        accountid:selectedAccountId
      }
      getAllVenueList(edituser).then(result => {
        notification('Venue updated successfully ', 'success');
        getVenueAll(selectedAccountId);
      }).catch((error) => {
        errorHandle(error);
      }
      );
    }
    resetForm()
    setRecordForEdit(null)
    setOpenPopup(false)

  };

  const openInPopup = item => {
    setRecordForEdit(item);
    setOpenPopup(true);
    setDisable(true);
    setTitlename(false);
  };

  const getVenueAll = (e) => {
    const user = { action_type: 'list',accountid:e };

    getAllVenueList(user).then(result => {
      setShowLoading(false);
      setRecords(result);
    }).catch((error) => {
      setShowLoading(false);
      errorHandle(error);
    }
    );
  }
  const getAccountidDetails = (e) => {

    setRecords([]);

    setShowLoading(true);
   
    getEventById(e.target.value);
  
    setSelectedAccountId(e.target.value)

    getVenueAll(e.target.value)
    
   }


  const onDelete = id => {
  const Checking = validateVenue.find(e=>e.venueid===id);
  if(Checking===undefined&&validateVenueEvent){
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    const deleted = { action_type: "delete", id: id }
    getAllVenueList(deleted).then(result => {
      notification("Venue deleted successfully ", 'success');
      getVenueAll(selectedAccountId);
    }).catch((error) => {
      errorHandle(error);
    }
    );
  }
  else{
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    notification('Event is held on in this venue','error');
  }
  };
  const exportFile = () => {

    const downloadfile = records.map((e)=>{
      return{
        VenueId:e.venueid,
        Venuename:e.venuename,
        Address:e.address,
        Tax:e.concatpercentage,
        Zipcode:e.zipcode,
        City:e.city,
        State:e.state,
        Country:e.country,
        Currencytype:e.currencytype,
        Timezone:e.timezone
      }
    })
    
		const ws = XLSX.utils.json_to_sheet(downloadfile);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "VenueConfiguration.xlsx");
	};

  return (
    <div >
      <Helmet title="Venues" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                      <Grid item lg={6} sm={6} xs={6}>
                      <Typography variant="h5" gutterBottom className="breadcrumbs">
                       Venues 
                        </Typography>
                      </Grid>
                      <Grid item lg={6} sm={6} xs={6}>
                      <Toolbar className="selectbar singleline">
                      <Box>
                          {currentUser.roleid===1&&record.length > 0 ? (
                            <select
                              value={selectedAccountId}
                              onChange={getAccountidDetails}
                              className="select-box mt-0 mb-0"
                            >
                              <option value="0">Select Customer</option>
                              {(record).map((v, k) => {
                                if (v.roleid !== 1)
                                  return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>
                                
                              })}
                            </select>
                          ) : (<div></div>)}
                
                          </Box>
                      </Toolbar>
                      </Grid>
            </Grid>
       </Grid>
      <Divider />

        <div className={classes.pageContent}>
        <Toolbar className="searchbar">     
          <Button text="Download" onClick={exportFile} className="link-red" />
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />

                      <Controls.ActionButton
                        onClick={() => {
                          setOpenPopup(true); setRecordForEdit(null);
                          setTitlename(true); setDisable(false)
                        }} className="outlined-primary">
                        <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
                      </Controls.ActionButton>
          </Toolbar>
          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((records, index) => (
                  <TableRow key={index}>
                    <TableCell className="p-1">{records.venuename}</TableCell>
                    <TableCell className="p-1">{records.address}</TableCell>
                    <TableCell className="p-1">{records.city}</TableCell>
                    <TableCell className="p-1">{records.state}</TableCell>
                    <TableCell className="p-1">{records.country}</TableCell>
                    <TableCell className="p-1">{records.zipcode}</TableCell>
                    <TableCell className="p-1">{records.timezone}</TableCell>
                    <TableCell className="p-1">{records.currencytype}</TableCell>
                    <TableCell className="p-1">{records.concatpercentage}</TableCell>

                    <TableCell className="p-1">

                      <Controls.ActionButton
                        onClick={() => { openInPopup(records) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: `Are you sure to delete the record ?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete(records.venueid) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>	  
            <TableBody>
              {!recordsAfterPagingAndSorting().length && <TableRow style={{ textAlign: 'center' }}>
              {showLoading?(<TableCell className="p-1 text-left w-100" colSpan="10"><Loading /></TableCell>):
              <TableCell className="p-1 text-left" colSpan="10">{ <Alert severity="warning">No records found</Alert> }</TableCell>} 

              </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination />
        </div>
        <Popup
          title={titlename ? "Create Venue" : "Edit Venue"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >

          <VenueForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
            disable={disable}
            cancelbutton={cancelbutton}
          />
        </Popup>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default VenueCreations;