import React, { useState, useEffect } from 'react';
import useTable from '../../components/useTable';
import { TableBody, TableRow, Box, TableCell, Grid, Toolbar, makeStyles, InputAdornment, Typography, Divider } from '@material-ui/core';
import Input from '../../components/controls/Input';
import { useHistory } from "react-router-dom";
import { Search } from '@material-ui/icons';
import Notification from '../../components/Notification';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from 'universal-cookie';
import { Alert } from "@material-ui/lab";
import { useDispatch } from 'react-redux';
import Loading from '../../components/controls/Loading';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Controls from '../../components/controls/Controls';
import Popup from "../../components/Popup";
import Allotment from './Allotment';
import userService from '../../services/userService';
import {getAllallotment} from '../../services/allotmentService';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pageContent: {
    width: '100%',
    padding: '0px 15px',
    margin: '0px auto',
  },
  select: {
    width: '100% !important',
  },
  controlSm: {
    fontSize: '12px',
    padding: '0px 20px',
  },
  pageContentMain: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      margin: '0px auto',
      width: ' 89%',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px auto',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px auto',
      width: '93%',

    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: '70%',
    },
  },

  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  addBar: {
    float: 'right',
    padding: '0px',
  },

}));

const drawerWidth = 250;
const headCells = [
 
  { id: "allocationname", label: "Hold Name" },
  {id:"createddate",label:"Created Date"},
  { id: 'actions', label: 'Actions', disableSorting: true }
];


const AllotmentList = () => {

  const classes = useStyles();
  const history = useHistory();
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [accountid,setAccountID]= useState([]);
  const [records, setRecords] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [titlename, setTitlename] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(currentUser.accountid);
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [condition, setCondition] = useState(false);
  


  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error.response.data.message))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }

  useEffect(() => {
    userService.getUserAccounts()
      .then(result => {
        setAccountID(result)
        getall(selectedAccountId)
        
      })
      .catch((error) => {
        setShowLoading(false)
        errorHandle(error)

      });

  }, []);


  
  const getall=(e)=>{
  const user={
    "action_type":"list",
    "account_id":e
  }
 getAllallotment(user).then((res)=>{
    if(res.data!==undefined){
     console.log(res.data)
    setRecords(res.data);
    setShowLoading(false) 
   
    }
  }).catch((error) => {
      errorHandle(error);
  });

  
  
  }


  const openInPopup = item => {
    setRecordForEdit(item);
    setTitlename(false);
    setOpenPopup(true);
  };


  const cancelbutton = () => {
    setOpenPopup(false);
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting } = useTable(records, headCells, filterFn, condition);

  const handleSearch = e => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: items => {
        if (searchString === "") {
          return items;
        }
        else {
          return items.filter(x => (x.allocationname.toLowerCase().includes(searchString.toLowerCase())
          ))
        }
      }
    })
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove('currentUser'); history.push('/');
    }, 2000);
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const addOrEdit = (value,state) => {
    
  if(value.allocationid===0){
      const users =  {
        "action_type":"add",
        "id":0,
        "account_id":selectedAccountId,
        "allocation_name":value.allocationname,
        "all":Number(state.isall),
        "api":Number(state.isapi),
        "boxoffice":Number(state.isboxoffice)
    }
    getAllallotment(users).then((res)=>{
      if((res.message).toLowerCase()=="created"){
       notification("Hold added successfully ", "success");
       getall(selectedAccountId)
       
      }
     
    }).catch((error) => {
     
      notification(error.message, 'error');
      errorHandle(error);
    });
  }else{
    const users =  {
      action_type:"edit",
      id:value.allocationid,
      account_id:selectedAccountId,
      allocation_name:value.allocationname,
      "all":Number(state.isall),
      "api":Number(state.isapi),
      "boxoffice":Number(state.isboxoffice)
  }
  getAllallotment(users).then((res)=>{
  
    if((res.message).toLowerCase()=="updated"){
     notification("Hold updated successfully ", "success");
     getall(selectedAccountId);
  
    }
   
  }).catch((error) => {
    
    notification("res.message", 'error');
    errorHandle(error);
  });

  }
   setRecordForEdit(null);
    setOpenPopup(false);

  };

  const getAccountidDetails = (e) => {
    setRecords([]);
    setShowLoading(true);
    getall(e.target.value);
    setSelectedAccountId(e.target.value);
  }

  const onDelete = (record) => {
   
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    const user={
      "action_type":"delete",
      "id":record
    }
  getAllallotment(user).then((res)=>{
      
      if((res.message).toLowerCase()=="deleted"){
        notification("Hold deleted successfully ", "success");
        getall(selectedAccountId)
   
      }else{
        notification("Mapped hold cannot be deleted","error")
      }

    }).catch((error) => {
     
      errorHandle(error);
    });
  };

  return (
    <div >
      <Helmet title="Allocations" />
      <main className={classes.content}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={6} md={7} sm={7} xs={12}>
              <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
              Allocations
      </Typography>
              <Box variant="subtitle1" className="welcome-msg">
                <span style={{ flexGrow: '1', paddingBottom: '12px' }}>Welcome, <strong>{currentUser.firstName} {currentUser.lastName}</strong></span>
              </Box>

            </Grid>
            <Grid item lg={6} md={5} sm={5} xs={12}>
              <Toolbar className="selectbar">
                <Box>
                  {currentUser.roleid === 1 && accountid.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={getAccountidDetails}
                      className="select-box mt-0 mb-0"
                    >
                      <option value="0">Select Customer</option>
                      {(accountid).map((v, k) => {
                        if (v.roleid !== 1)
                          return <option key={k} id={k} value={v.accountid}>{v.accountname}</option>

                      })}
                    </select>
                  ) : null}
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>

        <Divider className="mb-1" />
        <div className={classes.pageContent}>
          <Toolbar className={classes.addBar}>
            <Input
              label="Search"
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
              size="small" />

            <Controls.ActionButton
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setTitlename(true)
            //    setAllocation({ ...allocation, recordForEdit: null, titlename: true })
              }} className="outlined-primary">
              <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
            </Controls.ActionButton>
          </Toolbar>
          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {
                recordsAfterPagingAndSorting().map((record, index) => (
                  <TableRow key={index}>
                    
                    <TableCell >{record.allocationname}</TableCell>
                    <TableCell >{record.createddate}</TableCell>
                    
                    <TableCell className="p-1">
                      <Controls.ActionButton
                        onClick={() => { openInPopup(record) }} className="outlined-primary">
                        <EditOutlinedIcon fontSize="small" variant="outlined" color="primary" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: ` Are you sure to delete the record of the allotment   [${record.allocationname}] ?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => { onDelete(record.allocationid) }
                          })
                        }}>
                        <DeleteOutlinedIcon fontSize="small" variant="outlined" color="secondary" />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length &&
                <TableRow style={{ textAlign: 'center' }}>
                  {showLoading ? (<TableCell className="p-1 text-left w-100" colSpan="5"><Loading /></TableCell>) :
                    <TableCell className="p-1 text-left" colSpan="5">{<Alert severity="warning">No records found</Alert>}</TableCell>}
                </TableRow>}
            </TableBody>

          </TblContainer>
          <TblPagination />
        </div>
        <Popup
          title={titlename ? "New Hold" : "Edit Hold"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <Allotment
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}

            cancelbutton={cancelbutton}
          />
        </Popup>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

      </main>
      <Footer />
    </div>
  );
};

export default AllotmentList;