import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {EventExpansion} from "../order/EventExpansion"
import {Grid} from "@material-ui/core";
import NoEventsFound from '../../components/controls/NoEventsFound';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};



const TabTicket = ({ detail,PurcTicket, order }) => {

  return (
    <div>
      {detail.eventdetailfile && detail.eventdetailfile.length > 0 ?
            detail.eventdetailfile.map((eventDetail, eventKey) => (
              <div key={eventKey} >
                <EventExpansion order={order} eventDetail={{
                  ...eventDetail,
                  ...{...detail,eventdetailfile:undefined,eventParentId:detail.id,PurcTicket:PurcTicket}
                }} />
              </div>)
            )
            : (
              <Grid item lg={12} xs={12}>
               <NoEventsFound />
            </Grid>
            )}
    </div>
  );
}

export default TabTicket;