import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  //resetPassword as authResetPassword,
  forgotActionPassword as authForgotPassword,
  //Validatereset as authValidateToken,
} from "../../services/authService";


export function signIn(credentials) {
  
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          payload:response.user
        });
    
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function eventedit(item) {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_EVENT_LIST,
      payload:item,
    });
  };
}
export function order(item) {
  return async (dispatch) => {
    dispatch({
      type: types.ORDER_EVENT_LIST,
      payload:item,
    });
  };
}

export function orderupsell(item){
  return async (dispatch) => {
    dispatch({
      type: types.ORDERUPSELL_EVENT_LIST,
      payload:item,
    });
  };
}

export function upselledit(item) {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_UPSELL,
      payload:item,
    });
  };
}

export function exchangeticket(item){
  
  return async (dispatch) => {
    dispatch({
      type: types.Exchange_Ticket,
      payload:item,
    });
  };
}

export function getUserData(credentials) {
  
  return async (dispatch) => {
    await  dispatch({
          type: types.AUTH_USER_DATA_SUCCESS,
          payload: credentials
         
        });
  };
}

export function Template(item){
  return async (dispatch) => {
    dispatch({
      type: types.Brand_Template,
      payload: item
     
    });
};
}

export function Promocode(item){
  return async (dispatch) => {
    dispatch({
      type: types.Coupon_Promo_Code,
      payload: item
     
    });
};
}

export function resetPassword(credentials) {
  return async (dispatch) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          payload: credentials.userid
         
        });
  };
}

// export function resetPassword(credentials) {
//   return async (dispatch) => {
//     dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

//     return authResetPassword(credentials)
//       .then((response) => {
//         dispatch({
//           type: types.AUTH_RESET_PASSWORD_SUCCESS,
//           userid: response.user.userid,
         
//         });
//       })
//       .catch((error) => {
//         dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
//         throw error;
//       });
//   };
// }




export function forgotActionPassword(credentials) {
  return async (dispatch) => {
  dispatch({ type: types.AUTH_FORGOT_PASSWORD_REQUEST });
  
  return authForgotPassword(credentials)
  .then((response) => {
  dispatch({
  type: types.AUTH_FORGOT_PASSWORD_SUCCESS,
  email: response.email,
  });
  })
  .catch((error) => {
  dispatch({ type: types.AUTH_FORGOT_PASSWORD_FAILURE });
  throw error;
  });
  };
  }
