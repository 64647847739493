import React, { useState , useEffect } from 'react';
import {Typography, Grid, Box, makeStyles, Toolbar, Divider, Breadcrumbs, Link } from '@material-ui/core';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Controls from '../../components/controls/Controls';
import "./template.scss"; 
import TempFooter from "./TempFooter"; 
import AddLogo from "./AddLogo";
import QrCode from "./QrCode";
import { NavLink } from "react-router-dom";
import { getTemplateDetails, getticketTemplateManagement } from '../../services/brandingServices';
import { useSelector, useDispatch } from 'react-redux';
import Ads from "./Ads";
import { Template } from '../../redux/actions/authActions';
import Notification from '../../components/Notification';
import { universaldate } from '../../utils/dateFormat';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  }, 
  pageContent:{
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar, 
  
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down("sm")]: {
      width: '100% !important',
    }
  },
  
}));

const drawerWidth = 250;

const TicketTemplate = () => {
  const classes = useStyles();
const [addLogo,setAddLogo] =useState(null);
const [adsImage,setAdsImage] =useState([]);
const [allImage,setAllImage] =useState(null);
const [event,setEvent] =useState(null);
const [venues,setVenues] =useState(null);
const [disable,setDisable] =useState(false);
const [oneClick,setOneClick] =useState(false);
const Data = useSelector(state => state.authReducer.Template);
const dispatch = useDispatch();
const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
const [advertises,setAdvertises]=useState("Ads");

const timeOutSet = () => {
  notification("Your Session Expired....Try again...","error");
  setTimeout(() => {
   //cookies.remove('currentUser');history.push('/');
  }, 2000);
}

const notification = (message, type) => {
  setNotify({
    isOpen: true,
    message: message,
    type: type
  })
}

const errorHandle=(error)=>{
  const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
   messages === "Invalid Token"? timeOutSet() : notification(messages, 'error');
  }

useEffect(()=>{
  if(Data!=null){
    setEvent(Data.data);
    setVenues(Data.venuedetails);
    setDisable(Data.validate==="Edit");
  getTemplateDetails({
    "action_type" :"eventlogo list",
    "event_id": Data.id
 }).then((result) => {
  
  const Logo = (result.data.logo).length>0 ? (result.data.logo[0].logourl!==""?result.data.logo[0].logourl: Data.data.thumbnailimage) : Data.data.thumbnailimage;
  setAddLogo(Logo)
  setAdsImage(result.data.ads)
  setAllImage({logo:result.data.logo[0],adimage:result.data.ads});
  setAdvertises(result.data.logo.length>0?(result.data.logo[0].adsheader):"Ads")
dispatch(Template(null));  
  }).catch(error => {
    dispatch(Template(null));  
    errorHandle(error); 
  })
}
},[Data])

const saveChanges=()=>{
  setOneClick(true);
  if(event!=null&&disable){
  const formData = new FormData();
  const logoimage = allImage.logo!==undefined && allImage.logo.logourl===addLogo?addLogo:addLogo.name!==undefined?addLogo.name:"";
  const logofile = allImage.logo!==undefined && allImage.logo.logourl===addLogo?addLogo:addLogo.name!==undefined?addLogo:"";
const ads1=adsImage[0] ? adsImage[0].files ? adsImage[0].files : adsImage[0].ads : "";
const adsheader=advertises && advertises!==""?advertises:"Ads"
  
const ads2=adsImage[1] ? adsImage[1].files ? adsImage[1].files : adsImage[1].ads : "";
const ads3=adsImage[2] ? adsImage[2].files ? adsImage[2].files : adsImage[2].ads : "";
const arrayAdsImage= adsImage.length>0 ? adsImage.map(x=>{return{
  "eventadid":x.eventadid,
  "eventid":x.eventid,
  "ads":x.ads,
  "adssequence":x.adssequence
}}) : [];
    const ticketTemplate = {
            "action_type": allImage.logo!==undefined?"edit":"add",//(add/ edit)
            "template_id": allImage.logo!==undefined?allImage.logo.eventlogoid:"0", //(add- 0/ edit - created id from DB)        
            "event_id": event.id,
            "eventname": event.eventname,
            "logo_url": logoimage,
            "ads_header":adsheader,
            "eventticketads": arrayAdsImage
        }

   formData.append('ticketTemplate',  JSON.stringify(ticketTemplate));
   logofile!==""&&formData.append('file', logofile);
   ads1!==""&&formData.append('file', ads1);
   ads2!==""&&formData.append('file', ads2);
   ads3!==""&&formData.append('file', ads3);
    getticketTemplateManagement(formData).then((result) => {
      setDisable(false);
      notification('Changes Saved Successfully', 'success');
      }).catch(error => {
        errorHandle(error); 
        setOneClick(false);
      })
      }
}

// const datetype=(e)=>{
//   let parts = e.split("-");
//   let day = parseInt(parts[1], 10);
//   let month = parseInt(parts[0], 10);
//   let year = parseInt(parts[2], 10);
//   let timeSplit = e.split(",");
//   let times = timeSplit[1].replace('AM','am').replace('PM','pm');

//   let monthLength = [ "January", "Feb", "March", "April", "May", "June", "July" , "Augest" , "September" , "October" ,"November" , "December" ];

//   return (`${monthLength[month-1]} ${day}, ${year} @ ${times} `)
// }

  return (
    <div >
      <Helmet title="Ticket Template" />
      <main className={classes.content}>
      <Grid item lg={12} xs={12}>
                    <Grid container spacing={0}>                      
                    <Grid item lg={6} md={7} sm={7} xs={12}>
                      <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
                       Ticket Template
                        </Typography>
                        <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
                        <Link component={NavLink} exact to="/Branding" underline="hover" color="inherit">
                        Branding
                        </Link>
                            <Typography color="textPrimary">Ticket Template</Typography>
                        </Breadcrumbs>
                      </Grid>
                      <Grid item lg={6} md={5} sm={5} xs={12}>
                      <Toolbar className="selectbar">                     
                      <Box>
                      {disable?<Controls.Button text="Save Changes" onClick={saveChanges} disabled={oneClick} className="link-red"/>:null}
                      </Box>
                      </Toolbar>
                      </Grid>
            </Grid>
       </Grid>
        <Divider />  
      <div className={disable?"":"disabled preview"}>
        <div className={classes.pageContent}>  
      <AddLogo getlogo={setAddLogo} setlogos={addLogo} disable={disable}/> 
 <div className="container-fluid">
  <Grid className="bg-gray">
      <Typography variant="h6" gutterBottom component="div">
       {event!=null? event.eventname:"Black Tie Dinner & Ballroom Dance"}
      </Typography>
      <Typography variant="caption" display="block">
      {event!=null? (universaldate(event.eventdetailfile[0].eventdatetime)):"01-15-2022,6:00PM"}
      </Typography>
      <Typography variant="caption" display="block">
        {event!=null? event.venuename:"Central Park"}
      </Typography>
      <Typography variant="caption" display="block">
      {venues!=null?venues.address: 'New York, NY, United States'}
      </Typography>
  <Grid className="row mt-3">
    <Grid className="col-1-5">Ticket Quantity 
      <p>4</p>
    </Grid>
    <Grid className="col-1-5">Selected Add-on
    <p>5</p>
    </Grid>
    <Grid className="col-1-5">Ticket Cost
    <p>492.0</p>
    </Grid>
    <Grid className="col-1-5">Add-on Cost
    <p>100</p>
    </Grid>
    <Grid className="col-1-5">Promo Code
    <p> </p>
    </Grid>
  </Grid>

  <Grid className="row">
    <Grid className="col-1-5">Booking Fee 
      <p>50</p>
    </Grid>
    <Grid className="col-1-5">Total Cost
    <p>884.72</p>
    </Grid>
    <Grid className="col-1-5">Currency
    <p>{event!=null? event.currencytype:"USD"}</p>
    </Grid>
    <Grid className="col-1-5">{venues!=null?venues.taxcategory:'Tax Name'}
    <p>242.72</p>
    </Grid>
    <Grid className="col-1-5">
    <p></p>
    </Grid>
  </Grid>

  <Grid className="row">
    <Grid className="col-1-5">Booked By
      <p>John Doe <br/> <span className="lowercase">Johndoe@email.com</span></p>      
    </Grid>   
  </Grid>  
  </Grid>  
  <QrCode/>
  
  <Ads addoredit={adsImage.length>0?adsImage[0].eventadid:0}
   eventid={event!=null?event.id:''} getimages={adsImage} setimages={setAdsImage}
    notification={notification} disable={disable} allImage={allImage} getadvert={setAdvertises} setAdvert={advertises}/>
</div>
	<TempFooter/> 

        </div>
        </div>
        <Notification
          notify={notify}
          setNotify={setNotify}
        />
      </main>
      <Footer />
    </div> 
   
  );
}

export default TicketTemplate;