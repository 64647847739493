
import { Avatar,Paper,Grid, makeStyles, Box, Typography,Divider ,Card,
  CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useHistory,Link } from "react-router-dom";
import { signIn } from "../../redux/actions/authActions";
import { Helmet } from "react-helmet";
import Loading from "../../components/controls/Loading";




const useStyles = makeStyles((theme) => ({
  root: {
    '& >  + ': {
      marginLeft: theme.spacing(2),
    },    
  },
  wrapper:{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '20px',
    width: '30%',
    margin: '0px auto',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    }
    },
paper: {
    shadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',     
    borderRadius: '20px',
  },
  card: {
    borderRadius: '20px',   
  },
  link:{
    textTransform: 'capitalize',
    fontWeight: 'normal',
    textDecoration: 'underline',
    fontSize: '14px',
    "&:hover":{
      textDecoration: 'none',
     }
  }, 
  btnRadius: {
      borderRadius: '20px', 
      background: 'linear-gradient(45deg, #fc8b87 5%, #c4201b 90%)',
      minWidth: '130px',
      outline: '0px !important',
      fontWeight: '400 !important', 
      color: '#fffff',
      textTransform: 'capitalize !important',
    }, 
  title:{ 
      fontFamily: 'OstrichSans-Medium',
      letterSpacing: '1.5px !important',
      color:'#bf1914 !important',
      fontSize: '22px !important',
      textTransform: 'uppercase',
      lineHeight: '70px',
      fontWeight: '600',
    },
}));



const initialFValues = {
  email:"",
  password: "",
  
}; 

const Login = (props) =>{
 
  
  const history = useHistory();
  const classes = useStyles();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' }); 
 const dispatch = useDispatch();
  const cookies = new Cookies();
  const[showButton,setShowButton] = useState(true);
  const [loading,setLoading] = useState(true)

  const validate = (fieldValues = values)=>{
  
      const temp = {...errors};
      if("email" in fieldValues){
          if(values.email){
          temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? "": "Email is not valid.";
          }else{
              temp.email=fieldValues.email?"":"Email is required"
          }
      }
      if ("password" in fieldValues) {
       
          temp.password = fieldValues.password?"":"Password is required"
                  
      }

      setErrors({...temp});
      return Object.values(temp).every((x) => x === "");


  };
  const formIsValid = (fieldValues = values) => {
      if( validate()) {
              const isValid =
              fieldValues.email &&
              fieldValues.password &&
              
              Object.values(errors).every((x) => x === "");
         
             return isValid;
              }
           };

  const {values,
         setValues,
         errors,
         setErrors,
         handleInputChange,
         resetForm } = useForm(initialFValues,true,validate);
       
useEffect(()=>{
  const currentUser = cookies.get('currentUser');
  if(currentUser){
    history.push("/EventDashboard")
  }
  setLoading(false)
},[])
         
const handleSubmit = async(event)=> {
          event.preventDefault();
          
          if (formIsValid()) {
            setShowButton(false);  
              try {              
                   await dispatch(
                     signIn({ email: values.email, password: values.password })
                   );
                   const currentUser = cookies.get('currentUser');
                   if(currentUser){
                     resetForm();
                     history.push("/EventDashboard");
                   }
                 } 
                 catch (error) {
                  setShowButton(true);
                  const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
                  notification(messages,"error");
                 }

          }
         
      };
  
const notification = (message,type)=>{
          setNotify({
            isOpen: true,
            message: message,
            type: type
          })
        }

                
     
  return(
      <div className="login-bg">
             <Helmet title="Sign In" />
           {loading?(<div className="p-1 text-left w-100" colSpan="2"><Loading /></div>):
      
        ( <div display="flex" justifyContent="center" className="w-100">
           <Avatar alt="OTS Logo"  onClick={()=>history.push('/')} src="/static/img/login-logo.png" variant="rounded" className="loginLogo"/> 
           <div className="loginTop" variant="rounded">&nbsp;</div>
           <Paper className={classes.paper}>
                          <Card className={classes.card}>
                          <CardContent>
                          <Typography value="title" variant="h5" className={classes.title}>
                          LOGIN
                          </Typography>
      
     <Form onSubmit={handleSubmit} className="create-user">
   <Notification
        notify={notify}
        setNotify={setNotify}
    />
          <Grid container item spacing={2} width="100">
          <Grid item lg={12} sm={12} xs={12}>
              
          <Controls.Input
                          name="email"
                          label="Email Address"
                          value={values.email||''}
                          
                          onChange={handleInputChange}
                          error={errors.email} className="smaller-input" />
          </Grid>
         
          <Divider />
          <Grid item lg={12} sm={12} xs={12}>

          <Controls.Input
                      type="password"
                      name="password"
                      label="Password"
                      value={values.password}
                      onChange={handleInputChange}
                      error={errors.password} className="smaller-input"/>
                </Grid>
         
                <Grid item lg={6} sm={6} xs={7}>
                {showButton ? <Controls.Button type="submit"
                          text="Sign In" size="medium" className="link-red ml-0"/> : <Controls.Button
  text="Sign In" size="medium" className="link-red ml-0"/> }   
                   </Grid>
               
               <Grid item lg={6} sm={6} xs={5}>
                 <Box>
                   <Link to="/ForgotPassword" style={{ display: 'flex',justifyContent: 'flex-end', float: 'right' }} className="text-red my-2">
                     Forgot Password?
                  </Link>
            </Box>
           
                 </Grid>
                
          </Grid> 

      </Form>
      

</CardContent>
</Card>
</Paper>
</div> )  }       
</div>
  )
};

export default Login;