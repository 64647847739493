import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, makeStyles, TablePagination, TableSortLabel } from "@material-ui/core";

const useStyles = makeStyles(theme =>({
    root: {
        width: "100%"
      },
      container: {
            maxHeight: 'calc(100vh - 277px) !important',
            width: '100%',
            overflowX: 'auto',
      },
    table:{
        marginTop : theme.spacing(3),
        '& thead th' : {            
            color : theme.palette.primary.main,
            backgroundColor : theme.palette.primary.light
        },        
        '& tbody tr:hover' : {
            backgroundColor : '#fffbf2',
            cursor : 'pointer'
        }
    },
}));

const useTable = (records, headCells, filterFn , condition, defaultRowsPerPage = 10) => {
    const classes = useStyles();

    //pagination
    const rowsPerPageOptions = [10,15,20];
    const [page,setPage] = useState(0); //Initially 0th page
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage); //Initially 5 rows per page
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const TblContainer = props =>(
        <div className={classes.container}>
            
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
            { props.children }
        </Table> 
    

        </div>
    );

    const TblHead = props =>{

        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }

        return(
            <TableHead>
                <TableRow >
                    {
                        headCells.map((headCell,index) => (
                            <TableCell key={index}
                                sortDirection={orderBy === headCell.id ? order : false}>
                                {headCell.disableSorting ? headCell.label :
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={() => { handleSortRequest(headCell.id) }}>
                                        {headCell.label}
                                    </TableSortLabel>
                                }
                            </TableCell>))
                    }
                </TableRow>
            </TableHead>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value,10));
        setPage(0);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        const splitValue = orderBy?.split('.');
        let first = a[orderBy];
        let last = b[orderBy];
        if (splitValue?.length > 1) {
            let firstValue = a;
            let lastValue = b;
            for (let key of splitValue) {
                if (!firstValue) return 0;
                firstValue = firstValue[key];
                lastValue = lastValue[key];
            }
            first = firstValue;
            last = lastValue;
        }
        if (last < first) {
            return -1;
        }
        if (last > first) {
            return 1;
        }
        return 0;
    };

    const recordsAfterPagingAndSorting = () => {
        if(!condition){
        return stableSort(filterFn.fn(records), getComparator(order, orderBy))
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        }
        else{
            return stableSort(filterFn.fn(records), getComparator(order, orderBy))
            .slice(page * 0, (page + 1) * rowsPerPage)
        }
    };

    const TblPagination = props =>(
        <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={props.rowsPerPageOptions || rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            count={records.length}
            onPageChange = {handleChangePage}
            onRowsPerPageChange = {handleChangeRowsPerPage}
            
        />
    );

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    }
    
};

export default useTable;