import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import {
  TableBody,
  TableRow,
  Link,
  TableCell,
  Toolbar,
  makeStyles,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  Box,
  TableContainer,
  Table,
  TableHead,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import Input from "../../components/controls/Input";
import { useHistory } from "react-router-dom";
import { Search } from "@material-ui/icons";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import Cookies from "universal-cookie";
import { Alert } from "@material-ui/lab";
import { getEventByAccountId } from "../../services/eventService";
import { useDispatch } from "react-redux";
import { Template } from "../../redux/actions/authActions";
import Loading from "../../components/controls/Loading";
import taxService from "../../services/taxService";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  publishTemplateDetails,
  getBase64File,
} from "../../services/brandingServices";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import {
  getCustomerStyles,
  styleManagement,
  deleteStyleManagement,
} from "../../services/brandingServices";
import userService from "../../services/userService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  pageContent: {
    width: "100%",
    padding: "0px 15px",
    margin: "0px auto",
  },
  select: {
    width: "100% !important",
  },
  controlSm: {
    fontSize: "12px",
    padding: "0px 20px",
  },
  pageContentMain: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: "50px",
    [theme.breakpoints.up("xs")]: {
      margin: "0px auto",
      width: " 89%",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "0px auto",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0px auto",
      width: "93%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0px auto",
      width: "70%",
    },
  },

  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  addBar: {
    float: "right",
    padding: "0px",
  },
}));

const drawerWidth = 250;

const headCells = [
  { id: "eventname", label: "Name" },
  { id: "venuename", label: "Venue" },
  { id: "emailtemplate", label: "Email Template" },
  { id: "tickettemplate", label: "Ticket Template" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Branding = () => {
  const classes = useStyles();
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [events, setEvents] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [fileValue, setFileValue] = useState("");
  const [filename, setFilename] = useState("");
  const [checkFile, setCheckFile] = useState(null);
  const [accountid, setAccountID] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [condition, setCondition] = useState(false);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const currentUser = cookies.get("currentUser");
  const [openPopup, setOpenPopup] = useState(false);
  const checkParam = (reqParams, param_key) => {
    return reqParams.includes(param_key);
  };
  const [selectedAccountId, setSelectedAccountId] = useState(
    currentUser.accountid
  );

  useEffect(() => {
    let cancel = false;

    userService
      .getUserAccounts()
      .then((result) => {
        if (cancel) return;
        setAccountID([...result]);
      })
      .catch((error) => {
        errorHandle(error);
      });
    return () => {
      cancel = true;
    };
  }, []);

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error.response.data.message))) {
      notification(error, "error");
    } else {
      const messages =
        error.response && !error.response.data.message
          ? error.response.data.error
            ? error.response.data.error
            : error.response.data
          : error.message;
      messages === "Invalid Token"
        ? timeOutSet()
        : notification(messages, "error");
    }
  };

  const getBrandingList = (getId) => {
    taxService
      .venueByAccountId({ id: getId })
      .then((result) => {
        setRecords(result);
      })
      .catch((error) => {
        errorHandle(error);
      });

    const user = {
      accountid: getId,
    };
    getEventByAccountId(user)
      .then((res) => {
        setShowLoading(false);
        setEvents(res.data);
      })
      .catch((error) => {
        setShowLoading(false);
        errorHandle(error);
      });
  };

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      getBrandingList(selectedAccountId);
      getStyleList(selectedAccountId);
    }
    return () => {
      cancel = true;
    };
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(events, headCells, filterFn, condition);

  const handleSearch = (e) => {
    let target = e.target;
    let searchString = target.value;
    setFilterFn({
      fn: (items) => {
        if (searchString === "") {
          return items;
        } else {
          return items.filter((x) =>
            x.eventname.toLowerCase().includes(searchString.toLowerCase())
          );
        }
      },
    });
    if (searchString === "") {
      setCondition(false);
    } else {
      setCondition(true);
    }
  };

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      cookies.remove("currentUser");
      history.push("/");
    }, 2000);
  };

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type,
    });
  };
  const handleClickEmail = (record, check) => {
    dispatch(Template({ data: record, id: record.id, validate: check }));
    history.push("/EmailTemplate");
  };

  const handleClickTicket = (record, check) => {
    const checking = records.find((x) => x.venueid === record.venueid);
    const venuedetail = checking !== undefined ? checking : null;
    dispatch(
      Template({
        data: record,
        id: record.id,
        venuedetails: venuedetail,
        validate: check,
      })
    );
    history.push("/TicketTemplate");
  };

  const onSubmit = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    publishTemplateDetails({
      action_type: "event template publish",
      event_id: id,
    })
      .then((res) => {
        notification("Published Successfully", "success");
      })
      .catch((err) => {
        notification("Publish Failed", "error");
      });
  };
  const publishChanges = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure to publish the changes?`,
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onSubmit(id);
      },
    });
  };
  const clearbutton = () => {
    setFileValue("");
  };

  const uploadFile = async (e) => {
    console.log("uploadFile", e.target.files[0].type);
    if (e.target.files[0].type === "text/css") {
      setFileValue(e.target.value);
      let reader = new FileReader();
      let file = e.target.files[0];
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);
        setFilename(e.target.files[0].name);
        setCheckFile(file);
      }
      setCheckFile(file);
    } else {
      notification("CSS file is required.", "error");
    }
  };

  const handleFileSubmit = () => {
    if (fileValue !== "") {
      const formData = new FormData();
      const cssfile = checkFile;
      const styles = {
        action_type: "add",
        style_id: "0",
        customer_id: selectedAccountId,
        filename: filename,
        style_url: filename,
        status: styleList.length > 0 ? 0 : 1,
      };
      formData.append("styles", JSON.stringify(styles));
      formData.append("file", cssfile);

      styleManagement(formData)
        .then((result) => {
          if (result.message !== "Failed") {
            notification("StyleSheet added successfully ", "success");
            setFileValue("");
            setOpenPopup(false);
            getStyleList(selectedAccountId);
          } else {
            notification("This StyleSheet name is already added.", "error");
          }
        })
        .catch((error) => {
          errorHandle(error);
        });
    } else {
      notification("Stylesheet is required.", "error");
      setFileValue("");
    }
  };

  const getStyleList = (id) => {
    const user = {
      action_type: "getmaster list",
      customer_id: id,
    };
    getCustomerStyles(user)
      .then((res) => {
        setStyleList(res.data);
      })
      .catch((error) => {
        errorHandle(error);
      });
  };

  const onDelete = (style) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const deleteStyle = {
      style_id: style.styleid,
    };
    deleteStyleManagement(deleteStyle)
      .then((res) => {
        notification("Stylesheet deleted successfully ", "success");
        getStyleList(selectedAccountId);
      })
      .catch((error) => {
        errorHandle(error);
      });
  };

  const onStatusChange = (style) => {
    const formData = new FormData();
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const cssfile = style.filepath;
    const statusStyle = {
      action_type: "edit",
      style_id: style.styleid,
      customer_id: style.customerid,
      filename: style.filename,
      style_url: style.filepath,
      status: style.active === 1 ? 0 : 1,
    };
    formData.append("styles", JSON.stringify(statusStyle));
    formData.append("file", cssfile);
    styleManagement(formData)
      .then((res) => {
        notification("Status changed successfully ", "success");
        getStyleList(selectedAccountId);
      })
      .catch((error) => {
        errorHandle(error);
      });
  };
  const getAccountidDetails = (e) => {
    setShowLoading(true);
    setEvents([]);
    getBrandingList(e.target.value);
    getStyleList(e.target.value);
    setSelectedAccountId(e.target.value);
  };

  const download = (url, filename) => {
    const path = {
      filepath: url,
    };
    getBase64File(path)
      .then((res) => {
        if (res.message === "Success") {
          let link = document.createElement("a");
          link.href = `data:text/css;base64,${res.data}`;
          link.setAttribute("download", filename);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          notification("Download Failed ", "error");
        }
      })
      .catch((error) => {
        errorHandle(error);
      });
  };

  return (
    <div>
      <Helmet title="Branding" />

      <main className={classes.content}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={0}>
            <Grid item lg={6} sm={6} xs={6}>
              <Typography variant="h5" gutterBottom className="breadcrumbs">
                Branding
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xs={6}>
              <Toolbar className="selectbar singleline">
                <Box>
                  {currentUser.roleid === 1 && accountid.length > 0 ? (
                    <select
                      value={selectedAccountId}
                      onChange={getAccountidDetails}
                      className="select-box mt-0 mb-0"
                    >
                      <option value="0">Select Customer</option>
                      {accountid.map((v, k) => {
                        if (v.roleid !== 1)
                          return (
                            <option key={k} id={k} value={v.accountid}>
                              {v.accountname}
                            </option>
                          );
                      })}
                    </select>
                  ) : (
                    <div></div>
                  )}
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </Grid>
        <Divider className="mb-2" />

        <div className={classes.pageContent}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <b>Purchase Styling</b>
            </Box>
            <Box>
              <Tooltip
                TransitionComponent={Zoom}
                title="Download default template "
                arrow
              >
                <Link
                  href="#"
                  className="text-red m-2"
                  onClick={() =>
                    download(
                      "https://s3.amazonaws.com/ots-uat/styles/1_default-stylesheet.css",
                      "default.css"
                    )
                  }
                >
                  CSS Template
                </Link>
              </Tooltip>
              <Controls.ActionButton
                onClick={() => {
                  setOpenPopup(true);
                  setFileValue("");
                }}
                className="outlined-primary"
              >
                <AddCircleOutlineIcon fontSize="medium" variant="outlined" />
              </Controls.ActionButton>
            </Box>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell className="text-center">Active</TableCell>
                  <TableCell className="text-center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {styleList.map((style, index) => (
                  <TableRow key={index}>
                    <TableCell>{style.filename}</TableCell>
                    <TableCell className="text-center pr-1">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => onStatusChange(style)}
                            className="p-0 m-0"
                            checked={style.active !== 0}
                          />
                        }
                        className="small-icon p-0 m-0"
                      />
                    </TableCell>
                    <TableCell className="text-center">
                      <Controls.ActionButton
                        onClick={() =>
                          download(style.filepath, style.filename, style)
                        }
                      >
                        <CloudDownloadOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="primary"
                        />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: ` Are you sure to delete the style sheet  [${style.filename}]?`,
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(style);
                            },
                          });
                        }}
                      >
                        <DeleteOutlinedIcon
                          fontSize="small"
                          variant="outlined"
                          color="secondary"
                        />
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box className="pt-3">
              <b>Ticket and Email</b>
            </Box>
            <Toolbar className={classes.addBar}>
              <Input
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                size="small"
              />
            </Toolbar>
          </Box>
          <TblContainer className={classes.container}>
            <TblHead className="p-1" />
            <TableBody>
              {recordsAfterPagingAndSorting().map((record, index) => (
                <TableRow key={index}>
                  <TableCell>{record.eventname}</TableCell>
                  <TableCell>{record.venuename}</TableCell>
                  <TableCell>
                    {
                      <Button
                        size="small"
                        className="btn-redoutline m-2"
                        onClick={() => handleClickEmail(record, "preview")}
                      >
                        {" "}
                        <VisibilityIcon className="mr-2" />
                        Preview
                      </Button>
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <Button
                        size="small"
                        className="btn-redoutline m-2"
                        onClick={() => handleClickTicket(record, "preview")}
                      >
                        <VisibilityIcon className="mr-2" />
                        Preview
                      </Button>
                    }
                  </TableCell>

                  <TableCell className="custom-control">
                    <FormControl>
                      <Select className="custom-morevertical">
                        <MenuItem
                          onClick={() => handleClickTicket(record, "Edit")}
                        >
                          Edit Ticket Template
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleClickEmail(record, "Edit")}
                        >
                          Edit Email Template
                        </MenuItem>
                        <MenuItem onClick={() => publishChanges(record.id)}>
                          Publish Changes
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              {!recordsAfterPagingAndSorting().length && (
                <TableRow style={{ textAlign: "center" }}>
                  {showLoading ? (
                    <TableCell className="p-1 text-left w-100" colSpan="5">
                      <Loading />
                    </TableCell>
                  ) : (
                    <TableCell className="p-1 text-left" colSpan="5">
                      {<Alert severity="warning">No records found</Alert>}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </div>

        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />

        <Popup
          title="Upload Stylesheet"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <Grid container item spacing={2} md={12} className="m-0">
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="position-relative"
            >
              <input
                className="choosefile w-100"
                type="file"
                name="custom-style"
                accept=".css"
                value={fileValue}
                onChange={uploadFile}
              />
              <label className="upload-label">Upload Stylesheet</label>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="text-right">
              <Controls.Button
                text="Clear"
                color="default"
                size="medium"
                onClick={clearbutton}
                className="link-gray"
              />
              <Controls.Button
                type="submit"
                text="Upload"
                size="medium"
                onClick={handleFileSubmit}
                className="link-red"
              />
            </Grid>
          </Grid>
        </Popup>
      </main>
      <Footer />
    </div>
  );
};

export default Branding;
