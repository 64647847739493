import axios from "axios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL,
});


export async function getAllClient(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/customers/manageAPIInformation`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function getIndividualList(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/customers/manageCustomerAPI`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}

export async function validateSendgridKey(userid) {

    const currentUser = cookies.get('currentUser');

    return await
        http
            .post(`/events/validateSendgridKey`, userid, {
                headers: { 'authorization': currentUser ? `Bearer ${currentUser.token}` : `` }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
}