import { Grid, makeStyles, Divider, Paper, Container, Breadcrumbs,
  Typography, Link, InputAdornment, Dialog, IconButton, Button, Box, Switch, CircularProgress, FormControlLabel } from '@material-ui/core';
import React, { useState, useEffect, } from "react";
import { Snackbar } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { createUpsell, updateUpsell, getEventByAccountId, getUpsellByEventId } from '../../services/eventService';
import { upselledit } from '../../redux/actions/authActions';
import { Alert } from "@material-ui/lab";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import { NavLink } from "react-router-dom";
import customerService from "../../services/customerService";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
root: {
  '& > + ': {
    margin: theme.spacing(2),
  },
  width: "100%",
},
select: {
  width: '100% !important',
},
pageContentMain: {
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  marginBottom: '50px',
  [theme.breakpoints.up('xs')]: {
    margin: '0px auto',
    width: ' 89%',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0px auto',
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0px auto',
    width: '93%',

  },
  [theme.breakpoints.up('lg')]: {
    margin: '0px auto',
    width: '70%',
  },
},   
pageContent: {
  margin: theme.spacing(3),
  padding: theme.spacing(3),
},
content: {
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
},
drawerPaper: {
  width: drawerWidth,
},
toolbar: theme.mixins.toolbar,
  buttonRight:{
      display: 'grid',
      justifyContent: 'flex-end',
      marginRight: '0px !important',
  }
}));

const drawerWidth = 250;

const initialFValues = {
id: 0,
eventid: "",
upsellname: "",
upselldescription: "",
availablecount: "",
cost: "",
invenuecost: "",
mincount:"",
maxcount:"",
upselltaxcategory:"",
isallocation:0,
};

const UpsellCreation = () => { 
  const classes = useStyles();
  const history = useHistory();
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const check = useSelector(state => state.authReducer);
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const currentUser1 = cookies.get('upselladdedit');
  const [fileImage, setFileImage] = useState(null);
  const [filenameImage, setFilenameImage] = useState("");
  const [uploadedFile, setUploadedFile] = useState({fileimage:''});    
  const [event, setEvent] = useState([]);  
  const [dbsend, setDbsend] = useState("");
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [imageValue, setImageValue] = useState("");  
  const [preview, setPreview] = useState(false); 
   const dispatch = useDispatch(); 
  const [currency,setCurrency]=useState('');   
  const [taxCatgry,settaxCatgry] = useState([]);
  const [variantList, setVariantList] = useState([
    { id: 1, variantname: '', variantdescription: '', variantquantity: 0, variantinventoryid: '' },
  ])
  const [variantError, setVariantError] = useState([]);
  const [oneClick, setOneClick] = useState(false);

  const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }
 
  useEffect(() => {  
    let cancel = false; 
    const getIds = currentUser1?currentUser1.eventAccountId : currentUser.accountid 
      const users = {
          accountid: getIds
      }
      getEventByAccountId(users).then((result) => { 
        if (cancel) return;                  
        if(result.data != null){
          setEvent(result.data)   
        }         
      }).catch((error) => {
        errorHandle(error);
      });
      return () => {
        cancel = true;
      }
    }, [])
   
  

  



    const validate = (fieldValues = values) => {        
      const temp = { ...errors };
      if ("upsellname" in fieldValues) {
        temp.upsellname = fieldValues.upsellname ? "" : "Name is required";
      }
      if ("eventid" in fieldValues) {
        temp.eventid = fieldValues.eventid ? "" : "Event is required";
      }
      if ("availablecount" in fieldValues) {
        if(fieldValues.availablecount===""){
        temp.availablecount ="Available Count is required";
        }else if(dbsend==="Edit" && ((count - Number(values.balancecount)) > Number(fieldValues.availablecount)) ){
          temp.availablecount =`Already upsells are sold up to ${(count - Number(values.balancecount))}, Please provide a starting value higher from sold count`;
        }
        else{
          temp.availablecount ="";
        }
      }
  
      if ("upselldescription" in fieldValues) {
        temp.upselldescription = fieldValues.upselldescription ? "" : "Description is required";
      }
      if ("cost" in fieldValues) {
        temp.cost = fieldValues.cost ? "" : "Online Cost is required";
      }
      if ("invenuecost" in fieldValues) {
        temp.invenuecost = fieldValues.invenuecost ? "" : "In Venue Cost is required";
      }
      if ("mincount" in fieldValues) {
        if (values.maxcount !== "") {
          if (Number(fieldValues.mincount) > Number(values.maxcount)) {
            temp.mincount = "Minimum count should not be higher than Maximum count";
          } else {
            temp.mincount = ""
          }
        } else {
          temp.mincount = fieldValues.mincount ? "" : "Minimum upsell count is required";
        }
      }
      if ("maxcount" in fieldValues) {
        if(Number(fieldValues.maxcount)<Number(values.mincount)){
          temp.maxcount = "Maximum count should not be lesser than Minimum count";
        }else if(Number(fieldValues.maxcount)>Number(values.availablecount)){
          temp.maxcount = "Maximum count should not be greater than available count"
        }else{
        temp.maxcount = fieldValues.maxcount ? "" : "Maximum upsell count is required";
        }
      }
  
      
      setErrors({ ...temp });
  
      return Object.values(temp).every((x) => x === "");   
  
    };

    const formIsValid = (fieldValues = values) => {
      let condition = true;
      setOneClick(true);
      if(dbsend !== 'Edit' && fileImage===null){
          condition=false;
          setUploadedFile({fileimage:fileImage===null?'Please upload Upsell image':''});                
      } 
    
  
   
      if (validate() && condition && variantError.length === 0) {
     
        getUpsellByEventId({eventid: values.eventid, channel: "boxoffice"}).then((res) => {  
          const VenueId = event.find(x=>x.id===values.eventid)
           const venues= VenueId.venueid     
        let isValid = checkUpsellExist(res);
        if(isValid){
          let json = {
            event_id: values.eventid,
            upselltaxcategory_id:Number(values.upselltaxcategory) , 
            upsell_name:values.upsellname,
            upsell_description: values.upselldescription,
            venue_id:  Number(venues),     
            available_count: Number(values.availablecount),
            upsell_cost: Number(values.cost),
            upsell_invenuecost: Number(values.invenuecost),
            min_count:Number(values.mincount),
            max_count:Number(values.maxcount),
            variants: variantList.filter(x=>x.variantname !== ""),
            isallocation:values.isallocation,
            external_inventoryid: values.external_inventoryid,
          }
          
          if (dbsend !== 'Edit') { 
            json.action_type = 'add';
            json.id= 0;
            json.active_status= 1;
            json.upsell_image= filenameImage;

            const formData = new FormData();
            formData.append('upsell', JSON.stringify(json));            
            formData.append('file', fileImage);
            createUpsell(formData).then((result) => {
              setOpen(true);
              setOneClick(false);
            }).catch(error => {
              errorHandle(error);
              setOneClick(false);
            })
          
          }
          else {
            json.action_type= 'edit';
            json.id= values.upsellid;
            json.active_status= values.activestatus;
            json.upsell_image= fileImage != null ? filenameImage : values.image;
            
            const formData = new FormData();
            formData.append('upsell', JSON.stringify(json));            
            if(fileImage != null) {
                formData.append('file', fileImage);
            }

            updateUpsell(formData).then((result) => {      
              setOpen(true);
              setOneClick(false);
            }).catch(error => {
              errorHandle(error);
              setOneClick(false);
            })
          }
        } 
      })
      .catch((error) => {
        errorHandle(error);
        setOneClick(false);
      });
      } else {
        setOneClick(false);
      }       
    };
 
    

    const checkUpsellExist = (res) =>{                 
        if(res != null && res.length > 0){
          if(dbsend !== 'Edit' && res.filter((e) => e.upsellname.toUpperCase() === values.upsellname.toUpperCase()).length > 0){
            notification('Upsell name already exist', 'error');
            return false;
          }
          else if(dbsend === 'Edit' && res.filter((e) => e.upsellid !== values.upsellid && e.upsellname.toUpperCase() === values.upsellname.toUpperCase()).length > 0){
            notification('Upsell name already exist', 'error');
            return false;
          }
          else{
            return true;
          }
        }
        else{
          return true;
        }        
    }

    const timeOutSet = () => {
      notification("The Time Gets Expired.....Try again...", "error");
      setTimeout(() => {
        cookies.remove('currentUser');
        cookies.remove('UpsellConfiguration');
         history.push('/');
      }, 2000);
    }
    const notification = (message, type) => {
      setNotify({
        isOpen: true,
        message: message,
        type: type
      })
    }
  
    const { values,
      setValues,
      errors,
      setErrors,
      handleInputChange,
      resetForm } = useForm(initialFValues, true, validate);
  
    const handleSubmit = (event) => {
      event.preventDefault();
      formIsValid();
    };   
  

    const uploadFile = (e) => {

      setImageValue(e.target.value);
      let reader = new FileReader();
      let file = e.target.files[0];
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);
        setFileImage(file);
        setFilenameImage(e.target.files[0].name);
        setUploadedFile(''); 
      }    
    }

    useEffect(() => {
      let cancel = false; 
      if (check.upsell != null && check.upsell.length !== 0) {
        if (cancel) return;
        setValues(check.upsell);
        if(check.upsell.variants.length > 0){
          setVariantList(check.upsell.variants);
        }
        setCount(check.upsell.availablecount);
        setDbsend('Edit');
        setCurrency(check.upsell.currencytype)
        const empty = [];
        dispatch(upselledit(empty));
      }
      return () => {
        cancel = true;
      }
    }, [])

    useEffect(()=>{
      symbolValue();
    

    },[values.eventid])
  
    const symbolValue=()=>{
      const symbol = [
        {label:"$",value:"USD"},
        {label:"£",value:"GBP"},
    ]  
      const paisa = event.find(e=>e.id===values.eventid);
    
     if(paisa!==undefined){
    
      const symbolLable = symbol.find(e=>e.value===paisa.currencytype)
      setCurrency(symbolLable.label)
      }
    }

    const onKeyPressHandler = e=> {
       if (e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) {
       }
       else{
         e.preventDefault(); 
       }
   }
   const onKeyPressHandler1 = e=> {
    if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
    }
    else{
      e.preventDefault(); 
    }
 }


 useEffect(() => {
  
   let cancel = false;
   if(values.eventid!==""&&event.length!==0){
   const venueIdd = event.filter(x => x.id === values.eventid)
   const venueMap = venueIdd.map(x => x.venueid)

   const users = {
     action_type: "subtaxcategory list", taxcategory_id: Number(venueMap[0])
   }
   if (cancel) return;
   customerService.getTaxSubcategory(users).then((result) => {
     
     if (result.data !== null&&result.data.lenght!==0) {
       settaxCatgry(result.data)
       
     }
   }).catch((error) => {
     errorHandle(error);
   });
  }else{
    settaxCatgry([{upselltaxcategoryid:"null",upselltaxcategory:"Select event to choose category"}])
  }
   return () => {
     cancel = true;
   }
}, [values.eventid,event])

const handleFormChange = (event, index) => {
  const{name, value} = event.target;
  let data = [...variantList];
  data[index][name] = value;
  errormethod(index, name, value);
  setVariantList(data);
  
}

const removeFields = (index) => {
  let data = [...variantList];
  data.splice(index, 1);
  if(data.length === 0){
    setVariantList([{
      id: 1,
      variantname: '',
      variantdescription: '',
      variantquantity: 0,
    }]);
  }else{
  setVariantList(data);
  }
  let err = variantError.filter(x=>x.id !== index);
  setVariantError(err);
}


const addFields = (index) => {
  if(variantList[index]?.variantname !== ""){
  let object = {
    id: variantList[index]?.id + 1,
    variantname: '',
    variantdescription: '',
    variantquantity: 0,
    variantinventoryid: ''
  }
  setVariantList([...variantList, object]);
}else{
  notification("Please provide the values in exisiting field",'error')
}
}

const errormethod = (index, name, values) => {

  let count = 0;
  for(let a=0; a<variantList.length && count <= 1;a++){   
    if(values === variantList[a].variantname){
      count = count+1
    }
  }
  if(count>1){
    setVariantError([...variantError, {id: index, variantname: "This Variants Name is repeated"} ]);
  }else{
    let err = variantError.filter(x=>x.id !== index);
    setVariantError(err);
  }
}

    return (

      <div >
      <Helmet title="Upsell Configuration" />
    <main className={classes.content}>
    <Typography variant="h5" gutterBottom className="breadcrumbs pb-0">
    Upsell Configuration
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
    <Link component={NavLink} exact to="/Upsells" underline="hover" color="inherit">
          Upsells
    </Link>
        <Typography color="textPrimary">Upsell Configuration</Typography>
    </Breadcrumbs>
    <Divider className="my-3" /> 
        <Form onSubmit={handleSubmit} className="create-user">
        <Paper className={classes.pageContentMain}>
          <Notification
            notify={notify}
            setNotify={setNotify}
          />
          <Grid container item spacing={2} md={12} className="w-auto m-0">
            
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Controls.Select
                name="eventid"
                label="Event"
                value={values.eventid || ''}
                options={event}
                disabled={dbsend==='Edit'}
                onChange={handleInputChange}
                keyName="id"
                valueName="eventname"
                input={<input name="eventid" id="eventid" />}
                error={errors.eventid} className={classes.select}
                
              />
             
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Controls.Input
                name="upsellname"
                label="Name"                  
                value={values.upsellname || ''}
                onChange={handleInputChange}
                inputProps={{ maxLength: 500 }}
                error={errors.upsellname} className="smaller-input" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Controls.Input
                name="upselldescription"
                label="Description"
                value={values.upselldescription || ''}
                onChange={handleInputChange}     
                inputProps={{ maxLength: 4000 }}             
                error={errors.upselldescription} className="smaller-input" />
            </Grid>


            <Grid  item lg={12} md={12} sm={12} xs={12}>
                <Controls.Input
                name="external_inventoryid"
                label="Inventory Id (Point One)"
                value={values.external_inventoryid || ''}
                onChange={handleInputChange}     
                inputProps={{ maxLength: 100 }}             
                error={errors.external_inventoryid} className="smaller-input" />
            </Grid>


            <Grid item xs={12} position="relative">

            <Box className="decimal ticket-allotment">
         {variantList.map((form, index) => {
           return (
             <Grid container spacing={2} >
        
             <Grid item lg={2} md={2} sm={2} xs={12}>
             <Controls.Input
                            name="variantname"
                            label="Variant Name"
                            value={form.variantname}
                            className="smaller-input"
                            onChange={event =>  handleFormChange(event, index)}
                            error={variantError.find(x=>x.id === index)?.variantname|| ""}
                             />

            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
            <Controls.Input
                            name="variantdescription"
                            label="Variant Description"
                            value={form.variantdescription}
                            className="smaller-input"
                            onChange={event => handleFormChange(event, index)}
                            error={""}
                            />
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={12}>
            <Controls.Input
                            name="variantinventoryid"
                            label="Inventory Id (Point One)"
                            value={form.variantinventoryid}
                            className="smaller-input"
                            onChange={event => handleFormChange(event, index)}
                            error={""}
                            />
            </Grid>
             <Box >
            <div className="my-4 ml-1">
            <DeleteOutlinedIcon fontSize="medium" variant="outlined" className="text-red pointer" onClick={() =>  removeFields(index)} /></div>
          </Box>

          <Grid item lg={12} md={12} sm={12} xs={12}>
             {variantList.length - 1 === index && <Button size="medium" variant="outlined" className="btn-redoutline" onClick={() => addFields(index)}>
              <AddCircleOutlineIcon color="primary" className="text-red" /><b>&nbsp;Add Variant Options</b></Button>} 
          </Grid>
        </Grid>
      );
  })}
</Box>
</Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controls.Input
                name="availablecount"
                label="Count"
                onKeyPress={onKeyPressHandler}
                inputProps={{
                  maxLength: 10,
                }}
                value={values.availablecount ?? ''}
                onChange={handleInputChange}
                error={errors.availablecount} className="smaller-input" />
            </Grid>
            <Divider />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controls.Input
                name="cost"
                type='number'
                label="Online Cost"
                value={values.cost || ''}
                inputProps={{
                  maxLength: 5,
                }}
                onKeyPress={onKeyPressHandler1}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                }}
                error={errors.cost} className="smaller-input" />
            </Grid> 
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controls.Input
                name="invenuecost"
                type='number'
                label="In Venue Cost"
                value={values.invenuecost || ''}
                inputProps={{
                  maxLength: 5,
                }}
                onKeyPress={onKeyPressHandler1}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
                }}
                error={errors.invenuecost} className="smaller-input" />
            </Grid> 

             <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controls.Input
                name="mincount"
                label="Minimum Upsell Count"
                value={values.mincount ?? ''}
                inputProps={{
                  maxLength: 3,
                }}
                onKeyPress={onKeyPressHandler}
                onChange={handleInputChange}
                error={errors.mincount} className="smaller-input" />
            </Grid>        

             <Grid item lg={6} md={6} sm={12} xs={12}>
              <Controls.Input
                name="maxcount"
                label="Maximum Upsell Count"
                value={values.maxcount ?? ''}
                inputProps={{
                  maxLength: 3,
                }}
                onKeyPress={onKeyPressHandler}
                onChange={handleInputChange}
                error={errors.maxcount} className="smaller-input" />
            </Grid>        
            <Grid item lg={6} md={6} sm={12} xs={12}>
            <Controls.Select
                name="upselltaxcategory"
                label="Category"
                value={values.upselltaxcategory || ''}
                options={taxCatgry}
                onChange={handleInputChange}
                keyName="upselltaxcategoryid"
               valueName="upselltaxcategory"
                input={<input name="upselltaxcategory" id="upselltaxcategoryid" />}
              error={errors.upselltaxcategory} className={classes.select}
                
              />
            </Grid> 
            <Divider />
            <Grid item lg={6} md={6} sm={12} xs={12} className="position-relative">
              <input className="choosefile"
                type='file'
                name="imageUpload"
                accept=".png, .jpg, .jpeg"
                value={imageValue}
                onChange={uploadFile} />
              <label className="upload-label">Upsell Image</label>
              <span style={{color:'red'}}>{uploadedFile.fileimage}</span>
              {dbsend&&!filenameImage?<Controls.Button text="Preview Image" size="small" variant="outlined" className="btn-redoutline" onClick={()=>{setPreview(true)}} />:null}      
            </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className="position-relative">
                  <FormControlLabel
                    control={<Switch
                      checked={values.isallocation === 1}
                      onChange={(e) => setValues({ ...values, isallocation: e.target.checked ? 1 : 0 })}
                    />}
                    label={values.isallocation === 0 ? "All channel" : "Box Office channel only"}
                  />
                </Grid>

      
            <Grid container item spacing={2} className={classes.buttonRight}>
          <Grid item lg={12} md={12} sm={12} xs={12} className="p-0">
          <Controls.Button text="Clear" color="default" size="medium" className="link-gray"
              onClick={() => { resetForm();setImageValue('');setUploadedFile({fileimage:''}); setFileImage(null);setDbsend(''); 
              setVariantList([{
                id: 1,
                variantname: '',
                variantdescription: '',
                variantinventoryid: '',
                variantquantity: 0,
              }]); }} />
          <Controls.Button
            type="submit" disabled={oneClick}
            endIcon={oneClick && <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
            text="Submit" size="medium" className="link-red" />
            </Grid>
            </Grid>
  
  
          </Grid>
        </Paper>         
        
          </Form>
          </main>
          <Snackbar
          style={{
            height: "100%",
            backgroundcolor: "#DB7093 "
          }}
          anchororigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={open}
          onClose={() => setOpen(true)}
        >
          <Alert color="success" severity="success"
            key={`top,center`}
            anchororigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <p>{dbsend !== 'Edit' ? 'Upsell Created Successfully' : 'Upsell Updated Successfully'}</p> <br/>
            <p>
            Do you want to create another Upsell ?
            </p>
            <Container>
              <div className="pt-3">
                <Controls.Button color="default" text="Yes" size="medium" className="link-gray"
                  onClick={() => { setOpen(false) ;  const empty = [];
                  dispatch(upselledit(empty)); resetForm();setDbsend('');setImageValue('');setFileImage(null);
                  setVariantList([{
                    id: 1,
                    variantname: '',
                    variantdescription: '',
                    variantinventoryid: '',
                    variantquantity: 0,
                  }]); }} />
                <Controls.Button variant="contained" text="No" size="medium" className="link-red"
                  onClick={() => { history.push('/Upsells') }} />
              </div>
            </Container>
          </Alert>
        </Snackbar>
  
            <Dialog
        modal={false}
        open={preview}
        onClose={() => setPreview(false)}
      >

        <div className='events__body__image shadow'>
          <IconButton onClick={() => setPreview(false)} className="close-preview"><HighlightOffOutlinedIcon/></IconButton>
          <div className="p-2 h-100">{<LazyLoadImage src={values.image} alt={values.image}/>}</div>
        </div>

      </Dialog>
          <Footer />
      </div>
  
    )
};

export default UpsellCreation;