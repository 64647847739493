import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  Divider,
  makeStyles,
  Typography,
  Grid,
  Link,
  IconButton
} from '@material-ui/core'
import Footer from '../../components/layouts/Footer'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  RoomOutlined as RoomOutlinedIcon,
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon,
  PeopleOutlineOutlined as PeopleOutlineOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  ArrowUpwardOutlined as ArrowUpwardOutlinedIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon
} from '@material-ui/icons'
import Holds from './Holds'
import Popup from '../../components/Popup'
import TeamStatsDialog from './TeamStatsDialog'
import TicketDetail from '../dashboard/TicketDetail'
import { getEventPerformanceViewData } from '../../services/eventService'
import UpsellTable from '../dashboard/UpsellTable'
import { universaldate } from '../../utils/dateFormat'
import Loading from '../../components/controls/Loading'

const LocationIcon = styled(RoomOutlinedIcon)`
  color: #0c5ac6;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px;
  gap: 11px;
  width: 44px;
  height: 44px;
  background: rgba(12, 90, 198, 0.2);
  border-radius: 6px;
`

const DateIcon = styled(EventAvailableOutlinedIcon)`
  color: ${(props) => props.inputcolor};
  background: ${(props) => props.backgroundcolor};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 11px;
  gap: 11px;
  width: 44px;
  height: 44px;
  border-radius: 5.5px;
`

const RevenueIcon = styled(TrendingUpOutlinedIcon)`
  color: #ffffff;
  background: #119d0e;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
`
const SoldTicketsIcon = styled(ConfirmationNumberOutlinedIcon)`
  color: #ffffff;
  background: #9d1b33;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 66.67px;
  height: 64px;
  border-radius: 8px;
`

const drawerWidth = 250
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  Toolbar: {
    width: '50% !important',
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  borderOutline: {
    padding: 20,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 8,
    boxShadow: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    flexGrow: 1
  },
  smallTitle: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.5,
    textAlign: 'left',
    color: '#878787',
  },
  smallText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.2,
    color: '#323232',
  },
  revenueTicketsStyle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: -0.5,
    color: '#323232',
  },
  redIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 20,
    gap: 10,
    width: 64,
    height: 64,
    background: 'rgba(255, 255, 255, 0.2)',
    color: '#9D1B33',
    border: '1px solid #9D1B33',
    borderRadius: 8,
  },
  mediumHeading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: -1,
    color: '#323232',
  },
  redLink: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: -0.5,
    color: '#9D1B33',
  },
}))

export default function PerformanceView() {
  const classes = useStyles()
  const history = useHistory();
  const { eventOccurrenceId } = useParams();
  const [openPopup, setOpenPopup] = useState(false)
  const [openOrdersPopup, setOpenOrdersPopup] = useState(false)
  const [openUpsellsPopup, setOpenUpsellsPopup] = useState(false);
  const [upsells, setUpsells] = useState([]);
  const [eventOccurrence, setEventOccurrence] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const eventPerformanceViewData = await getEventPerformanceViewData(eventOccurrenceId);
      setUpsells(eventPerformanceViewData.data.upsells || []);
      setEventOccurrence(eventPerformanceViewData.data.eventOccurrence || {});
      setIsLoading(false)
    }
    eventOccurrenceId && fetchData();
  }, [eventOccurrenceId])
  return (
    <div>
      <Helmet title="Performance View" />

      <main className={classes.content}>
        <Grid container item xs={12}>
          <IconButton onClick={() => history.push("/EventDashboard")}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={0} direction="column">
              <Grid item>
                <Typography variant="h5" gutterBottom className="breadcrumbs">
                  {eventOccurrence.eventname} {eventOccurrence.eventdetailfile?.event_subcategory ? eventOccurrence.eventdetailfile?.event_subcategory : ''}
                </Typography>
              </Grid>
              <Grid
                item
                variant="subtitle1"
                className="welcome-msg"
                style={{ padding: '0px 12px 5px 12px' }}
              >
                Date/Time: {universaldate(eventOccurrence.eventdetailfile?.eventdatetime)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        { !isLoading ? (
          <>
            <Grid container className={classes.pageContent}>
              <Grid item lg={4} className={classes.borderOutline}>
                <Grid container spacing={3} >
                  <Grid item md={3} lg={3} xl={3}>
                    <LocationIcon />
                  </Grid>
                  <Grid item md={9} lg={9} xl={9}>
                    <Grid container direction="column">
                      <Grid item className={classes.smallTitle}>
                        Venue
                      </Grid>
                      <Grid item className={classes.smallText}>
                        {eventOccurrence.venuename}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={3} lg={3} xl={3}>
                    <DateIcon
                      inputcolor="#119D0E"
                      backgroundcolor="rgba(17, 157, 14, 0.2)"
                    />
                  </Grid>
                  <Grid item md={9} lg={9} xl={9}>
                    <Grid container direction="column">
                      <Grid item className={classes.smallTitle}>
                        Onsale
                      </Grid>
                      <Grid item className={classes.smallText}>
                        DateTime: {universaldate(eventOccurrence.eventdetailfile?.onsaledate)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={3} lg={3} xl={3}>
                    <DateIcon
                      inputcolor="#BD1D1E"
                      backgroundcolor="rgba(189, 29, 30, 0.2)"
                    />
                  </Grid>
                  <Grid item md={9} lg={9} xl={9}>
                    <Grid container direction="column">
                      <Grid item className={classes.smallTitle}>
                        Offsale
                      </Grid>
                      <Grid item className={classes.smallText}>
                        DateTime: {universaldate(eventOccurrence.eventdetailfile?.enddate)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={7}>
                <Grid container>
                  <Grid item className={classes.borderOutline}>
                      <Grid container spacing={3}>
                        <Grid item md={3} lg={3} xl={3}>
                          <RevenueIcon />
                        </Grid>
                        <Grid item md={9} lg={9} xl={9}>
                          <Grid container direction="column">
                            <Grid item className={classes.smallTitle}>
                              Total Revenue
                            </Grid>
                            <Grid item className={classes.revenueTicketsStyle}>
                              <span>{eventOccurrence.currencytype === 'USD' ? '$' : '£'}</span>
                              {eventOccurrence.revenue}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>

                  <Grid item className={classes.borderOutline}>
                    <Grid container spacing={3}>
                      <Grid item md={3} lg={3} xl={3}>
                        <SoldTicketsIcon />
                      </Grid>
                      <Grid item md={9} lg={9} xl={9}>
                        <Grid container direction="column">
                          <Grid item className={classes.smallTitle}>
                            Total Tickets Sold
                          </Grid>
                          <Grid item className={classes.revenueTicketsStyle}>
                            {eventOccurrence.totalTicketsSold}/{eventOccurrence.eventdetailfile?.numberoftickets}
                          </Grid>                          
                          { eventOccurrence?.totalTicketsReserved ? 
                            <Grid item className={classes.revenueTicketsStyle}>
                            {eventOccurrence.totalTicketsReserved} Tickets Reserved
                          </Grid> : null                       
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: 0 }}>
                  {eventOccurrence.mapinventory === 1 && (
                    <Grid item className={classes.borderOutline}>
                      <Grid container spacing={3}>
                        <Grid item md={5} lg={5} xl={5}>
                          <PeopleOutlineOutlinedIcon className={classes.redIcon} />
                        </Grid>
                        <Grid item md={7} lg={7} xl={7}>
                          <Grid container direction="column">
                            <Grid item className={classes.mediumHeading}>
                              Team Stats
                            </Grid>
                            <Grid item className={classes.smallTitle}>
                              <Link
                                component="button"
                                className={classes.redLink}
                                style={{ textDecoration: 'none' }}
                                onClick={() => setOpenPopup(true)}
                              >
                                View here
                              </Link>

                              {openPopup ? (
                                <Popup
                                  title={'Team Stats'}
                                  openPopup={openPopup}
                                  setOpenPopup={setOpenPopup}
                                >
                                  <TeamStatsDialog
                                    eventId={eventOccurrence._id}
                                    eventDetailId={eventOccurrenceId}
                                  />
                                </Popup>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item className={classes.borderOutline}>
                    <Grid container spacing={3}>
                      <Grid item md={5} lg={5} xl={5}>
                        <ShoppingCartOutlinedIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item md={7} lg={7} xl={7}>
                        <Grid container direction="column">
                          <Grid item className={classes.mediumHeading}>
                            Orders
                          </Grid>
                          <Grid item className={classes.smallTitle}>
                            <Link
                              component="button"
                              className={classes.redLink}
                              style={{ textDecoration: 'none' }}
                              onClick={() => setOpenOrdersPopup(true)}
                            >
                              View here
                            </Link>

                            <Popup
                              title="Order Details"
                              openPopup={openOrdersPopup}
                              setOpenPopup={setOpenOrdersPopup}
                            >
                              <TicketDetail
                                eventID={eventOccurrence._id}
                                eventDetailID={eventOccurrenceId}
                                eventTitle={eventOccurrence.eventname}
                                dateTime={universaldate(eventOccurrence.eventdetailfile?.eventdatetime)}
                              />
                            </Popup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <Grid item className={classes.borderOutline}>
                    <Grid container spacing={3}>
                      <Grid item md={5} lg={5} xl={5}>
                        <ArrowUpwardOutlinedIcon className={classes.redIcon} />
                      </Grid>
                      <Grid item md={7} lg={7} xl={7}>
                        <Grid container direction="column">
                          <Grid item className={classes.mediumHeading}>
                            Upsells
                          </Grid>
                          <Grid item className={classes.smallTitle}>
                            <Link
                              component="button"
                              className={classes.redLink}
                              style={{ textDecoration: 'none' }}
                              onClick={() => setOpenUpsellsPopup(true)}
                            >
                              View here
                            </Link>

                            <Popup
                              title="Upsell Details"
                              openPopup={openUpsellsPopup}
                              setOpenPopup={setOpenUpsellsPopup}
                            >
                            <UpsellTable 
                              upsellDetails={upsells}
                              inPopup={true}
                            />
                            </Popup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* parent container end */}
            </Grid>

            <Holds isLoading={isLoading} eventOccurrenceId={eventOccurrenceId} />
            
          </>
        ) : <Loading /> }

      </main>

      <Footer />
    </div>
  )
}
