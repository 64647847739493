import * as types from "../../constants";
const initialstate = {
  orderDetails: {
    eventticketdetailid: null,
    email: '',
    order_comments: ''
  },
}

export default function reducer(state = initialstate, actions) {
  switch (actions.type) {
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: {
          email: actions.payload.email,
          eventticketdetailid: actions.payload.eventticketdetailid,
          order_comments: actions.payload.order_comments
        }
      };
    case types.UPDATE_ORDER_EMAIL_SUCCESS:
      return {
        ...state,
        orderDetails: {
          email: actions.payload.email,
          eventticketdetailid: actions.payload.eventticketdetailid
        }
      };
    case types.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...actions.payload.orderDetails,
        }
      };
    default:
      return state;
  }
}